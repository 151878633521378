import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FavoriteButton from "../fav";

export const IsFavWithHeart = (props) => {
  return (
    <button
      className="button like rounded like-effect-click  d-flex align-items-center justify-content-center"
      data-toggle="tooltip"
      title="Like"
    >
      <FavoriteButton
        handleClick={props.onClick}
        isFav={props.isFav}
        btnStyle={(isFav) => {
          return {
            background: isFav
              ? props.favBg
                ? props.favBg
                : "transparent"
              : "transparent",
            width: "2.5rem",
            height: "2.5rem",
          };
        }}
        className={`border-0 rounded-circle d-flex align-items-center justify-content-center p-0 m-0`}
        handleContent={(isFav) => (
            <FontAwesomeIcon
              icon={faHeart}
              className="fa-sharp fa-solid fa-heart m-0 p-0"
              style={{
                color: isFav
                  ? props.favColor
                    ? props.favColor
                    : "red"
                  : props.disabledColor
                  ? props.disabledColor
                  : "white",
              }}
            />
        )}
      />
    </button>
  );
};
