/* eslint-disable react-hooks/exhaustive-deps */
import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { DoctorDetailsHeader } from "./components/doctorDetailsHeader";
import "../../css/main.css";
import "../../css/media.css";
import { DoctorProfileDetails } from "./components/doctorProfileDetails";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { TabsBanner } from "./components/tabsBanner";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { OverallRatingContent } from "./components/stories/overallRatingContent";
import { ReviewItem } from "./components/stories/reviewItem";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Loading } from "../../shared/loadings/Loading";
import { useGetDoctorByIdForPublic } from "../../Store/Doctor/doctorHooks";

export const DoctorDetailsStories = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const params = useParams();
  const getDoctorByIdForPublic = useGetDoctorByIdForPublic();
  useEffect(() => {
    if (params.id) {
      getDoctorByIdForPublic(params.id);
    }
  }, [params.id]);
  return (
    <div>
      <Loading duration={500} />
      <Header />
      <DoctorDetailsHeader />
      <div className="doctor-detailed-wrapper">
        <DoctorProfileDetails solidStar={faStar} />
        <section className="doctor-detailed-section">
          <TabsBanner id={params.id} />
          <OverallRatingContent solidStar={faStar} />
          <div className="container-fluid">
            <ReviewItem solidStar={faStar} />
            <ReviewItem solidStar={faStar} />
            <ReviewItem solidStar={faStar} />
            <ReviewItem solidStar={faStar} />
          </div>
          <div className="button-flex justify-content-center mt-5">
            <a href className="primary__btn5">
              View More Reviews
            </a>
          </div>
        </section>
      </div>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Book your consultation today</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
