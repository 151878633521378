import { ReviewsHeader } from "./components/reviewsHeader";
import { FlaggedReviewsList } from "./components/flaggedReviewsComponents/flaggedReviewsList";
import { reviewTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";

export const FlaggedReviews = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <ReviewsHeader title="My Reviews (19)" />
        <TabsGroup tabs={reviewTabsData()} />
        <FlaggedReviewsList />
      </div>
    </section>
  );
};
