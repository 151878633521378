import { ProfileForm } from "./components/profilePageComponents/profileForm";
import "../../css/main.css";
import "../../css/media.css";
import { useEffect } from "react";
import { currentDoctorId } from "../../Store/selectors/UserSelectors";
import { Loading } from "../../shared/loadings/Loading";
import { useGetAllProcedures } from "../../Store/procedure/procedureHooks";
import { useGetDoctorById } from "../../Store/Doctor/doctorHooks";

export const DashboardProfile = () => {
  const getDoctor = useGetDoctorById();
  const getAllProcedures = useGetAllProcedures();
  useEffect(() => {
    if (currentDoctorId()) getDoctor(currentDoctorId());
  }, [getDoctor]);

  useEffect(() => {
    getAllProcedures();
  }, [getAllProcedures]);
  return (
    <>
      <Loading duration={500} />
      <ProfileForm />
    </>
  );
};
