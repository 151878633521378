import "../../../css/main.css";
import "../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLock } from "@fortawesome/free-solid-svg-icons";
import { EmailRequestFailedModal } from "./emailRequestFailedModal";
import { useState } from "react";
import { ModalPopup } from "../../../shared/Modals/ModalPopup";
import { useMediaQuery } from "@mui/material";

export const ForgetPasswordModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");
  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={showFailedModal ? false : true}
    >
      <div
        className="bg-white m-0 p-0"
        id="forgotPassword"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-content">
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "#e78a82",
                width: "2rem",
                height: "2rem",
              }}
              className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
            >
              <FontAwesomeIcon icon={faClose} className="ms-2" />
            </button>
          </div>
          <div className="modal-body">
            <div className="forgot-password-inner">
              <span className="icon-box--modal">
                <FontAwesomeIcon icon={faLock} />
              </span>
              <h5 className="mt-3">Forgot Password?</h5>
              <p>Enter the e-mail associated with your account</p>
              <input
                type="text"
                className="form-control"
                placeholder="Enter the email address"
              />
              <button
                type="button"
                className="primary__btn3"
                data-bs-toggle="modal"
                data-bs-target="#forgotPassword1"
                onClick={() => setShowFailedModal(true)}
              >
                Submit
              </button>
              <EmailRequestFailedModal
                showModal={showFailedModal}
                setShowModal={setShowFailedModal}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
