export const faqsData = [
  {
    ques: "When Should You Consult A Cosmetologist?",
    ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
  },
  {
    ques: "What Kind Of Services Offered By Cosmetologist In Abu Dhabi?",
    ans: "Aliquam cursus vitae nulla non rhoncus. Nunc condimentum erat nec dictum tempus. Suspendisse aliquam erat hendrerit vehicula vestibulum.",
  },
  {
    ques: "Is Cosmetologist In Abu Dhabi Is Affordable?",
    ans: "Pellentesque aliquam ligula libero, vitae imperdiet diam porta vitae. sed do eiusmod tempor incididunt ut labore et dolore magna.",
  },
  {
    ques: "What Is The Average Cost Of Cosmetologist In Abu Dhabi?",
    ans: "Pellentesque aliquam ligula libero, vitae imperdiet diam porta vitae. sed do eiusmod tempor incididunt ut labore et dolore magna.",
  },
  {
    ques: "Popular Specialities In Abu Dhabi?",
    ans: "Pellentesque aliquam ligula libero, vitae imperdiet diam porta vitae. sed do eiusmod tempor incididunt ut labore et dolore magna.",
  },
  {
    ques: "Best And Popular Doctors In Abu Dhabi?",
    ans: "Pellentesque aliquam ligula libero, vitae imperdiet diam porta vitae. sed do eiusmod tempor incididunt ut labore et dolore magna.",
  },
];
