import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsLike } from "../../../../shared/AddToComps/isLike";
import { ReviewStars } from "../../../../shared/reviewStars";
import { faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { ReviewCommentBox } from "../../../doctor-details/components/reviewCommentBox";
import { ShareDropdown } from "../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { imgBaseUrl } from "src/services/config/config";
import { isDoctor } from "src/Store/selectors/isDoctor";
import { getStep2NestedField } from "src/services/dynamicContentService";

export const UserReviewItem = (props) => {
  //   const [showSubmit, setShowSubmit] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const matches = useMediaQuery("(max-width:575px)");

  const readOrUnreadPath =
    window.location.pathname.includes("new-reviews") ||
    window.location.pathname.includes("unread-reviews") ||
    window.location.pathname.includes("read-reviews");
  const liveOrHidePath =
    window.location.pathname.includes("live-reviews") ||
    window.location.pathname.includes("hide-reviews");

  return (
    <div
      className="story-box pb-3 bg-white"
      key={props.ind + 1}
    >
      <span className={`name-style ${props.ind % 2 === 0 ? "name-red" : "name-blue"}`}>
        {getStep2NestedField(props.data, "patient", "firstName")[0].toUpperCase()}
        {getStep2NestedField(props.data, "patient", "lastName")[0].toUpperCase()}
      </span>
      <div className="story-box-head">
        <div className="story-box-head-left">
          <h4>{getStep2NestedField(props.data, "patient", "firstName") + " " + getStep2NestedField(props.data, "patient", "lastName")}</h4>
        </div>
        <div className="story-box-head-right d-flex align-items-center gap-1 justify-content-center justify-content-md-start">
          <span>{moment(props.data.treatmentDate).format("DD MMM YYYY")}</span>
          <div
            style={{
              width: "2.5rem",
              height: "2.5rem",
            }}
            className="rounded-circle border-0 d-flex align-items-center justify-content-center position-relative"
          >
            <IsLike />
          </div>
          <span>Highly recommend the doctor</span>
        </div>
      </div>
      <div className="rating-box-wrapper">
        <div className="rating-box-stories">
          <ReviewStars value={props.data.overallRating} onChange={() => {}} />
          <span className="number">(1,378)</span>
          <span>
            <FontAwesomeIcon icon={faEye} /> 2,300{" "}
          </span>
        </div>

        <div className="rating-box-wrap-right">
          <span>{getStep2NestedField(props.data, "bodyPart", "name")}</span>
        </div>
      </div>
      <h5>{props.data.reviewTitle}</h5>
      {props.data.photosData && props.data.photosData.length > 0 && (
        <ul className="img-list-popup d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap flex-sm-nowrap">
          {props.data.photosData.map((item, i) => (
            <li>
              <img
                src={`${imgBaseUrl + item.img}`}
                className="img-fluid"
                style={{
                  width: "8rem",
                  minWidth: "8rem",
                  height: "6rem",
                  objectFit: "cover",
                }}
                alt=""
              />
            </li>
          ))}
        </ul>
      )}
      <p className="fw-bold">{getStep2NestedField(props.data, "treatment", "name")}</p>
      <p>{props.data.experienceDescription}</p>
      <div className="story-types">
        <h6 style={{ whiteSpace: "nowrap" }} className="fs-5">
          Satisfied with:
        </h6>
        <ul>
          {props.data.howGoodWasDoctor.map((d, i) => (
            <li key={i + 1}>
              <a href className="fs-6">
                {d}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {readOrUnreadPath && isDoctor() && (
        <div className="comment-features mt-0 mb-0 pb-0">
          <button
            onClick={() => props.handleUpdate(props.data)}
            className="primary__btn3 px-4 py-2 mt-0 mb-0"
          >
            {props.data.read ? "Mark as unread" : "Mark as read"}
          </button>
        </div>
      )}
      {/* <div className="row w-100 mt-4">
        <div className="col-lg-2">
          <span>
            <img src={img1} className="img-fluid" alt="" />
          </span>
        </div>
        <div className="col-lg-7">
          <div className="doctor-detail-second w-100">
            <h5 className="rose-text fs-6">
              Dr. Lauren Clinton,{" "}
              <span className="text-secondary">Answered on 12 Sep 2020 </span>
            </h5>
            <p>
              Thank you, Lorem ipsum dolor sit amet, consectetur adipiscing
              elit,
            </p>
          </div>
        </div>
        <div
          className="col-lg-3 d-flex justify-content-end align-items-start"
          style={{ marginTop: "-20px" }}>
          <a
            href
            style={{ fontSize: "17px" }}
            className="primary__btn py-2 px-4 disabled"
            data-toggle="tooltip"
            title="After commenting only, you are able to proceed for live!">
            Edit
          </a>
        </div>
      </div> */}

      {/* {props.data.status !== "Hide" && props.ind === 0 && (
        <div className="comment-textarea position-relative px-1">
          <div
            className={`position-relative bg-white pb-0 ${
              showCommentBox ? "pt-4" : ""
            }`}
            style={{ zIndex: "10" }}>
            <textarea
              name=""
              id=""
              className={`form-control`}
              style={{
                height: "4rem",
                transitionDuration: "500ms",
              }}
              rows="2"
              placeholder="Add a Comment"
              defaultValue="This is a Comment"
              onClick={() => setShowSubmit(!showSubmit)}></textarea>
          </div>
          <div
            className={`position-relative w-100 overflow-hidden`}
            style={{
              marginTop: showSubmit ? "0.75rem" : "-3.5rem",
              transitionDuration: "500ms",
              height: "3rem",
              zIndex: "0",
            }}>
            <button
              className={`submit-comment-btn rose-bg rounded-pill text-white fs-5 d-flex align-items-center justify-content-center position-absolute border-0 ${
                showSubmit ? "end-0" : "end-100"
              } `}
              style={{
                transitionDuration: "500ms",
                width: "10rem",
                height: "3rem",
                opacity: showSubmit ? "1" : "0",
                zIndex: "0",
              }}>
              Submit
            </button>
          </div>
        </div>
      )} */}

      <div
        className={`comment-area-wrap-div position-relative py-0`}
        style={{
          transitionDuration: "500ms",
          zIndex: "20",
          top: showCommentBox ? "0" : "0",
        }}
      >
        <div className=" w-100 bg-white py-0 d-flex align-items-center flex-wrap justify-content-between gap-4">
          {props.data.status === "Live" && liveOrHidePath ? (
            <div className="d-flex align-items-center gap-3 flex-wrap flex-sm-nowrap">
              <div className="d-flex align-items-center gx-2">
                <IsLike smileIcon={true} />
                <span className="fs-6 text-secondary">340 likes</span>
              </div>

              {!showCommentBox && (
                <span
                  className="comment-onclick fs-6 text-secondary"
                  onClick={() => {
                    setShowCommentBox(!showCommentBox);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faComment}
                    className="rose-text"
                    style={{ fontSize: "20px" }}
                  />
                  240 Comments
                </span>
              )}
            </div>
          ) : (
            <div></div>
          )}
          {isDoctor() && liveOrHidePath && (
            <div className="py-0 share-box-right share-box-flex text-left d-flex align-items-center">
              <button
                onClick={() => props.handleUpdate(props.data)}
                style={{
                  background: "black",
                  whiteSpace: "nowrap",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
                className="primary__btn text-white px-4 fs-6 active rounded-pill"
              >
                {props.data.status === "Hide" ? "Go Live" : "Hide from Live"}
              </button>

              <div className="mx-1 mx-sm-2 position-relative">
                <button
                  onClick={() => setShowShareDropdown(!showShareDropdown)}
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    background: showShareDropdown ? "#e78a82" : "transparent",
                  }}
                  className="rounded-circle d-flex align-items-center justify-content-center border-0"
                >
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      color: "rgb(161 161 170)",
                      fontSize: "22px",
                    }}
                  />
                </button>
                {showShareDropdown && (
                  <ShareDropdown
                    position={`mt-3 ${
                      matches ? "start-0 translate-middle mt-5" : "end-0"
                    }`}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {showCommentBox && (
        <ReviewCommentBox setShowCommentBox={setShowCommentBox} />
      )}
    </div>
  );
};
