import { useEffect } from "react";
import { favoruitesTabs } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { FavDoctorsCards } from "./components/favDoctorsComponents/favDoctorsCards";
import { FavHeader } from "./components/favHeader";
import { useGetFavDoctorsByPatient } from "../../../Store/favoruiteDoctor/favoruiteDoctorHooks";
import { Loading } from "../../../shared/loadings/Loading";

export const FavDoctors = () => {
  const getFavDoctors = useGetFavDoctorsByPatient();
  useEffect(() => {
    getFavDoctors();
  }, [getFavDoctors]);
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <FavHeader title="Doctors (19)" />
        <TabsGroup tabs={favoruitesTabs} />
        <FavDoctorsCards />
      </div>
    </section>
  );
};
