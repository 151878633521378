import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/smile.png";
import img2 from "../../../images/like.png";
import img3 from "../../../images/heart.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

export const Banner4 = () => {
  return (
    <div className="experience-sharing-block">
      <div className="row">
        <div className="col-lg-4">
          <h4>Share your Experience</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur ullarper. Sed pretium ipsum
            elit, sit amet auctor massa m
          </p>
        </div>
        <div className="col-lg-4">
          <div className="rating">
            <div className="">
              <FontAwesomeIcon icon={faStar} />
            </div>
            <div className="">
              <FontAwesomeIcon icon={faStar} />
            </div>
            <div className="">
              <FontAwesomeIcon icon={faStar} />
            </div>
            <div className="">
              <FontAwesomeIcon icon={faStarHalfAlt} />
            </div>
            <div className="">
              <FontAwesomeIcon icon={faStarHalfAlt} />
            </div>
          </div>
          <div className="rating-symbols">
            <span>
              <img src={img1} className="img-fluid" alt="" />
            </span>
            <span>
              <img src={img2} className="img-fluid" alt="" />
            </span>
            <span>
              <img src={img3} className="img-fluid" alt="" />
            </span>
          </div>
        </div>
        <div className="col-lg-4">
          <a href className="primary__btn" style={{cursor: "pointer"}}>
            Write a Review
          </a>
        </div>
      </div>
    </div>
  );
};
