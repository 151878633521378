import { SettingsHeader } from "./components/settingsHeader";
import { SecurityQuestionsContent } from "./components/securityQuestionsComponents/securityQuestionsContent";
import { TabsGroup } from "../../../shared/tabsGroup";
import { settingsTabsData } from "../../../demoData/tabsData";

export const SecurityQuestions = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SettingsHeader title="Security Questions" />
        <TabsGroup tabs={settingsTabsData()} />
        <SecurityQuestionsContent />
      </div>
    </section>
  );
};
