import { PATIENT_PROFILE_FORM_DATA } from "./actionTypes";

const initialData = {
  formData: {
    title: "Mr"
  },
};
export function PatientReducer(state = initialData, action) {
  switch (action.type) {
    case PATIENT_PROFILE_FORM_DATA: {
      const data = action.payload;
      return {
        ...state,
        formData: data,
      };
    }
    default:
      return state;
  }
}
