import { useAppAction, useAppSelector } from "../../services/hooks/hooks";
import { getAllDoctors, getDoctorById, getDoctorByIdForPublic, getSearchedDoctors, setDoctorProfileFormData, updateDoctor } from "./doctorAction";

export const useUpdateDoctor = () => useAppAction(updateDoctor);
export const useGetAllDoctors = () => useAppAction(getAllDoctors);
export const useGetDoctorByIdForPublic = () => useAppAction(getDoctorByIdForPublic);
export const useSetDoctorProfileFormData = () => useAppAction(setDoctorProfileFormData);
export const useGetSearchedDoctors = () => useAppAction(getSearchedDoctors);
export const useDoctor = () => useAppSelector((state) => state.doctor);
export const useGetDoctorById = () =>
  useAppAction(getDoctorById);
