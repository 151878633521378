import { WalletTabs } from "./components/walletTabs";
import { WalletHeader } from "./components/walletHeader";
import { MyOrdersList } from "./components/myOrdersComponents/myOrdersList";
import { useEffect, useState } from "react";
import { getOrdersByDoctor } from "src/Store/order/orderAction";
import { Loading } from "src/shared/loadings/Loading";

export const WalletLocation = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res1 = await getOrdersByDoctor("Completed");
    let res2 = await getOrdersByDoctor("Pending");
    setList([...res1, ...res2]);
  };
  return (
    <section class="doctor-detail-stories-tab">
       <Loading duration={500} />
      <div class="container-fluid p-0">
        <WalletHeader title="Locate Facility" />
        <WalletTabs />
        <MyOrdersList data={list} />
      </div>
    </section>
  );
};
