import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";
import { currentPatientId } from "../selectors/UserSelectors";
import { getAPIResponseData, setItemQuantity } from "../../services/generalService";
import { CART_BY_PATIENT } from "./actionTypes";
export const handleCart = (data) => {
  return async (dispatch) => {
    let res = await thisCart();
    if (res && res.length > 0) {
      let obj = {
        id: res[0].id,
        offers:
          res[0].offers
            ?  setItemQuantity(data.offers[0], res[0].offers)
            : data.offers,
      };
      dispatch(updateCart(obj));
    } else {
      dispatch(createCart(data));
    }
  };
};

export const createCart = (data) => {
  return (dispatch) => {
    axiosInstance
      .post(`/carts`, { data: data })
      .then(() => {
        toast.success("Item added to your cart successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const updateCart = (data) => {
  return (dispatch) => {
    axiosInstance
      .put(`/carts/${data.id}`, { data: data })
      .then(() => {
        toast.success("Item added to your cart successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};


export const updateCartItems = (data, msg) => {
  return (dispatch) => {
    axiosInstance
      .put(`/carts/${data.id}`, { data: data })
      .then(() => {
        if(msg){
          toast.success(msg);
        }
        dispatch(getCartByPatient())
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};


export const thisCart = async () => {
  let res = await axiosInstance.get(
    `/carts?filters[patient][id][$eq]=${currentPatientId()}`
  );
  return getAPIResponseData(res.data.data);
};
export const setCartData = (data) => {
  return {
    type: CART_BY_PATIENT,
    payload: data,
  };
};

export const getCartByPatient = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/carts?filters[patient][id][$eq]=${currentPatientId()}`)
      .then((response) => {
        let res = getAPIResponseData(response.data.data);
        dispatch(setCartData(res[0]));
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};


export const emptyCart = (id) => {
  return (dispatch) => {
    axiosInstance
      .delete(`/carts/${id}`)
      .then((response) => {
         dispatch(getCartByPatient());
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}