import { setLoading } from "../loading/loadingAction";
import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { currentPatientId } from "../selectors/UserSelectors";
import { getAPIResponseData } from "../../services/generalService";
import { FAV_DOCTORS_BY_PATIENT } from "./actionTypes";
import OutHook from "../../services/navigation";

export const createFavDoctor = (data) => {
  return (dispatch) => {
    if (currentPatientId()) {
      axiosInstance
        .post(`/favoruite-doctors`, { data: data })
        .then(() => {
          toast.success("Doctor added to your Favoruites!");
          dispatch(setLoading(false));
          dispatch(getFavDoctorsByPatient());
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    } else {
      alert("Please first Login as a patient...");
      OutHook.navigateTo("/sign-in");
    }
  };
};

export const setAllFavDoctorsData = (data) => {
  return {
    type: FAV_DOCTORS_BY_PATIENT,
    payload: data,
  };
};
export const getFavDoctorsByPatient = () => {
  return (dispatch) => {
    if (currentPatientId()) {
      axiosInstance
        .get(
          `/favoruite-doctors?filters[patient][id][$eq]=${currentPatientId()}&populate=*`
        )
        .then((response) => {
          dispatch(
            setAllFavDoctorsData(getAPIResponseData(response.data.data))
          );
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    }
  };
};

export const deleteFavDoctorById = (id) => {
  return (dispatch) => {
    if (currentPatientId()) {
      axiosInstance
        .delete(`/favoruite-doctors/${id}`)
        .then(() => {
          toast.success("Doctor removed to your Favoruites!");
          dispatch(setLoading(false));
          dispatch(getFavDoctorsByPatient());
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    } else {
      alert("Please first Login as a patient...");
      OutHook.navigateTo("/sign-in");
    }
  };
};
