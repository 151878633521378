import { useEffect, useState } from "react";
import { EmptyWallet } from "src/shared/emptyListPages/emptyWallet";
import { useCart } from "../../../../../Store/cart/cartHook";
import { CartTableItem } from "./cartTableItem";
import { useNavigate } from "react-router-dom";
import notiImg from "../../../../../images/emptyImg3.png";

export const CartTable = () => {
  const cart = useCart();
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let a = cart.item;
    if (a && a.offers && a.offers.length > 0) {
      setCartItems(a.offers);
    } else {
      setCartItems([]);
    }
  }, [cart.item]);
  return cartItems && cartItems.length > 0 ? (
    <div className="tab_container articles-container px-0">
      <div id="tab1" className="tab_content">
        <table className="table table-style-2 wallet-cart w-100" id="example">
          <thead>
            <tr>
              <th style={{ width: "320px" }}> Product</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((d, ind) => (
              <CartTableItem item={d} ind={ind} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <EmptyWallet
      image={notiImg}
      title="Your cart is empty"
      showButton={true}
      desc={[
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse",
        <br />,
        "feugiat mi venenatis, pulvinar",
      ]}
      buttonText="Explore Offers"
      onClickButton={(e) => {
        e.preventDefault();
        navigate("/special-offers");
      }}
    />
  );
};
