import "../../../../css/main.css";
import "../../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { AddToFav } from "../../../../shared/AddToComps/addToFav";
import { imgBaseUrl } from "../../../../services/config/config";
import { useOfferState } from "../../../../Store/offer/offerHooks";
import {
  useCreateFavOffer,
  useDeleteFavOfferById,
  useFavOffer,
} from "../../../../Store/favoruiteOffer/favoruiteOfferHooks";
import { currentPatientId } from "../../../../Store/selectors/UserSelectors";

export const OffersListing = (props) => {
  const offerState = useOfferState();
  const createFavOffer = useCreateFavOffer();
  const removeFavOffer = useDeleteFavOfferById();
  const useFavOfferState = useFavOffer();

  const isFavOffer = (id) => {
    let res = useFavOfferState.list.find(
      (d) => d.offer && d.offer.data && d.offer.data.id === id
    );
    if (res) return res.id;
  };

  const handleFavFunc = (id) => {
    if (isFavOffer(id)) {
      removeFavOffer(isFavOffer(id));
    } else {
      createFavOffer({
        patient: currentPatientId(),
        offer: id,
      });
    }
  };

  return (
    <div className="detail-offers-list">
      <div className="row">
        {offerState.list.map((data, ind) => (
          <div className="col-lg-3 col-md-6 overflow-hidden" key={ind + 1}>
            <div className="detailed-offers-block overflow-hidden">
              <div className="offer-img-box">
                <img
                  src={`${imgBaseUrl + data.image}`}
                  className="img-fluid"
                  style={{ height: "400px" }}
                  alt=""
                />
                <span className="expired-on">Expired on 22-2-2022</span>
                <button
                  className="position-absolute start-0 top-0 rounded-circle mt-2 ms-2 d-flex align-items-center justify-content-center border-0"
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    background: "black",
                  }}
                >
                  <AddToFav
                    size="2.8rem"
                    isFav={isFavOffer(data.id)}
                    onClick={() => handleFavFunc(data.id)}
                  />
                </button>
              </div>
              <div className="detailed-offers-inner pb-4">
                <h5>{data.title}</h5>
                <div className="rating">
                  <div className="">
                    <FontAwesomeIcon icon={props.solidStar} />
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={props.solidStar} />
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={props.solidStar} />
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <span>(1378)</span>
                </div>
                <p className="two-lines-elipsis">{data.description}</p>
                <div className="pricing-box">
                  <h5 className="strike-through">
                    <s>AED {data.actualAmount}</s>AED {data.discountedAmount}
                  </h5>
                  <h6>{data.discountPercentage}%</h6>
                </div>
                <a href className="primary__btn my-0">
                  Add to Wallet
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
