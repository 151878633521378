import "../../../../../css/main.css";
import img2 from "../../../../../images/img2.jpg";
export const NewNotificationsList = () => {
  return (
    <div className="tab_container overflow-hidden">
      <div id="tab1" className="tab_content">
        <div className="stories-list">
          <div className="story-box">
            <span className="name-style name-red">RF</span>
            <div className="story-box-head">
              <h4>Roy Ford</h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review gap-3">
                  12 Sep 2022 | 30 min ago
                  <span
                    className="primary__btn4 px-3 pt-0"
                    style={{ height: "1.8rem" }}
                  >
                    Reviews
                  </span>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Posted New Review</p>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>

          <div className="story-box">
            <span className="name-style name-blue">CG</span>
            <div className="story-box-head">
              <h4>Carolyin Garrett</h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago
                  <span
                    className="primary__btn4 px-3 pt-0"
                    style={{ height: "1.8rem" }}
                  >
                    Reviews
                  </span>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Commented on your answer</p>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>

          <div className="story-box">
            <span className="name-style name-blue">PV</span>
            <div className="story-box-head">
              <h4>Patrica Valdelz</h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago
                  <span
                    className="primary__btn4 px-3 pt-0"
                    style={{ height: "1.8rem" }}
                  >
                    Reviews
                  </span>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Appoinment Booked</p>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>

          <div className="story-box">
            <span className="name-style img-inner">
              <img src={img2} className="img-fluid" alt="" />
            </span>
            <div className="story-box-head">
              <h4>Botox for Chin</h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago
                  <span
                    className="primary__btn4 px-3 pt-0"
                    style={{ height: "1.8rem" }}
                  >
                    Reviews
                  </span>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Purchased Offer</p>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
