import { Footer } from "../../../shared/footer/footer";
import { Header } from "../../../shared/header";
import { HeaderSlider } from "../components/headerSlider";
import "../../../css/main.css";
import "../../../css/media.css";
import { TabsBanner } from "../components/tabsBanner";
import { RequestCallback } from "../../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { HeadingComp } from "./components/headingComp";
import { DetailsContent } from "./components/detailsContent";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export const BeforeAfterDetail = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const [showFaceModal, setShowFaceModal] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowFaceModal(false);
      setShowFiltersModal(false);
    });
  }, []);
  useEffect(() => {
    if (showFaceModal || showFiltersModal) {
      document.scrollingElement.style.overflow = "hidden";
    } else {
      document.scrollingElement.style.overflow = "auto";
    }
  }, [showFaceModal, showFiltersModal]);
  return (
    <div>
      <Header />
      <HeaderSlider />
      <section className="before-after-listing-page-section before-after-detail-section">
        <TabsBanner activeTab="Photos" />
        <div className="container-fluid">
          <div className="pt-5 pt-md-0">
            <div id="tab1" className="">
              <section className="before-after-blocks">
                <div className="tab-head-block py-5">
                  <HeadingComp
                    heading="Botox Reviews (540)"
                    backPath="/before-after-face-detail"
                  />
                </div>
                <DetailsContent solidStar={faStar} />
              </section>
            </div>
          </div>
        </div>
      </section>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Book your consultation today</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
