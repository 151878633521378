import {
  BEFOR_AFTER_BY_DOCTOR,
  BEFOR_AFTER_BY_DOCTOR_AND_STATUS,
  ALL_BEFORE_AFTERS,
  BEFORE_AFTERS_BY_BODY_PART,
} from "./actionTypes";

const initialData = {
  list: [],
  listBYStatus: [],
  listBYStatusAndDoctor: [],
  fullList: [],
};
export function BeforeAfterReducer(state = initialData, action) {
  switch (action.type) {
    case BEFOR_AFTER_BY_DOCTOR: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    case BEFOR_AFTER_BY_DOCTOR_AND_STATUS: {
      const data = action.payload;
      return {
        ...state,
        listBYStatusAndDoctor: data,
      };
    }
    case ALL_BEFORE_AFTERS: {
      const data = action.payload;
      return {
        ...state,
        fullList: data,
      };
    }
    case BEFORE_AFTERS_BY_BODY_PART: {
      const data = action.payload;
      return {
        ...state,
        listBYStatus: data,
      };
    }
    default:
      return state;
  }
}
