export const FlagDropdown = (props) => {
  return (
    <div
      className={`bg-white rounded-3 d-flex align-items-center gap-3 px-2 py-3 overflow-hidden position-absolute top-100 ${props.position}`}
      style={{
        zIndex: "30",
        background: "rgba(231, 138, 130, 0.26)",
        boxShadow: "1px 1px 6px 1px #00000024",
      }}
    >
      <ul className="flex flex-col align-items-start gap-1">
        <li className="fs-6 py-1 px-3 rounded-2 text-left flagDropdown-item">Flag This Review</li>
        <li className="fs-6 py-1 px-3 rounded-2 text-left flagDropdown-item">Irrelevant</li>
        <li className="fs-6 py-1 px-3 rounded-2 text-left flagDropdown-item">Fake</li>
        <li className="fs-6 py-1 px-3 rounded-2 text-left flagDropdown-item">Junk</li>
      </ul>
    </div>
  );
};
