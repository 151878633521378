import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { searchOptions } from "../../../../demoData/selectOptionsData";
import { DoneModal } from "./doneModal";
import { useState } from "react";

export const ProfileForm = () => {
  const [showModal, setShowModal] = useState(false)
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="tab_container articles-container overflow-hidden px-0">
      <div id="tab1" className="tab_content px-0">
        <div className="form-card">
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Healthcare Facility Name</label>
                <input type="text" className="form-control" />
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>Cosmetic Medical Facility</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Upload Facility Logo</label>
                <div
                  className="file-upload-wrapper"
                  data-text="Select your file!"
                >
                  <input
                    name="file-upload-field form-control"
                    type="file"
                    className="file-upload-field"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>Maximum Size 2mb &nbsp; &nbsp;PDF/Image</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Trade License Number</label>
                <input type="text" className="form-control" />
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>1578</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">
                  Upload Trade License Documents
                </label>
                <div
                  className="file-upload-wrapper"
                  data-text="Select your file!"
                >
                  <input
                    name="file-upload-field form-control"
                    type="file"
                    className="file-upload-field"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>Maximum Size 2mb &nbsp; &nbsp;PDF/Image</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Medical License Number</label>
                <input type="text" className="form-control" />
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>1578</span>
              </div>
            </div>
          </div>

          <div className="form-group add-video-link">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">
                  Upload Medical License Documents
                </label>
                <div
                  className="file-upload-wrapper"
                  data-text="Select your file!"
                >
                  <input
                    name="file-upload-field form-control"
                    type="file"
                    className="file-upload-field"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>Https://www.video.com/ygjkusgk</span>
              </div>
            </div>
          </div>

          <div className="form-group form-group-select">
            <div className="row">
              <div className="col-lg-9">
                <div className="row gy-4">
                  <div className="col-lg-3">
                    <label className="fieldlabels">Country</label>
                    <Select
                      options={searchOptions}
                      getOptionLabel={getOptionLabel}
                      defaultValue={searchOptions[0]}
                      isSearchable={true}
                      className="offers-box-select" // Add a custom class name for styling
                      classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="fieldlabels">State</label>
                    <Select
                      options={searchOptions}
                      getOptionLabel={getOptionLabel}
                      defaultValue={searchOptions[0]}
                      isSearchable={true}
                      className="offers-box-select" // Add a custom class name for styling
                      classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="fieldlabels">District</label>
                    <Select
                      options={searchOptions}
                      getOptionLabel={getOptionLabel}
                      defaultValue={searchOptions[0]}
                      isSearchable={true}
                      className="offers-box-select" // Add a custom class name for styling
                      classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="fieldlabels">City</label>
                    <Select
                      options={searchOptions}
                      getOptionLabel={getOptionLabel}
                      defaultValue={searchOptions[0]}
                      isSearchable={true}
                      className="offers-box-select" // Add a custom class name for styling
                      classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group tags">
            <div className="row">
              <div className="col-lg-9">
                <label className="maplink">Google Map Link</label>
                <input
                  type="text"
                  name="maplink"
                  className="form-control"
                  placeholder="Add healthcare Facility location link"
                />
              </div>
              <div className="col-lg-3 m-auto" style={{fontSize: "13px"}}>
                <span>https://goo.gl/maps/b1c2</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9 text-end">
                <button
                  type="button"
                  className="primary__btn3 submit-action-button"
                  onClick={() => setShowModal(true)}
                >
                  Submit
                </button>
                <DoneModal showModal={showModal} setShowModal={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
