import Chart from "react-apexcharts";

const state = {
  series: [
    {
      name: "series1",
      data: [0, 500, 300, 900],
    },
  ],
  options: {
    plotOptions: {
      bar: {
        borderRadius: 0,
      },
    },
    colors: ["#FF1259"],
    title: {
      text: "",
      style: {
        fontSize: "18px",
        fontWeight: "normal",
      },
    },
    grid: {
      show: false,
    },
    chart: {
      height: 400,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 4,
    },

    xaxis: {
      type: "string",
      categories: ["Lower Approx", "", "", "High Approx"],
    },
    yaxis: {
      type: "string",
      categories: ["0", "100", "200", "300", "400", "500", "600", "700", "800", "900", "1000"],
      title: {
        text: "",
        style: {
          color: "#787A7B",
          fontSize: "10px",
          fontWeight: "normal",
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },

    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};

export const LineChart = (props) => {
  return (
    <div className="w-full">
      <Chart
        options={state.options}
        series={state.series}
        type="line"
        height={props.height}
      />
    </div>
  );
};
