import { useNavigate } from "react-router-dom";
import "../css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const TabsGroup = (props) => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <ul
      className="tabs tab-common-dashboard px-4 pt-4 gap-4 w-100"
      style={{ overflowY: "hidden", overflowX: "auto", whiteSpace: "nowrap" }}
    >
      {props.tabs.map((tab, ind) => (
        <li
          className={`px-0 pt-1 me-2 ${
            isActive(tab.subPath ? tab.subPath : tab.path) ? "active" : ""
          }`}
          key={ind + 1}
        >
          <a
            className="px-0 pt-0 pb-1"
            href={tab.path}
            onClick={(e) => {
              e.preventDefault();
              navigate(tab.path);
            }}
          >
            {tab.title}
            {tab.showPlus && (
              <span
                onClick={props.handlePlusClick && props.handlePlusClick}
                className="delete-icon-box ms-2"
              >
                <FontAwesomeIcon icon={faPlus} />
              </span>
            )}
            {tab.sub && <span>{tab.sub}</span>}
          </a>
        </li>
      ))}
    </ul>
  );
};
