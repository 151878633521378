import { getAPIResponseData } from "./generalService";

export const doctorProfileFormInitialData = {
  profileImg: "",
  title: "",
  firstName: "",
  lastName: "",
  educations: [],
  regNo: "",
  regDocumemt: "",
  healthcareFacilityName: "",
  country: {},
  state: {},
  district: {},
  city: {},
  designation: "",
  professionalExperiences: [],
  totalExperienceYears: null,
  departments: [],
  specialties: [],
  procedures: [],
  workingDays: [],
  languages: [],
  shortBio: "",
  awards: [],
  membership: [],
  publications: [],
};


export const setSelectValueArray = (arr) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({ value: d, label: d });
    });
  }
  return res;
};

export const setSelectValueArrayfromNestedOjects = (arr) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({ value: d.id, label: d.name });
    });
  }
  return res;
};
export const makeDoctorFormDataToShow = (data) => {
  const finalData = {
    ...data,
    gender: { value: data.gender, label: data.gender },
    country: { value: data.country, label: data.country },
    state: { value: data.state, label: data.state },
    district: { value: data.district, label: data.district },
    city: { value: data.city, label: data.city },
    departments: setSelectValueArray(data.departments),
    specialties: setSelectValueArray(data.specialties),
    procedures: data.procedures && data.procedures.data ? setSelectValueArrayfromNestedOjects(getAPIResponseData(data.procedures.data)) : [],
    workingDays: setSelectValueArray(data.workingDays),
    languages: setSelectValueArray(data.languages),
  };
  return finalData;
};