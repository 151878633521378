import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/card.png";

export const Banner8 = () => {
  return (
    <div className="find-doctor">
      <div className="row">
        <div className="col-lg-5">
          <h4>Mivana Support</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur ullarper. Sed pretium ipsum
            elit, sit amet auctor massa m auctor massa m
          </p>
        </div>
        <div className="col-lg-3 mt-auto text-center">
          <img src={img1} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-4 m-auto">
          <a href className="primary_btn">
            Finance Your Procedure{" "}
          </a>
        </div>
      </div>
    </div>
  );
};
