import { useMediaQuery } from "@mui/material";
import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { RequestCallback } from "../../shared/requestCallback";
import { Faqs } from "../Find_A_Doctor/components/faqs";
import { Banner1 } from "./components/banner1";
import { Banner2 } from "./components/banner2";
import { Banner3 } from "./components/banner3";
import { Banner4 } from "./components/banner4";
import { Banner5 } from "./components/banner5";
import { Banner6 } from "./components/banner6";
import { Banner7 } from "./components/banner7";
import { Banner8 } from "./components/banner8";
import { BotoxAlternativesCarousel } from "./components/botoxAlternativesCarousel";
import { BotoxInjectionHeader } from "./components/botoxInjectionHeader";
import { BotoxStoriesCarousel } from "./components/botoxStoriesCarousel";
import { BotoxTreatmentsCarousel } from "./components/botoxTreatmentsCarousel";

export const BotoxInjections = () => {
  const matches = useMediaQuery("(max-width:992px)");
  return (
    <div>
      <Header />
      <BotoxInjectionHeader />
      <section className="accordion-section common-pad">
        <div className="container-fluid">
          <Faqs />
          <Banner1 />
          <Faqs />
          <Banner2 />
          <Faqs />
        </div>
        <div style={{ marginTop: matches ? "-3rem" : "" }}>
          <RequestCallback />
        </div>
        <div className="container-fluid py-0">
          <div style={{ marginTop: matches ? "-6rem" : "-6rem" }}>
            <Faqs />
          </div>
          <Banner3 />
          <Faqs />
          <Banner4 />
          <Faqs />
          <Banner5 />
          <Faqs />
          <Banner6 />
          <Faqs />
          <BotoxAlternativesCarousel />
          <Banner7 />
          <BotoxTreatmentsCarousel />
          <Banner8 />
          <BotoxStoriesCarousel />
        </div>
      </section>
      <Footer />
    </div>
  );
};
