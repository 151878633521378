import "../../../../../css/main.css";

export const OpenTickesTable = () => {
  return (
    <div className="tab_container articles-container">
      <div id="tab1" className="tab_content">
        <div className="overflow-scroll-table">
          <table className="table table-style-2 w-100 overflow-auto">
            <thead>
              <tr>
                <th>SN</th>
                <th>Category</th>
                <th>Description</th>
                <th>Raised on</th>
                <th>Ticket #</th>
                <th>Agent</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody style={{whiteSpace: "nowrap", fontSize: "14px"}}>
              <tr>
                <td>1</td>
                <td>Botox Hair (Offer)</td>
                <td>Not able to add to wallet</td>
                <td>12 Sep 2022 | 2 days ago</td>
                <td>#57492</td>
                <td>Megan Hart</td>
                <td>Open</td>
              </tr>

              <tr className="bg-light-grey">
                <td>2</td>
                <td>Botox Hair (Offer)</td>
                <td>Not able to add to wallet</td>
                <td>12 Sep 2022 | 2 days ago</td>
                <td>#57492</td>
                <td>Megan Hart</td>
                <td>Queued</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Botox Hair (Offer)</td>
                <td>Not able to add to wallet</td>
                <td>12 Sep 2022 | 2 days ago</td>
                <td>#57492</td>
                <td>Megan Hart</td>
                <td>Open</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Botox Hair (Offer)</td>
                <td>Not able to add to wallet</td>
                <td>12 Sep 2022 | 2 days ago</td>
                <td>#57492</td>
                <td>Megan Hart</td>
                <td>Open</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Botox Hair (Offer)</td>
                <td>Not able to add to wallet</td>
                <td>12 Sep 2022 | 2 days ago</td>
                <td>#57492</td>
                <td>Megan Hart</td>
                <td>Open</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
