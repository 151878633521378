/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { DoctorItem } from "./subcomponents/doctorItem";
import { useDoctor } from "../../../Store/Doctor/doctorHooks";
import { NoDataComp } from "../../../shared/emptyComponents/noDataComp";

export const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const doctor = useDoctor();
  useEffect(() => {
    if (doctor.list) {
      let arr = [];
      doctor.list.forEach((data, ind) => {
        arr.push(
          <div className="doctors-listing-box" key={ind + 1}>
            <DoctorItem doctor={data} />
          </div>
        );
      });

      Promise.all(arr).then((res) => {
        console.log(res);
        setDoctors(res);
      });
    }
  }, [doctor.list]);

  return (
    <div>
      {doctors && doctors.length > 0 ? (
        doctors
      ) : (
        <NoDataComp
          title="No Data, Search again!"
          desc="Data related to your search does not exist."
          className="mt-5"
        />
      )}
    </div>
  );
};
