import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import { OffersFiltrBox } from "./components/offersFiltrBox";
import { OffersList } from "./components/offersList";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { RequestCallback } from "../../shared/requestCallback";
import { useEffect } from "react";
import { useGetAllOffers, useOfferState } from "../../Store/offer/offerHooks";
import { useGetFavOffersByPatient } from "../../Store/favoruiteOffer/favoruiteOfferHooks";

export const SpecialOffers = () => {
  const offerState = useOfferState();
  const getAllOffers = useGetAllOffers()
  const getFavOffersByPatient = useGetFavOffersByPatient();
  useEffect(() => {
    getAllOffers();
    getFavOffersByPatient();
  }, []);
  return (
    <div>
      <Header />
      <section className="special-offers-page">
        <div className="button-flex justify-content-between badge-heading common-pad">
          <h4 className="">Offers (40)</h4>
          <p>consectetur adipiscing elit. Integer vulputate vehic</p>
        </div>
        <div className="container-fluid">
          <div id="tab7" className="py-5">
            <OffersFiltrBox />
            <OffersList starIcon={faStar} offersData={offerState.fullList} />
          </div>
        </div>
        <RequestCallback />
      </section>
      <Footer />
    </div>
  );
};
