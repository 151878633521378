import { useMediaQuery } from "@mui/material";
import "../../../../../css/main.css";
import { MySwitch } from "../../../../../demoData/switch";

export const EmailContent = () => {
  const match = useMediaQuery("(max-width:766px)");

  return (
    <div
      className="tab-pane fade show active"
      id="home"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
      <h4>E-mail Settings</h4>
      <p>
        You are currently receiving all promotional communications on
        <a href className="color-4" mailto="carolyn@gmail.com">
          carolyn@gmail.com
        </a>
      </p>
      <div className="form-check form-switch">
        <MySwitch />
        <label
          className="form-check-label"
          style={{ fontSize: match ? "18px" : "" }}
          for="switch1"
        >
          I want to receive:
          <span style={{fontSize: match ? "14px": ""}}>You can disable these at any time</span>
        </label>
      </div>
      <div className="form-check form-switch">
        <MySwitch />
        <label
          className="form-check-label"
          style={{ fontSize: match ? "18px" : "" }}
          for="switch2"
        >
          Announcement
          <span style={{fontSize: match ? "14px": ""}}>
            Most important updates on new and exciting products. Sent around
            once in a month.
          </span>
        </label>
      </div>
      <div className="form-check form-switch">
        <MySwitch />
        <label
          className="form-check-label"
          style={{ fontSize: match ? "18px" : "" }}
          for="switch3"
        >
          I want to receive:
          <span style={{fontSize: match ? "14px": ""}}>You can disable these at any time</span>
        </label>
      </div>
      <div className="form-check form-switch">
        <MySwitch />
        <label
          className="form-check-label"
          style={{ fontSize: match ? "18px" : "" }}
          for="switch4"
        >
          I want to receive:
          <span style={{fontSize: match ? "14px": ""}}>You can disable these at any time</span>
        </label>
      </div>
      <div className="form-check form-switch">
        <MySwitch />
        <label
          className="form-check-label"
          style={{ fontSize: match ? "18px" : "" }}
          for="switch5"
        >
          I want to receive:
          <span style={{fontSize: match ? "14px": ""}}>You can disable these at any time</span>
        </label>
      </div>

      <button
        className="primary__btn3 py-2"
        style={{ marginTop: "4px", zIndex: "10", position: "relative" }}
      >
        Save
      </button>
    </div>
  );
};
