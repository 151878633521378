import { BannerSection } from "./Components/bannerSection";
import { CtaBlock } from "./Components/ctaBlock";
import { WhyClientChoose } from "./Components/whyClientChoose";
import { PopularProcedure } from "./Components/popularProcedure";
import { BeforeAfter } from "./Components/beforeAfter";
import { Aesthetic } from "./Components/aesthetic";
import { FeaturedProcedures } from "./Components/FeaturedProcedure/featuredProcedures";
import { OfferBlock } from "./Components/offerBlock";
import { ReviewSection } from "./Components/reviewSection";
import { BlogSection } from "./Components/blogSection";
import { Footer } from "../../shared/footer/footer";

import { Header } from "../../shared/header";

export const Home = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <BannerSection />
      <CtaBlock />
      <WhyClientChoose />
      <PopularProcedure />
      <BeforeAfter />
      <Aesthetic />
      <FeaturedProcedures />
      <OfferBlock />
      <ReviewSection />
      <BlogSection />
      <Footer />
    </div>
  );
};
