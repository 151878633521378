import { notificationTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { NotificationHeader } from "./components/notificationHeader";
import { ReadNotificationsList } from "./components/notiReadComponents/readNotificationsList";

export const NotificationRead = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <NotificationHeader title="Read (125)" />
        <TabsGroup tabs={notificationTabsData()} />
        <ReadNotificationsList />
      </div>
    </section>
  );
};
