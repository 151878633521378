import { useNavigate } from "react-router-dom";
import "../../../css/main.css";
import "../../../css/media.css";

export const TabsBanner = (props) => {
  const navigate = useNavigate();
  return (
    <div className="before-after-tab-1">
      <div className="overflow-scroll-tab px-5">
        <div className="row">
          <div className="col-xl-7 col-sm-6 flex-width">
            <ul className="tabs">
              <li
                rel="tab1"
                className={`${props.activeTab === "Photos" ? "active-white" : ""}`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/before-after");
                  }}
                  href="/before-after"
                  className="ps-0"
                >
                  Photos
                </a>
              </li>
              <li
                rel="tab2"
                className={`${props.activeTab === "Reviews" ? "active-white" : ""}`}
              >
                <a
                  href="/before-after-face-reviews"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/before-after-face-reviews");
                  }}
                >
                  Reviews
                </a>
              </li>
              <li
                rel="tab3"
                className={`${props.activeTab === "Q&A" ? "active-white" : ""}`}
              >
                <a
                  href="/before-after-face-question-answer-listing"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/before-after-face-question-answer-listing");
                  }}
                >
                  Q & A
                </a>
              </li>
              <li
                className={` text-white ${
                  props.activeTab === "Ask a Doctor" ? "active-white" : ""
                }`}
              >
                <a href>Ask a Doctor</a>
              </li>
            </ul>
          </div>
          <div className="col-xl-5 col-sm-6 flex-width">
            <div className="breadcrumb">
              <a href>Before and After</a> &nbsp; / &nbsp; <a href>Photos</a>
              &nbsp; / &nbsp;
              <a href>Face</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
