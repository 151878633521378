import { faFlag } from "@fortawesome/free-regular-svg-icons";
import "../../../css/main.css";
import "../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { ShareDropdown } from "../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { FlagDropdown } from "../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/flagDropdown";

export const UserReviewItem = () => {
  const matches = useMediaQuery("(max-width:575px)");

  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showFlagDropdown, setShowFlagDropdown] = useState(false);
  return (
    <div className="story-inner-box">
      <div className="story-box">
        <span className="name-style name-green1">FF</span>
        <div className="story-box-head">
          <div className="story-box-head-left">
            <h4>Mohammed Piyaz</h4>
          </div>
          <div className="story-box-head-right">
            <span>12 Sep 2022 | 24 months ago</span>
          </div>
        </div>

        <p className="p-head-size-17">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. .Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Integer vulputate vehicula urna ut Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Integer vulputate vehicula urna ut Lorem
          ipsum dolor sit amet, consectetur adipiscing elit.{" "}
        </p>

        <div className="d-flex align-items-center justify-content-between">
          <div className="share-box-left">
            <span>
              <FontAwesomeIcon icon={faReply} className="rose-text" />
              Reply
            </span>
          </div>

          <div className="share-box-right share-box-flex text-left">
            <div className="mx-1 mx-sm-2 position-relative">
              <button
                onClick={() => setShowShareDropdown(!showShareDropdown)}
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  background: "lightGrey",
                }}
                className="rounded-circle d-flex align-items-center justify-content-center border-0"
              >
                <FontAwesomeIcon
                  icon={faShareNodes}
                  style={{ color: "rgb(161 161 170)",  fontSize: "22px" }}
                />
              </button>
              {showShareDropdown && (
                <ShareDropdown
                  position={`mt-3 ${
                    matches ? "start-0 translate-middle mt-5" : "end-0"
                  }`}
                />
              )}
            </div>
            <div className="position-relative">
              <button
                onClick={() => setShowFlagDropdown(!showFlagDropdown)}
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  background: "lightGrey",
                }}
                className="rounded-circle d-flex align-items-center justify-content-center border-0"
              >
                <FontAwesomeIcon
                  icon={faFlag}
                  style={{ color: "rgb(161 161 170)", fontSize: "22px" }}
                />
              </button>
              {showFlagDropdown && <FlagDropdown position={`mt-3 end-0`} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
