import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../css/main.css";
import "../../../css/media.css";
import { botoxStoriesData } from "../../../demoData/botoxStoriesData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1570 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1570, min: 1230 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1230, min: 640 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

export const BotoxStoriesCarousel = () => {
  return (
    <div className="botox-stories botox-similiar-box">
      <div className="commen-head-sec">
        <div className="row">
          <div className="col-lg-7">
            <h3>Botox Stories</h3>
          </div>
          <div className="col-lg-5">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              ullamcorper. Sed pretium ipsum elit, sit amet auctor massa maximus
              eget.
            </p>
          </div>
        </div>
      </div>
      <Carousel
        responsive={responsive}
        arrows={true}
        showDots={false}
        infinite={true}
        autoPlay={false}
        customLeftArrow={
          <button
            style={{ width: "2rem", height: "2rem", marginBottom: "7rem" }}
            className={`rounded-circle z-3 me-2 position-absolute bottom-0 start-0 simplecarousel-chev d-flex align-items-center justify-content-center`}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mx-auto" />
          </button>
        }
        customRightArrow={
          <button
            style={{ width: "2rem", height: "2rem", marginBottom: "7rem" }}
            className={`rounded-circle z-3 ms-2 position-absolute bottom-0 end-0 simplecarousel-chev d-flex align-items-center justify-content-center`}
          >
            <FontAwesomeIcon icon={faChevronRight} className="mx-auto" />
          </button>
        }
      >
        {botoxStoriesData.map((data, ind) => (
          <div className="item mx-2 pb-4" key={ind + 1}>
            <div className="botox-stories-item">
              <span>
                <img src={data.img} className="w-100" alt="" />
              </span>
              <div className="botox-stories-content">
                <p className="fs-6">{data.desc}</p>
                <div className="d-flex align-items-center flex-wrap justify-content-between gap-1 p-0">
                  <p className="mt-3" style={{ fontSize: "14px" }}>
                    12 jun 2022
                  </p>
                  <button
                    className="date d-flex justify-content-end fs-6 rounded-pill px-4 py-2 border-0"
                    style={{ marginRight: "-10px", background: "black", color: "white" }}
                  >
                    View more
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
