import { useState } from "react";
import "../../../../css/main.css";
import { HelpModal2 } from "../../../../shared/Modals/helpModal2";
import Select from "react-select";
import { sampleOptions } from "../../../../demoData/selectOptionsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const QAHeader = (props) => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="head-menu-design">
      <div className="row">
        <div className="col-xl-3">
          <h4>{props.title}</h4>
        </div>

        <div className="d-none d-lg-block col-12 col-xl-9">
          <div className="overflow-scroll-block">
            <div className="button-flex head-flex-filter d-flex align-items-center justify-end gap-3">
              <input
                type="datetime-local"
                style={{
                  maxWidth: "10rem",
                  height: "3.2rem",
                  border: "2px solid lightGrey",
                  borderRadius: "8px",
                }}
                className="px-2 m-0 input-group-addon"
              />
              <div className="width-filter m-0" style={{ minWidth: "13rem" }}>
                <div className="select-search-icon">
                  <Select
                    options={sampleOptions}
                    getOptionLabel={getOptionLabel}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
              <div className="width-filter m-0" style={{ minWidth: "13rem" }}>
                <div className="select-search-icon">
                  <Select
                    options={sampleOptions}
                    getOptionLabel={getOptionLabel}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => setShowHelpModal(true)}
                type="button"
                className="help border-radius-40 m-0"
              >
                <svg
                  class="svg-inline--fa fa-hire-a-helper"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="hire-a-helper"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M443.1 0H71.9C67.9 37.3 37.4 67.8 0 71.7v371.5c37.4 4.9 66 32.4 71.9 68.8h372.2c3-36.4 32.5-65.8 67.9-69.8V71.7c-36.4-5.9-65-35.3-68.9-71.7zm-37 404.9c-36.3 0-18.8-2-55.1-2-35.8 0-21 2-56.1 2-5.9 0-4.9-8.2 0-9.8 22.8-7.6 22.9-10.2 24.6-12.8 10.4-15.6 5.9-83 5.9-113 0-5.3-6.4-12.8-13.8-12.8H200.4c-7.4 0-13.8 7.5-13.8 12.8 0 30-4.5 97.4 5.9 113 1.7 2.5 1.8 5.2 24.6 12.8 4.9 1.6 6 9.8 0 9.8-35.1 0-20.3-2-56.1-2-36.3 0-18.8 2-55.1 2-7.9 0-5.8-10.8 0-10.8 10.2-3.4 13.5-3.5 21.7-13.8 7.7-12.9 7.9-44.4 7.9-127.8V151.3c0-22.2-12.2-28.3-28.6-32.4-8.8-2.2-4-11.8 1-11.8 36.5 0 20.6 2 57.1 2 32.7 0 16.5-2 49.2-2 3.3 0 8.5 8.3 1 10.8-4.9 1.6-27.6 3.7-27.6 39.3 0 45.6-.2 55.8 1 68.8 0 1.3 2.3 12.8 12.8 12.8h109.2c10.5 0 12.8-11.5 12.8-12.8 1.2-13 1-23.2 1-68.8 0-35.6-22.7-37.7-27.6-39.3-7.5-2.5-2.3-10.8 1-10.8 32.7 0 16.5 2 49.2 2 36.5 0 20.6-2 57.1-2 4.9 0 9.9 9.6 1 11.8-16.4 4.1-28.6 10.3-28.6 32.4v101.2c0 83.4 .1 114.9 7.9 127.8 8.2 10.2 11.4 10.4 21.7 13.8 5.8 0 7.8 10.8 0 10.8z"
                  ></path>
                </svg>
              </button>
            </div>
            <HelpModal2
              showModal={showHelpModal}
              setShowModal={setShowHelpModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
