import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Outlet } from "react-router-dom";
import { Header } from "../../header";
import { FixedFooterNav } from "../../../Pages/dashboard/subComponents/fixedFooterNav";
import { Footer2 } from "../../footer/footer2";
import { Sidebar } from "./sidebar";

export const HealthCareLayout = (props) => {
  return (
    <div>
      <Header />
      <section className="wrapper-dashboard">
        <div className="container-fluid">
          <span className="menu-click-sidebar">
            <FontAwesomeIcon icon={faBars} />
          </span>
          <div className="row">
            <Sidebar />
            <div class="col-lg-9">
              <section class="doctor-detail-stories-tab">
                <div class="container-fluid p-0">
                  <Outlet />
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <FixedFooterNav />
      <Footer2 />
    </div>
  );
};
