/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { BannerDetails } from "./components/bannerDetails";
import { DoctorBanner } from "./components/doctorBanner";
import { TabBanner } from "./components/tabBanner";
import "../../css/main.css";
import "../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { SomeoneModal } from "./components/someoneModal";
import { useEffect, useState } from "react";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { AppointmentForm } from "./components/appointmentForm";
import {
  useDoctor,
  useGetDoctorByIdForPublic,
} from "../../Store/Doctor/doctorHooks";
import { useParams } from "react-router-dom";
import { getFullName2 } from "../../services/dynamicContentService";

export const BookAppointment = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(0);
  const getDoctorByIdForPublic = useGetDoctorByIdForPublic();
  const params = useParams();
  const doctor = useDoctor();

  useEffect(() => {
    if (params.id) getDoctorByIdForPublic(params.id);
  }, [params.id]);

  return (
    <div>
      <Header />
      <BannerDetails />
      <DoctorBanner solidStar={faStar} />
      <section className="book-appoinment-section">
        <TabBanner setTab={setTab} />
        <div className="container-fluid">
          {tab === 1 && (
            <div className="video-consultation-div py-5">
              <div className="myself-someone-else active d-flex flex-column align-items-center">
                <span>
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <h6>MySelf</h6>
              </div>
              <div className="myself-someone-else d-flex flex-column align-items-center">
                <button type="button" onClick={() => setShowModal(true)}>
                  <span>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                </button>
                <h6 className="mx-auto">Someone</h6>
              </div>
            </div>
          )}
          {tab === 0 && <AppointmentForm />}
        </div>
        <SomeoneModal showModal={showModal} setShowModal={setShowModal} />
      </section>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Contact Dr. {getFullName2(doctor.docById)}</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
