import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import { ReviewStars } from "../../../../../shared/reviewStars";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { IsLike } from "../../../../../shared/AddToComps/isLike";
import { useEffect, useState } from "react";
import moment from "moment";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";
import { imgBaseUrl } from "../../../../../services/config/config";

export const NewReviewsList = (props) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    if (props.data) {
      setReviews(props.data);
    }
  }, [props.data]);
  return (
    <div className="tab_container py-0">
      {reviews && reviews.length > 0 ? (
        <div id="tab1" className="tab_content py-0">
          <div className="stories-list py-0">
            {reviews.map((data, ind) => (
              <div className="story-box py-0" key={ind + 1}>
                <span className={`name-style`}>BH</span>
                <div className="story-box-head">
                  <div className="story-box-head-left">
                    <h4>{data.reviewTitle}</h4>
                  </div>
                  <div className="story-box-head-right d-flex align-items-center gap-1 justify-content-center justify-content-md-start">
                    <span>
                      {moment(data.treatmentDate).format("DD MMM YYYY")}
                    </span>
                    <div
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                      className="rounded-circle border-0 d-flex align-items-center justify-content-center position-relative">
                      <IsLike />
                    </div>
                    <span>Highly recommend the doctor</span>
                  </div>
                </div>
                <div className="rating-box-wrapper">
                  <div className="rating-box-stories">
                    <ReviewStars
                      value={data.overallRating}
                      onChange={() => {}}
                    />
                    <span className="number">(1,378)</span>
                    <span>
                      <FontAwesomeIcon icon={faEye} /> 2,300{" "}
                    </span>
                  </div>
                  {data.bodyPart &&
                    data.bodyPart.data &&
                    data.bodyPart.data.attributes && (
                      <div className="rating-box-wrap-right">
                        <span>{data.bodyPart.data.attributes.name}</span>
                      </div>
                    )}
                </div>

                {data.treatment &&
                  data.treatment.data &&
                  data.treatment.data.attributes && (
                    <h5>{data.treatment.data.attributes.name}</h5>
                  )}
                {data.photosData && data.photosData.length > 0 && (
                  <ul className="img-list-popup d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap flex-sm-nowrap">
                    {data.photosData.map((item, i) => (
                      <li>
                        <img
                          src={`${imgBaseUrl + item.img}`}
                          className="img-fluid"
                          style={{
                            width: "8rem",
                            minWidth: "8rem",
                            height: "6rem",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </li>
                    ))}
                  </ul>
                )}
                <p>{data.experienceDescription}</p>
                <div className="story-types pb-0 mb-0">
                  <h6 style={{ whiteSpace: "nowrap" }} className="fs-5">
                    Satisfied with:
                  </h6>
                  {data.howGoodWasDoctor &&
                    data.howGoodWasDoctor.length > 0 && (
                      <ul className="d-flex flex-wrap gap-2">
                        {data.howGoodWasDoctor.map((d, i) => (
                          <li key={i + 1} className="px-0 mx-0">
                            <a href className="fs-6 mx-0">
                              {d}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                </div>

                <div className="comment-box mt-0 pt-0">
                  <div className="comment-features mt-0 pt-0">
                    <a href className="primary__btn3 py-2 px-4">
                      {props.commentsReview ? "Edit Preview" : "Mark as read"}
                    </a>
                  </div>
                </div>
                {props.showLastCommrntBox && ind === reviews.length - 1 && (
                  <div className="comment-box">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control py-4"
                        placeholder="Add a Comment"
                      />
                      <button type="submit" className="send-comment">
                        Send
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
