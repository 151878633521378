import { useNavigate } from "react-router-dom";
import { EmptyComp } from "../../../shared/emptyComponents/emptyComp";
import notiImg from "../../../images/notification.png";

export const NoNotification = () => {
  const navigate = useNavigate();
  return (
    <section className="doctor-detail-stories-tab">
      <div className="container-fluid p-0">
        <div className="no-content pt-5 mt-5 pe-0 pe-lg-5">
          <EmptyComp
            image={notiImg}
            title="No Notification Yet"
            showButton={true}
            desc={["We will notify if something arrives"]}
            buttonText="Refresh"
            onClickButton={(e) => {
              e.preventDefault();
              navigate("/user-dashboard/notification-new");
            }}
          />
        </div>
      </div>
    </section>
  );
};
