import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import Select from "react-select";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { CongratsModal } from "../../../../../shared/Modals/congratsModal";
import { useState } from "react";

export const SecurityQuestionsContent = () => {
  const [showModal, setShowModal] = useState(false);
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="tab_container articles-container px-0 overflow-hidden">
      <div id="tab1" className="tab_content px-0">
        <div className="settings-div set-secret-questions">
          <div className="row">
            <div className="col-lg-6">
              <h4>Set Secret Question</h4>
              <p>
                Select your secret question and answer. You can use it when you
                forget your registered email address
              </p>
              <div className="d-flex gap-2">
                <div
                  className="rose-bg rounded-circle d-flex align-items-center justify-content-center text-white fs-6 pt-1"
                  style={{
                    width: "2.5rem",
                    minWidth: "2.5rem",
                    height: "2.5rem",
                  }}
                >
                  Q
                </div>
                <div
                  className="form-group mt-0 w-100"
                  style={{ paddingLeft: "0" }}
                >
                  <Select
                    options={[
                      {
                        value: "what is your favorite colour?",
                        label: "what is your favorite colour?",
                      },
                    ]}
                    defaultValue={{
                      value: "what is your favorite colour?",
                      label: "what is your favorite colour?",
                    }}
                    getOptionLabel={getOptionLabel}
                    className="profile-form-select" // Add a custom class name for styling
                    classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {/* <select name="" id="" className="form-control mySelect m-0">
                    <option value="">what is your favorite colour?</option>
                  </select> */}
                  <input type="text" className="form-control mt-2 ms-0" />
                </div>
              </div>
              <div className="d-flex gap-2">
                <div
                  className="rose-bg rounded-circle d-flex align-items-center justify-content-center text-white fs-6 pt-1"
                  style={{
                    width: "2.5rem",
                    minWidth: "2.5rem",
                    height: "2.5rem",
                  }}
                >
                  Q
                </div>
                <div
                  className="form-group mt-0 w-100"
                  style={{ paddingLeft: "0" }}
                >
                  <Select
                    options={[
                      {
                        value: "What is your memorable date?",
                        label: "What is your memorable date?",
                      },
                    ]}
                    defaultValue={{
                      value: "What is your memorable date?",
                      label: "What is your memorable date?",
                    }}
                    getOptionLabel={getOptionLabel}
                    className="profile-form-select" // Add a custom class name for styling
                    classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <input type="text" className="form-control mt-2 ms-0" />
                </div>
              </div>
              <div className="d-flex gap-2">
                <div
                  className="rose-bg rounded-circle d-flex align-items-center justify-content-center text-white fs-6 pt-1"
                  style={{
                    width: "2.5rem",
                    minWidth: "2.5rem",
                    height: "2.5rem",
                  }}
                >
                  Q
                </div>
                <div
                  className="form-group mt-0 w-100"
                  style={{ paddingLeft: "0" }}
                >
                  <Select
                    options={[
                      {
                        value: "what is your favorite Sports?",
                        label: "what is your favorite Sports?",
                      },
                    ]}
                    defaultValue={{
                      value: "what is your favorite Sports?",
                      label: "what is your favorite Sports?",
                    }}
                    getOptionLabel={getOptionLabel}
                    className="profile-form-select" // Add a custom class name for styling
                    classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />

                  <input type="text" className="form-control mt-2 ms-0" />
                </div>
              </div>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                type="button"
                className="primary__btn3 py-2"
              >
                Save
              </button>
              <CongratsModal
                showModal={showModal}
                setShowModal={setShowModal}
                msg="Your security questions are saved successfully. You can use it
                when you forget your registered email address."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
