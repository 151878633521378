import { useNavigate } from "react-router-dom";
import "../../../../css/main.css";

export const BeforeAfterTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="overflow-scroll-tab2 pb-0">
      <ul
        className="tabs tab-common-dashboard w-100 px-4 pt-4 gap-5"
        style={{ overflowY: "hidden" }}
      >
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/beforeAfter-upload-photos") ? "active" : ""
          }`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/beforeAfter-upload-photos"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/beforeAfter-upload-photos");
            }}
          >
            Upload Photos{" "}
            {!isActive("/doctor-dashboard/beforeAfter-upload-photos") && <span>3</span>}
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/beforeAfter-under-review") ? "active" : ""
          }`}
          rel="tab2"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/beforeAfter-under-review"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/beforeAfter-under-review");
            }}
          >
            Under Review
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/beforeAfter-need-improvement") ? "active" : ""
          }`}
          rel="tab3"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/beforeAfter-need-improvement"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/beforeAfter-need-improvement");
            }}
          >
            Need Improvement
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/beforeAfter-approved-photos") ? "active" : ""
          }`}
          rel="tab4"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/beforeAfter-approved-photos"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/beforeAfter-approved-photos");
            }}
          >
            Approved Photos
          </a>
        </li>
      </ul>
    </div>
  );
};
