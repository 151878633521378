import axios from "axios";
import { GET_ALL_ROLES } from "./actionTypes";
import { toast } from "react-toastify";
import { baseUrl } from "../../services/config/config";
export function getAllRoles() {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/users-permissions/roles?populate=*`)
      .then((response) => {
        dispatch(setRolesData(response.data.roles));
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}

export function setRolesData(data) {
  return {
    type: GET_ALL_ROLES,
    payload: data,
  };
}
