import { useEffect } from "react";
import { useGetFavOffersByPatient } from "../../../Store/favoruiteOffer/favoruiteOfferHooks";
import { favoruitesTabs } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { FavHeader } from "./components/favHeader";
import { FaVItemsList2 } from "./components/favItemsList/faVItemsList2";

export const FavOffers = () => {
  const getFavOffers = useGetFavOffersByPatient();
  useEffect(() => {
    getFavOffers();
  }, [getFavOffers]);
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <FavHeader title="Offers (19)" />
        <TabsGroup tabs={favoruitesTabs} />
        <FaVItemsList2 page="offers" />
      </div>
    </section>
  );
};
