export const QAList = () => {
  return (
    <div className="tab_container three-block-container px-0">
      <div id="tab1" className="tab_content">
        <div className="questions-answer-div">
          <div className="story-box">
            <div className="rating-box-wrapper">
              <span className="name-style name-red">MM</span>
              <div className="rating-box-stories">
                <span className="number ms-0">Chest Reduction</span>
                <div className="story-box-head">
                  <div className="story-box-head-right">
                    <span>12 Sep 2022 | 24 months ago</span>
                  </div>
                </div>
              </div>
            </div>
            <a href>
              <h5 className="mt-3 rose-text">
                Received Botox and filler - feel more confident with my
                appearance ?
              </h5>
              <p className="mt-3 p-head-size-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </a>
          </div>
          <div className="story-box">
            <div className="rating-box-wrapper">
              <span className="name-style name-red">WN</span>
              <div className="rating-box-stories">
                <span className="number ms-0">Chest Reduction</span>
                <div className="story-box-head">
                  <div className="story-box-head-right">
                    <span>12 Sep 2022 | 24 months ago</span>
                  </div>
                </div>
              </div>
            </div>
            <a href>
              <h5 className="mt-3 rose-text">
                Received Botox and filler - feel more confident with my
                appearance ?
              </h5>
              <p className="mt-3 p-head-size-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </a>
          </div>
          <div className="story-box">
            <div className="rating-box-wrapper">
              <span className="name-style name-red">AN</span>
              <div className="rating-box-stories">
                <span className="number ms-0">Chest Reduction</span>
                <div className="story-box-head">
                  <div className="story-box-head-right">
                    <span>12 Sep 2022 | 24 months ago</span>
                  </div>
                </div>
              </div>
            </div>
            <a href>
              <h5 className="mt-3 rose-text">
                Received Botox and filler - feel more confident with my
                appearance ?
              </h5>
              <p className="mt-3 p-head-size-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
