import { ReferFriendHeader } from "./components/referFriendHeader";
import { InviteesStatusContent } from "./components/inviteesStatusComponents/inviteesStatusContent";
import { TabsGroup } from "../../../shared/tabsGroup";
import { referFriendTabs } from "../../../demoData/tabsData";

export const InviteesStatus = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <ReferFriendHeader title="Invitees Status" />
        <TabsGroup tabs={referFriendTabs()} />
        <InviteesStatusContent />
      </div>
    </section>
  );
};
