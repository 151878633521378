import FavoriteButton from "../fav";
import { HandleHelpfullContent } from "./subComponents/handleHelpfullContent";

export const IsHelpfull = (props) => {
  const myStyle = (isFav) => {
    return {
      background: isFav ? "black" : props.bg ? props.bg : "white",
      color: isFav ? "white" : props.color ? props.color : "black",
      border: props.border ? props.border : "1px solid gray",
      borderRadius: "50px",
      width: "7.5rem",
      height: "2.5rem",
    };
  };

  return (
    <FavoriteButton
      btnStyle={myStyle}
      className={`d-flex align-items-center justify-content-center`}
      handleContent={(isFav) => (
        <HandleHelpfullContent isFav={isFav} label={props.label} />
      )}
    />
  );
};
