import img1 from "../../../../images/qa1.jpg";
import img2 from "../../../../images/qa2.jpg";
import img3 from "../../../../images/qa3.jpg";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { IsLike } from "../../../../shared/AddToComps/isLike";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";
import { AddToFav } from "../../../../shared/AddToComps/addToFav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import { ReplyBox } from "./replyBox";
import { useState } from "react";
import docImg from "../../../../images/arab F-3.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
const quesData = [1, 2, 3, 4];

export const QuestionAnswers = () => {
  const matches = useMediaQuery("(max-width:1200px)");
  const matches2 = useMediaQuery("(max-width:766px)");
  const [expand, setExpand] = useState(0);

  return (
    <div className="questions-answer-div">
      {quesData.map((data, ind) => (
        <Accordion
          expanded={expand === data}
          key={ind + 1}
          style={{
            boxShadow: "none",
            border: matches2 ? "1px solid gray" : "none",
            borderRadius: matches2 ? "10px" : "",
            padding: matches2 ? "2px" : "",
            marginTop: matches2 ? "10px" : "",
          }}
        >
          <div
            className="question-answer-box"
            style={{
              boxShadow: "none",
              border: "none",
            }}
          >
            <span className="search-icon-qa">Q</span>
            <div className="question-ans-head-main">
              <h5 className="fs-4 fs-sm-3 fs-lg-2 ps-3 ps-md-0">
                Do I have gyno? Is there any way to get rid of it without
                surgery?
              </h5>
              <h6>
                12 Sep 2022 | 24 months ago <span>Tummy tuck</span>
              </h6>
              {data === 1 && (
                <ul className="img-list-popup">
                  <li>
                    <img src={img1} className="img-fluid" alt="" />
                  </li>
                  <li>
                    <img src={img2} className="img-fluid" alt="" />
                  </li>
                  <li>
                    <img src={img3} className="img-fluid" alt="" />
                  </li>

                  <li>
                    <img
                      src={img1}
                      className="img-fluid"
                      style={{ width: "max-content" }}
                      alt=""
                    />
                    <span>5+</span>
                  </li>
                </ul>
              )}
            </div>

            <div className="q-a-inner">
              <span className="doc-img-qa">
                <img src={docImg} className="img-fluid" alt="" />
              </span>
              <div className="qa-head-inner">
                <h6>
                  Dr. Lauren Clinton, &nbsp;&nbsp;
                  <span>Answered on 12 Sep 2020 </span>
                </h6>
                <p className="view-clicked">Viewed by 2,300 </p>
              </div>
              <p className="click-resp">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>

              <div className="wrapper-clickinner-box">
                <div className="share-box-section d-flex align-items-center justify-content-between gx-3 gy-2 flex-wrap">
                  <div className="share-box-left">
                    <AccordionSummary
                      onClick={() =>
                        expand === data ? setExpand(0) : setExpand(data)
                      }
                    >
                      <span className="reply-onclick">
                        <FontAwesomeIcon icon={faReplyAll} className="me-2" />{" "}
                        Reply
                      </span>
                    </AccordionSummary>
                  </div>
                  <div className="share-box-right d-flex align-items-center gap-3 flex-wrap">
                    <AddToFav />
                    <span>1 person found this helpful</span>
                    <IsHelpfull label="Helpful" />
                    <span>120 persons Recommend this Doctor</span>
                    <IsLike />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              paddingLeft: matches ? (matches2 ? "10px" : "8rem") : "10rem",
            }}
            className={`${matches ? " pe-2 pb-3 pb-md-0 pe-md-0" : ""}`}
          >
            <AccordionDetails
              className="p-0"
              style={{
                boxShadow: "none",
                borderBottom: "none",
              }}
            >
              <ReplyBox setShowCommentBox={() => {}} />
            </AccordionDetails>
          </div>
        </Accordion>
      ))}
    </div>
  );
};
