import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { REVIEW_BY_AND_STATUS, REVIEW_FORM_DATA } from "./actionTypes";
import {
  getAPIResponseData,
} from "../../services/generalService";
import { setLoading } from "../loading/loadingAction";
import { currentDoctorId, currentPatientId } from "../selectors/UserSelectors";
import axios from "axios";
import { baseUrl } from "src/services/config/config";

export const createReview = (data, handleSuccess) => {
  return () => {
    axiosInstance
      .post(`/new-reviews`, { data: data })
      .then(() => {
        toast.success("Review created successfully!");
        handleSuccess();
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setReviewFormData = (data) => {
  return {
    type: REVIEW_FORM_DATA,
    payload: data,
  };
};
// *********************************************************************

export const setReviewsDataByStatus = (data) => {
  return {
    type: REVIEW_BY_AND_STATUS,
    payload: data,
  };
};
export const getReviewsByPatientAndStatus = (status) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/new-reviews?filters[patient][id][$eq]=${currentPatientId()}&filters[status][$eq]=${status}&populate=*`
      )
      .then((response) => {
        dispatch(
          setReviewsDataByStatus(getAPIResponseData(response.data.data))
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};


export const getReviewsByDoctorAndStatus = (status) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/new-reviews?filters[doctor][id][$eq]=${currentDoctorId()}&filters[status][$eq]=${status}&populate=*`
      )
      .then((response) => {
        dispatch(
          setReviewsDataByStatus(getAPIResponseData(response.data.data))
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};


export const updateReviewStatus = (data, lastStatus) => {
  return (dispatch) => {
    axiosInstance
      .put(
        `/new-reviews/${data.id}`, {data : data}
      )
      .then((response) => {
       if(currentDoctorId()){
        dispatch(
          getReviewsByDoctorAndStatus(lastStatus)
        );
       }
       else if (currentPatientId()){
        dispatch(
          getReviewsByPatientAndStatus(lastStatus)
        );
       }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

// *********************************************************************

export const getAllReviewsByDoctor = async () => {
  let res = await axios
    .get(
      `${baseUrl}/new-reviews?filters[offer][doctor][id][$eq]=${currentDoctorId()}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};


export const getAllReviewsByPatient = async () => {
  let res = await axiosInstance
    .get(
      `/new-reviews?filters[patient][id][$eq]=${currentPatientId()}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};

export const getDoctorReviewsByStatus = async (id, status) => {
  let res = await axios
    .get(
      `${baseUrl}/new-reviews?filters[offer][doctor][id][$eq]=${id}&filters[status][$eq]=${status}&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};
export const getPatientReviewByStatus = async (status) => {
  let res = await axios
    .get(
      `${baseUrl}/new-reviews?filters[patient][id][$eq]=${currentPatientId()}&filters[status][$eq]=${status}&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};
export const getPatientReviewByIsRead = async (read) => {
  let res = await axiosInstance
    .get(
      `/new-reviews?filters[patient][id][$eq]=${currentPatientId()}&filters[read][$eq]=${read}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};
export const getDoctorReviewsByIsRead = async (read) => {
  let res = await axiosInstance
    .get(
      `/new-reviews?filters[offer][doctor][id][$eq]=${currentDoctorId()}&filters[read][$eq]=${read}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};

export const updateReview = async (data, msg, getData) => {
  try{
  await axiosInstance
    .put(
      `${baseUrl}/new-reviews/${data.id}`,
      {data: data}
    );
    getData()
    toast.success(msg)
  }
  catch(error){
    toast.error("Something went wrong!")
  }
};