import { useEffect, useState } from "react";
import { QATabs, QATabsForDoctor } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { QAHeader } from "./components/qaHeader";
import {
  currentDoctorId,
  currentPatientId,
} from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import { getDoctorQaByIsRead, getPatientQaByIsRead, updateQa } from "../../../Store/qa/qaAction";
import { isDoctor } from "src/Store/selectors/isDoctor";
import { QAList } from "./components/subComponents/qaList";

export const ReadQA = () => {
  const [qas, setQA] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    if(currentDoctorId()){
        res = await getDoctorQaByIsRead(true)
    }
    if(currentPatientId()){
      res = await getPatientQaByIsRead(true)
    }
    setQA(res)
  }

  const handleUpdate= (data) => {
    if(data.read){
      updateQa({ id: data.id, read: false }, "Question marked as unread!", getData)
    }
    else{
    updateQa({ id: data.id, read: true }, "Question marked as read!", getData)
    }
  }

  return (
    <Loading duration={500}>
      <section class="doctor-detail-stories-tab">
        <div class="container-fluid p-0">
          <QAHeader title="Read Questions (19)" />
          <TabsGroup tabs={isDoctor() ? QATabsForDoctor() : QATabs()} />
          <QAList data={qas} handleUpdate={handleUpdate} />
        </div>
      </section>
    </Loading>
  );
};
