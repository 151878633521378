/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import docImg from "../../../images/arab F-3.jpg";
import { ReviewStars } from "../../reviewStars";
import { useNavigate } from "react-router-dom";
import { imgBaseUrl } from "../../../services/config/config";
import {
  currentDoctorId,
} from "../../../Store/selectors/UserSelectors";
import { useDoctor, useGetDoctorById } from "../../../Store/Doctor/doctorHooks";
import { useEffect } from "react";
const linksData = [
  { name: "Dashboard", activePath: "/home", path: "/doctor-dashboard/home" },
  {
    name: "My Profile",
    activePath: "profile",
    path: "/doctor-dashboard/profile",
  },
  {
    name: "Notifications",
    activePath: "/notification-",
    path: "/doctor-dashboard/notification-new",
  },
  {
    name: "Upcoming appointments",
    activePath: "/upcomming-appointments",
    path: "/doctor-dashboard/upcomming-appointments",
  },
  {
    name: "Completed appointments",
    activePath: "/completed-appointments",
    path: "/doctor-dashboard/completed-appointments",
  },
  {
    name: "Video Consultation",
    activePath: "/video-consultation",
    path: "/doctor-dashboard/video-consultation",
  },
  {
    name: "Reviews",
    activePath: "-reviews",
    path: "/doctor-dashboard/new-reviews",
  },
  {
    name: "Articles",
    activePath: "articles",
    path: "/doctor-dashboard/upload-articles",
  },
  {
    name: "Offers",
    activePath: "-offers",
    path: "/doctor-dashboard/upload-offers",
  },
  {
    name: "Wallet",
    activePath: "/wallet",
    path: "/doctor-dashboard/wallet-orders",
  },
  { name: "Q&A", activePath: "-qa", path: "/doctor-dashboard/new-qa" },
  {
    name: "Settings",
    activePath: "/settings",
    path: "/doctor-dashboard/settings-change-password",
  },
  {
    name: "Refer a Friend",
    activePath: "/refers",
    path: "/doctor-dashboard/refers-invite-friend",
  },
  {
    name: "Support",
    activePath: "/support",
    path: "/doctor-dashboard/support-raise-ticket",
  },
  {
    name: "Before & After Photos",
    activePath: "/beforeAfter",
    path: "/doctor-dashboard/beforeAfter-upload-photos",
  },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const doctor = useDoctor();
  const getDoctorById = useGetDoctorById();
  useEffect(() => {
    getDoctorById(currentDoctorId());
  }, [currentDoctorId]);
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="col-lg-3 d-lg-block d-none">
      <div className="sidebar">
        {doctor && doctor.formData && (
          <div className="doctor-name">
            <span className="doc-img">
              <img
                src={
                  doctor.formData.profileImg
                    ? `${imgBaseUrl + doctor.formData.profileImg}`
                    : docImg
                }
                className="img-fluid doc-img doctor-img1"
                style={{objectFit: "cover"}}
                alt=""
              />
            </span>
            <h6>
              {doctor.formData.firstName + " "}
              {doctor.formData.lastName && doctor.formData.lastName}
            </h6>
            <div className="detail-box">
              <ReviewStars />
              <span className="number">(1,378)</span>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-placement="top"
                title="Tooltip on top"
              >
                <FontAwesomeIcon icon={faTrophy} />
              </button>
            </div>
          </div>
        )}

        <ul>
          {linksData.map((data, ind) => (
            <li
              key={ind + 1}
              className={`${isActive(data.activePath) ? "active" : ""}`}
            >
              <a
                href={data.path}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(data.path);
                }}
              >
                {data.name}
              </a>
            </li>
          ))}
          <li>
            <a type="button" className="sidebar-logout" href>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
