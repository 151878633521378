import "../../../../../css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { ReviewCommentBox } from "../../../../doctor-details/components/reviewCommentBox";
import { getFullNameByWhoAsked } from "../../../../../services/generalService";
import moment from "moment";
import { imgBaseUrl } from "src/services/config/config";
import { isDoctor } from "src/Store/selectors/isDoctor";
import { getStep2NestedField } from "src/services/dynamicContentService";

export const QaIttem = (props) => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);

  const readOrUnreadPath =
    window.location.pathname.includes("new-qa") ||
    window.location.pathname.includes("unread-qa") ||
    window.location.pathname.includes("read-qa");
  const liveOrHidePath =
    window.location.pathname.includes("live-qa") ||
    window.location.pathname.includes("hide-qa");
  return (
    <div className="story-box py-4" key={props.ind + 1}>
      <span
        className={`name-style ${
          props.ind % 2 === 0 ? "name-red" : "name-blue"
        }`}
      >
        {getFullNameByWhoAsked(props.data).split(" ")[0][0].toUpperCase()}
        {getFullNameByWhoAsked(props.data).split(" ")[1][0].toUpperCase()}
      </span>
      <div className="story-box-head">
        <div className="story-box-head-left">
          <h4>{getFullNameByWhoAsked(props.data)}</h4>
        </div>
        <div className="story-box-head-right">
          <span>{moment(props.data.treatmentDate).format("DD MMM YYYY")}</span>
          <br />
        </div>
      </div>
      <div className="rating-box-stories d-flex justify-content-between flex-row-reverse px-0">
        <span className="number ms-0">
          <span>{getStep2NestedField(props.data, "treatment", "name")}</span>
        </span>
        <span>
          <FontAwesomeIcon icon={faEye} /> 2,300{" "}
        </span>
      </div>
      <h5>{props.data.QATitle}</h5>
      {props.data.photosData && props.data.photosData.length > 0 && (
        <ul className="img-list-popup d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap flex-sm-nowrap">
          {props.data.photosData.map((item, i) => (
            <li>
              <img
                src={`${imgBaseUrl + item.img}`}
                className="img-fluid"
                style={{
                  width: "8rem",
                  minWidth: "8rem",
                  height: "6rem",
                  objectFit: "cover",
                }}
                alt=""
              />
            </li>
          ))}
        </ul>
      )}
      <p>{props.data.description}</p>
      {readOrUnreadPath && isDoctor() && (
        <div className="comment-features mt-0 mb-0 pb-0">
          <button
            onClick={() => props.handleUpdate(props.data)}
            className="primary__btn3 px-4 py-2 mt-0 mb-0"
          >
            {props.data.read ? "Mark as unread" : "Mark as read"}
          </button>
        </div>
      )}
      {!showCommentBox && props.data.status === "Live" && liveOrHidePath && (
        <div className="comment-textarea position-relative px-1 pb-0">
          <div
            className={`position-relative bg-white pb-0 ${
              showCommentBox ? "pt-4" : ""
            }`}
            style={{ zIndex: "10" }}
          >
            <textarea
              name=""
              id=""
              className={`form-control border`}
              style={{
                height: "4rem",
                transitionDuration: "500ms",
              }}
              rows={2}
              placeholder="Add a Comment"
              defaultValue="This is a Comment"
              onClick={() => setShowSubmit(!showSubmit)}
            ></textarea>
          </div>
          <div
            className={`position-relative w-100 overflow-hidden`}
            style={{
              marginTop: showSubmit ? "0.75rem" : "-3.5rem",
              transitionDuration: "500ms",
              height: "3rem",
              zIndex: "0",
            }}
          >
            <button
              className={`submit-comment-btn rose-bg rounded-pill text-white fs-5 d-flex align-items-center justify-content-center position-absolute border-0 ${
                showSubmit ? "end-0" : "end-100"
              } `}
              style={{
                transitionDuration: "500ms",
                width: "10rem",
                height: "3rem",
                opacity: showSubmit ? "1" : "0",
                zIndex: "0",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <div
        className="comment-area-wrap-div position-relative pb-0 mb-0"
        style={{
          transitionDuration: "500ms",
          zIndex: "20",
          top: showCommentBox ? "2rem" : "0",
        }}
      >
        <div className="d-flex align-items-center justify-content-between pb-0">
          {props.data.status === "Live" && liveOrHidePath ? (
            <button
              className="border-0"
              style={{
                outline: "none",
                boxShadow: "none",
                background: "transparent",
              }}
            >
              {!showCommentBox && props.ind === 0 && (
                <span
                  className="comment-onclick fs-6 text-secondary"
                  onClick={() => {
                    setShowSubmit(false);
                    setShowCommentBox(!showCommentBox);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faComment}
                    className="rose-text"
                    style={{ fontSize: "22px" }}
                  />
                  240 Comments
                </span>
              )}
            </button>
          ) : (
            <div></div>
          )}
          {isDoctor() && liveOrHidePath && (
            <div className="button-flex justify-content-end">
              <button
                onClick={() => props.handleUpdate(props.data)}
                style={{
                  background: "black",
                  height: "max-content",
                  whiteSpace: "nowrap",
                }}
                className="rounded-pill text-white py-2 fs-6 px-4"
              >
                {props.data.status === "Live" ? "Hide from live" : "Go Live"}
              </button>
              {/* <div className="position-relative">
              <button
                onClick={() => setShowShareDropdown(!showShareDropdown)}
                style={{
                  background: "transparent",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
                className="rounded-circle border-0 d-flex align-items-center justify-content-center">
                <FontAwesomeIcon
                  icon={faShareNodes}
                  style={{ color: "grey", fontSize: "26px" }}
                />
              </button>
              {showShareDropdown && (
                <ShareDropdown
                  position={`mt-5 mt-sm-3 ${
                    matches ? "start-0 translate-middle" : "end-0"
                  }`}
                />
              )}
            </div> */}
            </div>
          )}
        </div>
        {showCommentBox && props.data.status === "Live" && (
          <ReviewCommentBox setShowCommentBox={setShowCommentBox} />
        )}
      </div>
    </div>
  );
};
