import { SettingsHeader } from "./components/settingsHeader";
import { ActiveDevicesTable } from "./components/activeDeviceComponents/activeDevicesTable";
import { TabsGroup } from "../../../shared/tabsGroup";
import { settingsTabsData } from "../../../demoData/tabsData";

export const ActiveDevices = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SettingsHeader title="Active Devices" />
        <TabsGroup tabs={settingsTabsData()} />
        <ActiveDevicesTable />
      </div>
    </section>
  );
};
