import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ShareDropdown } from "../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { ReportErrorModal } from "../../../shared/Modals/reportErrorModal";

export const BannerDetails = () => {
  const matches = useMediaQuery("(max-width:575px)");
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="banner-details">
      <div className="row">
        <div className="col-lg-10">
          <a href className="fs-5" style={{ color: "lightGrey" }}>
            Home {">"} UAE {">"} Abu Dhabi {">"}Al Ain {">"} Cosmetology {">"}{" "}
            Cosmetics/Plastic Surgeon {">"} Dr. Lauren Clinton
          </a>
        </div>
        <div className="col-lg-2">
          <ul>
            <li>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faFlag} />
              </button>
            </li>
            <ReportErrorModal
              showModal={showModal}
              setShowModal={setShowModal}
            />
            <div className="position-relative">
              <li>
                <div className="social-share-box">
                  <span
                    className="share-bt"
                    style={{
                      background: showShareDropdown ? "#e78a82" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowShareDropdown(!showShareDropdown)}
                  >
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      style={{
                        color: showShareDropdown ? "white" : "rgb(161 161 170)",
                      }}
                    />
                  </span>
                </div>
              </li>
              {showShareDropdown && (
                <ShareDropdown
                  position={`mt-3 ${
                    matches ? "start-0 translate-middle mt-5" : "end-0"
                  }`}
                />
              )}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
