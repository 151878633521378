import { useNavigate } from "react-router-dom";
import "../../../../css/main.css";

export const ArticlesTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="overflow-scroll-tab2 pb-0">
      <ul className="tabs tab-common-dashboard ps-4 pt-4 gap-5">
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/upload-articles") ? "active" : ""
          }`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/upload-articles"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/upload-articles");
            }}
          >
            Upload Articles
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/under-review-articles") ? "active" : ""
          }`}
          rel="tab2"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/under-review-articles"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/under-review-articles");
            }}
          >
            Under Review
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/need-improvement-articles") ? "active" : ""
          }`}
          rel="tab3"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/need-improvement-articles"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/need-improvement-articles");
            }}
          >
            Need Improvement
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/approved-articles") ? "active" : ""
          }`}
          rel="tab4"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/approved-articles"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/approved-articles");
            }}
          >
            Approved Articles
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/flagged-articles") ? "active" : ""
          }`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/flagged-articles"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/flagged-articles");
            }}
          >
            Flagged
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${
            isActive("/doctor-dashboard/articles-history") ? "active" : ""
          }`}
          rel="tab6"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/articles-history"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/articles-history");
            }}
          >
            History
          </a>
        </li>
      </ul>
    </div>
  );
};
