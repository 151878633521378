import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import { FiltersBanner } from "./components/filtersBanner";
import { PopularPlaces } from "./components/popularPlaces";
import { DoctorsList } from "./components/doctorsList";
import { Footer } from "../../shared/footer/footer";

import { useNavigate } from "react-router-dom";
import { Faqs } from "./components/faqs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faVrCardboard } from "@fortawesome/free-solid-svg-icons";
import { PopularPlacesModal } from "./components/subcomponents/popularPlacesModal";
import { useEffect, useState } from "react";
import { BannerFiltersModal } from "./components/subcomponents/bannerFiltersModal";
import { BotoxTreatmentCosts } from "./components/botoxTreatmentCosts";
import { FindServiceModal } from "./components/subcomponents/findServiceModal";

export const DoctorsListing = () => {
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowFiltersModal(false);
      setShowPlacesModal(false);
    });
  }, []);
  useEffect(() => {
    if (showPlacesModal || showFiltersModal) {
      document.scrollingElement.style.overflow = "hidden";
    } else {
      document.scrollingElement.style.overflow = "auto";
    }
  }, [showPlacesModal, showFiltersModal]);
  return (
    <div>
      {/* header section  */}
      <Header />
      <section className="doctors-listing-block doctors-listing-section">
        <div className="container-fluid">
          <FiltersBanner />
          <div className="resp-block-box justify-content-end fixed-box">
            <span
              className="open-click open-click1"
              onClick={() => setShowPlacesModal(true)}
            >
              <FontAwesomeIcon icon={faDashboard} />
            </span>
            <span
              className="open-click open-click2"
              onClick={() => setShowFiltersModal(true)}
            >
              <FontAwesomeIcon icon={faVrCardboard} />
            </span>
          </div>
          {showPlacesModal && (
            <PopularPlacesModal
              setShowModal={setShowPlacesModal}
              setShowModal2={setShowModal}
            />
          )}
          {showFiltersModal && (
            <BannerFiltersModal setShowModal={setShowFiltersModal} />
          )}
          <FindServiceModal showModal={showModal} setShowModal={setShowModal} />
          <h2>
            197 Best Doctors in Abu Dhabi, UAE
            <a
              href
              onClick={() => navigate("/find-doctor")}
              className="consult-link"
            >
              Consult with Specialist
            </a>
          </h2>
          <div className="row">
            <div className="col-lg-3">
              <BotoxTreatmentCosts />
              <PopularPlaces />
            </div>
            <div className="col-lg-9">
              <DoctorsList />
              <Faqs />
            </div>
          </div>
          <h6 className="avg-ratings">
            Average Ratings 4.9 and 1604 Patient Stories
          </h6>
        </div>
      </section>
      <Footer />
    </div>
  );
};
