/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export const SkinColorComp = (props) => {
  const [inpValue, setInpValue] = useState("");

  useEffect(() => {
    if (props.defaultValue) {
      setInpValue(props.defaultValue);
    }
  }, [props.defaultValue]);
  useEffect(() => {
    props.onChange(inpValue);
  }, [inpValue]);
  return (
    <div className="form-group form-group-select">
      <label className="fieldlabels">
        Select skintone closest to your skin <span>*</span>
      </label>
      <div className="row">
        <div className="col-xl-11">
          <div className="row">
            <div className="col-xl-7">
              <div className="box-colors ">
                <input
                  type="radio"
                  checked={inpValue === "Ivory"}
                  onClick={() => setInpValue("Ivory")}
                  id="ivory"
                  name="animal"
                  value=""
                />
                <label
                  style={{ width: "max-content" }}
                  className="ps-2 pe-3 d-flex align-items-center justify-content-center"
                  for="ivory"
                >
                  <ul className="first-set">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li>Ivory</li>
                  </ul>
                </label>

                <input
                  type="radio"
                  checked={inpValue === "Light"}
                  onClick={() => setInpValue("Light")}
                  id="light"
                  name="animal"
                  value=""
                />
                <label
                  style={{ width: "max-content" }}
                  className="ps-2 pe-3 d-flex align-items-center justify-content-center"
                  for="light"
                >
                  <ul className="second-set">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li>Light</li>
                  </ul>
                </label>

                <input
                  type="radio"
                  checked={inpValue === "Fair"}
                  onClick={() => setInpValue("Fair")}
                  id="fair"
                  name="animal"
                  value=""
                />
                <label
                  style={{ width: "max-content" }}
                  className="ps-2 pe-3 d-flex align-items-center justify-content-center"
                  for="fair"
                >
                  <ul className=" third-set">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li>Fair</li>
                  </ul>
                </label>

                <input
                  type="radio"
                  checked={inpValue === "Tan"}
                  onClick={() => setInpValue("Tan")}
                  id="tan"
                  name="animal"
                  value=""
                />
                <label
                  style={{ width: "max-content" }}
                  className="ps-2 pe-3 d-flex align-items-center justify-content-center"
                  for="tan"
                >
                  <ul className="fourth-set">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li>Tan</li>
                  </ul>
                </label>

                <input
                  type="radio"
                  checked={inpValue === "Brown"}
                  onClick={() => setInpValue("Brown")}
                  id="brown"
                  name="animal"
                  value=""
                />
                <label
                  style={{ width: "max-content" }}
                  className="ps-2 pe-3 d-flex align-items-center justify-content-center"
                  for="brown"
                >
                  <ul className="fifth-set">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li>Brown</li>
                  </ul>
                </label>

                <input
                  type="radio"
                  checked={inpValue === "Dark Brown"}
                  onClick={() => setInpValue("Dark Brown")}
                  id="deep"
                  name="animal"
                  value=""
                />
                <label
                  style={{ width: "max-content" }}
                  className="ps-2 pe-3 d-flex align-items-center justify-content-center"
                  for="deep"
                >
                  <ul className="sixth-set">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li>Dark Brown</li>
                  </ul>
                </label>
              </div>
            </div>
            <div className="col-xl-5">
              <p>
                This info helps us show you procedures that may be suitable for
                skin tone,along with the reviews and photos with people with
                similiar skin tone
              </p>
            </div>
          </div>
        </div>

        <div className="col-xl-3 m-auto">Dr. John Abraham</div>
      </div>
    </div>
  );
};
