import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

export const FooterLinks = () => {
  return (
    <div>
      <div className="footer-box py-0">
        <Accordion
          style={{
            boxShadow: "none",
            background: "transparent",
            padding: "0",
          }}
          className="set"
        >
          <AccordionSummary className="set w-full flex items-center justify-between gap-x-4">
            <h3>Surgical</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a href="/">Patient Journey</a>
              </li>
              <li>
                <a href="/">All Treatments</a>
              </li>
              <li>
                <a href="/">Body Surgery</a>
              </li>
              <li>
                <a href="/">Breast Surgery</a>
              </li>
              <li>
                <a href="/">Facial Surgery</a>
              </li>
              <li>
                <a href="/">Recovery Timelines</a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="footer-box py-0">
        <Accordion
          style={{
            boxShadow: "none",
            background: "transparent",
            padding: "0",
          }}
          className="set"
        >
          <AccordionSummary className="set w-full flex items-center justify-between gap-x-4">
            <h3>Non Surgical</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a href="/">Patient Journey</a>
              </li>
              <li>
                <a href="/">All Treatments</a>
              </li>
              <li>
                <a href="/">Body Surgery</a>
              </li>
              <li>
                <a href="/">Injectables</a>
              </li>
              <li>
                <a href="/">Laser Treatments</a>
              </li>
              <li>
                <a href="/">Recovery Timelines</a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="footer-box py-0">
        <Accordion
          style={{
            boxShadow: "none",
            background: "transparent",
            padding: "0",
          }}
          className="set"
        >
          <AccordionSummary className="set w-full flex items-center justify-between gap-x-4">
            <h3>Patient Hub</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a href="/">Before & Afters</a>
              </li>
              <li>
                <a href="/">Stories </a>
              </li>
              <li>
                <a href="/">Our Offers</a>
              </li>
              <li>
                <a href="/">Concerns</a>
              </li>
              <li>
                <a href="/">Write a Review</a>
              </li>
              <li>
                <a href="/">Guidelines & Policies</a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="footer-box py-0">
        <Accordion
          style={{
            boxShadow: "none",
            background: "transparent",
            padding: "0",
          }}
          className="set"
        >
          <AccordionSummary className="set w-full flex items-center justify-between gap-x-4">
            <h3>Insights</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a href="/">About</a>
              </li>
              <li>
                <a href="/">Contact</a>
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
                <a href="/">FAQ</a>
              </li>
              <li>
                <a href="/">Blog</a>
              </li>
              <li>
                <a href="/">Media</a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="footer-box py-0">
        <Accordion
          style={{
            boxShadow: "none",
            background: "transparent",
            padding: "0",
          }}
          className="set"
        >
          <AccordionSummary className="set w-full flex items-center justify-between gap-x-4">
            <h3>Explore</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <a href="/">Find Doctor</a>
              </li>
              <li>
                <a href="/">Claim Profile</a>
              </li>
              <li>
                <a href="/">Sign In </a>
              </li>
              <li>
                <a href="/">Sign Up</a>
              </li>
              <li>
                <a href="/">Enquire Now</a>
              </li>
              <li>
                <a href="/">Travel</a>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
