import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from "../../../../../images/userWallet1.jpg";
import img2 from "../../../../../images/wallet-table3.jpg";
import { PaginationBox } from "../../../../../shared/paginationBox";

const tableData = [img1, img2, img2, img2];

export const WishlistTable = () => {
  return (
    <div className="tab_container articles-container px-0">
      <div id="tab1" className="tab_content">
        <table
          className="table table-style-2 wishlist-table w-100"
          id="example"
        >
          <thead>
            <tr>
              <th width="320"> Product</th>
              <th>Description</th>
              <th>Price</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((d, ind) => (
              <tr key={ind + 1}>
                <td className="d-flex align-items-center gap-3 w-100">
                  <span>
                    <img
                      src={d}
                      className="img-fluid"
                      style={{ width: "6rem", minWidth: "6rem" }}
                      alt=""
                    />
                  </span>
                  <div
                    className="flex-table-box product-block-box"
                    style={{ width: "10rem", minWidth: "10rem" }}
                  >
                    <h6>
                      Botox Injection
                      <span className="product-sub-text1">Offers</span>
                      <span className="product-sub-text2">
                        Expires On 20 Dec2022
                      </span>
                    </h6>
                  </div>
                </td>
                <td>
                  <p className="dec-text">
                    Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.
                    Suspendisse Feugiat Mi Venenatis, Pulvinar Felis Sit Amet,
                    Congue Lectus. Ut Venenatis Sagittis Augue, Vel Bibendum
                    Nisl Porttitor Ut. Mauris Ac Turpis Elit. Class Aptent
                    Taciti Sociosqu Ad Litora Torquent Per Conubia Nostra, Per
                    Inceptos Himenaeo…Etiam Id Dapibus Nunc. Donec At Vestibulum
                    Nisl.{" "}
                  </p>
                </td>
                <td>
                  <div
                    className="price-strikethrough"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <h6>
                      <span>AED 1,000</span>
                      AED 1,000
                    </h6>
                  </div>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  Added on <br />
                  20 nov 2022
                </td>
                <td>
                  <a
                    href
                    className="primary__btn3 py-1 px-4 smallText"
                    style={{ whiteSpace: "nowrap", marginTop: "-5px" }}
                  >
                    Added to cart
                  </a>
                </td>
                <td>
                  {" "}
                  <span className="delete-icon-box">
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationBox />
      </div>
    </div>
  );
};
