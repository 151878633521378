import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home/home";
import { FindDoctor } from "./Pages/Find_A_Doctor/find_a_doctor";
import { Procedure } from "./Pages/Procedure/procedure";
import { SignIn } from "./Pages/SignIn/SignIn";
import { SignUpUsers } from "./Pages/sign-up-users/sign-up-users";
import { SignUpDoctors } from "./Pages/sign-up-doctors/sign-up-doctors";
import { SpecialOffers } from "./Pages/specialOffers/specialOffers";
import { BeforAfter } from "./Pages/beforAfter/beforAfter";
import { FaceReviews } from "./Pages/beforAfter/face-reviews";
import { BeforeAfterFaceDetail } from "./Pages/beforAfter/before-after-face-detail/before-after-face-detail";
import { FaceLiposuctionReviews } from "./Pages/beforAfter/before-after-face-detail/face-liposuction-reviews";
import { BeforeAfterDetail } from "./Pages/beforAfter/before-after-detail/before-after-detail";
import { FaceLiposuctionReviewsDetail } from "./Pages/beforAfter/face-liposuction-reviews-detail/face-liposuction-reviews-detail";
import { BotoxInjections } from "./Pages/botox-injections/botox-injections.";
import { BotoxDoctors } from "./Pages/Find_A_Doctor/botoxDoctors";
import { DoctorsListing } from "./Pages/Find_A_Doctor/doctorsListing";
import { BookAppointment } from "./Pages/book-appointment/book-appointment";
import { Badge } from "./Pages/badge/badge";
import { FaceQuestionAnswerListing } from "./Pages/beforAfter/face_question-answer-listing";
import { FaceQuestionAnswerSelection } from "./Pages/beforAfter/face_question-answer-selection";
import { FaceQuestionAnswerDetails } from "./Pages/beforAfter/before-after-face-detail/face-question-answer-details";
import { DoctorDetails } from "./Pages/doctor-details/doctorDetails";
import { CommingSoon } from "./Pages/comming-soon/comming-soon";
import { Blog } from "./Pages/blog/blog";
import { DoctorDetailsStories } from "./Pages/doctor-details/doctor-details-stories";
import { DoctorDetailsQA } from "./Pages/doctor-details/doctor-details-QA";
import { DoctorDetailsPhotos } from "./Pages/doctor-details/doctor-details-photos";
import { DoctorDetailsVideos } from "./Pages/doctor-details/doctor-details-videos";
import { DoctorDetailsProcedures } from "./Pages/doctor-details/doctor-details-procedures";
import { DoctorDetailsArticles } from "./Pages/doctor-details/doctor-details-articles";
import { ArticleDetiails } from "./Pages/doctor-details/article-detiails";
import { DoctorDetailsOffers } from "./Pages/doctor-details/doctor-details-offers";
import { DoctorDetailsQAOld } from "./Pages/doctor-details/doctor-details-QA-old";
import { HealthCareProfile } from "./Pages/healthCareFacility/healthCareProfile";
import { DoctorsList } from "./Pages/healthCareFacility/doctorsList";
import { HealthCreWalletStatus } from "./Pages/healthCareFacility/healthCareWallet/healthCreWalletStatus";
import { HealthCareWalletOrder } from "./Pages/healthCareFacility/healthCareWallet/healthCareWalletOrder";
import { AddDoctor } from "./Pages/healthCareFacility/addDoctor";
import { HealthCareLayout } from "./shared/layouts/healthCareLayout/healthCareLayout";
import { DashboardRoutes } from "./routes/dashboardRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavigationComponent } from "./services/navigation";
import { UserDashboardRoutes } from "./routes/userDashboardRoutes";
import { DashboardLayout } from "./shared/layouts/dashboardLayout/dashboardLayout";
import { UserDashboardLayout } from "./shared/layouts/userDashboardLayout/userDashboardLayout";
import { DoctorFilterProvider } from "./services/doctorsFilter/doctorFilterProvider";

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/sign-in" />} />
          <Route path="/" element={<Navigate to="/home" />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route
            path="/find-doctor"
            element={
              <DoctorFilterProvider>
                <FindDoctor />
              </DoctorFilterProvider>
            }
          />
          <Route path="/find-doctor-botox-doctors" element={<BotoxDoctors />} />
          <Route
            path="/find-doctor-doctors-listing"
            element={<DoctorsListing />}
          />
          <Route path="/procedure" element={<Procedure />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up-users" element={<SignUpUsers />} />
          <Route path="/sign-up-doctors" element={<SignUpDoctors />} />
          <Route path="/special-offers" element={<SpecialOffers />} />
          <Route path="/before-after" element={<BeforAfter />} />
          <Route path="/before-after-face-reviews" element={<FaceReviews />} />
          <Route
            path="/before-after-face-detail"
            element={<BeforeAfterFaceDetail />}
          />
          <Route
            path="/before-after-face-liposuction-reviews"
            element={<FaceLiposuctionReviews />}
          />
          <Route path="/before-after-detail" element={<BeforeAfterDetail />} />
          <Route
            path="/before-after-face-liposuction-reviews-detail"
            element={<FaceLiposuctionReviewsDetail />}
          />
          <Route path="/botox-injections" element={<BotoxInjections />} />
          <Route path="/book-appointment/:id" element={<BookAppointment />} />
          <Route path="/badge" element={<Badge />} />
          <Route
            path="/before-after-face-question-answer-listing"
            element={<FaceQuestionAnswerListing />}
          />
          <Route
            path="/before-after-face-question-answer-selection"
            element={<FaceQuestionAnswerSelection />}
          />
          <Route
            path="/before-after-face-question-answer-details"
            element={<FaceQuestionAnswerDetails />}
          />
          <Route
            path="/doctor-details-overview/:id"
            element={<DoctorDetails />}
          />
          <Route path="/comming-soon" element={<CommingSoon />} />
          <Route path="/blog" element={<Blog />} />
          <Route
            path="/doctor-details-stories/:id"
            element={<DoctorDetailsStories />}
          />
          <Route path="/doctor-details-q-a/:id" element={<DoctorDetailsQA />} />
          <Route
            path="/doctor-details-photos/:id"
            element={<DoctorDetailsPhotos />}
          />
          <Route
            path="/doctor-details-videos/:id"
            element={<DoctorDetailsVideos />}
          />
          <Route
            path="/doctor-details-procedures/:id"
            element={<DoctorDetailsProcedures />}
          />
          <Route
            path="/doctor-details-articles/:id"
            element={<DoctorDetailsArticles />}
          />
          <Route
            path="/doctor-details-articles-detiails"
            element={<ArticleDetiails />}
          />
          \
          <Route
            path="/doctor-details-offers/:id"
            element={<DoctorDetailsOffers />}
          />
          <Route
            path="/doctor-details-q-a-old"
            element={<DoctorDetailsQAOld />}
          />
          {/* dashboard  */}
          <Route path="doctor-dashboard" element={<DashboardLayout />}>
            <Route path="" element={<Navigate to="/doctor-dashboard/home" />} />
            <Route path="*" element={<DashboardRoutes />} />
          </Route>
          {/* Health Care dashboar  */}
          <Route path="health-care" element={<HealthCareLayout />}>
            <Route path="profile" element={<HealthCareProfile />} />
            <Route path="doctors-list" element={<DoctorsList />} />
            <Route path="wallet-status" element={<HealthCreWalletStatus />} />
            <Route path="wallet-order" element={<HealthCareWalletOrder />} />
            <Route path="add-doctor" element={<AddDoctor />} />
          </Route>
          {/* User dashboard  */}
          <Route path="user-dashboard" element={<UserDashboardLayout />}>
            <Route
              path=""
              element={<Navigate to="/user-dashboard/user-profile" />}
            />
            <Route path="*" element={<UserDashboardRoutes />} />
          </Route>
        </Routes>
        <NavigationComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
