import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const IOSSlider = styled(Slider)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? "rgba(252, 165, 73, 1)"
      : "rgba(252, 165, 73, 1)",
  height: 12,
  padding: "0",
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "white",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 10,
    fontWeight: "500",
    top: -6,
    borderRadius: "3px",
    padding: "3px 10px",
    backgroundColor: "#e78a82",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "white" : "white",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    minWidth: "0%",
    backgroundColor: "#e78a82",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    border: "1px solid rgb(161 161 170)",
    backgroundColor: "rgb(212 212 216)",
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
}));

export const RangeSlider = () => {
  const [value, setValue] = useState([45000, 75000]);
  return (
    <IOSSlider
      aria-label="ios slider"
      defaultValue={200}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      min={10000}
      max={110000}
      valueLabelDisplay="on"
    />
  );
};
