import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import { ReviewStars } from "../../../../../shared/reviewStars";
import { faEye, faFlag } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faExclamationCircle,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import { IsLike } from "../../../../../shared/AddToComps/isLike";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";

const myData = [1, 2, 3];

export const FlaggedReviewsList = (props) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    if (props.data) {
      setReviews(props.data);
    }
  }, [props.data]);
  return (
    <div className="tab_container py-0 px-0 overflow-hidden">
      {reviews && reviews.length > 0 ? (
        <div id="tab1" className="tab_content py-0">
          <div className="stories-list py-0">
            {reviews.map((data, ind) => (
              <div className="story-box border-0" key={ind + 1}>
                <span className={`name-style`}>BH</span>
                <div className="story-box-head">
                  <div className="story-box-head-left">
                    {/* <h4>{data.name}</h4> */}
                  </div>
                  <div className="story-box-head-right d-flex align-items-center gap-1 justify-content-center justify-content-md-start">
                    <span>
                      {moment(data.treatmentDate).format("DD MMM YYYY")}
                    </span>
                    <div
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                      className="rounded-circle border-0 d-flex align-items-center justify-content-center position-relative"
                    >
                      <IsLike />
                    </div>
                    <span>Highly recommend the doctor</span>
                  </div>
                </div>
                <div className="rating-box-wrapper">
                  <div className="rating-box-stories">
                    <ReviewStars
                      value={data.overallRating}
                      onChange={() => {}}
                    />
                    <span className="number">(1,378)</span>
                    <span>
                      <FontAwesomeIcon icon={faEye} /> 2,300{" "}
                    </span>
                  </div>
                  <div className="rating-box-wrap-right">
                    <span>{data.whichBodyPartYouTreated}</span>
                  </div>
                </div>
                <h5>{data.forWhichHealthProblemOrTreatmentDidYouVisit}</h5>
                <p>{data.experienceDescription}</p>
                <div className="story-types">
                  <h6 style={{ whiteSpace: "nowrap" }} className="fs-5">
                    Satisfied with:
                  </h6>
                  <ul>
                    {data.howGoodWasYourDoctorTodayAtEachOfTheFollowing.map(
                      (d, i) => (
                        <li key={i + 1}>
                          <a href className="fs-6">
                            {d}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <Accordion
                  className="mt-5 Accordion"
                  style={{
                    boxShadow: "none",
                    background: "transparent",
                    padding: "0",
                    outline: "none",
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    className="pb-0 pt-0 w-100 bg-white"
                    style={{ height: "4rem" }}
                  >
                    <h4
                      className="d-flex w-100 align-items-center gap-3 justify-content-between flagged-slide pe-3 py-1 bg-white"
                      style={{
                        top: "-3.3rem",
                        left: "-1rem",
                        marginTop: "5rem",
                      }}
                    >
                      Flagged
                      <div
                        className="bg-white d-flex pe-5 pt-3"
                        style={{ marginTop: "-1.4rem", marginRight: "-3.5rem" }}
                      >
                        <button
                          className="rounded-circle border-0 rose-bg d-flex align-items-center justify-content-center"
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            marginRight: "-3rem",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="text-white"
                            style={{ fontSize: "15px" }}
                          />
                        </button>
                      </div>
                    </h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flagged-box-list border-bottom border-secondary">
                      {myData.map((d, i) => (
                        <div className="flagged-box-inner">
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="flagged-box-left">
                                <span className="username-round">SS</span>
                                <div
                                  className="flagged-box-box"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  <h5>Susan Samuel (09:35)</h5>
                                  <div>
                                    <h6
                                      className="d-flex align-=items-center gap-2 flex-wrap"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <FontAwesomeIcon icon={faFlag} /> Flagged
                                      as{" "}
                                      <a
                                        href
                                        className="primary__btn3 px-3 py-1"
                                        style={{ width: "max-content" }}
                                      >
                                        Irrelevant
                                      </a>{" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div
                                className="flagged-box-right"
                                style={{ marginTop: "-10px" }}
                              >
                                <a
                                  href
                                  className="primary__btn3 px-4 py-2"
                                  style={{ width: "max-content" }}
                                >
                                  Contact Admin
                                </a>
                                <h6 className="reply-box">
                                  {" "}
                                  <FontAwesomeIcon icon={faReplyAll} /> Reply
                                  <span>
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                    />
                                  </span>{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
