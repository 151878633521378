import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./virtualSelect.css";
import Select from "react-select";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
const options4 = [
  { value: "Virtual", label: "Virtual" },
  { value: "Video Consultation", label: "Video Consultation" },
];

export const VirtualSelect = () => {
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <Select
      options={options4}
      defaultValue={options4[0]}
      isSearchable={false}
      getOptionLabel={getOptionLabel}
      className="custom-select2" // Add a custom class name for styling
      classNamePrefix="custom-select2" // Add a custom class prefix for styling
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};
