import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import doc from "../../../../images/Body contouring_Color.jpg";
import "../../../../css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const SelectOrderModal = (props) => {
  const matches = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "36rem"}
      closeOnDocumentClick={true}
    >
      <div className="modal-content py-5">
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-3"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faClose} className="ms-2" />
          </button>
        </div>
        <div className="modal-body">
          <div className="forgot-password-inner">
            <span className="icon-box--modal icon-box--image">
              <img src={doc} className="img-fluid w-100 h-100" alt="" />
            </span>
            <h5 className="mt-3 head-title">Botox Tummy Tuck </h5>
            <p>Offer</p>
            <h4>AED 5,404</h4>

            <div className="d-flex flex-column align-items-center gap-0">
              <button type="button" className="primary__btn3 paid">
                Amount Paid
              </button>
              <button type="button" className="primary__btn4 unpaid py-2 px-5">
                Amount Unpaid
              </button>
              <button
                type="button"
                className="primary__btn3"
                onClick={() => {
                  handleClose();
                  props.setShowModal2(true);
                }}
              >
                Cancelled
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
