import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { getAPIResponseData } from "../../services/generalService";
import { PURCHASED_OFFER_BY_PATIENT_ID } from "./actionTypes";
import { setLoading } from "../loading/loadingAction";

export const setReviewsDataByStatus = (data) => {
  return {
    type: PURCHASED_OFFER_BY_PATIENT_ID,
    payload: data,
  };
};
export const getPurchasedOffersByPatient = (id) => {
  return (dispatch) => {
    axiosInstance
      .get(`/purchased-offers?filters[patient][id][$eq]=${id}&populate=*`)
      .then((response) => {
        dispatch(
          setReviewsDataByStatus(getAPIResponseData(response.data.data))
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};
