import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import { Footer } from "../../shared/footer/footer";
import { UserSignUpForm } from "./components/userSignUpForm";
import { RegistrationTabs } from "../../shared/pageComponents/registrationTabs";
import { SignUpTabs } from "../../shared/pageComponents/signUpTabs";

export const SignUpUsers = () => {

  return (
    <div>
      <Header />
      <RegistrationTabs />
      <SignUpTabs />
      <UserSignUpForm />
      <Footer />
    </div>
  );
};
