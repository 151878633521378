import { ModalPopup } from "../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import Select from "react-select";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import { sampleOptions } from "../../../demoData/selectOptionsData";

export const FiltersModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white rounded-3 modal-common-head px-3 pb-4 pt-5">
        <span
          className="close-mega-menu"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="left_chev left-left_chev"
          />
          <FontAwesomeIcon icon={faChevronLeft} className="left_chev" /> Close
        </span>
        <div className="row row-cols-12 gap-4 pt-5">
          <div className="col-12">
            <input
              type="datetime-local"
              style={{
                height: "3.2rem",
                border: "2px solid lightGrey",
                borderRadius: "8px",
              }}
              className="px-2 m-0 w-100 input-group-addon"
            />
          </div>
          <div className="col-12">
            <div className="select-search-icon">
              <Select
                options={sampleOptions}
                getOptionLabel={getOptionLabel}
                isSearchable={true}
                className="profile-form-select-search" // Add a custom class name for styling
                classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-start mt-4">
          <input
            type="submit"
            style={{ background: "black" }}
            className="text-white rounded-pill border-0 px-4 py-2 fs-6"
            value="Submit"
          />
        </div>
      </div>
    </ModalPopup>
  );
};
