import { useNavigate } from "react-router-dom";
import "../css/aos.css";
import "../css/main.css";
import "../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faLocationDot,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { LanguageDropdown } from "./dropdowns/languageDropdown";
import { SearchDoctorsModal } from "./Modals/searchDoctorsModal";
import { MenuModal } from "./Modals/menuModal";
import { UserDropdown } from "./dropdowns/userDropdown";
import { CountryModal } from "./Modals/CountryModal";
import { isAuthenticated } from "../Store/selectors/AuthSelectors";

export const Header = () => {
  const matches = useMediaQuery("(max-width:1200px)");
  const [showLangDropdown, setShowLangDropdown] = useState(false);
  const [showCoutryModal, setShowCoutryModal] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showDoctorsModal, setShowDoctorsModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);

  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };

  const handleUserIcon = () => {
    if (isAuthenticated()) {
      setShowUserDropdown(!showUserDropdown);
    } else {
      navigate("/sign-in");
    }
  };

  return (
    <header className="main_nav_header">
      <div className="header-container-fluid">
        <div className="row">
          <div className="col-sm-5 coloumn-width">
            <div className="header-left">
              <span
                className="close-btn-header"
                onClick={() => setShowMenuModal(true)}
              >
                <FontAwesomeIcon icon={faBars} className="text-2xl" />
              </span>
              {showMenuModal && (
                <MenuModal
                  showMenuModal={showMenuModal}
                  setShowMenuModal={setShowMenuModal}
                />
              )}
              <ul className="mobile-resp">
                <li>
                  <a
                    className={`${isActive("find-doctor") ? "activemenu" : ""}`}
                    href="/find-doctor"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/find-doctor");
                    }}
                  >
                    Find Doctor
                  </a>
                </li>
                <li>
                  <a
                    className={`${isActive("procedure") ? "activemenu" : ""}`}
                    href="/procedure"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/procedure");
                    }}
                  >
                    Explore Procedure
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      isActive("special-offers") ? "activemenu" : ""
                    }`}
                    href="/special-offers"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/special-offers");
                    }}
                  >
                    Special Offer
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      isActive("before-after") ? "activemenu" : ""
                    }`}
                    href="/before-after"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/before-after");
                    }}
                  >
                    Before & After
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-2 col  m-auto coloumn-width-logo">
            <div className="logo">
              <a href="/">
                <img
                  src={require("../images/logo.svg").default}
                  className="img-fluid "
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-sm-5 resp-block ms-auto coloumn-width">
            <ul className={`${matches ? "" : "hidden"}`}>
              <li>
                <span
                  onClick={() => setShowLangDropdown(!showLangDropdown)}
                  className={`flag-click ${showLangDropdown ? "rose-bg" : ""}`}
                >
                  {" "}
                  EN
                </span>
                <LanguageDropdown
                  showLangDropdown={showLangDropdown}
                  position="mt-5"
                />
              </li>
              <li>
                <span
                  className="user-click"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowUserDropdown(!showUserDropdown)}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <UserDropdown
                  showUserDropdow={showUserDropdown}
                  position="mt-5"
                />
              </li>
            </ul>
          </div>
          <div className="col-xl-5 order-lg-3 m-auto">
            <div className="header-right">
              <form action="" className={`${matches ? "top-6" : ""}`}>
                <div
                  className="location"
                  onClick={() => setShowCoutryModal(true)}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Abu Dhabi , AE"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <CountryModal
                  showModal={showCoutryModal}
                  setShowModal={setShowCoutryModal}
                />
                <div
                  className="form-group search-megamenu"
                  onClick={() => setShowDoctorsModal(true)}
                >
                  <input
                    type="text"
                    className="form-control search-doctors"
                    placeholder="Search Doctors/Procedures"
                  />
                  <span className="search-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </div>
                {showDoctorsModal && (
                  <div
                    className="position-fixed top-0 end-0 start-0 bottom-0 pe-5"
                    style={{ background: "rgba(0,0,0,0.8)", zIndex: "500" }}
                  ></div>
                )}
                <SearchDoctorsModal
                  showModal={showDoctorsModal}
                  setShowModal={setShowDoctorsModal}
                />
              </form>
              <ul className="position-relative z-1">
                <li>
                  <span
                    onClick={() => setShowLangDropdown(!showLangDropdown)}
                    className={`flag-click ${
                      showLangDropdown ? "rose-bg text-white" : ""
                    }`}
                  >
                    EN
                  </span>
                  <LanguageDropdown
                    showLangDropdown={showLangDropdown}
                    position="mt-3"
                  />
                </li>
                <li>
                  <span
                    className="user-click"
                    style={{ cursor: "pointer" }}
                    onClick={handleUserIcon}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  {isAuthenticated() && (
                    <UserDropdown
                      showUserDropdow={showUserDropdown}
                      position="mt-3"
                    />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
