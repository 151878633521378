/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const Loading = (props) => {
  const [showLoading, setSHOWLoading] = useState(true);
  const isLoading = useSelector((state) => state.loading.isLoading);

  useEffect(() => {
    if (isLoading) {
      setTimeout(
        () => {
          setSHOWLoading(false);
        },
        props.duration ? props.duration : 0
      );
    } else {
      setTimeout(
        () => {
          setSHOWLoading(isLoading);
        },
        props.duration ? props.duration : 0
      );
    }
  }, [isLoading]);
  return showLoading ? (
    <div
      style={{ zIndex: "900" }}
      className="bg-white d-flex align-items-center justify-content-center gap-5 position-fixed start-0 end-0 top-0 bottom-0 h-100 w-100"
    >
      <h4 className="uppercase font-bold fs-3 rose-text">Loading...!</h4>
    </div>
  ) : (
    props.children
  );
};
