import "../../../css/main.css";
import "../../../css/media.css";
import { badgesData } from "../../../demoData/badgesData";

export const BadgesList = () => {
  return (
    <div className="badge-listing common-pad">
      <div className="row">
        {badgesData.map((data, ind) => (
          <div className="col-lg-4" key={ind + 1}>
            <div className="badge-box px-2">
              <div
                style={{ width: "max-content" }}
                className="position-relative mx-auto badgeImageMain"
              >
                {data.title === "User level 1" ? (
                  <img src={data.img} alt="" />
                ) : (
                  <img src={data.img} className="badgeImage" alt="" />
                )}
                {data.title !== "User level 1" && (
                  <span className="lockIcon">
                    <svg
                      className="svg-inline--fa fa-lock"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fal"
                      data-icon="lock"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M96 192V128C96 57.31 153.3 0 224 0C294.7 0 352 57.31 352 128V192H368C412.2 192 448 227.8 448 272V432C448 476.2 412.2 512 368 512H80C35.82 512 0 476.2 0 432V272C0 227.8 35.82 192 80 192H96zM128 192H320V128C320 74.98 277 32 224 32C170.1 32 128 74.98 128 128V192zM32 432C32 458.5 53.49 480 80 480H368C394.5 480 416 458.5 416 432V272C416 245.5 394.5 224 368 224H80C53.49 224 32 245.5 32 272V432z"
                      ></path>
                    </svg>
                  </span>
                )}
              </div>
              <h6>{data.title}</h6>
              <p>
                Lorem ipsum dolor sit amet, pro id zril molestie, cum ne omittam
                quaestio. Pri delectus conclusionemque te.
              </p>
              <div className="button-flex justify-content-center">
                <a href className="primary__btn3">
                  54 Views
                </a>
                <a href className="primary__btn3">
                  24 Patients
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
