import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import beforeAfter from "../../../images/before-after.png";
import beforeAfter2 from "../../../images/before-after.jpg";
import circleLarge from "../../../images/circle-large.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
export const BeforeAfter = () => {
  const navigate = useNavigate();
  return (
    <section className="before-after">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="before-after-box1">
              <h2>
                Before & After
                <span className="title-head">
                  <span className="type-writer">BE INSPIRED</span>
                </span>
              </h2>
              <p className="p-head-size">
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.ut ullamcorper.ut
                ullamcorper.ut ullamcorper.ut ullamcorper.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="book-consultation">
              <svg id="rectangle">
                <rect id="stroke" rx="3" ry="5" />
              </svg>
              <a
                href="/book-appoinment"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/book-appointment");
                }}
                className="book-appoinment-btn"
              >
                Book a Consultation
              </a>

              <p>
                Lorem ipsum dolor sit amet, <br />
                consectetur
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div
              className="before-after-img-block"
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="2500"
            >
              <img src={beforeAfter} className="img-fluid" alt="" />
              <span className="circle-round">
                <img src={circleLarge} className="img-fluid image" alt="" />
              </span>
            </div>
            <div className="before-after-img-block-resp">
              <img src={beforeAfter2} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="before-after-content">
              <h5>
                <FontAwesomeIcon icon={faStar} className="me-2" />
                Have a question about this?
              </h5>
              <p className="p-head-size-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit,
                sit amet auctor massa maximus eget. Donec luctus at sem et
                cursus. Morbi a rutrum libero.
              </p>
            </div>
            <div className="before-after-content">
              <h5>
                <FontAwesomeIcon icon={faStar} className="me-2" />
                Have a question about this?
              </h5>
              <p className="p-head-size-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit,
                sit amet auctor massa maximus eget. Donec luctus at sem et
                cursus. Morbi a rutrum libero.
              </p>
            </div>
            <div className="d-flex justify-content-center justify-content-lg-end">
              <a href className="view-more-btn2 mb-5 lg:mb-0 mt-3 lg:mt-5">
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
