import { redeemTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { RedeemHeader } from "./components/redeemHeader";
import { RedeemPointsList } from "./components/redeemPointComponents/redeemPointsList";

export const RedeemedPoints = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <RedeemHeader title="Redeemed Points" />
        <TabsGroup tabs={redeemTabsData} />
        <RedeemPointsList />
      </div>
    </section>
  );
};
