import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import circle from "../../../images/circle.png";
import aboutImg2 from "../../../images/about-img2.png";
import aboutUs from '../../../images/about-us.png'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS CSS file
import { useEffect } from "react";

export const WhyClientChoose = () => {
    useEffect(() => {
        AOS.init(); // Initialize AOS
      }, []);
  return (
    <div className="why-clients-choose pb-4 mt-5">
      <div className="container-fluid">
        <div
          className="before-after-box1"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <h2
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            Why clients choose our clinic
            <span className="title-head">
              <span className="type-writer">BE INSPIRED</span>
            </span>
          </h2>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <p
              className="p-head-size"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit,
              sit amet auctor massa maximus eget. Donec luctus at sem et cursus.
              Morbi a rutrum libero. Maecenas accumsan sapien.
            </p>
            <div className="feature-row">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="about-features"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="2500"
                  >
                    <div className="about-feature-img flex justify-center">
                      <img
                        src={aboutImg2}
                        className="img-fluid"
                        alt=""
                      />
                      <span>
                        <img src={circle} className="img-fluid image" alt="" />
                      </span>
                    </div>
                    <h4>Consultation</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="about-features"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="2500"
                  >
                    <div className="about-feature-img flex justify-center">
                      <img
                        src={aboutImg2}
                        className="img-fluid"
                        alt=""
                      />
                      <span>
                        <img src={circle} className="img-fluid image" alt="" />
                      </span>
                    </div>
                    <h4>Aesthetic</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-features">
                    <div
                      className="about-feature-img flex justify-center"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="2500"
                    >
                      <img
                        src={aboutImg2}
                        className="img-fluid"
                        alt=""
                      />
                      <span>
                        <img src={circle} className="img-fluid image" alt="" />
                      </span>
                    </div>
                    <h4>Consultation</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-features">
                    <div
                      className="about-feature-img flex justify-center flex justify-center"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="2500"
                    >
                      <img
                        src={aboutImg2}
                        className="img-fluid"
                        alt=""
                      />
                      <span>
                        <img src={circle} className="img-fluid image" alt="" />
                      </span>
                    </div>
                    <h4>Aesthetic</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <span className="about-img-right">
              <img src={aboutUs} className="img-fluid" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
