import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import doc from "../../../../images/arab F-3.jpg";
import "../../../../css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const AddDocOtpModal = (props) => {
  const matches = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "36rem"}
      closeOnDocumentClick={true}
    >
      <div className="modal-content py-5">
        <div className="modal-header border-0">
          <h5
            className="modal-title text-center w-100"
            id="otpVerificationLabel"
          >
            Validation to add doctor
          </h5>
          <button
            type="button"
            className="btn-close d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-3"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faClose} className="ms-2" />
          </button>
        </div>
        <div className="modal-body">
          <div className="otp-verification">
            <span>
              <img src={doc} className="img-fluid" alt="" />
            </span>
            <h5 className="mt-3 text-dark">Dr Lauren Clinton </h5>
            <p>We have sent you One Time Password to your email</p>
            <p className="otp-text">Please Enter OTP</p>
            <p className="otp-countdown" id="timer-countdown">
              05:00
            </p>

            <div className="verification-code">
              <label className="control-label mb-4">Verification Code</label>
              <div className="verification-code--inputs">
                <input type="text" maxlength="1" />
                <input type="text" maxlength="1" />
                <input type="text" maxlength="1" />
                <input type="text" maxlength="1" />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 d-flex justify-content-center">
          <a href type="button" className="primary__btn4">
            Resend OTP
          </a>
          <button
            type="button"
            className="primary__btn3 line-height"
            onClick={() => {
              handleClose();
              props.setShowModal2(true);
            }}
          >
            Verify OTP
          </button>
        </div>
      </div>
    </ModalPopup>
  );
};
