import { useEffect, useState } from "react";
import "../../../../../css/main.css";
import moment from "moment";
import { imgBaseUrl } from "../../../../../services/config/config";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";
import { getStep2NestedField } from "src/services/dynamicContentService";
import { RejectOrderModal } from "./rejectOrderModal";
export const MyOrdersList = (props) => {
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    if (props.data) {
      setOrders(props.data);
    }
  }, [props.data]);

  const totalBill = (offer) => {
    let b = 0;
    if (offer.discountedAmount > 0) {
      b = offer.discountedAmount * offer.quantity;
    } else {
      b = offer.actualAmount * offer.quantity;
    }
    return b;
  };

  const isMyOrder =
    window.location.pathname === "/doctor-dashboard/wallet-orders";
  return orders && orders.length > 0 ? (
    <div className="tab_container articles-container px-0">
      <div id="tab1" className="tab_content">
        <div className="overflow-scroll-table overflow-auto">
          <table className="table table-style-2 w-100" id="example">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Date of Purchase</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
                <th>Payment status</th>
                <th>Order Status</th>
                <th>Customer</th>
                {isMyOrder && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {orders.map((data, ind) => (
                <tr
                  key={ind + 1}
                  className={`${
                    ind === 1 ? "bg-pink" : ind === 4 ? "bg-grey" : ""
                  }`}
                >
                  <td>{data.orderId}</td>
                  <td>{moment(data.createdAt).format("DD MMM yyyy")}</td>
                  <td className="d-flex align-items-center gap-1 w-100">
                    <span>
                      <img
                        src={`${imgBaseUrl + data.offer.image}`}
                        className="img-fluid rounded-2"
                        style={{
                          width: "6rem",
                          minWidth: "6rem",
                          height: "4rem",
                        }}
                        alt=""
                      />
                    </span>
                    <div
                      className="flex-table-box product-block-box"
                      style={{ width: "10rem", minWidth: "10rem" }}
                    >
                      <h6 style={{ whiteSpace: "nowrap" }}>
                        {data.offer.title}
                        <span className="product-sub-text1">Offers</span>
                        <span className="product-sub-text2">
                          Expires On{" "}
                          {moment(data.offer.validTo).format("DD MMM YYYY")}
                        </span>
                      </h6>
                    </div>
                  </td>
                  <td className="text-center">{data.offer.quantity}</td>
                  <td>
                    <div className="price-strikethrough">
                      <h6>
                        <span style={{ whiteSpace: "nowrap" }}>
                          AED {data.offer.actualAmount}
                        </span>
                        <p
                          className="rose-text text-sm"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          AED {data.offer.discountedAmount}
                        </p>
                      </h6>
                    </div>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    AED {totalBill(data.offer)}
                  </td>
                  <td>
                    {data.paymentStatus === "Pending" ? (
                      <div className="d-flex align-items-center">
                        <span className="pending-round-orange"></span>
                        {data.paymentStatus}
                      </div>
                    ) : data.paymentStatus === "Rejected" ? (
                      <div className="d-flex align-items-center">
                        <span className="cancelled-round-red"></span>
                        {data.paymentStatus}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span className="processed-round-green"></span>{" "}
                        {data.paymentStatus}
                      </div>
                    )}
                  </td>
                  <td>
                    {data.status === "Pending" ? (
                      <div className="d-flex align-items-center">
                        <span className="pending-round-orange"></span>
                        {data.status}
                      </div>
                    ) : data.status === "Rejected" ? (
                      <div className="d-flex align-items-center">
                        <span className="cancelled-round-red"></span>
                        {data.status}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span className="processed-round-green"></span>{" "}
                        {data.status}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="flex-table-box">
                      <span>
                        <img
                          src={`${
                            imgBaseUrl +
                            getStep2NestedField(data, "patient", "profileImg")
                          }`}
                          className="img-fluid"
                          style={{
                            width: "3rem",
                            height: "3rem",
                            minWidth: "3rem",
                          }}
                          alt=""
                        />
                      </span>

                      <h6>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {getStep2NestedField(data, "patient", "firstName")}{" "}
                          {getStep2NestedField(data, "patient", "lastName")}
                        </span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {getStep2NestedField(data, "patient", "city")}
                          {", "}
                          {getStep2NestedField(data, "patient", "country")}
                        </span>
                      </h6>
                    </div>
                  </td>
                  {isMyOrder && (
                    <td>
                      <button
                        onClick={() => {
                          setSelectedItem(data);
                          setShowModal(true);
                        }}
                        className="primary__btn smallText py-2 px-0 mt-2"
                        style={{ width: "8rem" }}
                      >
                        Reject Order
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showModal && (
          <RejectOrderModal
            getData={props.getData}
            selectedItem={selectedItem}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </div>
  ) : (
    <NoDataComp
      title="No Data!"
      desc="There is no any data yet."
      className="mt-5"
    />
  );
};
