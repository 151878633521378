import { useNavigate } from "react-router-dom";
import "../../../../css/main.css";

export const HealthCareWalletTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="pb-2 pt-0 tabs tab-common-dashboard hidden gap-5 gap-lg-0 d-md-flex align-items-center justify-content-lg-between">
      <ul className="ps-4 pt-4 gap-4 overflow-hidden d-flex align-items-center gap-4">
        <li
          className={`px-0 pt-1 me-2 ${
            isActive("/health-care-wallet-order") ? "active" : ""
          }`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/health-care-wallet-order"
            onClick={(e) => {
              e.preventDefault();
              navigate("/health-care-wallet-order");
            }}
          >
            Order
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${
            isActive("/health-care-wallet-status") ? "active" : ""
          }`}
          rel="tab2"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/health-care-wallet-status"
            onClick={(e) => {
              e.preventDefault();
              navigate("/health-care-wallet-status");
            }}
          >
            Status
          </a>
        </li>
      </ul>
      <ul
        className="order-detail-list"
        style={{
          width: "max-content",
          whiteSpace: "nowrap",
        }}
      >
        <li>
          Total Order value <span>AED 1000</span>
        </li>
        <li>
          Paid Amount <span>AED 1000</span>
        </li>
        <li>
          Pending Amount <span>AED 1000</span>
        </li>
        <li>
          Cancelled Amount <span>AED 1000</span>
        </li>
      </ul>
    </div>
  );
};
