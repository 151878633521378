import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import userImg from "../../../../../images/arab F-3.jpg";
import { faClock, faStarHalfAlt } from "@fortawesome/free-regular-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { AddToFav } from "../../../../../shared/AddToComps/addToFav";
import { IsLike } from "../../../../../shared/AddToComps/isLike";
import { IsHelpfull } from "../../../../../shared/AddToComps/isHelpfull";
import { useNavigate } from "react-router-dom";
export const AnswerContent = (props) => {
  const navigate = useNavigate();
  return (
    <div className="q-a-inner">
      <span className="doc-img-qa">
        <img src={userImg} className="img-fluid" alt="" />
      </span>
      <div className="qa-head-inner">
        <h6>
          Dr. Lauren Clinton, &nbsp;&nbsp;<span>Answered on 12 Sep 2020</span>
        </h6>
        <a
          href="/book-appointment"
          onClick={(e) => {
            e.preventDefault();
            navigate("/book-appointment");
          }}
          className="primary__btn3 d-flex align-items-center"
        >
          <FontAwesomeIcon icon={faClock} style={{fontSize: '20px'}} />
          Book Appointment
        </a>
      </div>
      <div className="favorite-ans-div">
        <div className="rating">
          <div className="">
            <FontAwesomeIcon icon={props.solidStar} />
          </div>
          <div className="">
            <FontAwesomeIcon icon={props.solidStar} />
          </div>
          <div className="">
            <FontAwesomeIcon icon={props.solidStar} />
          </div>
          <div className="">
            <FontAwesomeIcon icon={faStarHalfAlt} />
          </div>
          <div className="">
            <FontAwesomeIcon icon={faStarHalfAlt} />
          </div>
        </div>
        <span>(3.5/5)</span>
        <a href className="primary__btn4 mt-0">
          Favorite Answer
        </a>
      </div>

      <p className="p-head-size-16">
        Accessory breast tissue in the arm pit is common in many women. The
        medical term is sometimes called a axillary tail of Spence. You are
        correct in your assessment that skin laxity is a major part of the
        problem and successful treatment needs to include excision of excess
        skin, fat and breast tissue. I’m assuming you don’t have children but
        pregnancy and breast-feeding would most likely cause they are out to
        enlarge. Excising breast tissue prophylactically should reduce the
        chance of that to some degree. Treatment options offered may include
        Liposuction, Liposuction with skin excision or direct surgical excision
        of skin and fat. Actual breast tissue tends to look very similar to fat
        in the area and the surgeons will not differentiate between breast
        tissue and fat but rather deep bulk and remove tissue with and below the
        skin. Personally I would probably recommend direct surgical excision of
        redundant tissue. This will leave a scar but the majority of scar should
        be well hidden in the arm pit. I suggest having multiple in person
        consultations with local board-certified plastic surgeons to hear when
        each of them has to say. Liposuction alone will most likely leave an
        insufficient outcome if skin excision is not done in conjunction. Best,
        Mats Hagstrom MD
      </p>
      <div className="share-box-section">
        <div className="share-box-left">
          <span>
            <FontAwesomeIcon icon={faReply} /> Reply
          </span>
        </div>

        <div className="share-box-right d-flex align-items-center flex-wrap gap-2">
          <button
            title="Add to Favourites!"
            className="border-0 bg-transparent"
          >
            <AddToFav />
          </button>
          <span>1 person found this helpful</span>
          <IsHelpfull label="Helpful" />
          <div className="d-flex align-items-center gap-x-2">
            <span>I Recommend this Doctor</span>
            <IsLike />
          </div>
        </div>
      </div>
    </div>
  );
};
