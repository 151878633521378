import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/play1.png";

export const Banner5 = () => {
  return (
    <div className="video-block">
      <div className="row">
        <div className="col-lg-8">
          <div className="video-block-left">
            <h4 className="text-white">
              Does Botox treatment worth it or not?
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit,
              sit amet auctor massa maximus eget.
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <span className="video-img-box">
            <img src={img1} className="img-fluid" alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};
