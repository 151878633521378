import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  filterByProcedureOptions,
  reviewOptions,
} from "../../../../demoData/selectOptionsData";
import { useMediaQuery } from "@mui/material";

export const OverviewFilterBox = (props) => {
  const matches = useMediaQuery("(max-width:550px)");

  const getOptionLabel = (option) => (
    <div className="flex items-center gap-x-4 justify-between">
      <span className="text-sm xl:text-base">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white rounded-lg modal-common-head px-6 pb-4 pt-20">
        <span
          className="close-mega-menu"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="left_chev left-left_chev"
          />
          <FontAwesomeIcon icon={faChevronLeft} className="left_chev" /> Close
        </span>
        <div className="grid grid-cols-12  gap-y-3 sm:gap-3 w-full ">
          <div className="col-span-12 sm:col-span-8">
            <Select
              options={filterByProcedureOptions}
              getOptionLabel={getOptionLabel}
              defaultValue={filterByProcedureOptions[0]}
              isSearchable={true}
              className="offers-box-select bg-white rounded-xl" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <Select
              options={reviewOptions}
              getOptionLabel={getOptionLabel}
              defaultValue={reviewOptions[0]}
              isSearchable={false}
              className="offers-box-select bg-white rounded-xl" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className="w-full xl:w-max flex justify-start mt-4">
          <input
            type="submit"
            className="bg-black text-white rounded-full px-8 py-2 text-sm sm:text-base"
            value="Submit"
          />
        </div>
      </div>
    </ModalPopup>
  );
};
