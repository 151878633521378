import "../../../../../css/main.css";
import { useEffect, useState } from "react";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";
import { QaIttem } from "./qaItem";

export const QAList = (props) => {
  const [qas, setQas] = useState([]);
  useEffect(() => {
    if (props.data) {
      setQas(props.data);
    }
  }, [props.data]);
  return (
    <div className="tab_container py-0">
      {qas && qas.length > 0 ? (
        <div id="tab1" className="tab_content" style={{ marginTop: "-18px" }}>
          <div className="stories-list">
            {qas.map((data, ind) => (
              <QaIttem data={data} ind={ind} handleUpdate={props.handleUpdate} />
            ))}
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
      
    </div>
  );
};
