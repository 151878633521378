import { ModalPopup } from "../../../../../shared/Modals/ModalPopup";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

export const TicketConfirmModal = (props) => {
  const matches = useMediaQuery("(max-width:630px)");
  const handleClose = () => {
    props.setShowModal(false);
  };

  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white modal-common-head rounded-3">
        <div className="modal-content">
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "#e78a82",
                width: "2rem",
                height: "2rem",
              }}
              className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
            >
              <FontAwesomeIcon icon={faClose} className="ms-2" />
            </button>
          </div>
          <div className="modal-body">
            <div className="forgot-password-inner">
              <span className="icon-box--modal">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <h5 className="mt-3">Ticket ! #57492 !</h5>
              <p>
                Successfully <br />
                Submitted your request
                <span className="">You will get a reply within 48 hrs </span>
              </p>

              <button
                type="button"
                className="primary__btn3"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
