import "../../../css/main.css";
import "../../../css/media.css";

export const HaveQuestion = () => {
  return (
    <section className="have-question-block">
      <div className="container-fluid">
        <div className="have-questions-box">
          <h4>Have Questions?</h4>
          <p>
            Our dedicated representatives are here to help. Mon-Fri 6am-5pm PST
          </p>
          <div className="call-email-flex">
            <a href className="primary__btn4">
              Call us
            </a>
            <a href className="primary__btn3">
              Email us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
