import {
  QA_FORM_DATA,
} from "./actionTypes";

const initialData = {
  formData: {},
};
export function QAReducer(state = initialData, action) {
  switch (action.type) {
    case QA_FORM_DATA: {
      const data = action.payload;
      return {
        ...state,
        formData: data,
      };
    }
    
    default:
      return state;
  }
}
