import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import Select from "react-select";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { sampleOptions } from "../../../../../demoData/selectOptionsData";
import { useRef, useState } from "react";
import { currentDoctorId } from "../../../../../Store/selectors/UserSelectors";
import { ProfileUploadDoc } from "../../../components/profilePageComponents/subComponents/ProfileUploadDoc";
import { formFinalData, getDataforSelect } from "../../../../../services/generalService";
import { useCreateBeforeAfter } from "../../../../../Store/beforAfter/beforeAfterHook";
import { useProcedureState } from "../../../../../Store/procedure/procedureHooks";
import { useBodyPartState } from "../../../../../Store/bodyPart/bodyPartHook";
import { useTreatmentState } from "../../../../../Store/treatment/treatmentHook";

export const UploadPhotosForm = () => {
  const [finalData, setFinalData] = useState({});
  const formRef = useRef(null);
  const imgRef = useRef(null);
  const imgRef2 = useRef(null);
  const createBeforeAfter = useCreateBeforeAfter();
  const procedure = useProcedureState();
  const bodyPartState = useBodyPartState()
  const treatmentState = useTreatmentState()
  const selectRefs = {
    select1: useRef(null),
    select2: useRef(null),
    select3: useRef(null),
    // Add refs for more select fields as needed
  };
  const handleChange = (key, val) => {
    finalData[key] = val;
    setFinalData(finalData);
  };
  const handleClear = () => {
    imgRef.current.clearData();
    imgRef2.current.clearData();
    formRef.current.reset();
    setFinalData({});
    Object.keys(selectRefs).forEach((key) => {
      if (selectRefs[key].current && selectRefs[key].current.select) {
        selectRefs[key].current.select.clearValue();
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // createBeforeAfter(
    //   formFinalData({ ...finalData, doctor: currentDoctorId() })
    // );
    handleClear();
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div
      className="tab_container with-select-search-icon"
      style={{ overflowX: "hidden" }}
    >
      <div id="tab1" className="tab_content">
        <form className="form-card" ref={formRef} onSubmit={handleSubmit}>
          <ProfileUploadDoc
            childRef={imgRef}
            // required
            label="Upload Before Photo"
            placeholder="Upload Photo"
            editableImg={finalData.beforePhoto}
            setFile={(val) => handleChange("beforePhoto", val)}
          />
          <ProfileUploadDoc
            childRef={imgRef2}
            // required
            label="Upload After Photo"
            placeholder="Upload Photo"
            editableImg={finalData.afterPhoto}
            setFile={(val) => handleChange("afterPhoto", val)}
          />
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Body Part</label>
                <div className="select-search-icon">
                  <Select
                    required
                    ref={selectRefs.select1}
                    options={getDataforSelect(bodyPartState.list)}
                    getOptionLabel={getOptionLabel}
                    value={finalData.bodyPart}
                    onChange={(e) => handleChange("bodyPart", e)}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Treatment</label>
                <div className="select-search-icon">
                  <Select
                    required
                    ref={selectRefs.select2}
                    options={getDataforSelect(treatmentState.list)}                  
                    getOptionLabel={getOptionLabel}
                    value={finalData.treatment}
                    onChange={(e) => handleChange("treatment", e)}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Concern</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={finalData.concern}
                  onChange={(e) => handleChange("concern", e.target.value)}
                  placeholder="Add"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Procedure</label>
                <div className="select-search-icon">
                  <Select
                  ref={selectRefs.select3}
                    required
                    options={getDataforSelect(procedure.list2)}                    
                    getOptionLabel={getOptionLabel}
                    value={finalData.procedure}
                    onChange={(e) => handleChange("procedure", e)}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels"> Title</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={finalData.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                  placeholder="Add"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Tags</label>
                <Select
                  required
                  options={sampleOptions}
                  getOptionLabel={getOptionLabel}
                  value={finalData.tags}
                  onChange={(e) => handleChange("tags", e)}
                  isMulti={true}
                  isSearchable={true}
                  className="profile-form-select" // Add a custom class name for styling
                  classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Description</label>
                <textarea
                  required
                  value={finalData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  rows="5"
                  className="form-control"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9 text-end">
                <button
                  type="submit"
                  className="primary__btn3 submit-action-button"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
