import feedImg1 from "../../../../images/detailed-offers/detailed-offers1.jpg";
import feedImg2 from "../../../../images/detailed-offers/detailed-offers2.jpg";
import feedImg3 from "../../../../images/detailed-offers/detailed-offers3.jpg";
import feedImg4 from "../../../../images/video-img3.jpg";
import feedImg5 from "../../../../images/play-small.png";

export const FeedsList = () => {
  return (
    <div className="tab_container px-0 overflow-hidden">
      <div id="tab1" className="tab_content px-0">
        <div className="stories-list">
          <div className="story-box">
            <span className="name-style name-red">RF</span>
            <div className="story-box-head">
              <h4>Roy Ford</h4>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <p className="date-time-review fs-6">
                  12 Sep 2022 | 30 min ago
                  <span className="primary__btn4 px-3 py-0">Reviews</span>
                </p>
              </div>
              <div className="col-lg-5">
                <div className="fav-block">
                  <a
                    href
                    style={{ whiteSpace: "nowrap" }}
                    className="primary__btn4 px-4 py-1 fs-6"
                  >
                    Add to favourites
                  </a>
                  <p className="text-end" style={{ whiteSpace: "nowrap" }}>
                    Chest Reduction
                  </p>
                </div>
              </div>
            </div>
            <h5 className="fs-5">
              Tuberous breast correction with areola reduction & augmentation
            </h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div className="story-types">
              <h6 className="fs-5" style={{ whiteSpace: "nowrap" }}>
                Satisfied with:
              </h6>
              <ul>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Friendlines
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Explanation of health issue
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Treatment Satisfaction
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Value for money
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Less wait time
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="story-box">
            <span className="name-style name-red">RF</span>
            <div className="story-box-head">
              <h4>Michelle Stanley</h4>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago
                  <span className="primary__btn4 px-3 py-0">Reviews</span>
                </p>
              </div>
              <div className="col-lg-5">
                <div className="fav-block">
                  <a
                    href
                    style={{ whiteSpace: "nowrap" }}
                    className="primary__btn4 px-4 py-1 fs-6"
                  >
                    Add to favourites
                  </a>
                  <p className="text-end" style={{ whiteSpace: "nowrap" }}>
                    Chest Reduction
                  </p>
                </div>
              </div>
            </div>
            <h5 className="fs-5">
              Tuberous breast correction with areola reduction & augmentation
            </h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div className="story-types">
              <h6 className="fs-5" style={{ whiteSpace: "nowrap" }}>
                Satisfied with:
              </h6>
              <ul>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Friendlines
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Explanation of health issue
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Treatment Satisfaction
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Value for money
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Less wait time
                  </a>
                </li>
              </ul>
            </div>
            <ul className="img-list-popup">
              <li>
                <img src={feedImg1} className="img-fluid" style={{width: "150px", height: '100px'}} alt="" />
              </li>
              <li>
                <img src={feedImg2} className="img-fluid" style={{width: "150px", height: '100px'}} alt="" />
              </li>
              <li>
                <img src={feedImg3} className="img-fluid" style={{width: "150px", height: '100px'}} alt="" />
              </li>
            </ul>
          </div>
          <div className="story-box">
            <span className="name-style name-red">RF</span>
            <div className="story-box-head">
              <h4>Natasa Palmer</h4>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago
                  <span className="primary__btn4 px-3 py-0">Reviews</span>
                </p>
              </div>
              <div className="col-lg-5">
                <div className="fav-block">
                  <a
                    href
                    style={{ whiteSpace: "nowrap" }}
                    className="primary__btn4 px-4 py-1 fs-6"
                  >
                    Add to favourites
                  </a>
                  <p className="text-end" style={{ whiteSpace: "nowrap" }}>
                    Chest Reduction
                  </p>
                </div>
              </div>
            </div>
            <h5 className="fs-5">
              Tuberous breast correction with areola reduction & augmentation
            </h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. .Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Integer vulputate vehicula urna
              ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. .Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div className="story-types mb-3">
              <h6 className="fs-5" style={{ whiteSpace: "nowrap" }}>
                Satisfied with:
              </h6>
              <ul>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Friendlines
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Explanation of health issue
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Treatment Satisfaction
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Value for money
                  </a>
                </li>
                <li>
                  <a href className="py-2 px-3 fs-6">
                    Less wait time
                  </a>
                </li>
              </ul>
            </div>
            <div className="related-video-block">
              <div className="overlay-video-related">
                <img src={feedImg4} className="img-fluid" style={{width: "200px", height: '150px'}} alt="" />
                <span>
                  <img src={feedImg5} className="img-fluid m-auto" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
