import { ModalPopup } from "./ModalPopup";
import "../../css/main.css";
import "../../css/media.css";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

export const HelpModal = (props) => {
  const matches = useMediaQuery("(max-width:630px)");
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white modal-common-head rounded-3">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-visible-exclamation text-start">
              <ul>
                <li
                  style={{ color: "black" }}
                  className="d-flex align-items-start gap-3"
                >
                  <FontAwesomeIcon icon={faCircle} className="rose-text mt-1" />{" "}
                  Once your healthcare facility added you in their dashboard,
                  your Unlink button will be enabled
                </li>
                <li
                  style={{ color: "black" }}
                  className="mt-2 d-flex align-items-start gap-3"
                >
                  <FontAwesomeIcon icon={faCircle} className="rose-text mt-1" />{" "}
                  You can unlink your profile from healthcare facility dashboard
                  by activating Unlink button
                </li>
              </ul>

              <button
                type="button"
                onClick={handleClose}
                style={{
                  height: "2.4rem",
                  marginTop: "4px",
                  padding: "6px 30px 6px 30px",
                }}
                className="fs-6 primary__btn3 mt-4"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
