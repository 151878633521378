import {
    faChevronDown,
    faReply,
    faShareNodes,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    useMediaQuery,
  } from "@mui/material";
  import img1 from "../../../../../images/qa1.jpg";
  import img2 from "../../../../../images/qa2.jpg";
  import img3 from "../../../../../images/qa3.jpg";
  import { useState } from "react";
  import { ShareDropdown } from "../../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
  import docImg1 from "../../../../../images/arab F-3.jpg";
  import { IsHelpfull } from "../../../../../shared/AddToComps/isHelpfull";
  import { IsLike } from "../../../../../shared/AddToComps/isLike";
  import { IsFavWithHeart } from "../../../../../shared/AddToComps/isFavWithHeart";
import moment from "moment/moment";
import { getStep2NestedField } from "src/services/dynamicContentService";
import { imgBaseUrl } from "src/services/config/config";
  
export const QuesAnsItem = (props) => {
    const [showShareDropdown, setShowShareDropdown] = useState(false);
    const matches = useMediaQuery("(max-width:575px)");
    console.log("ggggggg", props.data)
    return (
      props.data && <div>
        <div className="question-answer-box border-0">
        <span className="search-icon-qa">Q</span>
        <div className="question-ans-head-main">
          <h4 className="fs-2">
            <span style={{ color: "black" }}>
              {props.data.QATitle}
            </span>
          </h4>

          <div className="answer-div-primary w-100">
            <span>Asked by {props.data.whoAsked === "Patient" ? getStep2NestedField(props.data, "patient", "firstName") : getStep2NestedField(props.data, "doctor", "firstName")}</span>{" "}
            <span>{moment(props.data.treatmentDate).format("DD MMM YYYY")}</span>
            <span>{getStep2NestedField(props.data, "bodyPart", "name")}</span>
            <span className="ms-auto mr-0">Viewed by 2,300 </span>
          </div>
          {props.data.photosData && props.data.photosData.length > 0 &&  <ul className="img-list-popup d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap flex-sm-nowrap">
                  {props.data.photosData.map((item, i) => (
 <li>
 <img src={`${imgBaseUrl + item.img}`} className="img-fluid" style={{width: "8rem", minWidth : "8rem", height: "6rem", objectFit: "cover"}} alt="" />
</li>
                  )) }
                  </ul>}
          <p className="p-head-size-16 mt-3">
            {props.data.description}
          </p>

          <div className="share-btn-flex d-flex align-items-center justify-content-end w-100">
            <button onClick={() => props.EditQa(props.data)} className="primary__btn3 me-2 py-2 px-4">
              Edit Question
            </button>
            <div className="mx-2 mx-sm-3 mt-4 position-relative">
              <button
                onClick={() => setShowShareDropdown(!showShareDropdown)}
                className="d-flex align-items-center justify-content-center border-0 bg-transparent gap-2"
              >
                <FontAwesomeIcon
                  style={{ fontSize: "22px" }}
                  icon={faShareNodes}
                  className="mx-auto rose-text"
                />
              </button>
              {showShareDropdown && (
                <ShareDropdown
                  position={`mt-1 ${matches ? "start-0" : "end-0"}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Accordion
        className=" Accordion"
        style={{
          boxShadow: "none",
          background: "transparent",
          padding: "0",
          outline: "none",
          border: "none",
        }}
      >
        <AccordionSummary
          className="pb-0 pt-0 w-100 bg-white"
          style={{ height: "4rem" }}
        >
          <h4
            className="d-flex w-100 align-items-center gap-3 justify-content-between flagged-slide pe-3 py-1 bg-white"
            style={{ top: "-3.3rem", left: "-1rem", marginTop: "5rem" }}
          >
            <p className="bg-white  pe-3 mt-1 fs-2 position-relative z-5">
              Answers
            </p>
            <div
              className="bg-white d-flex pe-5 pt-3"
              style={{ marginTop: "-1.4rem", marginRight: "-3.5rem" }}
            >
              <button
                className="rounded-circle border-0 rose-bg d-flex align-items-center justify-content-center"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  marginRight: "-3rem",
                }}
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-white ms-2"
                  style={{ fontSize: "15px" }}
                />
              </button>
            </div>
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="qa-listing">
            <div className="q-a-inner border-bottom py-3">
              <div className="qa-head-inner d-flex flex-wrap gx-3">
                <h6>
                  Dr. Lauren Clinton, &nbsp;&nbsp;
                  <span>Answered on 12 Sep 2020 </span>
                </h6>
                <div className="button-flex">
                  <a className="primary__btn4 mt-0 fs-6 fs-lg-5 py-1 py-lg-2">
                    Marrked as favourite answer
                  </a>
                  <span className="viewed-by fs-6 mt-3 ms-3 text-secondary">
                    Viewed by 2,300
                  </span>
                </div>
              </div>
              <span className="doc-img-qa mt-2">
                <img src={docImg1} className="img-fluid" alt="" />
              </span>
              <h5 className="mt-2">Sidewalk consultation on complex issue</h5>
              <p className="p-head-size-16">
                Accessory breast tissue in the arm pit is common in many
                women. The medical term is sometimes called a axillary tail
                of Spence. You are correct in your assessment that skin
                laxity is a major part of the problem and successful
                treatment needs to include excision of excess skin, fat and
                breast tissue. I’m assuming you don’t have children but
                pregnancy and breast-feeding would most likely cause they
                are out to enlarge. Excising breast tissue prophylactically
                should reduce the chance of that to some degree. Treatment
                options offered may include Liposuction, Liposuction with
                skin excision or direct surgical excision of skin and fat.
                Actual breast tissue tends to look very similar to fat in
                the area and the surgeons will not differentiate between
                breast tissue and fat but rather deep bulk and remove tissue
                with and below the skin. Personally I would probably
                recommend direct surgical excision of redundant tissue. This
                will leave a scar but the majority of scar should be well
                hidden in the arm pit. I suggest having multiple in person
                consultations with local board-certified plastic surgeons to
                hear when each of them has to say. Liposuction alone will
                most likely leave an insufficient outcome if skin excision
                is not done in conjunction. Best, Mats Hagstrom MD
              </p>
              <div className="share-box-section">
                <div className="share-box-left">
                  <span>
                    <FontAwesomeIcon icon={faReply} /> Reply
                  </span>
                </div>

                <div className="share-box-right d-flex align-items-center gap-3 flex-wrap flex-md-nopwrap">
                  <button
                    className="button like rounded like-effect-click"
                    data-toggle="tooltip"
                    title="Add to Favourites!"
                  >
                    <IsFavWithHeart
                      favBg={"#e78a82"}
                      favColor="white"
                      disabledColor="gray"
                    />
                  </button>
                  <span>1 person found this helpful</span>
                  <IsHelpfull label="Helpful" />
                  <span>I Recommend this Doctor</span>

                  <IsLike />
                </div>
              </div>
            </div>
            <div className="q-a-inner border-bottom py-3">
              <div className="qa-head-inner d-flex flex-wrap gx-3">
                <h6>
                  Dr. Lauren Clinton, &nbsp;&nbsp;
                  <span>Answered on 12 Sep 2020 </span>
                </h6>
                <div className="button-flex">
                  <a className="primary__btn4 mt-0 fs-6 fs-lg-5 py-1 py-lg-2">
                    Marrked as favourite answer
                  </a>
                  <span className="viewed-by fs-6 mt-3 ms-3 text-secondary">
                    Viewed by 2,300
                  </span>
                </div>
              </div>
              <span className="doc-img-qa mt-2">
                <img src={docImg1} className="img-fluid" alt="" />
              </span>
              <h5 className="mt-2">Sidewalk consultation on complex issue</h5>
              <p className="p-head-size-16">
                Accessory breast tissue in the arm pit is common in many
                women. The medical term is sometimes called a axillary tail
                of Spence. You are correct in your assessment that skin
                laxity is a major part of the problem and successful
                treatment needs to include excision of excess skin, fat and
                breast tissue. I’m assuming you don’t have children but
                pregnancy and breast-feeding would most likely cause they
                are out to enlarge. Excising breast tissue prophylactically
                should reduce the chance of that to some degree. Treatment
                options offered may include Liposuction, Liposuction with
                skin excision or direct surgical excision of skin and fat.
                Actual breast tissue tends to look very similar to fat in
                the area and the surgeons will not differentiate between
                breast tissue and fat but rather deep bulk and remove tissue
                with and below the skin. Personally I would probably
                recommend direct surgical excision of redundant tissue. This
                will leave a scar but the majority of scar should be well
                hidden in the arm pit. I suggest having multiple in person
                consultations with local board-certified plastic surgeons to
                hear when each of them has to say. Liposuction alone will
                most likely leave an insufficient outcome if skin excision
                is not done in conjunction. Best, Mats Hagstrom MD
              </p>
              <div className="share-box-section">
                <div className="share-box-left">
                  <span>
                    <FontAwesomeIcon icon={faReply} />
                    Reply
                  </span>
                </div>

                <div className="share-box-right d-flex align-items-center gap-3 flex-wrap flex-md-nowrap">
                  <button
                    className="button like rounded like-effect-click"
                    data-toggle="tooltip"
                    title="Add to Favourites!"
                  >
                    <IsFavWithHeart
                      favBg={"#e78a82"}
                      favColor="white"
                      disabledColor="gray"
                    />
                  </button>
                  <span>1 person found this helpful</span>
                  <IsHelpfull label="Helpful" />
                  <span>I Recommend this Doctor</span>

                  <IsLike />
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      </div>
    )
}