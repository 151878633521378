// react
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export function useAppAction(action) {
  const dispatch = useDispatch();

  return useCallback((...args) => dispatch(action(...args)), []);
}

export function useAppSelector(selector) {
  return useSelector(selector);
}
