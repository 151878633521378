import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import { useEffect } from "react";

export const OfferBlock = () => {
    useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div className="offer-block overflow-hidden">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-5">
            <span
              className="offer-img-left"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            ></span>
          </div>
          <div className="col-lg-7 position-relative">
            <span className="offer-text">Lorem ipsum dolor</span>
            <div className="offer-block2">
              <div className="offer-left">
                <div className="offer-number">
                  <span>Offer</span>
                  <h3>
                    <span className="typed-text"></span>
                    <span className="cursor">&nbsp;</span>
                  </h3>
                </div>
                <p>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing</p>
              </div>
              <a href="/" className={`view-more-btn offer-block-btn`}>
               View More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
