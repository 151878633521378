import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { genderOptions, languageOptions, sampleOptions } from "../../../../demoData/selectOptionsData";
import { useEffect, useState } from "react";
import { ProfileUploadDoc } from "../../../dashboard/components/profilePageComponents/subComponents/ProfileUploadDoc";
import { currentPatientId } from "../../../../Store/selectors/UserSelectors";
import { formFinalData } from "../../../../services/generalService";
import { SkinColorComp } from "../skinColorComp";
import {
  usePatient,
  useSetPatientProfileFormData,
  useUpdatePatient,
} from "../../../../Store/Patient/patientHook";
import countries from "../../../../demoData/countries.json";

export const UserProfileForm = () => {
  const [finalData, setFinalData] = useState({});
  const setPatientProfileFormData = useSetPatientProfileFormData();
  const patient = usePatient();
  const updatePatient = useUpdatePatient();
  useEffect(() => {
    if (patient.formData) {
      setFinalData(patient.formData);
    }
  }, [patient.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setPatientProfileFormData(updatedFinalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentPatientId())
      updatePatient({ id: currentPatientId(), ...formFinalData(finalData) });
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="profile-section">
      <form onSubmit={handleSubmit} action="" id="msform" className="px-0">
        <div
          className="form-card"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <ProfileUploadDoc
            label="Upload Profile Photo"
            placeholder=""
            editableImg={finalData.profileImg}
            setFile={(val) => handleChange("profileImg", val)}
          />

          <div className="form-group form-group-select">
            <div className="row">
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-xl-2">
                    <label className="fieldlabels">
                      Title <span>*</span>
                    </label>
                    <Select
                      options={[
                        { label: "Mr", value: "Mr" },
                        { label: "Mrs", value: "Mrs" },
                      ]}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.title}
                      onChange={(val) => handleChange("title", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>

                  <div className="col-xl-5">
                    <label className="fieldlabels">First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={finalData.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-xl-5">
                    <label className="fieldlabels">Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={finalData.lastName}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-3 m-auto">Dr. John Abraham</div>
            </div>
          </div>

          <div className="form-group form-group-select">
            <div className="row">
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-xl-6">
                    <label className="fieldlabels">Mobile</label>
                    <div className="country-code-listing">
                      <input
                        type="number"
                        required
                        value={finalData.phone}
                        onChange={(e) => handleChange("phone", e.target.value)}
                        placeholder=""
                        className="form-control"
                      />
                      <button type="submit" className="edit-btn-btn">
                        Edit <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <label className="fieldlabels">E-mail ID</label>
                    <div className="edit-box">
                      <input
                        type="email"
                        value={finalData.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                        className="form-control"
                      />
                      <button type="submit" className="edit-btn-btn">
                        Edit <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 m-auto">
                UK, Britain, Yorkshire, Whales
              </div>
            </div>
          </div>

          <div className="form-group form-group-select">
            <div className="row">
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-xl-3">
                    <label className="fieldlabels">
                      Gender <span>*</span>
                    </label>
                    <Select
                      options={genderOptions}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.gender}
                      onChange={(val) => handleChange("gender", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">
                      DOB <span>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      required
                      value={finalData.birthDate}
                      onChange={(e) =>
                        handleChange("birthDate", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">
                      Language <span>*</span>
                    </label>
                    <Select
                      options={languageOptions}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.language}
                      onChange={(val) => handleChange("language", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">
                      Ethinicity <span>*</span>
                    </label>
                    <Select
                      options={sampleOptions}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.ethinicity}
                      onChange={(val) => handleChange("ethinicity", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-3 m-auto">Dr. John Abraham</div>
            </div>
          </div>

          <div className="form-group form-group-select">
            <div className="row">
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-xl-3">
                    <label className="fieldlabels">Blood Group </label>
                    <Select
                      options={sampleOptions}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.bloodGroup}
                      onChange={(val) => handleChange("bloodGroup", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">
                      Height <span>*</span>
                    </label>
                    <Select
                      options={sampleOptions}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.height}
                      onChange={(val) => handleChange("height", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">Weight</label>
                    <Select
                      options={sampleOptions}
                      getOptionLabel={getOptionLabel}
                      required
                      value={finalData.weight}
                      onChange={(val) => handleChange("weight", val)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">BMI</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={finalData.bmi}
                      onChange={(e) => handleChange("bmi", e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-3 m-auto">Dr. John Abraham</div>
            </div>
          </div>

          <SkinColorComp
            defaultValue={finalData.skinColor}
            onChange={(val) => handleChange("skinColor", val)}
          />
          <div className="form-group form-group-select">
            <div className="row">
              <div className="col-xl-9">
                <div className="row gy-3">
                  <div className="col-xl-3">
                    <label className="fieldlabels">
                      Country <span>*</span>
                    </label>
                    <Select
                      options={countries}
                      getOptionLabel={getOptionLabel}
                      value={finalData.country}
                      onChange={(e) => handleChange("country", e)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">State</label>
                    <Select
                      options={countries}
                      getOptionLabel={getOptionLabel}
                      value={finalData.state}
                      onChange={(e) => handleChange("state", e)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">District</label>
                    <Select
                      options={countries}
                      getOptionLabel={getOptionLabel}
                      value={finalData.district}
                      onChange={(e) => handleChange("district", e)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-xl-3">
                    <label className="fieldlabels">City</label>
                    <Select
                      options={countries}
                      getOptionLabel={getOptionLabel}
                      value={finalData.city}
                      onChange={(e) => handleChange("city", e)}
                      isSearchable={true}
                      className="my-form-select" // Add a custom class name for styling
                      classNamePrefix="my-form-select" // Add a custom class prefix for styling
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-3 m-auto">Dr. John Abraham</div>
            </div>
          </div>
          <button type="submit" name="next" className="action-button">
            Submit <i className="fa-light fa-arrow-right-long ms-3"></i>
          </button>
        </div>
      </form>
    </div>
  );
};
