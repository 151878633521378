import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import { faUser } from "@fortawesome/free-regular-svg-icons";
export const Analytics = () => {
  return (
    <div className="dashboard-firstblock">
      <div className="row">
        <div className="col-lg-3">
          <div className="small-box green">
            <span className="icon-box">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <div className="content-box-right">
              <h5>
                550 <span>Total Patient</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 ">
          <div className="small-box blue ">
            <span className="icon-box">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <div className="content-box-right">
              <h5>
                550 <span>Total Patient</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 ">
          <div className="small-box violet">
            <span className="icon-box">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <div className="content-box-right">
              <h5>
                550 <span>Total Patient</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="small-box dark-blue">
            <span className="icon-box">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <div className="content-box-right">
              <h5>
                550 <span>Total Patient</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
