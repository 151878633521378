import { ReviewsHeader } from "./components/reviewsHeader";
import { TabsGroup } from "../../../shared/tabsGroup";
import { userReviewsTabs } from "../../../demoData/tabsData";
import { useEffect, useState } from "react";
import { Loading } from "../../../shared/loadings/Loading";
import { getPatientReviewByStatus } from "src/Store/review/reviewAction";
import { UserReviewsList } from "./components/userReviewsList";

export const LiveUserReviews = () => {
  const [reviewsList, setReeviewsList] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    res = await getPatientReviewByStatus("Live")
    setReeviewsList(res)
  }

  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <ReviewsHeader title="Live Reviews (19)" />
        <TabsGroup tabs={userReviewsTabs} />
        <UserReviewsList data={reviewsList} handleUpdate={()=>{}} />

      </div>
    </section>
  );
};
