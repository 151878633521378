import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReviewStars } from "../../../../../shared/reviewStars";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteFavDoctor,
  useFavDoctor,
} from "../../../../../Store/favoruiteDoctor/favoruiteDoctorHooks";
import { imgBaseUrl } from "../../../../../services/config/config";
import { useEffect, useState } from "react";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";

export const FavDoctorsCards = () => {
  const [doctors, setDoctors] = useState([]);
  const favDoctorState = useFavDoctor();
  const removeFavDoctor = useDeleteFavDoctor();

  useEffect(() => {
    if (favDoctorState) {
      let arr = [];
      favDoctorState.list.forEach((d) => {
        if (d.doctor && d.doctor.data && d.doctor.data.id) {
          arr.push({
            id: d.doctor.data.id,
            ...d.doctor.data.attributes,
            entryId: d.id,
          });
        }
      });
      setDoctors(arr);
    }
  }, [favDoctorState]);
  return (
    <div className="tab_container three-block-container px-0">
      <div id="tab1" className="tab_content">
        <section className="doctors-listing-block doctors-listing-section pt-0">
          {doctors && doctors.length > 0 ? (
            <div className="row">
              {doctors.map((data, ind) => (
                <div className="col-lg-6" key={ind + 1}>
                  <div
                    className="doctors-listing-box position-relative"
                    style={{ border: "1px solid lightGray" }}
                  >
                    <div className="row">
                      <div className="col-xl-3">
                        <div className="doctors-listing-left-box">
                          <span>
                            <img
                              src={`${imgBaseUrl + data.profileImg}`}
                              className="img-fluid rounded-circle"
                              style={{
                                width: "8rem",
                                minWidth: "8rem",
                                height: "8rem",
                                objectFit: "cover",
                              }}
                              alt="doc1"
                            />
                          </span>
                          <a
                            href="doctor-details.html"
                            className="view-profile"
                            style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                          >
                            View Profile
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-9">
                        <div className="doctor-details">
                          <h5>
                            <a href className="fs-5">
                              {data.firstName + " "}{" "}
                              {data.lastName && data.lastName}
                            </a>
                          </h5>
                          <ul className="d-flex flex-column gap-0 mt-0">
                            {data.educations && data.educations.length > 0 && (
                              <li className="d-flex align-items-center flex-wrap gap-1 p-0 m-0">
                                {data.educations.map((d, i) => (
                                  <span key={i + 1} className="p-0 m-0">
                                    {d},
                                  </span>
                                ))}
                              </li>
                            )}

                            {(data.state || data.country) && (
                              <li>
                                {data.state}, {data.country}
                              </li>
                            )}
                            {data.totalExperienceYears && (
                              <li>
                                {data.totalExperienceYears}+ Yrs Experience
                              </li>
                            )}
                            {data.title && <li>{data.title}</li>}
                            {data.languages && data.languages.length > 0 && (
                              <li className="d-flex align-items-center gap-1 p-0 m-0">
                                Speaks:{" "}
                                {data.languages.map((d, i) => (
                                  <span key={i + 1} className="p-0 m-0">
                                    {d},
                                  </span>
                                ))}
                              </li>
                            )}
                          </ul>
                          <div className="detail-box mt-2">
                            <ReviewStars size="17px" />
                            <span className="number">(1,378)</span>
                            <a
                              href="badge.html"
                              type="button"
                              data-toggle="tooltip"
                              title="Hooray!"
                            >
                              <i className="fa-sharp fa-solid fa-trophy-star"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <span
                        className="delete-icon-box"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFavDoctor(data.entryId)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoDataComp
              title="No Data!"
              desc="There is no any data yet."
              className="mt-5"
            />
          )}
        </section>
      </div>
    </div>
  );
};
