import img1 from "../images/before-after-listing/before-after-listing1.jpg";
import img2 from "../images/before-after-listing/before-after-listing2.jpg";
import img3 from "../images/before-after-listing/before-after-listing3.jpg";
import img4 from "../images/before-after-listing/before-after-listing4.jpg";

export const FaceQuestionAnswersSelectionData = [
  {
    title: "Axillary Lipo tissue and loose skin, what are my options?",
    img: img1,
  },
  {
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    img: img2,
  },
  {
    title: "Axillary Lipo tissue and loose skin, what are my options?",
    img: img3,
  },
  {
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    img: img4,
  },
  {
    title: "Axillary Lipo tissue and loose skin, what are my options?",
    img: img1,
  },
  {
    title: "Axillary Lipo tissue and loose skin, what are my options?",
    img: img2,
  },
  {
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    img: img3,
  },
  {
    title: "Axillary Lipo tissue and loose skin, what are my options?",
    img: img4,
  },
  {
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    img: img1,
  },
  {
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    img: img2,
  },
  {
    title: "Axillary Lipo tissue and loose skin, what are my options?",
    img: img3,
  },
  {
    title: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    img: img4,
  },
];
