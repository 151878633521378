import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import { ReviewStars } from "../../../../../shared/reviewStars";
import { faEye, faFlag } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamationCircle,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import { IsLike } from "../../../../../shared/AddToComps/isLike";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PaginationBox } from "../../../../../shared/paginationBox";

const reviewsData = [{ color: "name-red", name: "Bhavani Shankar" }];
const myData = [1, 2, 3];

export const FlaggedReviewsList = (props) => {
  return (
    <div className="tab_container py-0 px-0 overflow-hidden">
      <div id="tab1" className="tab_content py-0">
        <div className="stories-list py-0">
          {reviewsData.map((data, ind) => (
            <div className="story-box" key={ind + 1}>
              <span className={`name-style ${data.color}`}>BH</span>
              <div className="story-box-head">
                <div className="story-box-head-left">
                  <h4>{data.name}</h4>
                </div>
                <div className="story-box-head-right d-flex align-items-center gap-1 justify-content-center justify-content-md-start">
                  <span>12 Sep 2022 | 24 months ago</span>
                  <div
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                    className="rounded-circle border-0 d-flex align-items-center justify-content-center position-relative"
                  >
                    <IsLike />
                  </div>
                  <span>Highly recommend the doctor</span>
                </div>
              </div>
              <div className="rating-box-wrapper">
                <div className="rating-box-stories">
                  <ReviewStars />
                  <span className="number">(1,378)</span>
                  <span>
                    <FontAwesomeIcon icon={faEye} /> 2,300{" "}
                  </span>
                </div>
                <div className="rating-box-wrap-right">
                  <span>Chest Reduction</span>
                </div>
              </div>

              <h5>
                Tuberous breast correction with areola reduction & augmentation
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer vulputate vehicula urna ut
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <div className="story-types">
                <h6 style={{ whiteSpace: "nowrap" }} className="fs-5">
                  Satisfied with:
                </h6>
                <ul>
                  <li>
                    <a href className="fs-6">
                      Friendlines
                    </a>
                  </li>
                  <li>
                    <a href className="fs-6">
                      Explanation of health issue
                    </a>
                  </li>
                  <li>
                    <a href className="fs-6">
                      Treatment Satisfaction
                    </a>
                  </li>
                  <li>
                    <a href className="fs-6">
                      Value for money
                    </a>
                  </li>
                  <li>
                    <a href className="fs-6">
                      Less wait time
                    </a>
                  </li>
                </ul>
              </div>
              <Accordion
                className="mt-5 Accordion"
                style={{
                  boxShadow: "none",
                  background: "transparent",
                  padding: "0",
                  outline: "none",
                }}
              >
                <AccordionSummary
                  className="pb-0 pt-0 w-100 bg-white"
                  style={{ height: "4rem" }}
                >
                  <h4
                    className="d-flex w-100 align-items-center gap-3 justify-content-between flagged-slide pe-3 py-1 bg-white"
                    style={{ top: "-3.3rem", left: "-1rem", marginTop: "5rem" }}
                  >
                    Flagged
                    <div
                      className="bg-white d-flex pe-5 pt-3"
                      style={{ marginTop: "-1.4rem", marginRight: "-3.5rem" }}
                    >
                      <button
                        className="rounded-circle border-0 bg-secondary d-flex align-items-center justify-content-center"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginRight: "-3rem",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="text-white"
                          style={{ fontSize: "15px" }}
                        />
                      </button>
                    </div>
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flagged-box-list">
                    {myData.map((d, i) => (
                      <div className="flagged-box-inner">
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="flagged-box-left">
                              <span className="username-round">SS</span>
                              <div
                                className="flagged-box-box"
                                style={{ whiteSpace: "wrap" }}
                              >
                                <h5>Susan Samuel (09:35)</h5>
                                <div>
                                  <h6
                                    className="d-flex align-=items-center gap-2 flex-wrap"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <FontAwesomeIcon icon={faFlag} /> Flagged as{" "}
                                    <a
                                      href
                                      className="primary__btn3 px-3 py-1"
                                      style={{ width: "max-content" }}
                                    >
                                      Irrelevant
                                    </a>{" "}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div
                              className="flagged-box-right"
                              style={{ marginTop: "-10px" }}
                            >
                              <a
                                href
                                className="primary__btn3 px-4 py-2"
                                style={{ width: "max-content" }}
                              >
                                Contact Admin
                              </a>
                              <h6 className="reply-box">
                                {" "}
                                <FontAwesomeIcon icon={faReplyAll} /> Reply
                                <span>
                                  <FontAwesomeIcon icon={faExclamationCircle} />
                                </span>{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
          <PaginationBox />
        </div>
      </div>
    </div>
  );
};
