import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

export const CommentBox = () => {
  return (
    <section className="common-pad">
      <div className="container-fluid">
        <div className="submit-reply">
          <a href className="mp-ico">
            MP
          </a>
          <form action="">
            <div className="form-group">
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className="form-control"
              >
                Write your comment here
              </textarea>
              <a href className="primary__btn3">
               <FontAwesomeIcon icon={faPenToSquare} /> Submit your reply
              </a>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
