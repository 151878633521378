import { useNavigate } from "react-router-dom";
import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/phone.png";

export const Banner6 = () => {
  const navigate = useNavigate();
  return (
    <div className="find-doctor">
      <div className="row">
        <div className="col-lg-5">
          <h4>Plan your Procedure</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur ullarper. Sed pretium ipsum
            elit, sit amet auctor massa m auctor massa m
          </p>
        </div>
        <div className="col-lg-4 mt-auto text-center">
          <img src={img1} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-3 m-auto text-right">
          <a
            href="/find-doctor-doctors-listing"
            onClick={(e) => {
              e.preventDefault();
              navigate("/find-doctor-doctors-listing");
            }}
            className="primary_btn"
          >
            Consult Now
          </a>
        </div>
      </div>
    </div>
  );
};
