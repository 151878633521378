import "../../css/main.css";
import "../../css/media.css";
import { HealthProfileHeader } from "./components/profileComponents/healthProfileHeader";
import { DoctorsListContent } from "./components/doctorsListComponents/doctorsListContent";

export const DoctorsList = () => {
  return (
    <div>
      <HealthProfileHeader
        title="Add Healthcare Facility"
        page="Doctors List"
      />
      <DoctorsListContent />
    </div>
  );
};
