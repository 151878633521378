import { ReviewsHeader } from "./components/reviewsHeader";
import { reviewTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { useEffect, useState } from "react";
import { getDoctorReviewsByStatus, updateReview } from "src/Store/review/reviewAction";
import { UserReviewsList } from "src/Pages/userDashboard/userReviews/components/userReviewsList";
import { currentDoctorId } from "src/Store/selectors/UserSelectors";

export const LiveReviews = () => {
  const [reviewsList, setReeviewsList] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    res = await getDoctorReviewsByStatus(currentDoctorId(), "Live")
    setReeviewsList(res)
  }
  const handleUpdate= (data) => {
      updateReview({ id: data.id, status: "Hide" }, "Review hidden successfully!", getData)
  }
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <ReviewsHeader title="My Reviews (19)" />
        <TabsGroup tabs={reviewTabsData()} />
        <UserReviewsList data={reviewsList} handleUpdate={handleUpdate} />
      </div>
    </section>
  );
};
