import { NotificationSettingsTabs } from "./notificationSettingsTabs";
import { useState } from "react";
import "../../../../../css/main.css";
import { EmailContent } from "./emailContent";
import { WhatsappContent } from "./whatsappContent";
import { UnsubscribeContent } from "./unsubscribeContent";

export const NotificationsettingsContent = () => {
  const [tab, setTab] = useState("Email");
  return (
    <div className="tab_container articles-container pt-0 px-0">
      <div id="tab1" className="tab_content">
        <div className="settings-div notification-block px-0 px-sm-5">
          <NotificationSettingsTabs tab={tab} setTab={setTab} />
          <div className="tab-content" id="myTabContent">
            {tab === "Email" && <EmailContent />}
            {tab === "Whatsapp" && <WhatsappContent />}
            {tab === "Unsubscribe" && <UnsubscribeContent />}
          </div>
        </div>
      </div>
    </div>
  );
};
