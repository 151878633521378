import { TabsGroup } from "../../../shared/tabsGroup";
import { userWalletTabs } from "../../../demoData/tabsData";
import { ReviewsHeader } from "../userReviews/components/reviewsHeader";
import { CartTable } from "./components/cartComponents/cartTable";
import { useGetCartByPatient } from "../../../Store/cart/cartHook";
import { useEffect } from "react";
import { Loading } from "src/shared/loadings/Loading";

export const MyCart = () => {
  const getCartByPatient = useGetCartByPatient()
  useEffect(() => {
    getCartByPatient()
  }, []);
  return (
    <section className="doctor-detail-stories-tab wallet-cart-table">
       <Loading duration={500} />
      <div className="container-fluid p-0">
        <ReviewsHeader title="My Cart (19)" />
        <TabsGroup tabs={userWalletTabs} />
        <CartTable />
      </div>
    </section>
  );
};
