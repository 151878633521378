import "../../../../../css/main.css";

export const InviteesStatusContent = () => {
  return (
    <div className="tab_container articles-container overflow-hidden px-0">
      <div id="tab1" className="tab_content px-0">
        <div className="refer-block">
          <h4>Together, we are going further!</h4>
          <div className="invitee-status">
            <div className="invitee-status-box">
              <span className="name-invitee blue">MB</span>
              <h6 className="fs-3">
                Marie Brewer <span>12 Sep 2022 | 2 days ago</span>
              </h6>
            </div>
            <div className="invitee-status-right">
              <a href className="pending primary__btn3 py-1 px-4">
                Pending
              </a>
            </div>
          </div>

          <div className="invitee-status">
            <div className="invitee-status-box">
              <span className="name-invitee green">MB</span>
              <h6 className="fs-3">
                Will Nelson <span>12 Sep 2022 | 2 days ago</span>
              </h6>
            </div>
            <div className="invitee-status-right">
              <a href className="pending primary__btn3 py-1 px-4">
                Pending
              </a>
            </div>
          </div>

          <div className="invitee-status">
            <div className="invitee-status-box">
              <span className="name-invitee yellow">MB</span>
              <h6 className="fs-3">
                Ann Matthews <span>12 Sep 2022 | 2 days ago</span>
              </h6>
            </div>
            <div className="invitee-status-right">
              <a href className="primary__btn4">
                Entrolled
              </a>
            </div>
          </div>

          <div className="invitee-status">
            <div className="invitee-status-box">
              <span className="name-invitee purple">MB</span>
              <h6 className="fs-3">
                Alex Fox <span>12 Sep 2022 | 2 days ago</span>
              </h6>
            </div>
            <div className="invitee-status-right">
              <a href className="primary__btn4">
                Entrolled
              </a>
            </div>
          </div>

          <div className="invitee-status">
            <div className="invitee-status-box">
              <span className="name-invitee blue">MB</span>
              <h6 className="fs-3">
                Carmen Vargas <span>12 Sep 2022 | 2 days ago</span>
              </h6>
            </div>
            <div className="invitee-status-right">
              <a href className="primary__btn4">
                Entrolled
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
