import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { departments, sampleOptions } from "../../../../../demoData/selectOptionsData";
import { useEffect, useState } from "react";
import { handleFieldContent } from "./contentsServices";
import { getDataforSelect, getSelectData } from "../../../../../services/generalService";
import { useProcedureState } from "../../../../../Store/procedure/procedureHooks";
import {
  useDoctor,
  useSetDoctorProfileFormData,
} from "../../../../../Store/Doctor/doctorHooks";

export const FormStep2 = (props) => {
  const [experienceFields, setExperienceFields] = useState(1);
  const [finalData, setFinalData] = useState({});
  const useDoctorState = useDoctor();
  const procedure = useProcedureState();
  const setDoctorProfileFormData = useSetDoctorProfileFormData();

  useEffect(() => {
    if (useDoctorState.formData) {
      if (
        useDoctorState.formData.professionalExperiences &&
        useDoctorState.formData.professionalExperiences.length > 1
      ) {
        setExperienceFields(
          useDoctorState.formData.professionalExperiences.length
        );
      }
      setFinalData(useDoctorState.formData);
    }
  }, [useDoctorState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setDoctorProfileFormData(updatedFinalData);
  };

  const handleChangeArray = (key, val, i) => {
    let arr = finalData[key] ? finalData[key] : [];
    arr[i - 1] = val;
    const updatedFinalData = { ...finalData, [key]: arr };
    setDoctorProfileFormData(updatedFinalData);
    setFinalData(updatedFinalData);
  };

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <fieldset>
      <div className="form-card">
        <div className="form-group">
          <label className="fieldlabels">Designation</label>
          <div className="row">
            <div className="col-lg-9">
              <input
                className="form-control"
                type="text"
                required
                value={finalData.designation}
                onChange={(e) => handleChange("designation", e.target.value)}
                placeholder="Enter"
              />
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Senior Dermatologist
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="fieldlabels">Professional Experience</label>
          <div className="row">
            <div className="col-11 col-lg-9">
              {handleFieldContent(
                experienceFields,
                setExperienceFields,
                finalData,
                handleChangeArray,
                "professionalExperiences"
              )}
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Specialist - ENT (2017 - 2022) Kings Cosmetic Medical Center,
                Britain, UK
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="fieldlabels">Total Years of experience</label>
          <div className="row">
            <div className="col-lg-9">
              <input
                className="form-control"
                type="number"
                required
                value={finalData.totalExperienceYears}
                onChange={(e) =>
                  handleChange("totalExperienceYears", e.target.value)
                }
                placeholder="Add numerals only"
              />
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                25+ years of experience
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="fieldlabels">Departments</label>
          <div className="row">
            <div className="col-lg-9">
              <div className="select-search-icon">
                {finalData.departments && <Select
                  options={departments}
                  getOptionLabel={getOptionLabel}
                  isMulti={true}
                  isSearchable={true}
                  value={getSelectData(finalData.departments)}
                  onChange={(e) => handleChange("departments", e)}
                  className="profile-form-select-search" // Add a custom class name for styling
                  classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />}
              </div>
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Cosmetology
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="fieldlabels">Specialty</label>
          <div className="row">
            <div className="col-lg-9">
              <div className="select-search-icon">
                {finalData.specialties && <Select
                  options={sampleOptions}
                  getOptionLabel={getOptionLabel}
                  value={getSelectData(finalData.specialties)}
                  onChange={(e) => handleChange("specialties", e)}
                  isMulti={true}
                  isSearchable={true}
                  className="profile-form-select-search" // Add a custom class name for styling
                  classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />}
              </div>
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Plastic Surgen
              </span>
            </div>
          </div>
        </div>
          <div className="form-group">
            <label className="fieldlabels">Procedures</label>
            <div className="row">
              <div className="col-lg-9">
                <div className="select-search-icon">
                  <Select
                    options={getDataforSelect(procedure.list2)}
                    getOptionLabel={getOptionLabel}
                    value={getSelectData(finalData.procedures)}
                    onChange={(e) => {
                      handleChange("procedures", e);
                    }}
                    isMulti={true}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 m-auto">
                <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                  Botox injections
                </span>
              </div>
            </div>
          </div>
      </div>
      <button
        onClick={() => props.setStep(3)}
        type="button"
        name="next"
        className="next action-button"
      >
        Next
        <FontAwesomeIcon icon={faArrowRightLong} />
      </button>
      <button
        onClick={() => props.setStep(1)}
        type="button"
        name="previous"
        className="previous action-button-previous"
        value="Previous"
      >
        <FontAwesomeIcon icon={faArrowLeftLong} /> Previous
      </button>
    </fieldset>
  );
};
