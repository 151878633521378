import { Dashboard } from "../Pages/dashboard/dashboard";
import { DashboardProfile } from "../Pages/dashboard/profile";
import { NotificationNew } from "../Pages/dashboard/notification/notificationNew";
import { NotificationUnread } from "../Pages/dashboard/notification/notificationUnread";
import { NotificationRead } from "../Pages/dashboard/notification/notificationRead";
import { UpcommingAppointments } from "../Pages/dashboard/upcommingAppointments";
import { CompletedAppointments } from "../Pages/dashboard/completedAppointments";
import { VideoConsultation } from "../Pages/dashboard/videoConsultation";
import { NewReviews } from "../Pages/dashboard/Reviews/newReviews";
import { UnreadReviews } from "../Pages/dashboard/Reviews/unreadReviews";
import { ReadReviews } from "../Pages/dashboard/Reviews/readReviews";
import { HideReviews } from "../Pages/dashboard/Reviews/hideReviews";
import { LiveReviews } from "../Pages/dashboard/Reviews/liveReviews";
import { FlaggedReviews } from "../Pages/dashboard/Reviews/flaggedReviews";
import { UploadArticles } from "../Pages/dashboard/articles/uploadArticles";
import { UnderReviewArticles } from "../Pages/dashboard/articles/underReviewArticles";
import { NeedImprovementArticles } from "../Pages/dashboard/articles/needImprovementArticles";
import { ApprovedArticles } from "../Pages/dashboard/articles/approvedArticles";
import { FlaggedArticles } from "../Pages/dashboard/articles/flaggedArticles";
import { ArticlesHistory } from "../Pages/dashboard/articles/articlesHistory";
import { UploadOffer } from "../Pages/dashboard/offers/uploadOffer";
import { UnderReviewOffers } from "../Pages/dashboard/offers/underReviewOffers";
import { LiveOffers } from "../Pages/dashboard/offers/liveOffers";
import { RejectedOffers } from "../Pages/dashboard/offers/rejectedOffers";
import { ExpiredOffers } from "../Pages/dashboard/offers/expiredOffers";
import { PurchasedOffers } from "../Pages/dashboard/offers/purchasedOffers";
import { OffersHistory } from "../Pages/dashboard/offers/offersHistory";
import { MyOrders } from "../Pages/dashboard/wallet/my-orders";
import { WalletLocation } from "../Pages/dashboard/wallet/walletLocation";
import { WalletHistory } from "../Pages/dashboard/wallet/walletHistory";
import { NewQA } from "../Pages/dashboard/q&a/new-q-a";
import { ReadQA } from "../Pages/dashboard/q&a/read-q-a";
import { UnreadQA } from "../Pages/dashboard/q&a/unread-q-a";
import { HideQA } from "../Pages/dashboard/q&a/hide-q-a";
import { LiveQA } from "../Pages/dashboard/q&a/live-q-a";
import { ChangePassword } from "../Pages/dashboard/settings/changePassword";
import { Step2Verification } from "../Pages/dashboard/settings/step2Verification";
import { NotificationSettings } from "../Pages/dashboard/settings/notificationSettings";
import { ActiveDevices } from "../Pages/dashboard/settings/activeDevices";
import { DeleteMyProfile } from "../Pages/dashboard/settings/deleteMyProfile";
import { SecurityQuestions } from "../Pages/dashboard/settings/securityQuestions";
import { InviteFriend } from "../Pages/dashboard/referFreind/inviteFriends";
import { InviteesStatus } from "../Pages/dashboard/referFreind/inviteesStatus";
import { RaiseTicket } from "../Pages/dashboard/support/raiseTicket";
import { OpenTicket } from "../Pages/dashboard/support/openTicket";
import { ClosedTicket } from "../Pages/dashboard/support/closedTicket";
import { TicketStatus } from "../Pages/dashboard/support/ticketStatus";
import { UploadPhotos } from "../Pages/dashboard/beforeAfter/uploadPhotos";
import { UnderReviewPhotos } from "../Pages/dashboard/beforeAfter/underReviewPhotos";
import { NeedImprovementPhotos } from "../Pages/dashboard/beforeAfter/needImprovementPhotos";
import { ApprovedPhotos } from "../Pages/dashboard/beforeAfter/approvedPhotos";
import { Route, Routes } from "react-router-dom";
import { CreateQA } from "../Pages/dashboard/q&a/create-qa";
import { AnswersQA } from "../Pages/dashboard/q&a/answers-qa";

export const DashboardRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="home" element={<Dashboard />} />
        <Route path="profile" element={<DashboardProfile />} />
        <Route path="notification-new" element={<NotificationNew />} />
        <Route path="notification-unread" element={<NotificationUnread />} />
        <Route path="notification-read" element={<NotificationRead />} />
        <Route
          path="upcomming-appointments"
          element={<UpcommingAppointments />}
        />
        <Route
          path="completed-appointments"
          element={<CompletedAppointments />}
        />
        <Route path="video-consultation" element={<VideoConsultation />} />
        <Route path="new-reviews" element={<NewReviews />} />
        <Route path="unread-reviews" element={<UnreadReviews />} />
        <Route path="read-reviews" element={<ReadReviews />} />
        <Route path="hide-reviews" element={<HideReviews />} />
        <Route path="live-reviews" element={<LiveReviews />} />
        <Route path="flagged-reviews" element={<FlaggedReviews />} />
        <Route path="upload-articles" element={<UploadArticles />} />
        <Route path="under-review-articles" element={<UnderReviewArticles />} />
        <Route
          path="need-improvement-articles"
          element={<NeedImprovementArticles />}
        />
        <Route path="approved-articles" element={<ApprovedArticles />} />
        <Route path="flagged-articles" element={<FlaggedArticles />} />
        <Route path="articles-history" element={<ArticlesHistory />} />
        <Route path="upload-offers" element={<UploadOffer />} />
        <Route path="under-review-offers" element={<UnderReviewOffers />} />
        <Route path="live-offers" element={<LiveOffers />} />
        <Route path="rejected-offers" element={<RejectedOffers />} />
        <Route path="expired-offers" element={<ExpiredOffers />} />
        <Route path="purchased-offers" element={<PurchasedOffers />} />
        <Route path="history-offers" element={<OffersHistory />} />
        <Route path="wallet-orders" element={<MyOrders />} />
        <Route path="wallet-location" element={<WalletLocation />} />
        <Route path="wallet-history" element={<WalletHistory />} />
        <Route path="new-qa" element={<NewQA />} />
        <Route path="read-qa" element={<ReadQA />} />
        <Route path="unread-qa" element={<UnreadQA />} />
        <Route path="hide-qa" element={<HideQA />} />
        <Route path="create-qa" element={<CreateQA />} />
        <Route path="answers-qa" element={<AnswersQA />} />
        <Route path="live-qa" element={<LiveQA />} />
        <Route path="settings-change-password" element={<ChangePassword />} />
        <Route
          path="settings-step2-verification"
          element={<Step2Verification />}
        />
        <Route
          path="settings-notification"
          element={<NotificationSettings />}
        />
        <Route path="settings-active-devices" element={<ActiveDevices />} />
        <Route path="settings-delete-profile" element={<DeleteMyProfile />} />
        <Route
          path="settings-security-questions"
          element={<SecurityQuestions />}
        />
        <Route path="refers-invite-friend" element={<InviteFriend />} />
        <Route path="refers-invitees-status" element={<InviteesStatus />} />
        <Route path="support-raise-ticket" element={<RaiseTicket />} />
        <Route path="support-open-ticket" element={<OpenTicket />} />
        <Route path="support-closed-ticket" element={<ClosedTicket />} />
        <Route path="support-ticket-status" element={<TicketStatus />} />
        <Route path="beforeAfter-upload-photos" element={<UploadPhotos />} />
        <Route
          path="beforeAfter-under-review"
          element={<UnderReviewPhotos />}
        />
        <Route
          path="beforeAfter-need-improvement"
          element={<NeedImprovementPhotos />}
        />
        <Route
          path="beforeAfter-approved-photos"
          element={<ApprovedPhotos />}
        />
      </Routes>
    </>
  );
};
