import "../../../../../css/main.css";
import "../../../../../css/media.css";
import { useEffect, useState } from "react";
import { imgBaseUrl } from "../../../../../services/config/config";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";

export const UnderReviewArticlesList = (props) => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    if (props.data) {
      setArticles(props.data);
    }
  }, [props.data]);

  const isNotArticle = window.location.pathname.includes("beforeAfter-");
  return (
    <div className="tab_container three-block-container">
      {articles && articles.length > 0 ? (
        <div id="tab1" className="tab_content">
          <div className="doctor-detail_articles-listing">
            {articles.map((data, ind) => (
              <div className="doctor-detail-row" key={ind + 1}>
                <div className="doctor-img-first">
                  <span>
                    <img
                      src={`${
                        imgBaseUrl +
                        (isNotArticle ? data.afterPhoto : data.treatmentPhoto)
                      }`}
                      className="img-fluid border"
                      style={{
                        width: "12rem",
                        height: "8rem",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </span>
                </div>
                <div className="doctor-detail-second">
                  <h5 style={{ fontSize: "17px" }}>{data.title}</h5>
                  <p className="fs-6">{data.description}</p>
                </div>
                <div className="doctor-detail-third">
                  <div className="published-date">
                    <a
                      href
                      className="primary__btn"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {data.status === "Under Review" && (
                        <span
                          className={`${
                            data.status === "Evaluation Started"
                              ? "orange-box"
                              : data.status === "Evaluation Completed"
                              ? "green-box"
                              : "gray-box"
                          } box-left`}
                        ></span>
                      )}
                      {data.status === "Under Review"
                        ? data.status
                        : data.status === "Need Improvement" ||
                          data.status === "Flagged"
                        ? "View Details"
                        : data.status === "Approved"
                        ? "View Article"
                        : ""}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
