import "../../../css/main.css";
import "../../../css/media.css";
import { useMediaQuery } from "@mui/material";
import { FindServiceModal } from "./subcomponents/findServiceModal";
import { useState } from "react";
import countries from "../../../demoData/countries.json";
import {
  handleAnyChange,
  handleCityChange,
  isFilter,
} from "../../../services/doctorsFilter/doctorFilterService";
import { useProcedureState } from "../../../Store/procedure/procedureHooks";
import { useFilters } from "../../../services/doctorsFilter/doctorFilterProvider";

export const PopularPlaces = () => {
  const [showModal, setShowModal] = useState(false);
  const matches = useMediaQuery("(max-width:992px)");
  const procedure = useProcedureState();

  const { doctorFilters, setDoctorFilters } = useFilters();

  const handleChange = (checked, val) => {
    handleCityChange(doctorFilters, setDoctorFilters, checked, val);
  };
  const handleChange2 = (val) => {
    handleAnyChange(doctorFilters, setDoctorFilters, "procedures", val);
  };

  const isProcedureActive = (val) => {
    if (
      isFilter(doctorFilters, "procedures") &&
      isFilter(doctorFilters, "procedures")[2] === val
    ) {
      return true;
    }
    return false;
  };
  return (
    <div
      className={`doctors-list-left resp-none-box ${matches ? "d-none" : ""}`}
    >
      <div className="popularplaces left-box">
        <h5>Popular Places in Abu Dhabi</h5>
        <div className="scrollbar ps-4" id="style-3">
          <form action="">
            <div>
              {countries.map((d, ind) => (
                <div
                  key={ind + 1}
                  className="mb-3 form-group checkbox-customise"
                >
                  <input
                    onChange={(e) => handleChange(e.target.checked, d.value)}
                    type="checkbox"
                    id={`popularplace${ind + 1}`}
                  />
                  <label for={`popularplace${ind + 1}`}>{d.label}</label>
                </div>
              ))}
            </div>
          </form>
        </div>
      </div>
      <div className="treatment-services left-box">
        <h5>Related Treatments & Services</h5>
        <ul>
          {procedure.list2.map(
            (d, ind) =>
              ind < 4 && (
                <li className="m-1" key={ind + 1}>
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: isProcedureActive(d.name) ? "black" : "",
                      color: isProcedureActive(d.name) ? "white" : "",
                    }}
                    className="primary__btn fs-6 px-3 py-1 bg-black m-0 rounded-pill"
                    onClick={() => {
                      handleChange2(d.name);
                    }}
                  >
                    {d.name}
                  </button>
                </li>
              )
          )}
        </ul>
        <button
          type="button"
          className="primary__btn3 treatment-services-click mt-2"
          onClick={() => setShowModal(true)}
        >
          Show All Treatments & Services
        </button>
        <FindServiceModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </div>
  );
};
