export const RedeemPointsList = () => {
  return (
    <div className="tab_container articles-container">
      <div id="tab1" className="tab_content">
        <div className="refer-block">
          <div className="invitee-status ps-0 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <h6 className="fs-4">
                During the purchase of Botox Hair (Offer){" "}
                <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned{" "}
                <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status ps-0 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <h6 className="fs-4">
                During the purchase of Botox Hair (Offer){" "}
                <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned{" "}
                <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status ps-0 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <h6 className="fs-4">
                During the purchase of Botox Hair (Offer){" "}
                <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned{" "}
                <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status ps-0 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <h6 className="fs-4">
                During the purchase of Botox Hair (Offer){" "}
                <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned{" "}
                <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status ps-0 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <h6 className="fs-4">
                Carmen Vargas <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned{" "}
                <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
