import FavoriteButton from "../fav";
import { AddToFavWithTextContent } from "./subComponents/addToFavWithTextContent";
const myStyle = (isFav) => {
  return {
    background: isFav ? "#e78a82" : "rgba(231, 138, 130, 0.26)",
    border: "1px solid #e78a82",
    borderRadius: "50px",
    width: "12rem",
    height: "2.5rem"
  };
};

export const AddToFavWithText = () => {
  return (
    <FavoriteButton
      btnStyle={myStyle}
      className={`d-flex align-items-center justify-content-center`}
      handleContent={(isFav) => <AddToFavWithTextContent isFav={isFav} />}
    />
  );
};
