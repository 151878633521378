import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import {
  faPenToSquare,
  faStarHalfAlt,
} from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
import { faCheck, faSliders } from "@fortawesome/free-solid-svg-icons";
import {
  filterByProcedureOptions,
  reviewOptions,
} from "../../../../demoData/selectOptionsData";
import { useState } from "react";
import { OverviewFilterBox } from "./overviewFilterBox";

export const OverallRatingContent = (props) => {
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-2 justify-content-between">
      <span className="fs-6">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="review-block-section common-pad container-fluid">
      <div className="button-flex justify-content-end fixed-box">
        <button
          type="button"
          className="btn btn-primary selectPopup-btn"
          onClick={() => setShowFiltersModal(true)}
        >
          <FontAwesomeIcon icon={faSliders} />
        </button>
        <OverviewFilterBox
          showModal={showFiltersModal}
          setShowModal={setShowFiltersModal}
        />
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="review-box-left">
            <h4>Reviews (1,240)</h4>
            <span className="rate">4.6</span>
            <h5>Overall rating</h5>
            <div className="rating justify-content-center">
              <FontAwesomeIcon icon={props.solidStar} />
              <FontAwesomeIcon icon={props.solidStar} />
              <FontAwesomeIcon icon={props.solidStar} />
              <FontAwesomeIcon icon={faStarHalfAlt} />
              <FontAwesomeIcon icon={faStarHalfAlt} />
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="review-box-right">
            <p className="resp-hidden-box p-head-size-17">
              These are patient’s opinions and do not necessarily reflect the
              doctor’s medical capabilities.Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Integer vulputate vehicula urna ut
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </p>
            <div className="button-flex justify-content-end">
              <a href className="primary__btn">
                Readmore
              </a>
            </div>
            <div className="readmore-result">
              <span className=" d-inline-block">
                <span className="rate">1,240</span>Results
              </span>
            </div>

            <div className="submit-filter">
              <a href className="primary__btn3">
                <FontAwesomeIcon icon={faPenToSquare} /> Submit Review
              </a>
              <div className="filter-box-right resp-hidden-box d-flex flex-wrap align-items-center gap-4">
                <div style={{ width: "20rem" }}>
                  <Select
                    options={filterByProcedureOptions}
                    getOptionLabel={getOptionLabel}
                    defaultValue={filterByProcedureOptions[0]}
                    isSearchable={true}
                    className="offers-box-select bg-white white-bg-select" // Add a custom class name for styling
                    classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div style={{ width: "10rem" }}>
                  <Select
                    options={reviewOptions}
                    getOptionLabel={getOptionLabel}
                    defaultValue={reviewOptions[0]}
                    isSearchable={false}
                    className="offers-box-select bg-white white-bg-select" // Add a custom class name for styling
                    classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
