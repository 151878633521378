import { SettingsHeader } from "./components/settingsHeader";
import { ChanbgePasswordForm } from "./components/changePasswordComponents/changePasswordForm";
import { TabsGroup } from "../../../shared/tabsGroup";
import { settingsTabsData } from "../../../demoData/tabsData";

export const ChangePassword = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SettingsHeader title="Change Password" />
        <TabsGroup tabs={settingsTabsData()} />
        <ChanbgePasswordForm />
      </div>
    </section>
  );
};
