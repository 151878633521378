import "../../../../css/main.css";
import "../../../../css/media.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { doctorInfoData } from "../../../../demoData/doctorInfoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDoctor } from "../../../../Store/Doctor/doctorHooks";

export const DoctorInfoContent = () => {
  const doctor = useDoctor();

  return (
    <div className="py-5">
      <p className="p-head-size">{doctor.docById.shortBio}</p>
      <div className="accordion-container">
        {doctorInfoData.map(
          (data, ind) =>
            doctor.docById[data.fieldName] &&
            doctor.docById[data.fieldName].length > 0 && (
              <div key={ind + 1}>
                <Accordion
                  style={{
                    boxShadow: "none",
                    borderBottom:
                      ind + 1 === doctorInfoData.length
                        ? ""
                        : "1px solid rgb(0 0 0 / 10%)",
                  }}
                  className="set p-0"
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="set p-0"
                  >
                    <h5 className="d-flex align-items-center gap-3">
                      <FontAwesomeIcon icon={data.titleIcon} /> {data.title}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails
                    className="content px-0 pt-0 pb-4"
                    style={{ marginTop: "-2rem" }}
                  >
                    <div className="content">
                      <div className="tab-content-detailed">
                        <ul>
                          {doctor.docById[data.fieldName].map((d, i) => (
                            <li key={i + 1}>{d}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )
        )}
      </div>
    </div>
  );
};
