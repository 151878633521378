import "../../../css/main.css";
import "../../../css/media.css";
import { HealthProfileHeader } from "../components/profileComponents/healthProfileHeader";
import { HealthCareWalletTabs } from "./components/healthCareWalletTabs";
import { HealthStatusTable } from "./components/healthCareStatusComponents/healthStatusTable";

export const HealthCareWalletOrder = () => {
  return (
    <div>
      <HealthProfileHeader title="All Orders" page="Doctors List" />
      <HealthCareWalletTabs />
      <HealthStatusTable />
    </div>
  );
};
