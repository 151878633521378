import { Loading } from "../../../shared/loadings/Loading";
import { OffersHeader } from "./components/offerHeader";
import { OffersTabs } from "./components/offersTabs";
import { UploadOfferForm } from "./components/uploadOfferCompoents/uploadOfferForm";

export const UploadOffer = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <OffersHeader title="Upload Offers" />
        <OffersTabs />
        <UploadOfferForm />
      </div>
    </section>
  );
};
