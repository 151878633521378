import { settingsTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { SettingsHeader } from "./components/settingsHeader";
import { VerificationContent } from "./components/step2VerificationComponents/verificationContent";

export const Step2Verification = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SettingsHeader title="Step 2 Verification" />
        <TabsGroup tabs={settingsTabsData()} />
        <VerificationContent />
      </div>
    </section>
  );
};
