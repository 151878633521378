import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import {
  faCheck,
  faChevronDown,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { VirtualSelect } from "./virtualSelect/virtualSelect";
import { useNavigate } from "react-router-dom";
import { RatingDropdown } from "../../../../shared/dropdowns/ratingDropdown";

const options1 = [
  { value: "Availability", label: "Availability" },
  { value: "Today", label: "Today" },
  { value: "Tommorow", label: "Tommorow" },
  { value: "A Week", label: "A Week" },
];

const options2 = [
  { value: "Gender", label: "Gender" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];
const options3 = [
  { value: "Experience", label: "Experience" },
  { value: "1 - 5 years", label: "1 - 5 years" },
  { value: "5 - 10 years", label: "5 - 10 years" },
  { value: "10 - 15 years", label: "10 - 15 years" },
  { value: "15+ years", label: "15+ years" },
];

export const BannerFiltersModal = (props) => {
  const navigate = useNavigate();
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="bg-white popular-places-modal overflow-y-auto">
      <div className={`filter-list`}>
        <form action="">
          <div className="filter-box">
            <span
              className="close-mega-menu"
              onClick={() => props.setShowModal(false)}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="left_chev left-left_chev"
              />
              <FontAwesomeIcon icon={faChevronLeft} className="left_chev" />{" "}
              Close
            </span>
            <div className="form-group mt-3">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                All
              </a>
            </div>
            <div className="form-group">
              <Select
                options={options1}
                getOptionLabel={getOptionLabel}
                defaultValue={options1[0]}
                isSearchable={false}
                className="custom-select" // Add a custom class name for styling
                classNamePrefix="custom-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="form-group mt-3">
              <Select
                options={options2}
                defaultValue={options2[0]}
                getOptionLabel={getOptionLabel}
                isSearchable={false}
                className="custom-select" // Add a custom class name for styling
                classNamePrefix="custom-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="form-group mt-3">
              <Select
                options={options3}
                defaultValue={options3[0]}
                getOptionLabel={getOptionLabel}
                isSearchable={false}
                className="custom-select" // Add a custom class name for styling
                classNamePrefix="custom-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="form-group mt-3 position-relative w-100">
              <button
                className="rating_btn d-flex align-items-center gap-5 justify-content-between"
                type="button"
                onClick={() => setShowRatingDropdown(!showRatingDropdown)}
              >
                <></>
                Rating
                <FontAwesomeIcon icon={faChevronDown} style={{fontSize: "13px"}} />
              </button>
              {showRatingDropdown && <RatingDropdown faSolidStar={faStar} />}
            </div>
            <div className="form-group mt-3">
              <VirtualSelect />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
