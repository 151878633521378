import React, { useState } from "react";
import Select from "react-select";
const options = [
  { value: "United States", label: "United States", code: "us" },
  { value: "Canada", label: "Canada", code: "ca" },
  { value: "Mexico", label: "Mexico", code: "mx" },
];
export const CountrySelect = () => {
  const [country, setCountry] = useState({
    value: "United States",
    label: "United States",
    code: "us",
  });
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3">
      <img
        src={`https://flagsapi.com/${option.code.toUpperCase()}/flat/64.png`}
        alt="flag"
        width={18}
      />
      <span className="fs-6 fs-xl-5">{option.label}</span>
    </div>
  );
  return (
    <div className="position-relative">
      {country.code && (
        <img
          src={`https://flagsapi.com/${country.code.toUpperCase()}/flat/64.png`}
          alt="flag"
          className="position-absolute flag_icon z-1"
          width={18}
        />
      )}
      <Select
        options={options}
        isSearchable
        value={country}
        getOptionLabel={getOptionLabel}
        placeholder="Select country"
        className="country-select" // Add a custom class name for styling
        classNamePrefix="country-select" // Add a custom class prefix for styling
        onChange={(val) => setCountry(val)}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
      />
    </div>
  );
};
