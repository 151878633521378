import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faBook,
  faFolderPlus,
  faGlobe,
  faGraduationCap,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";

export const doctorInfoData = [
  {
    title: "Education",
    fieldName: "educations",
    titleIcon: faGraduationCap,
    descItems: [
      "University of Maryland at Baltimore, School of Medicine",
      "Indiana University School of Medicine, 1968",
      "MtoGenl Hosp, Diagnostic Radiology, 1971 - 1974",
    ],
  },
  {
    title: "Experience",
    fieldName: "professionalExperiences",
    titleIcon: faFolderPlus,
    descItems: [
      "General Dentistry ( 2018 - Present )Wide Smile Dental Clinic",
      "Medical & General Dentistry ( 2016 - 2018 )Aurora Medical & Dental College",
      "Sr. Consultant ( 2014 - 2016 )New Apollo Hospital, California",
    ],
  },
  {
    title: "Languages",
    fieldName: "languages",
    titleIcon: faGlobe,
    descItems: ["English", "Arabic", "Spanish"],
  },
  {
    title: "Awards",
    fieldName: "awards",
    titleIcon: faMedal,
    descItems: [
      "Summa Cum Laue, Yale University ( April 8, 2015 )",
      "Summa Cum Laue, Yale University ( April 8, 2015 )",
      "Summa Cum Laue, Yale University ( April 8, 2015 )",
    ],
  },
  {
    title: "Membership",
    fieldName: "membership",
    titleIcon: faCheckSquare,
    descItems: [
      "Manchester Academy of Oral Medicine and Radiology",
      "United State Dental Council",
      "International Association of General Dentistry (IAGD)",
      "International Federation of Dental Educators and Associations (IFDEA, USA)",
      "Sydney Academy of Aesthetic & Cosmetic Dentistry",
    ],
  },
  {
    title: "Publications",
    fieldName: "publications",
    titleIcon: faBook,
    descItems: [
      "Summa Cum Laue, Yale University ( April 8, 2015 )",
      "Summa Cum Laue, Yale University ( April 8, 2015 )",
      "Summa Cum Laue, Yale University ( April 8, 2015 )",
    ],
  },
];
