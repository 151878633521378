import {
  faArrowLeftLong,
  faArrowRightLong,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReviewStars } from "../../../../../shared/reviewStars";
import { experienceRatingOptions, sampleOptions } from "../../../../../demoData/selectOptionsData";
import Select from "react-select";
import { useEffect, useState } from "react";
import { MultiChoiceQues } from "./subComponents/multiChoiceQues";
import { useReview, useSetReviewFormData } from "../../../../../Store/review/reviewHook";

export const ReviewForm2 = (props) => {
  const [finalData, setFinalData] = useState({});
  const setReviewFormData = useSetReviewFormData();
  const reviewState = useReview();

  useEffect(() => {
    if (reviewState.formData) {
      setFinalData(reviewState.formData);
    }
  }, [reviewState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setReviewFormData(updatedFinalData);
  };

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <form onSubmit={(e) => {e.preventDefault(); props.setShowForm(3)}} >
      <div className="form-card">
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q1</span> Please share your
              experience with Dr. lauren Clinton
            </label>
            <div className="col-lg-9">
              <div className="select-search-icon">
                <Select
                required
                  options={experienceRatingOptions}
                  getOptionLabel={getOptionLabel}
                  value={finalData.experienceRating}
                  onChange={(val) => handleChange("experienceRating", val)}
                  isSearchable={true}
                  className="my-form-select-search" // Add a custom class name for styling
                  classNamePrefix="my-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q2</span>How would you rate your
              overall experience with your Dr
            </label>
            <div className="col-lg-9">
              <div className="rating">
                <ReviewStars
                  value={finalData.overallRating ? finalData.overallRating : 0}
                  onChange={(val) => handleChange("overallRating", val)}
                />
                (3.5/5)
              </div>
            </div>
          </div>
        </div>
        <MultiChoiceQues
          ques="Would you like to
          recommend doctor?"
          quesNo="2"
          value={finalData.youLikedDoctor}
          choicesData={["Yes", "No"]}
          onChange={(val) => handleChange("youLikedDoctor", val)}
        />
        <MultiChoiceQues
          ques="How long did you wait to
          seen by the doctor?"
          quesNo="3"
          value={finalData.waitForDoctor}
          choicesData={[
            "Right Away",
            "Less waiting hours",
            "Long waiting hours",
          ]}
          onChange={(val) =>
            handleChange("waitForDoctor", val)
          }
        />
        <MultiChoiceQues
          ques="How good was your doctor
          today at each of the following?"
          quesNo="4"
          isMulti={true}
          value={finalData.howGoodWasDoctor}
          choicesData={[
            "Being polite",
            "Make you feel at ease",
            "Listening to you",
            "Friendliness",
            "Value for money",
            "Satisfied with treatment",
          ]}
          onChange={(val) =>
            handleChange("howGoodWasDoctor", val)
          }
        />
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q6</span>Tell us more about your
              experience with Dr.?
            </label>
            <div className="col-lg-9">
              <textarea
                type="text"
                value={finalData.experienceWithDoctor}
                onChange={(e) =>
                  handleChange(
                    "experienceWithDoctor",
                    e.target.value
                  )
                }
                className="form-control"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <button
              type="submit"
              name="next"
              className=" my-0 next action-button"
              value="Next"
            >
              Next <FontAwesomeIcon icon={faArrowRightLong} />
            </button>
            <button
              type="button"
              onClick={() => props.setShowForm(1)}
              className=" mb-0 mt-3 mt-sm-0 action-button-previous position-relative"
              value="Previous"
            >
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                className="position-absolute start-0 ms-4"
              />
              Previous
            </button>
    </form >
  );
};
