import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import {
  faArrowLeft,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";

export const HeadingComp = (props) => {
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="col-lg-8">
        <h2>
          <span className="head-intend">Face - </span> {props.heading}
        </h2>
        <div className="d-flex align-items-center gap-3">
          <IsHelpfull label="Botox" bg="black" color="white" />
          <a
            href="/botox-injections"
            onClick={(e) => {
              e.preventDefault();
              navigate("/botox-injections");
            }}
            className="read-more"
          >
            Read More <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </div>
      </div>
      <div className="col-lg-4 ms-auto">
        <div className="previous-back-btn">
          <span className="previous ms-2">Previous</span>
          <a
            href={props.backPath}
            onClick={(e) => {
              e.preventDefault();
              navigate(props.backPath);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
        </div>
      </div>
    </div>
  );
};
