import { useNavigate } from "react-router-dom";
import myImg from "../../../images/img6.png";
import { EmptyComp } from "../../../shared/emptyComponents/emptyComp";

export const StartsReviewNoti = () => {
  const navigate = useNavigate();
  return (
    <section className="doctor-detail-stories-tab">
      <div className="container-fluid p-0">
        <div className="no-content pt-5 mt-5 pe-0 pe-lg-5">
        <EmptyComp
            image={myImg}
            title="Let’s start your review"
            showButton={true}
            desc={[
              "Real experiences like yours are the heart of RealSelf.",
              <br />,
              "Sharing your story helps people make smart decisions.",
              <br />,
              "You can start your review at any stage of your journey and add",
              <br />,
              "updates at any time."
            ]}
            buttonText="Write a Review"
            onClickButton={(e) => {
              e.preventDefault();
              navigate("/user-dashboard/create-review");
            }}
          />
          
        </div>
      </div>
    </section>
  );
};
