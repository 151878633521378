/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import {
  faStar,
  faTrophy,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {
  faImage,
  faStarHalfAlt,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { imgBaseUrl } from "../../../../services/config/config";
import { currentPatientId } from "../../../../Store/selectors/UserSelectors";
import { useNavigate } from "react-router-dom";
import { IsFavWithHeart } from "../../../../shared/AddToComps/isFavWithHeart";
import {
  useCreateFavDoctor,
  useDeleteFavDoctor,
  useFavDoctor,
} from "../../../../Store/favoruiteDoctor/favoruiteDoctorHooks";

export const DoctorItem = (props) => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:992px)");
  const [favData, setFavData] = useState({});
  const [data, setData] = useState();
  const createFavDoctor = useCreateFavDoctor();
  const removeFavDoctor = useDeleteFavDoctor();
  const favDoctor = useFavDoctor();

  useEffect(() => {
    if (favDoctor) {
      let res = favDoctor.list.find(
        (d) => d.doctor && d.doctor.data && d.doctor.data.id === props.doctor.id
      );
      setFavData(res);
    }
  }, [favDoctor]);
  useEffect(() => {
    if (props.doctor) {
      setData(props.doctor);
    }
  }, [props.doctor]);

  const handleFavFunc = async () => {
    if (favData && favData.id) {
      removeFavDoctor(favData.id);
    } else {
      createFavDoctor({
        patient: currentPatientId(),
        doctor: data.id,
      });
    }
  };

  return (
    data && (
      <div className="row">
        <div className="col-xl-3">
          <div className="doctors-listing-left-box" style={{width: "max-content"}}>
            <div
              className="rounded-circle"
              style={{
                width: "18rem",
                minWidth: "18rem",
                height: "18rem",
                border: "1px solid gray",
              }}
            >
              <span>
                <img
                  src={`${imgBaseUrl + data.profileImg}`}
                  className="img-fluid rounded-circle"
                  style={{
                    width: "18rem",
                    minWidth: "18rem",
                    height: "18rem",
                    objectFit: "cover",
                  }}
                  alt="doc1"
                />
              </span>
            </div>
            <a
              onClick={() => navigate("/doctor-details-overview/" + data.id)}
              className={`view-profile ${matches ? "me-0" : ""}`}
              style={{ cursor: "pointer" }}
            >
              View Profile
            </a>
            <IsFavWithHeart
              onClick={handleFavFunc}
              isFav={favData && favData.id ? true : false}
            />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6">
          <div className="doctor-details">
            <h5 className="cursor-pointer" onClick={() => navigate("/")}>
              {data.firstName + " "} {data.lastName && data.lastName}
            </h5>
            <ul className="d-flex flex-column gap-3">
              {data.educations && data.educations.length > 0 && (
                <li className="d-flex align-items-center flex-wrap gap-1 p-0 m-0">
                  {data.educations.map((d, i) => (
                    <span key={i + 1} className="p-0 m-0">
                      {d},
                    </span>
                  ))}
                </li>
              )}

              {(data.state || data.country) && (
                <li>
                  {data.state}, {data.country}
                </li>
              )}
              {data.totalExperienceYears && (
                <li>{data.totalExperienceYears}+ Yrs Experience</li>
              )}
              {data.title && <li>{data.title}</li>}
              {data.languages && data.languages.length > 0 && (
                <li className="d-flex align-items-center gap-1 p-0 m-0">
                  Speaks:{" "}
                  {data.languages.map((d, i) => (
                    <span key={i + 1} className="p-0 m-0">
                      {d},
                    </span>
                  ))}
                </li>
              )}
            </ul>
            <div className="detail-box mt-3">
              <div className="rating">
                <div className="">
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <div className="">
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <div className="">
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <div className="">
                  <FontAwesomeIcon icon={faStarHalfAlt} />
                </div>
                <div className="">
                  <FontAwesomeIcon icon={faStarHalfAlt} />
                </div>
              </div>
              <span className="number">(1,378)</span>
              <a
                href
                onClick={(e) => {
                  navigate("/badge");
                }}
                type="button"
                data-toggle="tooltip"
                title="Hooray!"
              >
                <FontAwesomeIcon
                  icon={faTrophy}
                  className="fa-sharp fa-solid fa-trophy-star"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-6">
          <p className="before-after-view">
            Before & After
            <span>
              <a href onClick={() => navigate("/")}>
                <FontAwesomeIcon icon={faVideoCamera} />
              </a>
              <a href onClick={() => navigate("/")}>
                <FontAwesomeIcon icon={faImage} />
              </a>
            </span>
          </p>
          <p className="text-detailed-doc">
            <span>
              <FontAwesomeIcon icon={faThumbsUp} />
              95% &nbsp;
            </span>
            230 Patient Stories
            <br />
            Next Available : Today
          </p>
          <button
            onClick={() => {
              navigate("/book-appointment/" + data.id);
            }}
            className="primary__btn3"
          >
            Book an Appointment
          </button>
        </div>
      </div>
    )
  );
};
