import { redeemTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { EarnedPointsList } from "./components/earnedPointComponents/earnedPointsList";
import { RedeemHeader } from "./components/redeemHeader";

export const EarnedPoints = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <RedeemHeader title="Earned Points" />
        <TabsGroup tabs={redeemTabsData} />
        <EarnedPointsList />
      </div>
    </section>
  );
};
