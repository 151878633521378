import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imgBaseUrl } from "../../../../../services/config/config";
import {
  cartItemsUpdationFinalData,
  removeItemFromCartData,
} from "../../../../../services/generalService";
import {
  useCart,
  useUpdateCartItems,
} from "../../../../../Store/cart/cartHook";
import { currentPatientId } from "src/Store/selectors/UserSelectors";
import { useCreateOrder } from "src/Store/order/orderHook";
import ShortUniqueId from 'short-unique-id';

export const CartTableItem = (props) => {
  const updateCartItems = useUpdateCartItems();
  const createOrder = useCreateOrder();
  const cart = useCart();
  const uid = new ShortUniqueId({ length: 6 });
  const total = () => {
    let res = 0;
    if (props.item.discountedAmount > 0) {
      res = props.item.discountedAmount * props.item.quantity;
    } else {
      res = props.item.actualAmount * props.item.quantity;
    }

    return res;
  };
  return (
    props.item &&
    props.item.id && (
      <tr key={props.ind + 1}>
        <td className="d-flex align-items-center gap-3 w-100">
          <span>
            <img
              src={`${imgBaseUrl + props.item.image}`}
              className="img-fluid"
              style={{
                width: "6rem",
                minWidth: "6rem",
                height: "4rem",
                minHeight: "4rem",
                objectFit: "cover",
              }}
              alt=""
            />
          </span>
          <div
            className="flex-table-box product-block-box"
            style={{ width: "10rem", minWidth: "10rem" }}
          >
            <h6>
              {props.item.title}
              <span className="product-sub-text1">Offers</span>
              <span className="product-sub-text2">Expires On 20 Dec2022</span>
            </h6>
          </div>
        </td>
        <td>
          <div className="number">
            <button
              className="minus"
              onClick={() =>
                props.item.quantity > 1 &&
                updateCartItems(
                  cartItemsUpdationFinalData(
                    props.ind,
                    cart.item,
                    props.item.quantity - 1
                  ),
                  "Your cart item quantity reduced!"
                )
              }
            >
              -
            </button>
            <input type="text" value={props.item.quantity} />
            <button
              className="plus"
              onClick={() =>
                updateCartItems(
                  cartItemsUpdationFinalData(
                    props.ind,
                    cart.item,
                    props.item.quantity + 1
                  ),"Your cart item quantity incresed!"
                )
              }
            >
              +
            </button>
          </div>
        </td>
        <td>
          <div className="price-strikethrough" style={{ whiteSpace: "nowrap" }}>
            <h6>
              <span>AED {props.item.actualAmount}</span>
              AED {props.item.discountedAmount}
            </h6>
          </div>
        </td>
        <td style={{ whiteSpace: "nowrap" }}>AED {total()}</td>
        <td>
         <div className="d-flex align-items-center gap-2">
         <button
            onClick={() =>
              updateCartItems(removeItemFromCartData(props.item.id, cart.item), "Item removed from your cart!")
            }
            className="delete-icon-box"
            style={{color: "red"}}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        <button
          onClick={() => createOrder({ orderId: uid.rnd(), doctor: props.item.doctorId, status: "Pending", patient: currentPatientId(), offer: props.item })}
          style={{ background: "black", outline: "none" }}
          className="border-none text-white rounded-pill px-4 py-1"
        >
          Place Order
        </button>
         </div>
        </td>
      </tr>
    )
  );
};
