import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { beforeAfterPhotosData2 } from "../../../../demoData/beforeAfterPageData";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { useNavigate } from "react-router-dom";

export const FacePhotosList = (props) => {
  const navigate = useNavigate();
  return (
    <div className="before-after-phases">
      <div className="row">
        {beforeAfterPhotosData2.map((data, ind) => (
          <div className={`${props.detailsType==="FaceLiposuctionReviews" ? "col-xxl-4 col-lg-4  col-md-6" : "col-xxl-3 col-lg-4 col-md-6"}`} key={ind + 1}>
            <div className="before-after-img-box">
              <a
                href={props.detailsPath}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(props.detailsPath);
                }}
              >
                <div className="before-after-phase-head">
                  <h4>{props.detailsType==="FaceLiposuctionReviews" ? "Received Botox And Filler - Feel More Confident With My Appearance" : data.title}</h4>
                  <h6>Uploaded on 12 Sep 2020</h6>
                </div>
                <div className="before-after-flex">
                  <span>
                    <img src={data.img1} className="img-fluid" alt="" />
                  </span>
                  <span>
                    <img src={data.img2} className="img-fluid" alt="" />
                  </span>
                </div>
                <div className="detail-before-after">
                  <span>
                    <FontAwesomeIcon icon={faThumbsUp} /> 87% Worth It{" "}
                  </span>
                  <span className="people-num">
                    260 People Found This Helpful
                  </span>
                </div>
              </a>
            </div>
          </div>
        ))}
        <div className="button-flex justify-content-center">
          <a href className="primary__btn5">
            See More Before & After Photos
          </a>
        </div>
      </div>
    </div>
  );
};
