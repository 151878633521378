import { useNavigate } from "react-router-dom";
import "../../../css/main.css";
import "../../../css/media.css";

const tabsData = [
  { title: "Overview", path: "/doctor-details-overview/" },
  { title: "Stories", path: "/doctor-details-stories/" },
  { title: "Photos", path: "/doctor-details-photos/" },
  { title: "Q & A", path: "/doctor-details-q-a/" },
  { title: "Videos", path: "/doctor-details-videos/" },
  { title: "Procedures", path: "/doctor-details-procedures/" },
  { title: "Articles", path: "/doctor-details-articles/" },
  { title: "Offers", path: "/doctor-details-offers/" },
];
export const TabsBanner = (props) => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="overflow-scroll-tab">
      <ul className="tabs">
        {tabsData.map((data, ind) => (
          <li
            key={ind + 1}
            className={`${isActive(data.path) ? "active-white" : ""}`}
            rel="tab1"
          >
            <a
              href="data.path"
              onClick={(e) => {
                e.preventDefault();
                navigate(data.path + props.id);
              }}
            >
              {data.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
