import { useState } from "react";
import { AddToFav } from "../../../../../shared/AddToComps/addToFav";
import { IsLike } from "../../../../../shared/AddToComps/isLike";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { ReportErrorModal } from "../../../../../shared/Modals/reportErrorModal";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ShareDropdown } from "../../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import "../../../../../css/main.css";
import "../../../../../css/media.css";

export const ActionComp = () => {
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <ul className="d-flex align-items-center gap-3 pb-5 pb-lg-0">
      <div
        style={{ width: "2.5rem", height: "2.5rem", backgroundColor: "lightGrey" }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center"
      >
        <AddToFav />
      </div>
      <div
        style={{ width: "2.5rem", height: "2.5rem", backgroundColor: "lightGrey" }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center"
      >
        <IsLike />
      </div>
      <div>
        <button
          style={{ width: "2.5rem", height: "2.5rem", backgroundColor: "lightGrey" }}
          className="rounded-circle border-0 d-flex align-items-center justify-content-center ps-3"
          onClick={() => setShowModal(true)}
        >
          <FontAwesomeIcon
            icon={faFlag}
            style={{ color: "gray", fontSize: "20px" }}
          />
        </button>
        <ReportErrorModal showModal={showModal} setShowModal={setShowModal} />
      </div>
      <div className="position-relative">
        <button
          onClick={() => setShowShareDropdown(!showShareDropdown)}
          style={{ width: "2.5rem", height: "2.5rem", backgroundColor: "lightGrey" }}
          className="rounded-circle border-0 d-flex align-items-center justify-content-center ps-2"
        >
          <FontAwesomeIcon
            icon={faShareNodes}
            style={{ color: "gray", fontSize: "20px" }}
          />
        </button>
        {showShareDropdown && <ShareDropdown position={`mt-3 end-0`} />}
      </div>
    </ul>
  );
};
