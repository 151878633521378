import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useMediaQuery } from "@mui/material";
import { useProcedureState } from "../../../../Store/procedure/procedureHooks";
import { useEffect, useState } from "react";
import { getDataforSelect } from "../../../../services/generalService";
import { handleAnyChange } from "../../../../services/doctorsFilter/doctorFilterService";
import { useFilters } from "../../../../services/doctorsFilter/doctorFilterProvider";

export const FindServiceModal = (props) => {
  const matches = useMediaQuery("(max-width:820px)");
  const [procedures, setProcedures] = useState([]);
  const { doctorFilters, setDoctorFilters } = useFilters();
  const procedure = useProcedureState();
  const handleClose = () => {
    props.setShowModal(false);
  };

  useEffect(() => {
    setProcedures(getDataforSelect(procedure.list2));
  }, [procedure.list2]);

  const handleChange = (val) => {
    handleAnyChange(doctorFilters, setDoctorFilters, "procedures", val);
    handleClose();
  };

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "50rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white m-0 p-0 rounded-3">
        <div className="modal-content pt-3 pb-5">
          <div className="modal-header border-0">
            <h5
              className="modal-title text-lg sm:text-xl md:text-3xl"
              id="treatmentServicesModalLabel"
            >
              Related Treatments & Services
            </h5>
            <button
              type="button"
              className="h-8 text-white rose-bg rounded-pill border-0 px-3"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
          <div className="modal-body mt-5">
            <div className="related-treatments-services">
              <Select
                options={procedures}
                getOptionLabel={getOptionLabel}
                defaultMenuIsOpen={true}
                onChange={(e) => handleChange(e.value)}
                isSearchable={true}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
