import { useEffect } from "react";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { userOffersTabs } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { OffersHeader } from "./components/offerHeader";
import { OffersList } from "./components/purchasedOffersComponents/offersList";
import { Loading } from "../../../shared/loadings/Loading";
import { useGetPurchasedOffersByPatient, usePurchasedOffer } from "../../../Store/purchasedOffer/prchasedOfferHook";

export const PurchasedOffers = () => {
  const purchasedOffer = usePurchasedOffer()
  const getPurchasedOffersByPatient=useGetPurchasedOffersByPatient()
  useEffect(() => {
    getPurchasedOffersByPatient(currentPatientId());
  }, [getPurchasedOffersByPatient]);

  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <OffersHeader title="Purchased Offers" />
        <TabsGroup tabs={userOffersTabs} />
        <OffersList tab="Purchased" data={purchasedOffer.list} />
      </div>
    </section>
  );
};
