import { useAppAction, useAppSelector } from "../../services/hooks/hooks";
import {
  createBeforeAfter,
  getAllBeforeAfters,
  getBeforeAftersByBodyPart,
  getBeforeAftersByDoctor,
  getBeforeAftersByDoctorAndStatus,
} from "./beforAfterAction";

export const useCreateBeforeAfter = () => useAppAction(createBeforeAfter);
export const useGetBeforeAftersByDoctor = () =>
  useAppAction(getBeforeAftersByDoctor);
export const useGetBeforeAftersByDoctorAndStatus = () =>
  useAppAction(getBeforeAftersByDoctorAndStatus);
export const useGetAllBeforeAfters = () => useAppAction(getAllBeforeAfters);
export const useGetBeforeAftersByBodyPart = () => useAppAction(getBeforeAftersByBodyPart);
export const useBeforeAfter = () =>
  useAppSelector((state) => state.beforeAfter);
