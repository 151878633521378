import { useState } from "react";
import "../../../../css/main.css";
import { FormStep1 } from "./subComponents/formStep1";
import { FormStep2 } from "./subComponents/formStep2";
import { FormStep3 } from "./subComponents/formStep3";

export const ProfileForm = () => {
  const [step, setStep] = useState(1);
  return (
    <div className="card tab_container p-0 m-0">
      <form id="msform" className="p-s-lg-4 ps-0">
        <ul id="progressbar">
          <li className="active" id="account"></li>
          <li
            className={`${step === 2 || step === 3 ? "active" : ""}`}
            id="personal"
          ></li>
          <li className={`${step === 3 ? "active" : ""}`} id="confirm"></li>
        </ul>
        {step === 1 && <FormStep1 setStep={setStep} />}
        {step === 2 && <FormStep2 setStep={setStep} />}
        {step === 3 && <FormStep3 setStep={setStep} />}
      </form>
    </div>
  );
};
