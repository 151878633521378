import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { proceureOptions } from "../../../../demoData/selectOptionsData";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export const ArticlDetailsHeading = () => {
  const matches2 = useMediaQuery("(min-width:992px)");
  const navigate = useNavigate();
  const getOptionLabel = (option) => (
    <div className="flex items-center gap-x-4 justify-between">
      <span className="text-sm xl:text-base">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="tab-head-block">
      <div className="row mb-4">
        <div className="col-lg-3">
          <Select
            options={proceureOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={proceureOptions[0]}
            isSearchable={true}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className={`col-lg-9 ${matches2? "" : "mt-4"}`}>
          <div className="previous-back-btn">
            <span className="previous ms-2">All Articles</span>
            <a
              href="/doctor-details-articles"
              onClick={(e) => {
                e.preventDefault();
                navigate("/doctor-details-articles");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
