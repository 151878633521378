import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
export const ModalPopup = (props) => {
  return (
    <Popup
      position="top center"
      modal
      open={props.show}
      on="click"
      onOpen={() => props.setShow(true)}
      onClose={() => 
        props.handleClose()
      }
      closeOnDocumentClick={!props.closeOnDocumentClick ? false : true}
      lockScroll={true}
      overlayStyle={{ overflowY: "auto" }}
      contentStyle={{
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        width: props.width,
        backgroundColor: "transparent",
        border: "none",
      }}
    >
      <div className={`bg-transparent rounded-3 position-relative p-0 animated-div`}>
        {props.children}
      </div>
    </Popup>
  );
};
