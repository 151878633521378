export const isPatient = () => {
  let user = localStorage.getItem("mivana_userdata");
  let myuser = JSON.parse(user);
  if (
    myuser &&
    myuser.role &&
    myuser.role.name === "Patient"
  ) {
    return true;
  }
  return false;
};
