import Select from "react-select";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import {
  QA_Options,
  ageOptions,
  durationOptions,
  ethinicityOptions,
  genderOptions,
  heightOptions,
  offersOptions,
  populrSelectOptions,
  priceOptions,
  proceureOptions,
  viewOptions,
  weightOptions,
} from "../../../../demoData/selectOptionsData";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { RatingDropdown } from "../../../../shared/dropdowns/ratingDropdown";
import { faStar } from "@fortawesome/free-regular-svg-icons";

export const FilterBox = (props) => {
  const matches = useMediaQuery("(max-width:1600px)");
  const [selectedDuration, setSelectedDuration] = useState();
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);

  const handleDurationChange = (e) => {
    const value = parseInt(e.value);
    let res = getLastNDays(value);
    setSelectedDuration(res);
  };

  const getLastNDays = (n) => {
    const today = new Date();
    const lastNDays = [];
    for (let i = n - 1; i >= 0; i--) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      lastNDays.push(day.toDateString());
    }
    return lastNDays;
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  const getOptionLabel2 = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6">{`${selectedDuration[0]} - ${
        selectedDuration[selectedDuration.length - 1]
      }`}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div
      className={`resp-hidden-box px-1 mb-10 ${
        matches
          ? "gy-3 gx-3 row row-cols-12"
          : `gy-3 gx-6 row row-cols-${props.cols}`
      }`}
    >
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={proceureOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={proceureOptions[0]}
          isSearchable={true}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      {props.activeTab === "Offers" && (
        <div className={`position-relative ${matches ? "col-4" : "col"}`}>
          <button
            className="offers-box-rating_btn d-flex align-items-center gx-5 justify-content-between"
            type="button"
            onClick={() => setShowRatingDropdown(!showRatingDropdown)}
          >
            <></>
            Rating
            <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
          </button>
          {showRatingDropdown && <RatingDropdown faSolidStar={faStar} />}
        </div>
      )}
      {props.activeTab === "Offers" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={priceOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={priceOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Offers" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={offersOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={offersOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Q&A" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={QA_Options}
            getOptionLabel={getOptionLabel}
            defaultValue={QA_Options[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Q&A" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={populrSelectOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={populrSelectOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab !== "Offers" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={genderOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={genderOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Photos" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={ethinicityOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={ethinicityOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab !== "Offers" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={ageOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={ageOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Photos" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={heightOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={heightOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Photos" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={weightOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={weightOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {props.activeTab === "Photos" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={viewOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={viewOptions[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      {(props.activeTab === "Q&A" || props.activeTab === "Videos") && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={durationOptions}
            getOptionLabel={getOptionLabel}
            onChange={handleDurationChange}
            placeholder="Period"
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
              SingleValue: getOptionLabel2,
            }}
          />
        </div>
      )}
    </div>
  );
};
