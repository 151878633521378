import { useNavigate } from "react-router-dom";

export const SignUpTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => window.location.href.includes(val);

  return (
    <section className="doctor-registration-section signup-page banner2 user-doctor-banner">
      <ul className="tabs">
        <li rel="tab1" className={isActive("sign-up-users") ? "active" : ""}>
          <a
            href="/sign-up-users"
            onClick={(e) => {
              e.preventDefault();
              navigate("/sign-up-users");
            }}
          >
            For Users
          </a>{" "}
        </li>
        <li rel="tab2" className={isActive("sign-up-doctors") ? "active" : ""}>
          <a
            href="/sign-up-doctors"
            onClick={(e) => {
              e.preventDefault();
              navigate("/sign-up-doctors");
            }}
          >
            For Doctors
          </a>{" "}
        </li>
      </ul>
    </section>
  );
};
