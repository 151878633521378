import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import { faArrowUpRightDots, faHandDots, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { faGrinStars } from "@fortawesome/free-regular-svg-icons";

export const ReasonsToUse = () => {
    
    return (
        <section className="reasons-mivana">
        <div className="container-fluid common-pad">
            <h4>Top reasons to use Mivana</h4>
            <div className="row">
                <div className="col-lg-3">
                    <div className="reasons-mivana-box">
                        <span>
                            <FontAwesomeIcon icon={faArrowUpRightDots} />
                        </span>
                        <div className="mivana-box-content">
                            <h5>Grow your  professional network</h5>
                            <p>Share and discuss everyday moments of your practice with thousands of other doctors across the globe.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="reasons-mivana-box">
                        <span>
                            <FontAwesomeIcon icon={faUserDoctor} />
                        </span>
                        <div className="mivana-box-content">
                            <h5>Achieve more together</h5>
                            <p>Share and discuss everyday moments of your practice with thousands of other doctors across the globe.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="reasons-mivana-box">
                        <span>
                        <FontAwesomeIcon icon={faGrinStars} />
                            </span>
                        <div className="mivana-box-content">
                            <h5>Build your online reputation</h5>
                            <p>Share and discuss everyday moments of your practice with thousands of other doctors across the globe.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="reasons-mivana-box">
                        <span>
                        <FontAwesomeIcon icon={faHandDots} />
                            </span>
                        <div className="mivana-box-content">
                            <h5>Deliver smart patient care</h5>
                            <p>Share and discuss everyday moments of your practice with thousands of other doctors across the globe.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}