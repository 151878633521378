import React, { useState } from "react";
import { PuffLoader } from "react-spinners";
import "../css/aos.css";
import "../css/main.css";
import "../css/media.css";

const FavoriteButton = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFavoriteClick = () => {
    setIsLoading(true);
    props.handleClick();
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const override = {
    display: "block",
    borderColor: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <div className="position-relative">
      <button
        className={`overflow-hidden ${props.className} ${
          props.isFav ? "active" : "inActive"
        }`}
        style={props.btnStyle(props.isFav)}
        onClick={handleFavoriteClick}
      >
        {!isLoading && props.handleContent(props.isFav)}
        <PuffLoader
          color={"#e78a82"}
          loading={isLoading}
          cssOverride={override}
          size={42}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </button>
    </div>
  );
};

export default FavoriteButton;
