import { useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../../css/main.css";
import "../../../../css/media.css";
import img1 from "../../../../images/article/rhinoplasty.jpg";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";
import img2 from "../../../../images/article/rhinoplasty1.jpg";
import { ActionComp } from "./subcomponents/actionComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};

export const ArticleDetailsCarousel = () => {
  const matches2 = useMediaQuery("(min-width:992px)");
  return (
    <Carousel
      responsive={responsive}
      arrows={true}
      showDots={false}
      infinite={true}
      autoPlay={false}
      customLeftArrow={
        <button
          className={`rounded-pill fs-6 border-0 pe-5 ps-3 py-2 d-flex align-items-center gap-3 position-absolute ${
            matches2 ? "bottom-0 mb-5" : "bottom-0"
          } leftArrow2 customArrows`}
          style={{ zIndex: "20" }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          Previous
        </button>
      }
      customRightArrow={
        <button
          className={`rounded-pill fs-6 border-0 pe-3 ps-5 py-2 d-flex align-items-center gap-3 position-absolute text-black  ${
            matches2 ? "bottom-0 mb-5" : "bottom-0"
          } rightArrow2 customArrows`}
          style={{ zIndex: "20" }}
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      }
    >
      <div className="item pb-5">
        <div className="doctor-article-details-block pb-5">
          <div className="row">
            <div className="col-lg-8 order-resp-second">
              <h4>Rhinitis - What Should You Know?</h4>
              <p className="fs-6" style={{ lineHeight: "30px" }}>
                The Difference Between These Two Terms Depends Upon Who You Ask
                For The Definition. Most People Define Liposuction As The
                Technique For Removing A Considerable Amount Of Fat Whereas
                Liposculpture Is A Process For Removing A Lesser Amount Of Fat
                In A Way That It Reflects The Underlying Muscles. Liposculpture
                Is Great For Creating A Toned And Athletic Appearance Rather
                Than The Bulk Of The Shapeless Volume.
              </p>
              <p className="fs-6" style={{ lineHeight: "30px" }}>
                Liposuction Or Liposculpture: When You Are Spoilt For Choice,
                Which One Would You Opt For? The Reply Is Obvious; Most People
                Would Love To Be Sculpted Rather Than Sucked. The Term
                Liposculpture Rose Into Prominence Primarily To Connote A More
                Refined Overall Impression Of Sculpture Than Mere Suction. In
                The Early Times, There Were Some Cosmetic Surgeons Who Used This
                Term To Denote A Practice Of Lipo-Etching Or Superficial
                Liposuction Where More Fragile And Delicate Contouring Is
                Carried Out On The Upper Surface Of The Fat, But It Creates A
                Higher Risk Of Producing Irregularities And Undulations On The
                Contour.
              </p>
              <p className="fs-6" style={{ lineHeight: "30px" }}>
                Is It A Marketing Gimmick? Every Plastic Surgeon Does Sculpting
                While Performing Liposuction Irrespective Of The Method
                Implemented, Be It, Aquajet, Slimlipo, Lipotherme, VASER,
                Coollipo, Etc. Liposculpture Is Therefore, A Marketing Name
                Coined By The Enterprise That Produces The VASER
                Ultrasound-Assisted Liposuction Instrument.
              </p>
              <p className="fs-6" style={{ lineHeight: "30px" }}>
                Since Both These Terms Are Used Interchangeably, The Most
                Important Thing Is To Pay A Visit To A Certified Surgeon. So
                Regardless Of The Name By Which You Call It, It Is A Process
                That Reshapes The Body By Removing The Local Fat Deposits
                Permanently. This Is A Very Popular Process Among Both Males And
                Females.
              </p>
            </div>
            <div className="col-lg-4 order-resp-first">
              <div className="article-detail-box-right">
                <div className="helpful-block d-flex justify-content-end">
                  <IsHelpfull label="Helpful" />
                </div>
                <p className="published-date text-end">
                  Published on 12 sep 2022
                  <span>Viewed by 2,300 </span>
                </p>
                <span>
                  <img src={img1} className="img-fluid w-100" alt="" />
                </span>
                <h6>Rhinoplasty</h6>
                <div className="sharing-section highlight-share">
                  <a href className="primary__btn3">
                    Get Price Estimate
                  </a>
                  <ActionComp />
                </div>
              </div>
            </div>
          </div>
          <div className="story-types mb-4">
            <ul>
              <li style={{background: "lightGrey"}} className="fs-6 d-flex align-items-center rounded-pill px-3 py-1 text-dark mb-2">
                Friendlines
              </li>
              <li style={{background: "lightGrey"}} className="fs-6 d-flex align-items-center rounded-pill px-3 py-1 text-dark mb-2">
                Explanation of health issue
              </li>
              <li style={{background: "lightGrey"}} className="fs-6 d-flex align-items-center rounded-pill px-3 py-1 text-dark mb-2">
                Treatment Satisfaction
              </li>
              <li style={{background: "lightGrey"}} className="fs-6 d-flex align-items-center rounded-pill px-3 py-1 text-dark mb-2">
                Value for money
              </li>
              <li style={{background: "lightGrey"}} className="fs-6 d-flex align-items-center rounded-pill px-3 py-1 text-dark mb-2">
                Less wait time
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ****************************************** */}

      <div className="item pb-20">
        <div className="doctor-article-details-block">
          <div className="row">
            <div className="col-lg-8">
              <h4>Rhinitis - What Should You Know?</h4>
              <p>
                The Difference Between These Two Terms Depends Upon Who You Ask
                For The Definition. Most People Define Liposuction As The
                Technique For Removing A Considerable Amount Of Fat Whereas
                Liposculpture Is A Process For Removing A Lesser Amount Of Fat
                In A Way That It Reflects The Underlying Muscles. Liposculpture
                Is Great For Creating A Toned And Athletic Appearance Rather
                Than The Bulk Of The Shapeless Volume.
              </p>
              <p>
                Liposuction Or Liposculpture: When You Are Spoilt For Choice,
                Which One Would You Opt For? The Reply Is Obvious; Most People
                Would Love To Be Sculpted Rather Than Sucked. The Term
                Liposculpture Rose Into Prominence Primarily To Connote A More
                Refined Overall Impression Of Sculpture Than Mere Suction. In
                The Early Times, There Were Some Cosmetic Surgeons Who Used This
                Term To Denote A Practice Of Lipo-Etching Or Superficial
                Liposuction Where More Fragile And Delicate Contouring Is
                Carried Out On The Upper Surface Of The Fat, But It Creates A
                Higher Risk Of Producing Irregularities And Undulations On The
                Contour.
              </p>
              <p>
                Is It A Marketing Gimmick? Every Plastic Surgeon Does Sculpting
                While Performing Liposuction Irrespective Of The Method
                Implemented, Be It, Aquajet, Slimlipo, Lipotherme, VASER,
                Coollipo, Etc. Liposculpture Is Therefore, A Marketing Name
                Coined By The Enterprise That Produces The VASER
                Ultrasound-Assisted Liposuction Instrument.
              </p>
              <p>
                Since Both These Terms Are Used Interchangeably, The Most
                Important Thing Is To Pay A Visit To A Certified Surgeon. So
                Regardless Of The Name By Which You Call It, It Is A Process
                That Reshapes The Body By Removing The Local Fat Deposits
                Permanently. This Is A Very Popular Process Among Both Males And
                Females.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="article-detail-box-right">
                <div className="helpful-block  d-flex align-items-center justify-content-end gap-x-4">
                  <IsHelpfull label="Helpful" />
                  <span>Viewed by 2,300 </span>
                </div>
                <p className="published-date">Published on 12 sep 2022</p>
                <span>
                  <img src={img2} className="img-fluid w-100" alt="" />
                </span>
                <h6>Rhinoplasty</h6>
                <div className="sharing-section">
                  <a href className="primary__btn3">
                    Get Price Estimate
                  </a>
                  <ActionComp />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};
