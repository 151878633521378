import { useState } from "react";
import { searchData } from "../../demoData/searchData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import doctor from "../../images/doctor.jpg";
import "../../css/main.css";
import "../../css/aos.css";
import "../../css/media.css";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Expand from "react-expand-animated";

export const SearchDoctorsModal = (props) => {
  const [showData, setShowData] = useState(searchData);
  const handleSearch = (val) => {
    let arr = [];
    if (val) {
      searchData.forEach((d) => {
        let res = d.subHeadings.filter((x) =>
          x.toLowerCase().includes(val.toLowerCase())
        );
        arr.push({
          heading: d.heading,
          icon: d.icon,
          subHeadings: res ? res : [],
        });
      });
      setShowData(arr);
    } else {
      setShowData(searchData);
    }
  };
  const transitions = ["height", "opacity"];
  const styles = {
    open: { background: "rgba(0,0,0,0.8)", zIndex: "500" },
  };
  return (
    
      <Expand
        open={props.showModal}
        duration={500}
        styles={styles}
        transitions={transitions}
        className="position-fixed top-0 end-0 start-0 bottom-0 pe-5"
      >
        <div
          className="mega-menu-open d-flex flex-column position-relative"
          style={{
            boxShadow: "1px 1px 6px 1px #00000024",
            zIndex: "500",
          }}
        >
          <span
            className="close-mega-menu"
            onClick={() => props.setShowModal(false)}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="left_chev left-left_chev"
            />
            <FontAwesomeIcon icon={faChevronLeft} className="left_chev" /> Close
          </span>
          <div className="search-box-open">
            <input
              type="text"
              className="form-control"
              placeholder="Botox"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <i className="fa-light fa-magnifying-glass"></i>
            <p className="small">Hit enter to search or ESC to close</p>
          </div>

          <div className="row h-full overflow-y-auto">
            {showData.map((data, ind) => (
              <div
                className={` item_main_div mt-2 ${
                  data.heading === "Doctors" ? "col-lg-4" : "col-lg-2"
                }`}
                key={ind + 1}
              >
                <h5>
                  <FontAwesomeIcon icon={data.icon} className="fs-5" />{" "}
                  {data.heading}
                </h5>
                {data.subHeadings && data.subHeadings.length > 0 ? (
                  <div>
                    {data.subHeadings.map((d, i) =>
                      data.heading === "Doctors" ? (
                        <li
                          key={i + 1}
                          className="list_item align-items-center gap-2 fs-5 fs-xl-4 mt-2 pt-1"
                        >
                          <span>
                            <img
                              src={doctor}
                              className="img-fluid"
                              width={40}
                              alt="doctor"
                            />
                          </span>
                          <span className="doct-list-item">{d}</span>
                        </li>
                      ) : (
                        <li key={i + 1}>
                          <a href="/">{d}</a>
                        </li>
                      )
                    )}
                  </div>
                ) : (
                  <p className="text-secondary fs-4 mt-4">No Data!</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </Expand>
  );
};
