import {
    APPOINTMENTS_BY_STATUS
  } from "./actionTypes";
  
  const initialData = {
    listByStatus: [],
  };
  export function AppointmentReducer(state = initialData, action) {
    switch (action.type) {
      case APPOINTMENTS_BY_STATUS: {
        const data = action.payload;
        return {
          ...state,
          listByStatus: data,
        };
      }
      default:
        return state;
    }
  }
  