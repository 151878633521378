import FavoriteButton from "../fav";
import { IsFav3Content } from "./subComponents/isFav3Content";

export const IsFav3 = (props) => {
  const myStyle = (isFav) => {
    return {
      background: isFav ? "black" : props.bg ? props.bg : "white",
      color: isFav ? "white" : props.color ? props.color : "black",
      border: props.border ? props.border : "1px solid gray",
      borderRadius: "50px",
      width: "17rem",
      height: "3.8rem",
    };
  };

  return (
    <FavoriteButton
      btnStyle={myStyle}
      className={`d-flex align-items-center justify-content-center`}
      handleContent={(isFav) => (
        <IsFav3Content
          isFav={isFav}
          label={props.label}
          subLabel={props.subLabel}
        />
      )}
    />
  );
};
