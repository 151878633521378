import img1 from '../../../../../images/redeem/1.png'
import img2 from '../../../../../images/redeem/2.png'
export const EarnedPointsList = () => {
  return (
    <div className="tab_container articles-container">
      <div id="tab1" className="tab_content">
        <div className="refer-block">
          <h4>Together, we are going further!</h4>
          <div className="invitee-status pb-2 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <span className="name-invitee blue p-0">
                <img src={img1} className="img-fluid" alt="" />
              </span>
              <h6 className="fs-4">
                Refer a Friend <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status pb-2 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <span className="name-invitee blue p-0">
                <img src={img2} className="img-fluid" alt="" />
              </span>

              <h6 className="fs-4">
                Will Nelson <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status pb-2 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <span className="name-invitee blue p-0">
                <img src={img2} className="img-fluid" alt="" />
              </span>

              <h6 className="fs-4">
                Ann Matthews <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status pb-2 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <span className="name-invitee blue p-0">
                <img src={img2} className="img-fluid" alt="" />
              </span>

              <h6 className="fs-4">
                Alex Fox <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>

          <div className="invitee-status pb-2 d-flex flex-sm-row flex-column">
            <div className="invitee-status-box">
              <span className="name-invitee blue p-0">
                <img src={img2} className="img-fluid" alt="" />
              </span>

              <h6 className="fs-4">
                Carmen Vargas <span>12 Sep 2022 | 2 days ago</span>{" "}
              </h6>
            </div>
            <div className="invitee-status-right">
              <h5 className="fs-6 text-secondary">
                Rewards Earned <span className="fs-4 ms-2 rose-text">125 Points</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
