import { favoruitesTabs } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { FavHeader } from "./components/favHeader";
import { FavItemsList } from "./components/favItemsList/favItemsList";

export const FavBeforeAfter = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <FavHeader title="Before & After (19)" />
        <TabsGroup tabs={favoruitesTabs} />
        <FavItemsList />
      </div>
    </section>
  );
};
