import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/Mivana_Logo.png";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../../css/commingSoon.css";
import { useCountdown } from "./components/useCountdown";
import { useNavigate } from "react-router-dom";

const THREE_DAYS_IN_MS = 117 * 24 * 60 * 60 * 1000;
const NOW_IN_MS = new Date().getTime();

const targetDate = NOW_IN_MS + THREE_DAYS_IN_MS;

export const CommingSoon = () => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const navigate = useNavigate();
  return (
    <div>
      <div className="main_nav_header commingSoon_header main_nav_header_commingSoon">
        <div className="logo m-auto d-block">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <img src={logo} className="img-fluid mt-4" alt="" />{" "}
          </a>
        </div>
      </div>
      <section className="banner-section-commingSoon">
        <div className="counter-timer-block">
          <div id="countdown">
            <ul>
              <li>
                <span id="days">{days}</span>days
              </li>
              <li>
                <span id="hours">{hours}</span>Hours
              </li>
              <li>
                <span id="minutes">{minutes}</span>Minutes
              </li>
              <li>
                <span id="seconds">{seconds}</span>Seconds
              </li>
            </ul>
          </div>
        </div>
        <div className="slider-one">
          <div className="slider-one-image">
            <div className="slider-text"></div>
          </div>
        </div>
        <div className="slider-two">
          <div className="slider-two-image">
            <div className="slider-text"></div>
          </div>
        </div>
        <div className="slider-three">
          <div className="slider-three-image">
            <div className="slider-text"></div>
          </div>
        </div>
        <div className="slider-four">
          <div className="slider-four-image">
            <div className="slider-text"></div>
          </div>
        </div>

        <div className="social-icon-coming-soon">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/mivanainternational"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/mivanainternational"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/mivanainternational/"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.youtube.com/mivanainternational"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
