import { useEffect } from "react";
import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import aesthetics from "../../../images/aesthetics.jpg";
import play from "../../../images/play.png";
export const Aesthetic = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <section className="aesthetics">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0 m-auto">
            <div
              className="aesthetics-content"
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <h2 className="text-5xl">
                Aesthetics <br />
                Forever
              </h2>
              <p className="p-head-size">
                Lorem ipsum dolor sit amet, consecteturadipiscing eger
              </p>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div
              className="video-box"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <img src={aesthetics} className="img-fluid w-100" alt="" />
              <span>
                <img src={play} className="img-fluid pulse" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
