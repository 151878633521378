export const NoDataComp = (props) => {
  return (
    <div
      className={`w-100 h-100 flex align-items-center justify-content-center ${
        props.className ? props.className : ""
      }`}
    >
      <div>
        <p className="fs-5 font-bold p-0 m-0 text-dark text-center">
          {props.title}
        </p>
        <p className="fs-6 text-dark text-center">{props.desc}</p>
      </div>
    </div>
  );
};
