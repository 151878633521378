import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import aesthetics from "../../../images/aesthetics.jpg";
import { useMediaQuery } from "@mui/material";
export const CtaBlock = () => {
    const matches2 = useMediaQuery('(min-width:992px)');
    const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1300 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1300, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };
  return (
    <div style={{marginTop: "-3rem",}} className="position-relative z-2 px-lg-4">
      <div className="relative z-20 px-1">
        <Carousel
          responsive={responsive}
          arrows={matches2 ? false : true}
          showDots={false}
          infinite={true}
          autoPlay={false}
          customLeftArrow={
            <button
              style={{ backgroundColor: "#FDB8B6" }}
              className="rounded-full h-12 w-12 text-xs absolute left-6 text-black top-28 z-20"
            >
              <i className="fa fa-chevron-left text-base" aria-hidden="true"></i>
            </button>
          }
          customRightArrow={
            <button
              style={{ backgroundColor: "#FDB8B6" }}
              className="rounded-full h-12 w-12 text-xs absolute right-6 z-20 text-black top-28"
            >
              <i className="fa fa-chevron-right text-base" aria-hidden="true"></i>
            </button>
          }
        >
          <div className="item" style={{ padding: "3px 12px" }}>
            <div className="cta-block-flex">
              <div className="cta-box-single">
                <a href="/">
                  <span>
                    <img src={aesthetics} className="img-fluid" alt="" />
                  </span>
                  <div className="cta-content">
                    <h6>Top Doctors</h6>
                    <p className="mt-3">Seamless Appointment, Unparalleled Medical Attention</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="item" style={{ padding: "3px 12px" }}>
            <div className="cta-block-flex">
              <div className="cta-box-single">
                <a href="/">
                  <span>
                    <img src={aesthetics} className="img-fluid" alt="" />
                  </span>
                  <div className="cta-content">
                    <h6>Top Doctors</h6>
                    <p className="mt-3">Seamless Appointment, Unparalleled Medical Attention</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="item" style={{ padding: "3px 12px" }}>
            <div className="cta-block-flex">
              <div className="cta-box-single">
                <a href="/">
                  <span>
                    <img src={aesthetics} className="img-fluid" alt="" />
                  </span>
                  <div className="cta-content">
                    <h6>Top Doctors</h6>
                    <p className="mt-3">Seamless Appointment, Unparalleled Medical Attention</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="item" style={{ padding: "3px 12px" }}>
            <div className="cta-block-flex">
              <div className="cta-box-single">
                <a href="/">
                  <span>
                    <img src={aesthetics} className="img-fluid" alt="" />
                  </span>
                  <div className="cta-content">
                    <h6>Top Doctors</h6>
                    <p className="mt-3">Seamless Appointment, Unparalleled Medical Attention</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="item" style={{ padding: "3px 12px" }}>
            <div className="cta-block-flex">
              <div className="cta-box-single">
                <a href="/">
                  <span>
                    <img src={aesthetics} className="img-fluid" alt="" />
                  </span>
                  <div className="cta-content">
                    <h6>Top Doctors</h6>
                    <p className="mt-3">Seamless Appointment, Unparalleled Medical Attention</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="h-32 relative z-0 -mt-20 bg-white"></div>
    </div>
  );
};
