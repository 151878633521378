import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { BadgesList } from "./components/badgesList";

export const Badge = () => {
  const matches = useMediaQuery("(min-width:992px)");
  return (
    <div>
      <Header />
      <section className="doctor-detailed-section badge-block">
        <div className="button-flex justify-content-between badge-heading common-pad container-fluid">
          <h3 className="">Badges</h3>
          <p>
            Welcome to Mivana Community Badges! Here you can <br />
            bearn adges based on the set objectives
          </p>
        </div>
        <div className="container-fluid">
          <BadgesList />
        </div>
      </section>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Contact Dr. Lauren Clinton</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
