import Chart from "react-apexcharts";
import { monthsData } from "../../demoData/generalData";
const state = {
  series: [
    {
      name: "series1",
      data: [860, 840, 855, 845, 860, 845, 860, 840, 865, 860, 840, 855],
    },
    {
      name: "series2",
      data: [840, 860, 845, 855, 840, 855, 840, 860, 845, 840, 865, 860],
    },
  ],
  options: {
    plotOptions: {
      bar: {
        borderRadius: 0,
      },
    },
    colors: ["#2EB9FF", "#3377FF"],
    title: {
      text: "",
      style: {
        fontSize: "18px",
        fontWeight: "normal",
      },
    },
    grid: {
      show: true,
    },
    markers: {
      size: 4,
    },
    chart: {
      height: 400,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 3,
    },

    xaxis: {
      type: "string",
      categories: monthsData,
    },
    yaxis: {
      type: "string",
      categories: ["0", "100", "200", "300", "400", "500"],
      title: {
        text: "",
        style: {
          color: "#787A7B",
          fontSize: "10px",
          fontWeight: "normal",
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },

    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};

export const PatientVisitLineChart = () => {
  return (
    <div className="w-full">
      <Chart
        options={state.options}
        series={state.series}
        type="line"
        height={300}
      />
    </div>
  );
};
