import { useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import "../../../../css/aos.css";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { useEffect } from "react";
import antiaging from "../../../../images/Featured/Antiaging.jpg";
import antiagingColor from "../../../../images/Featured/Antiaging-Color.jpg";

import Hair_Color from "../../../../images/Featured/Hair_Color.jpg";
import Hair from "../../../../images/Featured/Hair.jpg";
import LipsColor from "../../../../images/Featured/Lips-Color.jpg";
import Lips from "../../../../images/Featured/Lips.jpg";
import Body_contouring_Color from "../../../../images/Featured/Body-contouring_Color.jpg";
import Body_contouring from "../../../../images/Featured/Body-contouring.jpg";
import Face_Lift_Color from "../../../../images/Featured/Face_Lift_Color.jpg";
import FaceLift from "../../../../images/Featured/Face-Lift.jpg";
import Acne_color from "../../../../images/Featured/Acne_color.jpg";
import Acne from "../../../../images/Featured/Acne.jpg";
import ButtliftColor from "../../../../images/Featured/Buttlift-Color.jpg";
import Buttlift from "../../../../images/Featured/Buttlift.jpg";
import DentalColor from "../../../../images/Featured/Dental-Color.jpg";
import Dental from "../../../../images/Featured/Dental.jpg";
import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};

export const FeaturedProcedureCarousel = () => {
  const matches2 = useMediaQuery("(min-width:766px)");
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div className="featured-procedure featured-procedure-responsive common-slider mt-14">
      <div className="container-fluid">
        <div className="row mb-6">
          <div className="col-lg-6">
            <div className="before-after-box1">
              <h2>
                Featured Procedures
                <span className="title-head">
                  <span className="type-writer">BE INSPIRED</span>
                </span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.ut ullamcorper.ut
                ullamcorper.ut ullamcorper.ut ullamcorper.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="book-consultation"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <svg id="rectangle">
                <rect id="stroke" rx="3" ry="5" />
              </svg>
              <a
                href="/book-appoinment"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/book-appointment");
                }}
                className="book-appoinment-btn"
              >
                Book a Consultation
              </a>
              <p>
                Lorem ipsum dolor sit amet, <br />
                consectetur
              </p>
            </div>
          </div>
        </div>
        {/* ********************************************  */}
        <Carousel
          responsive={responsive}
          arrows={matches2 ? false : true}
          showDots={false}
          infinite={true}
          autoPlay={false}
          customLeftArrow={
            <button
              className={`rounded-full h-12 w-12 text-xs absolute text-black ${
                matches2 ? "-bottom-10" : "bottom-0"
              } z-20 leftArrow customArrows`}
            >
              <i className="fa fa-chevron-left text-base" aria-hidden="true"></i>
            </button>
          }
          customRightArrow={
            <button
              className={`rounded-full h-12 w-12 text-xs absolute z-20 text-black  ${
                matches2 ? "-bottom-10" : "bottom-0"
              } rightArrow customArrows`}
            >
              <i className="fa fa-chevron-right text-base" aria-hidden="true"></i>
            </button>
          }
        >
          <div className="item">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={antiaging}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={antiagingColor}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Anti Aging</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={Hair}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={Hair_Color}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Hair Loss</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={Lips}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={LipsColor}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Lip Augmentation</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={Body_contouring}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={Body_contouring_Color}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Body Contouring</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={FaceLift}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={Face_Lift_Color}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Face Lift</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={Acne}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={Acne_color}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Acne & Rosace</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={Buttlift}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={ButtliftColor}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Butt Lift</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
          <div className="item pb-10">
            <div
              className="featured-procedure-block hover-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <a href="/">
                <span className="before-hover-grey">
                  <img
                    src={Dental}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <span className="after-hover-color">
                  <img
                    src={DentalColor}
                    className={`img-fluid ${
                      matches2 ? "" : "h-96 w-full object-cover"
                    }`}
                    alt=""
                  />
                </span>
                <h5>Dental</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.
                </p>
              </a>
            </div>
          </div>
        </Carousel>
        {/* ********************************************  */}
      </div>
    </div>
  );
};
