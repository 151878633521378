import { useNavigate } from "react-router-dom";
import "../../../../css/main.css";

export const WalletTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="overflow-scroll-tab2 pb-0">
      <ul className="tabs tab-common-dashboard ps-4 pt-4 gap-5">
        <li
          className={`px-0 pt-1 ${isActive("/doctor-dashboard/wallet-orders") ? "active" : ""}`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/wallet-orders"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/wallet-orders");
            }}
          >
          My Orders
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${isActive("/doctor-dashboard/wallet-location") ? "active" : ""}`}
          rel="tab2"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/wallet-location"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/wallet-location");
            }}
          >
            Location
          </a>
        </li>
        <li
          className={`px-0 pt-1 ${isActive("/doctor-dashboard/wallet-history") ? "active" : ""}`}
          rel="tab3"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/doctor-dashboard/wallet-history"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/wallet-history");
            }}
          >
            History
          </a>
        </li>
      </ul>
    </div>
  );
};
