import { ModalPopup } from "../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import Select from "react-select";
import {
  faCheck,
  faChevronDown,
  faChevronLeft,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { RatingDropdown } from "../../../shared/dropdowns/ratingDropdown";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";

const options1 = [
  { value: "Filter by Procedure", label: "Filter by Procedure" },
];

export const OffersFilterModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");

  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const getOptionLabel = (option) => (
    <div className="flex items-center gap-x-4 justify-between">
      <span className="text-sm xl:text-base">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white modal-common-head rounded-3 px-3 px-sm-5 pb-5 pt-5">
        <span
          className="close-mega-menu"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="left_chev left-left_chev"
          />
          <FontAwesomeIcon icon={faChevronLeft} className="left_chev" /> Close
        </span>
        <div className="d-flex flex-column align-items-center gap-4 w-100  mt-5">
          <div className="w-100">
            <Select
              options={options1}
              getOptionLabel={getOptionLabel}
              defaultValue={options1[0]}
              isSearchable={true}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="w-100 position-relative">
            <button
              className="offers-box-select__control d-flex align-items-center gap-5 justify-content-between"
              type="button"
              onClick={() => setShowRatingDropdown(!showRatingDropdown)}
            >
              <></>
              Rating
              <FontAwesomeIcon icon={faChevronDown} style={{fontSize: "14px"}} />
            </button>
            {showRatingDropdown && <RatingDropdown faSolidStar={faStar} />}
          </div>
          <div className="w-100">
            <Select
              options={options1}
              getOptionLabel={getOptionLabel}
              defaultValue={options1[0]}
              isSearchable={false}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="w-100">
            <Select
              options={options1}
              getOptionLabel={getOptionLabel}
              defaultValue={options1[0]}
              isSearchable={false}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-start mt-5">
          <input type="submit" style={{background: "black"}} className="text-white rounded-pill px-4 py-2 fs-6 fs-sm-5" value="Submit" />
        </div>
      </div>
    </ModalPopup>
  );
};
