import { faArrowRightLong, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { useEffect, useState } from "react";
import { MultiChoiceQues } from "../../../../userDashboard/userReviews/components/createReviewComponents/subComponents/multiChoiceQues";
import { useQA, useSetQAFormData } from "../../../../../Store/qa/qaHook";
import { getDataforSelect, getSelectData } from "../../../../../services/generalService";
import { useProcedureState } from "../../../../../Store/procedure/procedureHooks";
import { useBodyPartState } from "../../../../../Store/bodyPart/bodyPartHook";
import { useTreatmentState } from "../../../../../Store/treatment/treatmentHook";

export const CreateQAForm1 = (props) => {
  const [finalData, setFinalData] = useState({});
  const setQAFormData = useSetQAFormData();
  const qaState = useQA();
  const procedure = useProcedureState();
  const bodyPartState = useBodyPartState()
  const treatmentState = useTreatmentState()
  useEffect(() => {
    if (qaState.formData) {
      setFinalData(qaState.formData);
    }
  }, [qaState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setQAFormData(updatedFinalData);
  };

  const handleChangeArray = (key, val, i) => {
    let arr = finalData[key] ? finalData[key] : [];
    arr[i] = val;
    const updatedFinalData = { ...finalData, [key]: arr };
    setQAFormData(updatedFinalData);
    setFinalData(updatedFinalData);
  };

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
<form onSubmit={() => props.setShowForm(2)}>
      <div className="form-card d-flex flex-column gap-3">
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q1</span> Which body part you
              treated ?
            </label>
            <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
              <div className="select-search-icon">
                <Select
                required
                 options={getDataforSelect(bodyPartState.list)}
                  getOptionLabel={getOptionLabel}
                  value={finalData.bodyPart}
                  onChange={(val) =>
                    handleChange("bodyPart", val)
                  }
                  isSearchable={true}
                  className="my-form-select-search" // Add a custom class name for styling
                  classNamePrefix="my-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <MultiChoiceQues
          ques="Have you completed your
            treatment?"
          quesNo="2"
          choicesData={["Yes", "No"]}
          value={finalData.treatmentCompleted}
          onChange={(val) => handleChange("treatmentCompleted", val)}
        />
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q3</span>Please mention your
              treatment date
            </label>
            <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
              <input
              required
                name="file-upload-field form-control"
                type="date"
                className="form-control"
                defaultValue={finalData.treatmentDate}
                onChange={(e) => handleChange("treatmentDate", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q4</span>For which health problem
              or treatment did you visit?
            </label>
            <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
            <div className="select-search-icon">
                  <Select
                    required
                    options={getDataforSelect(treatmentState.list)}                  
                    getOptionLabel={getOptionLabel}
                    value={finalData.treatment}
                    onChange={(e) => handleChange("treatment", e)}
                    isSearchable={true}
                    className="profile-form-select-search" // Add a custom class name for styling
                    classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
            </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q5</span>Please select procedures
              involved in treatment?
            </label>
            <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
              <div className="select-search-icon">
                <Select
                required
                  options={getDataforSelect(procedure.list2)}
                  getOptionLabel={getOptionLabel}
                  isMulti
                  value={getSelectData(finalData.procedures)}
                  onChange={(val) =>
                    handleChange(
                      "procedures",
                      val
                    )
                  }
                  isSearchable={true}
                  className="my-form-select-search" // Add a custom class name for styling
                  classNamePrefix="my-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q6</span>Does this treatment
              worthit?
            </label>
            <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
              <div className="invisible-checkboxes">
                <div
                  className={`form-group position-relative`}
                  onClick={() =>
                    handleChangeArray("treatmentWorthIt", "Yes", 0)
                  }
                >
                  <label
                    className={`${
                      finalData.treatmentWorthIt &&
                      finalData.treatmentWorthIt[0] === "Yes"
                        ? "checked_checkboxes"
                        : ""
                    } checkbox-alias`}
                    for="r1"
                  >
                    Yes
                  </label>
                </div>
                <div
                  className={`form-group position-relative`}
                  onClick={() =>
                    handleChangeArray("treatmentWorthIt", "No", 0)
                  }
                >
                  <label
                    className={`${
                      finalData.treatmentWorthIt &&
                      finalData.treatmentWorthIt[0] === "No"
                        ? "checked_checkboxes"
                        : ""
                    } checkbox-alias`}
                    for="r1"
                  >
                    No
                  </label>
                </div>

                <div className="w-100 px-0">
                  <textarea
                    value={
                      finalData.treatmentWorthIt
                        ? finalData.treatmentWorthIt[1]
                        : ""
                    }
                    onChange={(e) =>
                      handleChangeArray(
                        "treatmentWorthIt",
                        e.target.value,
                        1
                      )
                    }
                    className="form-control mt-3 mx-0"
                    placeholder="Please specify the reason(optional)"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group position-relative mt-3">
        <div className="row">
          <label className="fieldlabels">
            <span className="questions-num">Q7</span>Please specify treatment
            cost?
          </label>
          <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
            <input
            required
              className="form-control"
              type="number"
              value={finalData.treatmentCost}
              onChange={(e) => handleChange("treatmentCost", e.target.value)}
              placeholder="Enter Amount"
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        name="next"
        className="next action-button"
      >
        Next <FontAwesomeIcon icon={faArrowRightLong} />
      </button>
    </form>
  );
};
