import { notificationTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { NotificationHeader } from "./components/notificationHeader";
import { NewNotificationsList } from "./components/notiNew_Components/notificationsList";

export const NotificationNew = () => {
  return (
    <section className="doctor-detail-stories-tab">
      <div className="container-fluid p-0">
        <NotificationHeader title="New Notifications (125)" />
        <TabsGroup tabs={notificationTabsData()} />
        <NewNotificationsList />
      </div>
    </section>
  );
};
