// beforeAfterHeaderSliderData imports
import img1 from "../images/before-after/stomach.jpg";
import img2 from "../images/before-after/breasts.jpg";
import img3 from "../images/before-after/face.jpg";
import img4 from "../images/before-after/nose.jpg";
import img5 from "../images/before-after/skin.jpg";
import img6 from "../images/before-after/chin.jpg";
import img7 from "../images/before-after/neck.jpg";
import img8 from "../images/before-after/butt.jpg";
import img9 from "../images/before-after/lips.jpg";
import img10 from "../images/before-after/jawline.jpg";
import img11 from "../images/before-after/hiips.jpg";

// beforeAfterPhotosData imports
import img12 from "../images/before-after-listing/before-after-listing1.jpg";
import img13 from "../images/before-after-listing/before-after-listing1.1.jpg";
import img14 from "../images/before-after-listing/before-after-listing2.jpg";
import img15 from "../images/before-after-listing/before-after-listing2.2.jpg";
import img16 from "../images/before-after-listing/before-after-listing3.jpg";
import img17 from "../images/before-after-listing/before-after-listing3.2.jpg";
import img18 from "../images/before-after-listing/before-after-listing4.jpg";
import img19 from "../images/before-after-listing/before-after-listing4.2.jpg";

// *********************************************************************************************
export const beforeAfterHeaderSliderData = [
  {
    title: "Stomach",
    img: img1,
  },
  {
    title: "Breasts",
    img: img2,
  },
  {
    title: "face",
    img: img3,
  },
  {
    title: "nose",
    img: img4,
  },
  {
    title: "skin",
    img: img5,
  },
  {
    title: "Chin",
    img: img6,
  },
  {
    title: "Neck",
    img: img7,
  },
  {
    title: "Butt",
    img: img8,
  },
  {
    title: "Lips",
    img: img9,
  },
  {
    title: "Jawline",
    img: img10,
  },
  {
    title: "legs",
    img: img11,
  },
  {
    title: "Stomach",
    img: img1,
  },
  {
    title: "Breasts",
    img: img2,
  },
  {
    title: "face",
    img: img3,
  },
  {
    title: "nose",
    img: img4,
  },
  {
    title: "skin",
    img: img5,
  },
  {
    title: "Chin",
    img: img6,
  },
  {
    title: "Neck",
    img: img7,
  },
  {
    title: "Butt",
    img: img8,
  },
  {
    title: "Lips",
    img: img9,
  },
  {
    title: "Jawline",
    img: img10,
  },
  {
    title: "legs",
    img: img11,
  },
];

export const beforeAfterPhotosData = [
  {
    title: "Botox Injections",
    img1: img12,
    img2: img13,
  },
  {
    title: "Face Lift",
    img1: img14,
    img2: img15,
  },
  {
    title: "Fillers For Eye",
    img1: img16,
    img2: img17,
  },
  {
    title: "Botox For Forhead",
    img1: img18,
    img2: img19,
  },
  {
    title: "Liposuction",
    img1: img12,
    img2: img13,
  },
  {
    title: "Botox For Forhead",
    img1: img12,
    img2: img13,
  },
];

export const beforeAfterPhotosData2 = [
  {
    title: "Botox Injections",
    img1: img12,
    img2: img13,
  },
  {
    title: "Face Lift",
    img1: img14,
    img2: img15,
  },
  {
    title: "Fillers For Eye",
    img1: img16,
    img2: img17,
  },
  {
    title: "Botox For Forhead",
    img1: img18,
    img2: img19,
  },
  {
    title: "Liposuction",
    img1: img12,
    img2: img13,
  },
  {
    title: "Face Lift",
    img1: img14,
    img2: img15,
  },
  {
    title: "Fillers For Eye",
    img1: img16,
    img2: img17,
  },
  {
    title: "Botox For Forhead",
    img1: img18,
    img2: img19,
  },
  {
    title: "Liposuction",
    img1: img12,
    img2: img13,
  },
  {
    title: "Face Lift",
    img1: img14,
    img2: img15,
  },
  {
    title: "Fillers For Eye",
    img1: img16,
    img2: img17,
  },
  {
    title: "Botox For Forhead",
    img1: img18,
    img2: img19,
  },
];
