/* eslint-disable react-hooks/exhaustive-deps */
import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { DoctorDetailsHeader } from "./components/doctorDetailsHeader";
import "../../css/main.css";
import "../../css/media.css";
import { DoctorProfileDetails } from "./components/doctorProfileDetails";
import { faSliders, faStar } from "@fortawesome/free-solid-svg-icons";
import { TabsBanner } from "./components/tabsBanner";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiltersModal } from "./components/subComponents/filtersModal";
import { useEffect, useState } from "react";
import { AlphabetFilter } from "./components/procedure/alphabetFilters";
import { ProceduresList } from "./components/procedure/proceduresList";
import { useParams } from "react-router-dom";
import { Loading } from "../../shared/loadings/Loading";
import { useGetProceduresByDoctor } from "../../Store/procedure/procedureHooks";
import { useGetDoctorByIdForPublic } from "../../Store/Doctor/doctorHooks";

export const DoctorDetailsProcedures = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filterTab, setFilterTab] = useState("All");
  const params = useParams();
  const getProceduresByDoctor = useGetProceduresByDoctor();
  const getDoctorByIdForPublic = useGetDoctorByIdForPublic();
  useEffect(() => {
    if (params.id) {
      getDoctorByIdForPublic(params.id)
      getProceduresByDoctor(params.id);
    }
  }, [params.id]);
  return (
    <div>
      <Loading duration={500} />
      <Header />
      <DoctorDetailsHeader />
      <div className="doctor-detailed-wrapper">
        <DoctorProfileDetails solidStar={faStar} />
        <section className="doctor-detailed-section">
          <TabsBanner id={params.id} />
          <div className="container-fluid">
            <div className="tab-head-block pt-5">
              <div className="button-flex justify-content-end fixed-box">
                <button
                  type="button"
                  className="btn btn-primary selectPopup-btn"
                  onClick={() => setShowFiltersModal(true)}
                >
                  <FontAwesomeIcon icon={faSliders} />
                </button>
                <FiltersModal
                  activeTab="Videos"
                  showModal={showFiltersModal}
                  setShowModal={setShowFiltersModal}
                />
              </div>
              <AlphabetFilter setFilterTab={setFilterTab} />
              <ProceduresList filterTab={filterTab} />
            </div>
          </div>
          <div className="button-flex justify-content-center mt-5">
            <a href className="primary__btn5">
              See More Procedures
            </a>
          </div>
        </section>
      </div>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Contact Dr. Lauren Clinton</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
