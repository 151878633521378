import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalPopup } from "./ModalPopup";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const options = [
  { value: "Pakistan", label: "Pakistan" },
  { value: "India", label: "India" },
  { value: "America", label: "America" },
];

export const CountryModal = (props) => {
  const handleClose = () => {
    props.setShowModal(false);
  };
  const getOptionLabel = (option) => (
    <div className="flex items-center gap-x-4 justify-between">
      <span className="text-sm xl:text-base">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width="32rem"
      closeOnDocumentClick={true}
    >
      <div className="bg-white m-0 p-0 rounded-3">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Choose Your Location
          </h5>
          <button
            onClick={handleClose}
            style={{background: "rgb(161 161 170)", width: "30px", height: "30px"}}
            className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
          >
            <FontAwesomeIcon icon={faClose} className="ms-2" />
          </button>
        </div>
        <div className="modal-body">
          <div className="form-selct-modal">
            <form action="">
              <div className="form-group">
                <label for="">Country</label>
                <Select
                  options={options}
                  getOptionLabel={getOptionLabel}
                  defaultValue={options[0]}
                  isSearchable={true}
                  className="offers-box-select" // Add a custom class name for styling
                  classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="form-group mt-4">
                <label for="">State</label>
                <Select
                  options={options}
                  getOptionLabel={getOptionLabel}
                  defaultValue={options[0]}
                  isSearchable={true}
                  className="offers-box-select" // Add a custom class name for styling
                  classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="primary__btn6">
            Submit
          </button>
        </div>
      </div>
    </ModalPopup>
  );
};
