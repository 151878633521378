import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import {
  faClose,
  faPenNib,
  faStethoscope,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import "../../../../css/main.css";
import "../../../../css/media.css";

export const HowItWorksModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="modal-content bg-white rounded-lg">
        <div className="modal-header">
          <h5 className="modal-title" id="favouriteModalLabel">
            How it works
          </h5>
          <button
            onClick={handleClose}
            style={{
              background: "rgb(161 161 170)",
              width: "30px",
              height: "30px",
            }}
            className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
          >
            <FontAwesomeIcon icon={faClose} className="ms-2" />
          </button>
        </div>
        <div className="modal-body">
          <div className="exclamtion-modal-content">
            <div className="exclamtion-modal-inner">
              <span>
                <FontAwesomeIcon icon={faStethoscope} />
              </span>
              <div className="content-modal-innner">
                <h6>Choose your doctor</h6>
                <p>
                  Find a doctor on RealSelf that accepts virtual consultations.
                </p>
              </div>
            </div>
            <div className="exclamtion-modal-inner">
              <span>
                <FontAwesomeIcon icon={faPenNib} />
              </span>
              <div className="content-modal-innner">
                <h6>Tell us about yourself</h6>
                <p>
                  Answer a few short questions about the procedure you're
                  interested in.
                </p>
              </div>
            </div>
            <div className="exclamtion-modal-inner">
              <span>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>
              <div className="content-modal-innner">
                <h6>Schedule your virtual consultation</h6>
                <p>Submit your request and get connected with a doctor.</p>
              </div>
            </div>
            <div className="exclamtion-modal-inner">
              <span>
                <FontAwesomeIcon icon={faVideo} />
              </span>
              <div className="content-modal-innner">
                <h6>Attend your virtual consultation</h6>
                <p>Arrange the details of your video call with your doctor.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
