import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import axiosInstance from "../../../../../services/AxiosInstance";
import { toast } from "react-toastify";

export const ProfileUploadDoc = forwardRef((props) => {
  const [img, setImg] = useState("");

  function clearData() {
    setImg("");
  }
  useImperativeHandle(props.childRef, () => ({
    clearData() {
      clearData();
    },
  }));

  useEffect(() => {
    if (props.editableImg) {
      setImg(props.editableImg);
    }
  }, [props.editableImg]);
  const handleChange = (e) => {
    let imgData = e.target.files[0];
    if (imgData && imgData.name) {
      let formData = new FormData();
      formData.append("files", imgData, imgData.name);
      axiosInstance
        .post("upload", formData)
        .then((response) => {
          setImg(imgData.name);
          props.setFile(response.data[0].url);
          toast.success("File uploaded successfully!");
        })
        .catch(() => {
          toast.error("Something is going wrong!");
        });
    }
  };
  return (
    <div className="form-group">
      <label className="fieldlabels">{props.label}</label>
      <div className="row">
        <div className="col-lg-9">
          <div className="file-upload-wrapper" data-text="">
            {img && (
              <p
                className="position-absolute top-0 start-0 ms-2"
                style={{ fontSize: "12px", marginTop: "10px" }}
              >
                {img}
              </p>
            )}
            <input
              name="file-upload-field form-control"
              type="file"
              required={props.required ? props.required : false}
              className="file-upload-field"
              placeholder={props.placeholder}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-lg-3 m-auto">
          {props.subText ? (
            <span>{props.subText}</span>
          ) : (
            <span>Maximum Size 2mb &nbsp; &nbsp;PDF/Image</span>
          )}
        </div>
      </div>
    </div>
  );
});
