import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLock } from "@fortawesome/free-solid-svg-icons";
import "../../../../../css/main.css";

export const PasswordChangeSuccessModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white rounded-3 modal-common-head px-3 pb-4 pt-5">
        <span
          style={{
            width: "2.2rem",
            height: "2.2rem",
            margin: "-10px -10px 0 0",
          }}
          className="close-mega-menu rounded-circle d-flex align-items-center justify-content-center p-0"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon icon={faClose} style={{ fontSize: "20px" }} />
        </span>

        <div className="modal-body">
          <div className="forgot-password-inner">
            <span className="icon-box--modal">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <h5 className="mt-3">Welldone !</h5>
            <p>Your Password has been Updated Successfully</p>
            <button
              type="button"
              className="primary__btn3"
              data-bs-toggle="modal"
              data-bs-target="#forgotPassword1"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
