import img1 from "../../../../../images/procedure-details/procedure-detail1.jpg";
import img2 from "../../../../../images/procedure-details/procedure-detail2.jpg";
import img3 from "../../../../../images/procedure-details/procedure-detail3.jpg";
import img4 from "../../../../../images/procedure-details/procedure-detail4.jpg";
import img5 from "../../../../../images/procedure-details/procedure-detail5.jpg";
import img6 from "../../../../../images/procedure-details/procedure-detail6.jpg";
import img7 from "../../../../../images/procedure-details/procedure-detail7.jpg";
import img8 from "../../../../../images/procedure-details/procedure-detail8.jpg";
import img9 from "../../../../../images/procedure-details/procedure-detail9.jpg";
import img10 from "../../../../../images/procedure-details/procedure-detail10.jpg";
import img11 from "../../../../../images/procedure-details/procedure-detail11.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
export const FavTreatmentsList = () => {
  return (
    <div className="tab_container three-block-container px-0 overflow-hidden">
      <div className="procedure-list">
        <div className="row gap-0">
          <div className="col-md-6 col-xl-4 treatments-flex m-0">
            <div className="procedure-box-overlay">
              <div
                className="procedure-box treatment-box"
                href="botox-injections.html"
              >
                <span style={{ width: "10rem" }}>
                  <img src={img1} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5">Botox Injections</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </div>
              <span className="delete-icon-box">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img2} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5">Neck Lift</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img3} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5">Tummy Tuck</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img4} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Otoplasty</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img5} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5">Eyelid Surgery</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img6} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Chin Implant</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img7} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5">Dimpleplasty</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img8} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Buccal Fat Removal</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img4} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Forehead Reduction</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>

          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img9} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Facial Féminisation</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>

          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img10} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Fox Eyebrow Lift</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>

          <div className="col-md-6 col-xl-4 treatments-flex">
            <div className="procedure-box-overlay">
              <a href className="procedure-box treatment-box">
                <span style={{ width: "10rem" }}>
                  <img src={img11} className="img-fluid" alt="" />
                </span>
                <span className="procedure-detail-box w-100">
                  <h5 className="rose-text fs-5"> Secret Facelift</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur ullarper. Sed
                    pretium ipsum elit, sit amet auctor massa m
                  </p>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
