import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import { useEffect } from "react";
import refer from "../../../images/refer.jpg";
import refer1 from "../../../images/refer1.jpg";
import smile from "../../../images/smile.png";
import like from "../../../images/like.png";
import heart from "../../../images/heart.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export const ReviewSection = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div className="review-section">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-xl-4 mt-auto">
            <span
              data-aos="flip-right"
              data-aos-easing="linear"
              data-aos-duration="1500"
              className="d-inline-block"
            >
              <img src={refer} className="img-fluid" alt="" />
            </span>
          </div>
          <div className="col-xl-4">
            <div className="review-content">
              <a href="/" className="view-more-btn2 mb-3">
                Write a Review
              </a>
              <p className="p-head-size">
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.Lorem ipsum dolor sit a
              </p>
              <div className="rating">
                <div className="star-box blink1">
                  <FontAwesomeIcon icon={faStar} className="blink-first" />
                </div>
                <div className="star-box blink2">
                  <FontAwesomeIcon icon={faStar} className="blink-first" />
                </div>
                <div className="star-box blink3">
                  <FontAwesomeIcon icon={faStar} className="blink-first" />
                </div>
                <div className="star-box blink4">
                  <FontAwesomeIcon icon={faStar} className="blink-first" />
                </div>
                <div className="star-box blink5">
                  <FontAwesomeIcon icon={faStar} className="blink-first" />
                </div>
              </div>
              <h4>Lorem ipsum dolor</h4>
              <a href="/" className="view-more-btn-colorchange">
                Refer a Friend
              </a>
              <p className="p-head-size">
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.Lorem ipsum dolor sit a
              </p>
            </div>
          </div>
          <div className="col-xl-4 mb-auto">
            <div
              className="rating-method"
              data-aos="flip-left"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <img src={refer1} className="img-fluid" alt="" />
              <div className="rating-symbols">
                <span>
                  <img src={smile} className="img-fluid" alt="" />
                </span>
                <span>
                  <img src={like} className="img-fluid" alt="" />
                </span>
                <span>
                  <img src={heart} className="img-fluid" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
