import "../../css/main.css";
import "../../css/media.css";
import Expand from "react-expand-animated";

export const LanguageDropdown = (props) => {
  const transitions = ["height", "opacity"];
  const styles = {
    open: {
      zIndex: "20",
      background: "transparent",
      boxShadow: "1px 1px 6px 1px #00000024",
    },
  };
  return (
    <Expand
      open={props.showLangDropdown}
      duration={500}
      styles={styles}
      transitions={transitions}
      className={`overflow-hidden rounded-2 position-absolute top-100 ${props.position} start-0 translate-middle-x px-0`}
    >
      <div
        style={{
          width: "6rem",
        }}
        className={`bg-white rounded-2 d-flex flex-column overflow-hidden gap-2`}
      >
        <div className="dd-list px-0">
          <ul>
            <li className="fw-normal py-1">Arabic</li>
            <li className="fw-normal py-1">Hindi</li>
            <li className="fw-normal py-1">Fake</li>
          </ul>
        </div>
      </div>
    </Expand>
  );
};
