import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { getAPIResponseData } from "../../services/generalService";
import { ALL_OFFERS, OFFERS_BY_DOCTOR, OFFERS_BY_DOCTOR_AND_STATUS } from "./actionTypes";
import { setLoading } from "../loading/loadingAction";
import axios from "axios";
import { baseUrl } from "../../services/config/config";

export const createOffer = (data) => {
  return () => {
    axiosInstance
      .post(`/offers`, { data: data })
      .then(() => {
        toast.success("Offer created successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setOffersData = (data) => {
  return {
    type: OFFERS_BY_DOCTOR,
    payload: data,
  };
};
export const getOffersByDoctor = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/offers?filters[doctor][id][$eq]=${id}`)
      .then((response) => {
        dispatch(setOffersData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setLoading(false));
      });
  };
};

export const setOffersDataByStatus = (data) => {
  return {
    type: OFFERS_BY_DOCTOR_AND_STATUS,
    payload: data,
  };
};
export const getOffersByDoctorAndStatus = (id, status) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/offers?filters[doctor][id][$eq]=${id}&filters[status][$eq]=${status}`
      )
      .then((response) => {
        dispatch(setOffersDataByStatus(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

// For Public

export const setAllOffersData = (data) => {
  return {
    type: ALL_OFFERS,
    payload: data,
  };
};

export const getAllOffers = () => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/offers?filters[status][$eq]=Live&populate=*`)
      .then((response) => {
        dispatch(setAllOffersData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};
