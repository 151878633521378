import "../../../../css/main.css";
import Select from "react-select";
import { sampleOptions } from "../../../../demoData/selectOptionsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const FeedHeader = (props) => {
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="head-menu-design">
      <div className="d-flex align-items-center justify-content-between flex-wrap gx-5 gp-3">
        <div className="">
          <h4>{props.title}</h4>
        </div>
        <div className="d-none d-lg-block ">
          <div className="searh-sort-filter d-flex align-items-center flex-wrap gap-2">
            <button
              type="button"
              className="primary__btn4 fs-6 py-2 px-3"
              style={{ marginTop: "0", whiteSpace: "nowrap" }}
            >
              Personised your feed
            </button>
            <div className="width-filter m-0" style={{ minWidth: "13rem" }}>
              <div className="select-search-icon">
                <Select
                  options={sampleOptions}
                  getOptionLabel={getOptionLabel}
                  isSearchable={true}
                  className="profile-form-select-search" // Add a custom class name for styling
                  classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
            <div className="width-filter m-0" style={{ minWidth: "13rem" }}>
              <div className="select-search-icon">
                <Select
                  options={sampleOptions}
                  getOptionLabel={getOptionLabel}
                  isSearchable={true}
                  className="profile-form-select-search" // Add a custom class name for styling
                  classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
            <div className="width-filter m-0" style={{ minWidth: "13rem" }}>
              <div className="select-search-icon">
                <Select
                  options={sampleOptions}
                  getOptionLabel={getOptionLabel}
                  isSearchable={true}
                  className="profile-form-select-search" // Add a custom class name for styling
                  classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
            <input
              type="datetime-local"
              style={{
                maxWidth: "10rem",
                height: "3.2rem",
                border: "2px solid lightGrey",
                borderRadius: "8px",
                minWidth: "13rem",
              }}
              className="px-2 m-0 input-group-addon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
