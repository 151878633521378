import { REVIEW_FORM_DATA, REVIEW_BY_AND_STATUS } from "./actionTypes";

const initialData = {
  formData: {},
  list: []
};
export function ReviewReducer(state = initialData, action) {
  switch (action.type) {
    case REVIEW_FORM_DATA: {
      const data = action.payload;
      return {
        ...state,
        formData: data,
      };
    }
    case REVIEW_BY_AND_STATUS: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    default:
      return state;
  }
}
