import { doctorDetailProcedurePageData } from "../../../../demoData/doctorDetailProcedurePageData";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { imgBaseUrl } from "../../../../services/config/config";
import { useProcedureState } from "../../../../Store/procedure/procedureHooks";

export const ProceduresList = (props) => {
  const [finalData, setFinalData] = useState(doctorDetailProcedurePageData);
  const procedure = useProcedureState();

  const navigate = useNavigate();
  useEffect(() => {
    if (procedure) {
      if (props.filterTab !== "All") {
        let res = procedure.list3.filter(
          (d) => d.name[0].toLowerCase() === props.filterTab.toLowerCase()
        );
        setFinalData(res);
      } else {
        setFinalData(procedure.list3);
      }
    }
  }, [props.filterTab, procedure]);
  return (
    <div className="procedure-list">
      <h4 className="font-weight-normal">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        feugiat mi venenatis,
      </h4>
      <div className="row">
        {finalData.map((data, ind) => (
          <div className="col-lg-4 col-sm-6 procedure-box-width" key={ind + 1}>
            <div className="procedure-box">
              <span>
                <img
                  src={`${imgBaseUrl + data.image}`}
                  className="img-fluid rounded-2 mx-auto border"
                  style={{width: "8rem", height: "5rem", objectFit: "cover"}}
                  alt=""
                />
              </span>
              <div className="procedure-detail-box">
                <h5>{data.name}</h5>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/book-appoinment");
                  }}
                  style={{ border: "1px solid gray", width: "auto" }}
                  className="rounded-pill px-sm-3 px-xl-4 py-1 py-sm-1 estimate_btn"
                >
                  Get Price Estimate
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
