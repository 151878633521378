import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import {
  getAPIResponseData,
  removeItemFromCartData,
} from "../../services/generalService";
import { updateCartItems } from "../cart/cartAction";
import { currentDoctorId, currentPatientId } from "../selectors/UserSelectors";

export const getOrdersByDoctor = async (status) => {
  let res = await axiosInstance
  .get(`/orders?filters[doctor][id][$eq]=${currentDoctorId()}&filters[status][$eq]=${status}&populate=*`)
  let arr = getAPIResponseData(res.data.data)
  return arr;
};


export const getMyOrders = async (status) => {
  let res = await axiosInstance
  .get(`/orders?filters[patient][id][$eq]=${currentPatientId()}&filters[status][$eq]=${status}&populate=*`)
  let arr = getAPIResponseData(res.data.data)
  return arr;
};

export const getMyCancelledOrders = async () => {
  let res = await axiosInstance
  .get(`/orders?filters[patient][id][$eq]=${currentPatientId()}&filters[facilityCancelled][$eq]=true&populate=*`)
  let arr = getAPIResponseData(res.data.data)
  return arr;
};

export const createOrder = (data) => {
  return (dispatch, getState) => {
    let x = getState();
    axiosInstance
      .post(`/orders`, { data: data })
      .then((response) => {
        dispatch(
          updateCartItems(removeItemFromCartData(data.offer.id, x.cart.item))
        );
        toast.success("Order placed successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const reOrder = (data, handleSuccess) => {
  return (dispatch, getState) => {
    let x = getState();
    axiosInstance
      .post(`/orders`, { data: data })
      .then((response) => {
       handleSuccess()
        toast.success("Same order placed again successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};


export const cancelOrderByDoctor = (data, handleSuccess) => {
  return (dispatch, getState) => {
    axiosInstance
      .put(`/orders/${data.id}`, { data: data })
      .then((response) => {
        handleSuccess()
        toast.success("Order rejected successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const cancelOrderByPatient = (id, handleSuccess) => {
  return (dispatch, getState) => {
    axiosInstance
      .delete(`/orders/${id}`)
      .then((response) => {
        handleSuccess()
        toast.success("Order cancelled successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};
