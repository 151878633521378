/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { QATabs, QATabsForDoctor } from "../../../demoData/tabsData";
import { Loading } from "../../../shared/loadings/Loading";
import { TabsGroup } from "../../../shared/tabsGroup";
import { useGetAllBodyParts } from "../../../Store/bodyPart/bodyPartHook";
import { useGetAllProcedures } from "../../../Store/procedure/procedureHooks";
import { useGetAllTreatments } from "../../../Store/treatment/treatmentHook";
import { CreateQAMain } from "./components/createQAComponents/createQAMain";
import { QAHeader } from "./components/qaHeader";
import { isDoctor } from '../../../Store/selectors/isDoctor';
import { useGetAllDoctors } from "src/Store/Doctor/doctorHooks";

export const CreateQA = () => {
  const getAllBodyParts = useGetAllBodyParts()
  const getAllProcedures = useGetAllProcedures();
  const getAllTreatments = useGetAllTreatments()
  const getAllDoctors = useGetAllDoctors()
  useEffect(() =>{
    getAllProcedures()
    getAllBodyParts()
    getAllTreatments()
    getAllDoctors()
  }, [])
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <QAHeader title="Create Questions (19)" />
        <TabsGroup tabs={isDoctor() ? QATabsForDoctor() : QATabs()} />
        <CreateQAMain />
      </div>
    </section>
  );
};
