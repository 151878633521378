import { TabsGroup } from "../../../shared/tabsGroup";
import { userWalletTabs } from "../../../demoData/tabsData";
import { ReviewsHeader } from "../userReviews/components/reviewsHeader";
import { WishlistTable } from "./components/wishlistComponents/wishlistTable";
import { Loading } from "src/shared/loadings/Loading";

export const MyWishlist = () => {
  return (
    <section class="doctor-detail-stories-tab">
       <Loading duration={500} />
      <div class="container-fluid p-0">
        <ReviewsHeader title="Unread Questions (19)" />
        <TabsGroup tabs={userWalletTabs} />
        <WishlistTable />
      </div>
    </section>
  );
};
