import { ModalPopup } from "./ModalPopup";
import "../../css/main.css";
import "../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useMediaQuery } from "@mui/material";

const options1 = [
  { value: "Select", label: "Select" },
  { value: "Phone Number", label: "Phone Number" },
  {
    value: "Qualification & Experiences",
    label: "Qualification & Experiences",
  },
  { value: "Awards", label: "Awards" },
  { value: "Publications", label: "Publications" },
  { value: "Services", label: "Services" },
  { value: "Timing", label: "Timing" },
  { value: "Photo", label: "Photo" },
  { value: "My page", label: "My page" },
  { value: "Not practising anymore", label: "Not practising anymore" },
];

export const ReportErrorModal = (props) => {
  const matches = useMediaQuery("(max-width:530px)");
  const handleClose = () => {
    props.setShowModal(false);
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white m-0 p-0 rounded-3">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="flagModalLabel">
              Report an Error
            </h5>
            <button
              onClick={handleClose}
              style={{ backgroundColor: "#e78a82", width: "2rem", height: "2rem" }}
              className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
            >
              <FontAwesomeIcon icon={faClose} className="ms-2" />
            </button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="form-group d-flex flex-column mb-2">
                <label className="text-secondary mb-1" for="">
                  Your Name
                </label>
                <input type="text" className="border rounded-3 py-2" />
              </div>
              <div className="form-group d-flex flex-column mb-2">
                <label className="text-secondary mb-1" for="">
                  Your E-mail
                </label>
                <input type="text" className="border rounded-3 py-2" />
              </div>
              <div className="form-group d-flex flex-column mb-2">
                <label className="text-secondary mb-1" for="">
                  Mobile number
                </label>
                <input type="text" className="border rounded-3 py-2" />
              </div>
              <div className="form-group issues-identified mb-2">
                <label className="text-secondary mb-1" for="">
                  Issues Identified
                </label>
                <Select
                  options={options1}
                  getOptionLabel={getOptionLabel}
                  defaultValue={options1[0]}
                  isSearchable={true}
                  className="offers-box-select" // Add a custom class name for styling
                  classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="form-group">
                <label className="text-zinc-800 mb-1" for="">
                  Description
                </label>
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div className="my-3 d-flex align-items-center gap-2 justify-content-start">
                <input
                  type="checkbox"
                  className="border rounded-3"
                  style={{width: "15px", height: "15px"}}
                  id="exampleCheck1"
                />
                <label className="form-check-label text-zinc-800" for="exampleCheck1">
                  I agree to the terms and conditions
                </label>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <a href className="primary__btn3">
              Submit
            </a>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
