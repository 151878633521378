import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ShareDropdown = (props) => {
  return (
    <div
      className={`d-flex align-items-center gap-3 px-4 py-2  overflow-hidden position-absolute top-100 ${props.position}`}
      style={{
        borderRadius: "50px",
        background: "rgb(255 214 214)",
        boxShadow: "1px 1px 6px 1px #00000024",
        zIndex: "10",
      }}
    >
      <button
        style={{
          width: "2.5rem",
          height: "2.5rem",
          background: "rgb(30 64 175)",
        }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center shadow-3"
      >
        <FontAwesomeIcon icon={faFacebook} className="text-white mx-auto" style={{fontSize: "24px"}} />
      </button>
      <button
        style={{
          width: "2.5rem",
          height: "2.5rem",
          background: "rgb(96 165 250)",
        }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center shadow-3"
      >
        <FontAwesomeIcon icon={faTwitter} className="text-white mx-auto" style={{fontSize: "24px"}} />
      </button>
      <button
        style={{
          width: "2.5rem",
          height: "2.5rem",
          background: "rgb(127 29 29)",
        }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center shadow-3"
      >
        <FontAwesomeIcon icon={faLinkedin} className="text-white mx-auto" style={{fontSize: "24px"}} />
      </button>
      <button
        style={{
          width: "2.5rem",
          height: "2.5rem",
          background: "rgb(74 222 128)",
        }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center shadow-3"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="text-white mx-auto" style={{fontSize: "24px"}} />
      </button>
      <button
        style={{
          width: "2.5rem",
          height: "2.5rem",
          background: "rgb(190 24 93)",
        }}
        className="rounded-circle border-0 d-flex align-items-center justify-content-center shadow-3"
      >
        <FontAwesomeIcon icon={faInstagram} className="text-white mx-auto" style={{fontSize: "24px"}} />
      </button>
    </div>
  );
};
