export const EmptyWallet = (props) => {
  return (
    <section className="doctor-detail-stories-tab">
      <div className="container-fluid p-0">
        <div className="no-content pt-5 mt-5 pe-0 pe-lg-5">
          <div className="no-content pe-0 pt-0">
            <span>
              <img src={props.image} className="img-fluid" alt="notiImg" />
            </span>
            <h4 className="mt-4">{props.title}</h4>
            <p>{props.desc}</p>
            {props.showButton && (
              <button onClick={props.onClickButton} type="button" className="primary__btn3 border-radius-40 fs-6 px-4 px-sm-5 py-2">
                {props.buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
