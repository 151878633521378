import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  useDeleteFavOfferById,
  useFavOffer,
} from "../../../../../Store/favoruiteOffer/favoruiteOfferHooks";
import { imgBaseUrl } from "../../../../../services/config/config";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";

export const FaVItemsList2 = (props) => {
  const [tab, setTab] = useState(0);
  const [offers, setOffers] = useState([]);
  const favOfferState = useFavOffer();
  const removeFavOffer = useDeleteFavOfferById();

  useEffect(() => {
    if (favOfferState) {
      let arr = [];
      favOfferState.list.forEach((d) => {
        if (d.offer && d.offer.data && d.offer.data.id) {
          arr.push({
            id: d.offer.data.id,
            ...d.offer.data.attributes,
            entryId: d.id,
          });
        }
      });
      setOffers(arr);
    }
  }, [favOfferState]);
  return (
    <div className="tab_container three-block-container px-0">
      <div id="tab1" className="tab_content">
        {props.page === "create folder" && (
          <div
            className="d-flex align-items-center gap-1"
            id="myTab"
            role="tablist"
          >
            <button
              onClick={() => setTab(0)}
              style={{
                background: tab === 0 ? "black" : "transparent",
                fontSize: "12px",
              }}
              className={`${
                tab === 0 ? "text-white" : " text-black"
              } rounded-pill py-1 px-3 border-0`}
              id="home-tab"
              type="button"
            >
              My Doctors
            </button>

            <button
              onClick={() => setTab(1)}
              style={{
                background: tab === 1 ? "black" : "transparent",
                fontSize: "12px",
              }}
              className={`${
                tab === 1 ? "text-white" : " text-black"
              } rounded-pill py-1 px-3 border-0`}
              id="profile-tab"
              type="button"
            >
              Photos
            </button>
          </div>
        )}
        {offers && offers.length > 0 ? (
          <div className="doctor-detail_articles-listing">
            {offers.map((item, ind) => (
              <div
                className={`${
                  props.page === "create folder"
                    ? "border-bottom p-4 mb-4"
                    : "detailed-offers-block doctor-detail-row"
                }  position-relative d-flex flex-column flex-xl-row`}
                key={ind + 1}
              >
                <img
                  src={`${imgBaseUrl + item.image}`}
                  className="img-fluid me-4 rounded-3 overflow-hidden border"
                  style={{
                    width: "15rem",
                    height: "10rem",
                    objectFit: "cover",
                  }}
                  alt=""
                />
                <div className={`doctor-detail-second mt-4 mt-xl-0 w-100`}>
                  <h5 className="offer-head text-start">{item.title}</h5>

                  <p className="text-start two_lines_elipsis_2">
                    {item.description}
                  </p>
                  <div
                    className="pricing-box d-flex align-items-center gap-2 mt-2"
                    style={{ width: "max-content" }}
                  >
                    <h5>AED {item.discountedAmount} </h5>
                    <h6 className="pb-1 pt-2 px-2 px-xl-3 fs-6 fs-xl-5">
                      {item.discountPercentage}%{" "}
                    </h6>
                  </div>
                </div>
                {/* {props.page !== "create folder" && (
                  <div className="doctor-detail-third d-flex justify-content-xl-end align-items-start m-0 p-0">
                    <div className="published-date mt-3 mt-xl-5">
                      <a
                        href
                        className="primary__btn px-5 px-xl-4"
                        style={{ width: "max-content" }}
                      >
                        View Offers
                      </a>
                    </div>
                  </div>
                )} */}
                <span
                  className="delete-icon-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFavOffer(item.entryId)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </span>
              </div>
            ))}
            {/* <PaginationBox /> */}
          </div>
        ) : (
          <NoDataComp
            title="No Data!"
            desc="There is no any data yet."
            className="mt-5"
          />
        )}
      </div>
    </div>
  );
};
