import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookMessenger,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useState, useEffect } from "react";

export const InviteFriendContent = () => {
  const [showTip, setShowTip] = useState(false);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setShowTip(false);
      }, 1000);
    };
  }, [showTip]);
  return (
    <div className="tab_container articles-container overflow-hidden px-0">
      <div id="tab1" className="tab_content px-0">
        <div className="refer-block">
          <div className="row">
            <div className="col-lg-5">
              <h4>Together, we are going further!</h4>
              <p>
                Copy your code, share it with your friends. Your personal code
              </p>

              <div className="copy-text">
                <input
                  type="text"
                  className="text"
                  value="mivana.com/invited/89ghsu6"
                />
                <button
                  onClick={() => setShowTip(true)}
                  className=""
                >
                  Copy
                  {showTip && (
                    <span className="px-3 py-2 position-absolute start-0 bottom-100 mb-3 rounded-2 rose-bg text-white fs-6">
                      Copied!
                    </span>
                  )}
                </button>
              </div>
              <span className="p-head-size-16 share-text">Or Share</span>
              <ul className="share-icons text-secondary">
                <li>
                  <a href>
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                  </a>
                </li>
                <li>
                  <a href>
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </li>
                <li>
                  <a href>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li>
                  <a href>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5">
              <h5>Lorem Lorem Lorem Lorem Lorem</h5>
              <ul className="ul-list-2">
                <li>
                  Total Invitation Sent <span>25</span>
                </li>
                <li>
                  Total Invitation Entrolled <span>25</span>
                </li>
                <li>
                  Total Invitation Pending <span>25</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
