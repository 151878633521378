export const AddToFavWithTextContent = (props) => {
  return (
    <span
      style={{
        color: props.isFav ? "white" : "#e78a82",
        fontSize: props.isFav ? "15px" : "15px",
        margin: "0 0 -2px 8px",
      }}
    >
      {props.isFav ? "Added to favoruites" : "Add to favoruites"}
    </span>
  );
};
