import { QAHeader } from "./components/qaHeader";
import { TabsGroup } from "../../../shared/tabsGroup";
import { QATabs, QATabsForDoctor } from "../../../demoData/tabsData";
import { useEffect, useState } from "react";
import {
  currentDoctorId,
  currentPatientId,
} from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import { getDoctorQaByStatus, getPatientQaByStatus, updateQa } from 'src/Store/qa/qaAction';
import { isDoctor } from "src/Store/selectors/isDoctor";
import { QAList } from "./components/subComponents/qaList";

export const LiveQA = () => {
  const [qas, setQA] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    if(currentDoctorId()){
        res = await getDoctorQaByStatus(currentDoctorId(),"Live")
    }
    if(currentPatientId()){
      res = await getPatientQaByStatus("Live")
    }
    setQA(res)
  }
  const handleUpdate= (data) => {
      updateQa({ id: data.id, status: "Hide" }, "Your question hide successfully!", getData)
  }
  return (
    <section className="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div className="container-fluid p-0">
        <QAHeader title="Live Questions (19)" />
        <TabsGroup tabs={isDoctor() ? QATabsForDoctor() : QATabs()} />
        <QAList data={qas} handleUpdate={handleUpdate} />
      </div>
    </section>
  );
};
