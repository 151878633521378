import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/main.css";
import "../css/media.css";

export const PaginationBox = () => {
  return (
    <ul className="pagination-box">
      <li>
        <a href className="" style={{ maxWidth: "2.5rem" }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </a>
      </li>
      <li>
        <a href>1</a>
      </li>
      <li>
        <a href>2</a>
      </li>
      <li>
        <a href>3</a>
      </li>
      <li>
        <a href>4</a>
      </li>
      <li>
        <a href className="" style={{ maxWidth: "2.5rem" }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </li>
    </ul>
  );
};
