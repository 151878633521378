/* eslint-disable react-hooks/exhaustive-deps */
import { AppointmentPageHeader } from "./components/upcommingAppointmentsComponents/appointmentPageHeader";
import { AppointmentsList } from "./components/upcommingAppointmentsComponents/appointmentsList";
import "../../css/main.css";
import "../../css/media.css";
import {  useGetApoointmentsByStatusAndDoctor, useGetApoointmentsByStatusAndPatient } from "../../Store/appointment/appointmentHooks";
import { useEffect } from "react";
import { currentDoctorId, currentPatientId } from "../../Store/selectors/UserSelectors";

export const UpcommingAppointments = () => {
  const getApoointmentsByStatusAndPatient = useGetApoointmentsByStatusAndPatient();
const getApoointmentsByStatusAndDoctor = useGetApoointmentsByStatusAndDoctor()
  useEffect(() => {
    if(currentPatientId()){
      getApoointmentsByStatusAndPatient("Upcoming");      
    }
    if(currentDoctorId()){
      getApoointmentsByStatusAndDoctor("Upcoming")
    }
  }, []);
  return (
    <div>
      <AppointmentPageHeader title="Upcoming Appoinments (19)" />
      <AppointmentsList />
    </div>
  );
};
