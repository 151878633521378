import { ModalPopup } from "./ModalPopup";
import "../../css/main.css";
import "../../css/media.css";
import { useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import myimg from "../../images/botox-treatment1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};
export const HelpModal2 = (props) => {
  const matches = useMediaQuery("(max-width:630px)");
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white modal-common-head rounded-3">
        <div className="modal-content">
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              type="button"
              style={{ background: "black" }}
              className="text-white border-0 rounded-3 px-4 py-1 float-right"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
          <div className="modal-body p-4">
            <div className="forgot-password-inner">
              <Carousel
                responsive={responsive}
                arrows={true}
                showDots={false}
                infinite={true}
                autoPlay={false}
                customLeftArrow={
                  <button
                    style={{ width: "2.2rem", height: "2.2rem", }}
                    className={`rounded-circle border-0 fs-6 position-absolute text-black bottom-0 z-3 leftArrow customArrows`}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="ps-2 pt-2"
                      style={{ fontSize: "15px", color: "white" }}
                    />
                  </button>
                }
                customRightArrow={
                  <button
                    style={{ width: "2.2rem", height: "2.2rem" }}
                    className={`rounded-circle border-0 fs-6 position-absolute z-3 text-black bottom-0 rightArrow customArrows`}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="ps-2 pt-2"
                      style={{ fontSize: "15px", color: "white" }}
                    />
                  </button>
                }
              >
                <div
                  style={{ borderRadius: "9px" }}
                  className="item border p-4 mb-5"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <span>
                        <img src={myimg} className="img-fluid w-100" alt="" />
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <p style={{color: "black"}}>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer .
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
