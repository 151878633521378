import "../../../../../css/main.css";

export const ActiveDevicesTable = () => {
  return (
    <div className="tab_container articles-container pt-5">
      <div id="tab1" className="tab_content">
        <div className="settings-div active-device">
          <div className="overflow-scroll-table">
            <table className="table table-style-2 w-100 overflow-auto">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Device</th>
                  <th>Channel</th>
                  <th>Location</th>
                  <th>Logged in</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>1</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Android</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Chrome</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Dubai , United Arab Emirites</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>1 Day ago</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Logout</td>
                </tr>

                <tr>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>2</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Computer</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Windows 10 - Chrome</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Dubai , United Arab Emirites</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>3 Days ago</td>
                  <td style={{whiteSpace: "nowrap", fontSize: "14px"}}>Current Device</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
