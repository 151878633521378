import {
  faArrowLeftLong,
  faArrowRightLong,
  faCheck,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { sampleOptions } from "../../../../../demoData/selectOptionsData";
import myImg from "../../../../../images/doctor-detail3.jpg";
import { CongratsModal } from "../../../../../shared/Modals/congratsModal";
import { useEffect, useState } from "react";
import { currentPatientId } from "../../../../../Store/selectors/UserSelectors";
import { formFinalData } from "../../../../../services/generalService";
import {
  useCreateReview,
  useReview,
  useSetReviewFormData,
} from "../../../../../Store/review/reviewHook";
import { UploadPhotosFormComp } from "./subComponents/uploadPhotosFormComp";

export const ReviewForm3 = (props) => {
  const [showModal, setShowModal] = useState(false);  
  const [finalData, setFinalData] = useState({});
  const setReviewFormData = useSetReviewFormData();
  const reviewState = useReview();
  const createReview = useCreateReview();

  useEffect(() => {
    if (reviewState.formData) {
      setFinalData(reviewState.formData);
    }
  }, [reviewState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setReviewFormData(updatedFinalData);
  };

  const handleSuccess = () => {
    setShowModal(true);
    setFinalData({})
    setReviewFormData(null);
    props.setShowForm(1)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("GGGGGGGGGGGGGGGGGG", { patient: currentPatientId(), ...formFinalData(finalData) })
    if (currentPatientId())
      createReview(
        { patient: currentPatientId(), ...formFinalData(finalData) },
        handleSuccess
      );
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-card">
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q1</span> Title of your review
            </label>
            <div className="col-lg-9">
            <input
            required
                className="form-control"
                type="text"
                defaultValue={
                  finalData.reviewTitle
                }
                onChange={(e) =>
                  handleChange(
                    "reviewTitle",
                    e.target.value
                  )
                }
                placeholder="Add Title"
              />
            </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q2</span>Share your experience
            </label>
            <div className="col-lg-9">
              <textarea
                type="text"
                value={finalData.experienceDescription}
                onChange={(e) =>
                  handleChange("experienceDescription", e.target.value)
                }
                className="form-control"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="upload-img-block">
            <div className="row">
              <div className="col-lg-9">
                <UploadPhotosFormComp setFinalData={setFinalData} myPhotos={finalData.photosData} finalData={finalData} saveData={setReviewFormData} />
              </div>
              <div className="col-lg-9">
                <p className="mt-3">
                  Note: All patient stories go under strict moderation process
                  before publishing to check abusive language,threats,
                  superlative comments on medical abilities and so on.
                </p>
                <div
                  className={`new ${
                    finalData.agreeWithMivana ? "checkedInput" : ""
                  }`}
                >
                  <form>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleChange("agreeWithMivana", e.target.checked);
                        }}
                        id="html"
                      />
                      <label for="html">
                        By enabling the send button, I agree to the Guidelines
                        and Policies of Mivana
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        name="next"
        className="my-0 next action-button"
      >
        Submit <FontAwesomeIcon icon={faArrowRightLong} />
      </button>

      <button
        type="button"
        onClick={() => props.setShowForm(2)}
        name="previous"
        className="mb-0 mt-3 mt-sm-0 action-button-previous position-relative"
        value="Previous"
      >
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className="position-absolute start-0 ms-4"
        />
        Previous
      </button>
      <CongratsModal
        showModal={showModal}
        setShowModal={setShowModal}
        msg="Your have successfully submitted the review."
      />
 </form>
  );
};
