import "../../css/aos.css";
import "../../css/main.css";
import "../../css/media.css";
export const EmptyComp = (props) => {
  return (
    <div className="no-content pe-0 pt-0">
      <span>
        <img src={props.image} className="img-fluid" alt="notiImg" />
      </span>
      <h4 className="mt-4">{props.title}</h4>
      <p>{props.desc}</p>
      {props.showButton && (
        <button onClick={props.onClickButton} type="button" className="primary__btn3 border-radius-40 fs-6 px-4 px-sm-5 py-2">
          {props.buttonText}
        </button>
      )}
    </div>
  );
};
