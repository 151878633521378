import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import {
  ALL_BEFORE_AFTERS,
  BEFORE_AFTERS_BY_BODY_PART,
  BEFOR_AFTER_BY_DOCTOR,
  BEFOR_AFTER_BY_DOCTOR_AND_STATUS,
} from "./actionTypes";
import { getAPIResponseData } from "../../services/generalService";
import { setLoading } from "../loading/loadingAction";
import axios from "axios";
import { baseUrl } from "../../services/config/config";

export const createBeforeAfter = (data) => {
  return () => {
    axiosInstance
      .post(`/before-afters`, { data: data })
      .then(() => {
        toast.success("Before & After created successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setBeforeAftersData = (data) => {
  return {
    type: BEFOR_AFTER_BY_DOCTOR,
    payload: data,
  };
};
export const getBeforeAftersByDoctor = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/before-afters?filters[doctor][id][$eq]=${id}`)
      .then((response) => {
        dispatch(setBeforeAftersData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

export const setBeforeAftersDataByStatus = (data) => {
  return {
    type: BEFOR_AFTER_BY_DOCTOR_AND_STATUS,
    payload: data,
  };
};
export const getBeforeAftersByDoctorAndStatus = (id, status) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/before-afters?filters[doctor][id][$eq]=${id}&filters[status][$eq]=${status}`
      )
      .then((response) => {
        dispatch(
          setBeforeAftersDataByStatus(getAPIResponseData(response.data.data))
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};



// For Public

export const setAllBeforeAfterData = (data) => {
  return {
    type: ALL_BEFORE_AFTERS,
    payload: data,
  };
};

export const getAllBeforeAfters = () => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/before-afters?populate=*`)
      .then((response) => {
        dispatch(setAllBeforeAfterData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

export const setBeforeAftersByBodyPart = (data) => {
  return {
    type: BEFORE_AFTERS_BY_BODY_PART,
    payload: data,
  };
};

export const getBeforeAftersByBodyPart = (bodyPart) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/before-afters?filters[body_part][name][$eq]=${bodyPart}&filters[status][$eq]=Approved&populate=*`)
      .then((response) => {
        dispatch(setBeforeAftersByBodyPart(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};
