import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/aos.css";
import "../../../../css/media.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export const PopularPlacesModal = (props) => {
  const matches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  return (
    <div className="bg-white popular-places-modal overflow-y-auto">
      <span
        className="close-mega-menu"
        onClick={() => props.setShowModal(false)}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="left_chev left-left_chev"
        />
        <FontAwesomeIcon icon={faChevronLeft} className="left_chev" /> Close
      </span>
      <div style={{width: matches? "90%" : "50%"}} className="mt-5 mx-auto pt-5">
        <div className="popularplaces left-box pb-0">
          <h5>Popular Places in Abu Dhabi</h5>
          <div className="scrollbar ps-2" id="style-3">
            <form action="">
              <div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace1" />
                  <label for="popularplace1">Al Ain</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace2" />
                  <label for="popularplace2">Al Dhafra</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace3" />
                  <label for="popularplace3">Yas</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace4" />
                  <label for="popularplace4">Saadiyat</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace5" />
                  <label for="popularplace5">MBZ</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace6" />
                  <label for="popularplace6">Mussafah</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace7" />
                  <label for="popularplace7">Al Ain</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace8" />
                  <label for="popularplace8">Al Dhafra</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace9" />
                  <label for="popularplace9">Yas</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace10" />
                  <label for="popularplace10">Saadiyat</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace11" />
                  <label for="popularplace11">MBZ</label>
                </div>
                <div className="mb-3 form-group checkbox-customise">
                  <input type="checkbox" id="popularplace12" />
                  <label for="popularplace12">Mussafah</label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="treatment-services left-box">
          <h5>Related Treatments & Services</h5>
          <ul>
            <li>
              <a
                href="/find-doctor"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/find-doctor");
                }}
                className="fs-6 text-dark me-1 mb-2"
              >
                Botox for TMJ
              </a>
            </li>
            <li>
              <a
                href="/find-doctor"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/find-doctor");
                }}
                className="fs-6 text-dark me-1 mb-2"
              >
                Micro Botox{" "}
              </a>
            </li>
            <li>
              <a
                href="/find-doctor"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/find-doctor");
                }}
                className="fs-6 text-dark me-1 mb-2"
              >
                Micro Botox{" "}
              </a>
            </li>
            <li>
              <a
                href="/find-doctor"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/find-doctor");
                }}
                className="fs-6 text-dark me-1 mb-2"
              >
                Botox for Vaginismus
              </a>
            </li>
          </ul>
          <button
            type="button"
            className="primary__btn3 treatment-services-click w-full mt-2 sm:mt-4"
            onClick={() => {
              props.setShowModal(false);
              setTimeout(() => {
                props.setShowModal2(true);
              }, 500);
            }}
          >
            Show All Treatments & Services
          </button>
        </div>
      </div>
    </div>
  );
};
