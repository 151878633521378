import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { formFinalData } from "../../../../../services/generalService";
import { CongratsModal } from "../../../../../shared/Modals/congratsModal";
import { useCreateReview, useReview, useSetReviewFormData } from "../../../../../Store/review/reviewHook";
import { currentPatientId } from "../../../../../Store/selectors/UserSelectors";
import { ReviewForm1 } from "./reviewForm1";
import { ReviewForm2 } from "./reviewForm2";
import { ReviewForm3 } from "./reviewForm3";

export const CreateReviewFrom = () => {
  const [showForm, setShowForm] = useState(1);
  
  
  return (
    <div className="tab_container overflow-hidden myreviews-section px-0 py-0">
      <div id="tab1" className="tab_content px-0">
        <div className="card formSlider">
          <div 
        // onSubmit={handleSubmit}
          id="msform" className="px-0">
            <ul id="progressbar">
              <li className={`active`} id="account"></li>
              <li
                className={`${
                  showForm === 2 || showForm === 3 ? "active" : ""
                }`}
                id="personal"
              ></li>
              <li
                className={`${showForm === 3 ? "active" : ""}`}
                id="confirm"
              ></li>
            </ul>
            {showForm === 1 && <ReviewForm1 setShowForm={setShowForm} />}
            {showForm === 2 && <ReviewForm2 setShowForm={setShowForm} />}
            {showForm === 3 && <ReviewForm3 setShowForm={setShowForm} />}
          </div>
        </div>
      </div>
    </div>
  );
};
