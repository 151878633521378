import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/aos.css";
import "../../css/main.css";
import "../../css/media.css";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "@mui/material";
import { FooterLinks } from "./footerLinks";

export const Footer = () => {
  const matches = useMediaQuery("(max-width:1200px)");

  return (
    <footer className="">
      <div className="container-fluid footer-primary">
        {matches ? (
          <FooterLinks />
        ) : (
          <div className="row no-gutters">
            <div className="col-xl-2 ms-auto">
              <div className="footer-box">
                <h3>Surgical</h3>
                <ul>
                  <li>
                    <a href="/">Patient Journey</a>
                  </li>
                  <li>
                    <a href="/">All Treatments</a>
                  </li>
                  <li>
                    <a href="/">Body Surgery</a>
                  </li>
                  <li>
                    <a href="/">Breast Surgery</a>
                  </li>
                  <li>
                    <a href="/">Facial Surgery</a>
                  </li>
                  <li>
                    <a href="/">Recovery Timelines</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="footer-box">
                <h3>Non Surgical</h3>
                <ul>
                  <li>
                    <a href="/">Patient Journey</a>
                  </li>
                  <li>
                    <a href="/">All Treatments</a>
                  </li>
                  <li>
                    <a href="/">Body Surgery</a>
                  </li>
                  <li>
                    <a href="/">Injectables</a>
                  </li>
                  <li>
                    <a href="/">Laser Treatments</a>
                  </li>
                  <li>
                    <a href="/">Recovery Timelines</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="footer-box">
                <h3>Patient Hub</h3>
                <ul>
                  <li>
                    <a href="/">Before & Afters</a>
                  </li>
                  <li>
                    <a href="/">Stories </a>
                  </li>
                  <li>
                    <a href="/">Our Offers</a>
                  </li>
                  <li>
                    <a href="/">Concerns</a>
                  </li>
                  <li>
                    <a href="/">Write a Review</a>
                  </li>
                  <li>
                    <a href="/">Guidelines & Policies</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="footer-box">
                <h3>Insights</h3>
                <ul>
                  <li>
                    <a href="/">About</a>
                  </li>
                  <li>
                    <a href="/">Contact</a>
                  </li>
                  <li>
                    <a href="/">Careers</a>
                  </li>
                  <li>
                    <a href="/">FAQ</a>
                  </li>
                  <li>
                    <a href="/">Blog</a>
                  </li>
                  <li>
                    <a href="/">Media</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="footer-box">
                <h3>Explore</h3>
                <ul>
                  <li>
                    <a href="/">Find Doctor</a>
                  </li>
                  <li>
                    <a href="/">Claim Profile</a>
                  </li>
                  <li>
                    <a href="/">Sign In </a>
                  </li>
                  <li>
                    <a href="/">Sign Up</a>
                  </li>
                  <li>
                    <a href="/">Enquire Now</a>
                  </li>
                  <li>
                    <a href="/">Travel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="footer-secondary">
          <div className="row">
            <div className="col-lg-4">
              <div className="social-icons-block">
                <h6>SOCIAL</h6>
                <ul className="social-footer-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/ortilhealthcare"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/ortilhealthcare"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/ortilhealthcare"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href=" https://www.youtube.com/channel/UCazb6NAxmhmtAFjTgBi6bBQ"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/ortilhealthcare?lang=en"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <p>Copyright © Mivana 2022. All Rights Reserved</p>
            </div>
            <div className="col-lg-4">
              <div className="mivana-link">
                <p>Mivana by Ortil Healthcare Pvt. Ltd.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
