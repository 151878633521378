export const getUser = () => {
  let user = localStorage.getItem("mivana_userdata");
  let myuser = JSON.parse(user);
  return myuser;
};

export const getCurrentDoctor = () => {
  const user = getUser();
  if (user && user.doctor && user.doctor.id) return user.doctor;
};
export const getCurrentPatient = () => {
  const user = getUser();
  if (user && user.patient && user.patient.id) return user.patient;
};
export const currentDoctorId = () => {
  const user = getUser();
  if (user && user.doctor && user.doctor.id) return user.doctor.id;
};
export const currentPatientId = () => {
  const user = getUser();
  if (user && user.patient && user.patient.id) return user.patient.id;
};
