import {
  faArrowLeftLong,
  faArrowRightLong,
  faCheck,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { sampleOptions } from "../../../../../demoData/selectOptionsData";
import myImg from "../../../../../images/doctor-detail3.jpg";
import { CongratsModal } from "../../../../../shared/Modals/congratsModal";
import { useEffect, useState } from "react";
import { formFinalData, getDataforSelect2, getDataforSelectforDoctor } from "../../../../../services/generalService";
import { useCreateQA, useQA, useSetQAFormData } from "../../../../../Store/qa/qaHook";
import { UploadPhotosFormComp } from "../../../../userDashboard/userReviews/components/createReviewComponents/subComponents/uploadPhotosFormComp";
import { currentDoctorId, currentPatientId } from "../../../../../Store/selectors/UserSelectors";
import { updateQa } from "src/Store/qa/qaAction";
import { useDoctor } from "src/Store/Doctor/doctorHooks";
import { isPatient } from "src/Store/selectors/isPatient";

export const CreateQAForm2 = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [finalData, setFinalData] = useState({});
  const setQAFormData = useSetQAFormData();
  const createQA = useCreateQA();
  const qaState = useQA();
const doctor = useDoctor()
console.log("ggggggggggg", doctor.list)
  useEffect(() => {
    if (qaState.formData) {
      setFinalData(qaState.formData);
    }
  }, [qaState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setQAFormData(updatedFinalData);
  };

  const handleSuccess = () => {
    setShowModal(true);
    setFinalData({});
    setQAFormData(null);
    props.setShowForm(1)    
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(finalData && finalData.id){
      let obj = { ...formFinalData(finalData) }
      updateQa(obj, "Question updated successfully!", props.handleClose)
    } else{
      let obj;
      if(currentPatientId()){
      obj = { patient: currentPatientId(), ...formFinalData(finalData), whoAsked: "Patient" }
      }
      if(currentDoctorId()){
        obj = { doctor: currentDoctorId(), ...formFinalData(finalData), whoAsked: "Doctor" }
        }
      createQA(obj, handleSuccess);
    }
    
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-card d-flex flex-column gap-3">
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q1</span> Title of your QA
            </label>
            <div className={props.fullView ? "col-lg-12" :"col-lg-9"}>
              <input
              required
                className="form-control"
                type="text"
                defaultValue={
                  finalData.QATitle
                }
                onChange={(e) =>
                  handleChange(
                    "QATitle",
                    e.target.value
                  )
                }
                placeholder="Add Title"
              />
                
              </div>
          </div>
        </div>
        <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q2</span>Brief Description
            </label>
            <div className={props.fullView ? "col-lg-12" :"col-lg-9"}>
              <textarea
                type="text"
                value={finalData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                className="form-control"
              ></textarea>
            </div>
          </div>
        </div>
       {isPatient() && <div className="form-group position-relative">
          <div className="row">
            <label className="fieldlabels">
              <span className="questions-num">Q1</span> From which doctor you want to ask this question?
            </label>
            <div className={`${props.fullView ? "col-lg-12" :"col-lg-9"}`}>
              <div className="select-search-icon">
                <Select
                required
                 options={getDataforSelectforDoctor(doctor.list, "firstName", "lastName", "id")}
                  getOptionLabel={getOptionLabel}
                  value={finalData.doctor}
                  onChange={(val) =>
                    handleChange("doctor", val)
                  }
                  isSearchable={true}
                  className="my-form-select-search" // Add a custom class name for styling
                  classNamePrefix="my-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>}
        <div className="form-group position-relative">
          <div className="upload-img-block">
            <div className="row">
              <div className={props.fullView ? "col-lg-12" :"col-lg-9"}>
              <UploadPhotosFormComp setFinalData={setFinalData} myPhotos={finalData.photosData} finalData={finalData} saveData={setQAFormData} />                
              </div>
              <div className={props.fullView ? "col-lg-12" :"col-lg-9"}>
                <p className="mt-3">
                  Note: All patient stories go under strict moderation process
                  before publishing to check abusive language,threats,
                  superlative comments on medical abilities and so on.
                </p>
                <div
                  className={`new ${
                    finalData.agreeWithMivana ? "checkedInput" : ""
                  }`}
                >
                  <form>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleChange("agreeWithMivana", e.target.checked);
                        }}
                        id="html"
                      />
                      <label for="html">
                        By enabling the send button, I agree to the Guidelines
                        and Policies of Mivana
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        name="next"
        className="my-0 next action-button"
      >
        Submit <FontAwesomeIcon icon={faArrowRightLong} />
      </button>
      <button
        type="button"
        onClick={() => props.setShowForm(1)}
        name="previous"
        className="mb-0 mt-3 mt-sm-0 action-button-previous position-relative"
        value="Previous"
      >
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className="position-absolute start-0 ms-4"
        />
        Previous
      </button>
      <CongratsModal
        showModal={showModal}
        setShowModal={setShowModal}
        msg="Your have successfully submitted the review."
      />
    </form>
  );
};
