import { useMediaQuery } from "@mui/material";
import "../../../../../css/main.css";
import { MySwitch } from "../../../../../demoData/switch";

export const WhatsappContent = () => {
  const match = useMediaQuery("(max-width:766px)");

  return (
    <div
      className="tab-pane fade show active"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <h4>WhatsApp settings</h4>
      <p>
        You are currently receiving all promotional communications on
        <a href className="color-4" mailto="carolyn@gmail.com">
          +971554569336
        </a>
      </p>

      <div className="form-check form-switch">
      <MySwitch />
        <label className="form-check-label" style={{fontSize: match ? "18px": ""}} for="switch6">
          I want to receive important notifications and updates via WhatsApp.
          <span>I want to unsubscribe entirely</span>
        </label>
      </div>

      <button
        className="primary__btn3 py-2"
        style={{ marginTop: "4px", zIndex: "10", position: "relative" }}
      >
        Save
      </button>
    </div>
  );
};
