import { useState } from "react";
import "../../../../../css/main.css";
import { PasswordChangeSuccessModal } from "./passwordChangeSuccessModal";

export const ChanbgePasswordForm = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="tab_container articles-container px-0 pt-5 overflow-hidden">
      <div id="tab1" className="tab_content">
        <div className="row">
          <div className="col-lg-6">
            <div className="settings-div">
              <form action="">
                <div className="form-group">
                  <label for="">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter"
                  />
                </div>
                <div className="form-group">
                  <label for="">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Password"
                  />
                  <div className="password d-flex align-items-center gap-2" style={{fontSize: "12px"}}>
                    <span>Upper case</span>
                    <span>Number</span>
                    <span>Lower case</span>
                    <span>Special characters</span>
                    <i className="fa-solid fa-circle-check"></i>
                  </div>
                </div>
                <div className="form-group">
                  <label for="">Confirm Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter"
                  />
                  <i className="fa-solid fa-circle-check"></i>
                </div>
                <button
                  type="button"
                  className="primary__btn3 fs-6 m-0 py-2"
                  onClick={() => setShowModal(true)}
                >
                  Confirm
                </button>
                <PasswordChangeSuccessModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
