import { toast } from "react-toastify";
import { baseUrl } from "../../services/config/config";
import axios from "axios";
import axiosInstance from "../../services/AxiosInstance";
import {
  ALL_DOCTORS,
  DOCTOR_BY_ID,
  DOCTOR_PROFILE_FORM_DATA,
} from "./actionTypes";
import { makeDoctorFormDataToShow } from "../../services/doctorProfileService";
import { setLoading } from "../loading/loadingAction";
import { getAPIResponseData } from "../../services/generalService";
import { getMe } from "../Auth/AuthAction";

export const createDoctor = (data) => {
  return () => {
    axios
      .post(`${baseUrl}/doctors`, { data: data })
      .then(() => {
        toast.success("You are added as Doctor successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const updateDoctor = (data) => {
  return (dispatch) => {
    axiosInstance
      .put(`/doctors/${data.id}`, { data: data })
      .then(() => {
        dispatch(getDoctorById(data.id));
        dispatch(getMe());
        toast.success("your profile updated successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setDoctorProfileFormData = (data) => {
  return {
    type: DOCTOR_PROFILE_FORM_DATA,
    payload: data,
  };
};
export const getDoctorById = (id) => {
  return (dispatch) => {
    axiosInstance
      .get(`/doctors/${id}?populate=*`)
      .then(async (response) => {
        const data = {
          id: response.data.data.id,
          ...response.data.data.attributes,
        };
        const res = await makeDoctorFormDataToShow(data);
        if (res && Object.keys(res).length > 0) {
          dispatch(setDoctorProfileFormData(res));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

// For Public
export const setAllDoctorsData = (data) => {
  return {
    type: ALL_DOCTORS,
    payload: data,
  };
};
export const getAllDoctors = () => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/doctors`)
      .then(async (response) => {
        dispatch(setAllDoctorsData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

export const setDoctorById = (data) => {
  return {
    type: DOCTOR_BY_ID,
    payload: data,
  };
};
export const getDoctorByIdForPublic = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/doctors/${id}`)
      .then(async (response) => {
        const data = {
          id: response.data.data.id,
          ...response.data.data.attributes,
        };
        dispatch(setDoctorById(data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

export const getSearchedDoctors = (url) => {
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setAllDoctorsData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};
