import axios from "axios";
import { SIGN_UP_DATA } from "./actionTypes";
import { toast } from "react-toastify";
import { baseUrl } from "../../services/config/config";
import {
  removeTokenFromLocalStorage,
  saveTokenInLocalStorage,
} from "../../services/AuthServics/auth.servics";
import axiosInstance from "../../services/AxiosInstance";
import { createPatient } from "../Patient/patientAction";
import { createDoctor } from "../Doctor/doctorAction";
import OutHook from "../../services/navigation";

export function setUserData(data) {
  return {
    type: SIGN_UP_DATA,
    payload: data,
  };
}
export function userSignUpAction(data) {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/auth/local/register`, data)
      .then((response) => {
        dispatch(
          createPatient({
            firstName: data.username,
            user: response.data.user.id,
          })
        );
        saveTokenInLocalStorage(response.data.jwt);
        dispatch(getMeForLogin());
        toast.success("You signed up successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}
export function doctorSignUpAction(data) {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/auth/local/register`, data)
      .then((response) => {
        dispatch(
          createDoctor({
            firstName: data.username,
            user: response.data.user.id,
          })
        );
        saveTokenInLocalStorage(response.data.jwt);
        dispatch(updateMe({ id: response.data.user.id, role: 1 }));
        toast.success("You signed up successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}

export function LoginAction(data) {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/auth/local`, data)
      .then((response) => {
        saveTokenInLocalStorage(response.data.jwt);
        toast.success("You signed in successfully!");
        dispatch(getMeForLogin());
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}
export function getMeForLogin() {
  return (dispatch) => {
    axiosInstance
      .get(`users/me?populate=*`)
      .then((response) => {
        localStorage.setItem("mivana_userdata", JSON.stringify(response.data));
        dispatch(setUserData(response.data));
        let myUser = response.data;
        if (myUser && myUser.role) {
          if (myUser.role.name === "Doctor") {
            localStorage.setItem("dashboardPath", "/doctor-dashboard");
            OutHook.navigateTo("/doctor-dashboard");
          } else if (myUser.role.name === "Patient") {
            localStorage.setItem("dashboardPath", "/user-dashboard");
            OutHook.navigateTo("/user-dashboard");
          } else {
            OutHook.navigateTo("/");
          }
        } else {
          OutHook.navigateTo("/");
        }
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}
export function getMe() {
  return (dispatch) => {
    axiosInstance
      .get(`users/me?populate=*`)
      .then((response) => {
        localStorage.setItem("mivana_userdata", JSON.stringify(response.data));
        dispatch(setUserData(response.data));
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}
export function updateMe(data) {
  return (dispatch) => {
    axiosInstance
      .put(`users/${data.id}?populate=*`, data)
      .then(() => {
        dispatch(getMeForLogin());
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
}

export function Logout() {
  return (dispatch) => {
    localStorage.removeItem("mivana_userdata");
    localStorage.removeItem("dashboardPath");
    dispatch(setUserData(null));
    removeTokenFromLocalStorage();
    OutHook.navigateTo("/sign-in");
  };
}

// // Forget Password
// export const handleForgetPassword = (data) => {
//   return (dispatch: (arg0) => void) => {
//     axios
//       .post(`${baseUrl}/auth/forgot-password`, data)
//       .then(() => {
//         dispatch(
//           setAlertData({
//             color: "green",
//             status: "200",
//             msg: "Email sent to reset password",
//             show: true,
//           })
//         );
//       })
//       .catch((error) => {
//         dispatch(
//           setAlertData({
//             color: "red",
//             name: error.name,
//             status: error.code,
//             msg: error.message,
//             show: true,
//           })
//         );
//       });
//   };
// };
