import "../../../../../css/main.css";
import { useRef, useState } from "react";
import { currentDoctorId } from "../../../../../Store/selectors/UserSelectors";
import { formFinalData } from "../../../../../services/generalService";
import { ProfileUploadDoc } from "../../../components/profilePageComponents/subComponents/ProfileUploadDoc";
import { useCreateOffer } from "../../../../../Store/offer/offerHooks";

export const UploadOfferForm = () => {
  const [finalData, setFinalData] = useState({});
  const imgRef = useRef(null);
  const formRef = useRef(null);
  const createOffer = useCreateOffer()
  const handleChange = (key, val) => {
    finalData[key] = val;
    setFinalData(finalData);
  };
  const handleClear = () => {
    imgRef.current.clearData();
    formRef.current.reset();
    setFinalData({});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
      createOffer(formFinalData({ ...finalData, doctor: currentDoctorId() }))
    handleClear();
  };

  return (
    <div
      className="tab_container px-0"
      style={{ overflowX: "hidden", overflowY: "visible" }}
    >
      <div id="tab1" className="tab_content">
        <form onSubmit={handleSubmit} ref={formRef} className="form-card offer-form-card">
          <ProfileUploadDoc
            childRef={imgRef}
            label="Upload Offer Image"
            placeholder="Upload Photo"
            editableImg={finalData.image}
            setFile={(val) => handleChange("image", val)}
          />
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels"> Offer Title</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  defaultValue={finalData.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                />
              </div>
              <div className="col-lg-3 m-auto">
                <span style={{ fontSize: "13px" }}>Anti Wrinkle Injection</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Offer Description</label>
                <textarea
                  required
                  value={finalData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  className="form-control"
                  rows="4"
                ></textarea>
              </div>
              <div className="col-lg-3 m-auto">
                <span style={{ fontSize: "13px" }}>Anti Wrinkle Injection</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-3">
                <label className="fieldlabels">Actual Amount</label>
                <input
                  type="number"
                  required
                  defaultValue={finalData.actualAmount}
                  onChange={(e) => handleChange("actualAmount", e.target.value)}
                  className="form-control"
                  placeholder="Enter Amount in numbers"
                />
                <span style={{ fontSize: "13px" }}>1,000</span>
              </div>
              <div className="col-lg-3">
                <label className="fieldlabels">
                  Discount in percentage (%)
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter amount in numbers"
                  required
                  defaultValue={finalData.discountPercentage}
                  onChange={(e) =>
                    handleChange("discountPercentage", e.target.value)
                  }
                />
                <span style={{ fontSize: "13px" }}>500</span>
              </div>
              <div className="col-lg-3">
                <label className="fieldlabels">Discounted Amount</label>
                <input
                  type="number"
                  className="form-control rose-bg"
                  required
                  defaultValue={finalData.discountedAmount}
                  onChange={(e) =>
                    handleChange("discountedAmount", e.target.value)
                  }
                />
                <span style={{ fontSize: "13px" }}>50</span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6">
                    <label className="fieldlabels" for="validfrom">
                      Valid from
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      required
                      defaultValue={finalData.validFrom}
                      onChange={(e) =>
                        handleChange("validFrom", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="fieldlabels">Valid to</label>
                    <input
                      type="date"
                      className="form-control"
                      required
                      defaultValue={finalData.validTo}
                      onChange={(e) => handleChange("validTo", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="primary__btn3 submit-action-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
