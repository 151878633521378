import "../../../../css/main.css";
import { PatientVisitLineChart } from "../../../../shared/chart/patientVisitLineChart";

export const PatientVisit = () => {
  return (
    <div className="col-lg-6">
      <div className="chart-block">
        <div className="head-menu-design">
          <div className="row">
            <div className="col-lg-9">
              <h4>Patient Visit </h4>
            </div>
            <div className="col-lg-3">
              <select className="form-control mySelect">
                <option value="">This Year</option>
              </select>
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <PatientVisitLineChart />
        </div>
      </div>
    </div>
  );
};
