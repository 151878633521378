import {
  toast
} from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import {
  getAPIResponseData
} from "../../services/generalService";
import {
  QA_FORM_DATA,
} from "./actionTypes";
import {
  currentDoctorId,
  currentPatientId
} from "../selectors/UserSelectors";
import axios from "axios";
import {
  baseUrl
} from "../../services/config/config";

export const createQA = (data, handleSuccess) => {
  return () => {
    axiosInstance
      .post(`/q-and-as`, {
        data: data
      })
      .then(() => {
        toast.success("Q&A created successfully!");
        handleSuccess();
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setQAFormData = (data) => {
  return {
    type: QA_FORM_DATA,
    payload: data,
  };
};

export const getAllQAByDoctor = async () => {
  let res = await axios
    .get(
      `${baseUrl}/q-and-as?filters[doctor][id][$eq]=${currentDoctorId()}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};


export const getAllQAByPatient = async () => {
  let res = await axios
    .get(
      `${baseUrl}/q-and-as?filters[patient][id][$eq]=${currentPatientId()}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};

export const getDoctorQaByStatus = async (id, status) => {
  let res = await axios
    .get(
      `${baseUrl}/q-and-as?filters[doctor][id][$eq]=${id}&filters[status][$eq]=${status}&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};
export const getPatientQaByStatus = async (status) => {
  let res = await axios
    .get(
      `${baseUrl}/q-and-as?filters[patient][id][$eq]=${currentPatientId()}&filters[status][$eq]=${status}&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};
export const getPatientQaByIsRead = async (read) => {
  let res = await axios
    .get(
      `${baseUrl}/q-and-as?filters[patient][id][$eq]=${currentPatientId()}&filters[read][$eq]=${read}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};
export const getDoctorQaByIsRead = async (read) => {
  let res = await axios
    .get(
      `${baseUrl}/q-and-as?filters[doctor][id][$eq]=${currentDoctorId()}&filters[read][$eq]=${read}&filters[status][$eq]=Live&populate=*`
    );
  let arr = getAPIResponseData(res.data.data)
  return arr;
};

export const updateQa = async (data, msg, getData) => {
  try{
  await axiosInstance
    .put(
      `${baseUrl}/q-and-as/${data.id}`,
      {data: data}
    );
    getData()
    toast.success(msg)
  }
  catch(error){
    toast.error("Something went wrong!")
  }
};