import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import { Footer } from "../../shared/footer/footer";

import { DoctorSignUpForm } from "./components/doctorSignUpForm";
import { ReasonsToUse } from "./components/reasonsToUse";
import { HaveQuestion } from "./components/haveQuestion";
import { MavinaSummary } from "./components/mavinaSummary";
import { RegistrationTabs } from "../../shared/pageComponents/registrationTabs";
import { SignUpTabs } from "../../shared/pageComponents/signUpTabs";
import { useEffect } from "react";
import { useGetAllRoles } from "../../Store/Role/roleHook";

export const SignUpDoctors = () => {
  const getAllRoles = useGetAllRoles();
  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  return (
    <div>
      <Header />
      <RegistrationTabs />
      <SignUpTabs />
      <DoctorSignUpForm />
      <ReasonsToUse />
      <HaveQuestion />
      <MavinaSummary />
      <Footer />
    </div>
  );
};
