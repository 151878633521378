import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import docImg from "../../../../images/arab F-3.jpg";
import { ReviewStars } from "../../../../shared/reviewStars";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { AddDocOtpModal } from "./addDocOtpModal";
import { AllDoneModal } from "./allDoneModal";

const list = [1, 2, 3, 4, 5, 6, 7, 8];
export const DoctorsListContent = (props) => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  return (
    <section className=" doctors-listing-page-healthcare px-3">
      <div className="container-fluid px-0">
        <div className="row justify-content-between">
          {list.map((data, ind) => (
            <div key={ind + 1} className="col-lg-2 list-box-flex">
              <div className="">
                <div className="doctors-listing-left-box w-100">
                  <span>
                    <img src={docImg} className="img-fluid" alt="" />
                  </span>
                </div>
                <div className="doctor-details">
                  <h5 className="fs-6 text-black"> Dr. Lauren Clinton</h5>
                  <div className="detail-box">
                    <ReviewStars />
                    <span className="number">(1,378)</span>
                    <a
                      href="badge.html"
                      type="button"
                      data-toggle="tooltip"
                      title="Hooray!"
                    >
                      <FontAwesomeIcon icon={faTrophy} />
                    </a>
                  </div>
                  <h6>Cosmetologist, Kerala</h6>
                  <p>Visiting Doctor</p>
                  <a
                    href
                    className="primary__btn3 w-75 mx-auto"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() =>
                      props.page === "Add Doctor" && setShowModal1(true)
                    }
                  >
                    {props.page === "Add Doctor"
                      ? "Add Doctor"
                      : "View Profile"}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <AddDocOtpModal showModal={showModal1} setShowModal={setShowModal1} setShowModal2={setShowModal2} />
        <AllDoneModal showModal={showModal2} setShowModal={setShowModal2} />
      </div>
    </section>
  );
};
