import { faSmile, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LikeContent = (props) => {
  return (
    <FontAwesomeIcon
      icon={props.smileIcon ? faSmile : faThumbsUp}
      className="mx-auto"
      style={{
        color: props.isFav ? "white" : "gray",
        fontSize: props.isFav ? "18px" : "22px",
      }}
    />
  );
};
