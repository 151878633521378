import { useMediaQuery } from "@mui/material";
import "../../../../../css/main.css";

export const NotificationSettingsTabs = (props) => {
  const matches = useMediaQuery("(max-width:580px)");

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          style={{
            fontSize: matches ? "13px" : "",
            padding: matches ? "18px" : "",
          }}
          className={`nav-link py-1 py-sm-2 ${
            props.tab === "Email" ? "active" : ""
          }`}
          id="home-tab"
          onClick={() => props.setTab("Email")}
        >
          E-mail
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          style={{
            fontSize: matches ? "13px" : "",
            padding: matches ? "18px" : "",
          }}
          className={`nav-link py-1 py-sm-2 ${
            props.tab === "Whatsapp" ? "active" : ""
          }`}
          id="profile-tab"
          onClick={() => props.setTab("Whatsapp")}
        >
          Whatsapp
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          style={{
            fontSize: matches ? "13px" : "",
            padding: matches ? "18px" : "",
          }}
          className={`nav-link py-1 py-sm-2 ${
            props.tab === "Unsubscribe" ? "active" : ""
          }`}
          id="contact-tab"
          onClick={() => props.setTab("Unsubscribe")}
        >
          Unsubscribe
        </button>
      </li>
    </ul>
  );
};
