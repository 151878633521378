import {useNavigate} from "react-router-dom";
import { useEffect } from "react";

const OutHook = {};

export const NavigationComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    OutHook.navigateTo = navigate;
  }, [navigate]);


  return null;
};

export default OutHook;