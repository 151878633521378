import { useNavigate } from "react-router-dom";
import { EmptyComp } from "../../../shared/emptyComponents/emptyComp";
import notiImg from "../../../images/notification.png";

export const NoFavoruits = () => {
  const navigate = useNavigate();
  return (
    <section className="doctor-detail-stories-tab">
      <div className="container-fluid p-0">
        <div className="no-content pt-5 mt-5 pe-0 pe-lg-5">
          <EmptyComp
            image={notiImg}
            title="No Favourites Yet"
            showButton={true}
            desc={[
              "Find the best doctors in your locality and book",
              <br />,
              "an appointment instantly.",
            ]}
            buttonText="Start Exploring"
            onClickButton={(e) => {
              e.preventDefault();
              navigate("/user-dashboard/favoruite-doctors");
            }}
          />
        </div>
      </div>
    </section>
  );
};
