import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import {
  ARTICLES_BY_DOCTOR,
  ARTICLES_BY_DOCTOR_AND_STATUS,
} from "./actionTypes";
import { getAPIResponseData } from "../../services/generalService";
import { baseUrl } from "../../services/config/config";
import axios from "axios";

export const createArticle = (data) => {
  return () => {
    axiosInstance
      .post(`/articles`, { data: data })
      .then(() => {
        toast.success("Article created successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setArticlesData = (data) => {
  return {
    type: ARTICLES_BY_DOCTOR,
    payload: data,
  };
};
export const getArticlesByDoctor = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/articles?filters[doctor][id][$eq]=${id}`)
      .then((response) => {
        dispatch(setArticlesData(getAPIResponseData(response.data.data)));
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setArticlesDataByStatus = (data) => {
  return {
    type: ARTICLES_BY_DOCTOR_AND_STATUS,
    payload: data,
  };
};
export const getArticlesByDoctorAndStatus = (id, status) => {
  return (dispatch) => {
    axiosInstance
      .get(`/articles?filters[doctor][id][$eq]=${id}&filters[status][$eq]=${status}`)
      .then((response) => {
        dispatch(
          setArticlesDataByStatus(getAPIResponseData(response.data.data))
        );
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};
