import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/before-after2.png";

export const Banner2 = () => {
  return (
    <div className="black-bg-design">
      <div className="row">
        <div className="col-lg-7">
          <h3>Botox Before & After</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit, sit
            amet auctor massa maximus eget.
          </p>
          <a href="Before-After–Face.html" className="primary__btn">
            See Before & After Photos
          </a>
        </div>
        <div className="col-lg-5 m-auto">
          <span className="d-block text-right">
            <img src={img1} className="img-fluid" alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};
