import { getDashboard } from "../services/getDashboard";

export const userReviewsTabs = [
  { title: "Create", path: "/user-dashboard/start-review", sub: "", subPath:"/user-dashboard/create-review" },
  { title: "New", path: "/user-dashboard/my-new-reviews", sub: "" },
  // { title: "Read", path: "/user-dashboard/my-read-reviews", sub: "" },
  // { title: "Unread", path: "/user-dashboard/my-unread-reviews", sub: "" },
  { title: "Hide", path: "/user-dashboard/my-hide-reviews", sub: "" },
  { title: "Live", path: "/user-dashboard/my-live-reviews", sub: "" },
  // { title: "Flagged", path: "/user-dashboard/my-flagged-reviews", sub: "" },
  { title: "Comments", path: "/user-dashboard/my-comment-reviews", sub: "" },
];

export const userOffersTabs = [
  { title: "Purchased", path: "/user-dashboard/purchased-offers-", sub: "" },
  {
    title: "Recommended",
    path: "/user-dashboard/recommended-offers-",
    sub: "",
  },
  { title: "Deals", path: "/user-dashboard/deals-offers-", sub: "" },
];

export const userWalletTabs = [
  {
    title: "My Wishlist",
    path: "/user-dashboard/wallet-empty-wishlist",
    subPath: "/user-dashboard/wallet-wishlist",
    sub: "",
  },
  { title: "My Cart", path: "/user-dashboard/wallet-cart", sub: "" },
  { title: "My Orders", path: "/user-dashboard/wallet-orders", sub: "" },
  { title: "History", path: "/user-dashboard/wallet-history", sub: "" },
  { title: "Location", path: "/user-dashboard/wallet-location", sub: "" },
  {
    title: "Cancelled by Facility",
    path: "/user-dashboard/wallet-cancelled",
    sub: "",
  },
];

export const notificationTabsData = () => [
  { title: "New", sub: "3", path: `${getDashboard()}/notification-new` },
  { title: "UnRead", sub: "", path: `${getDashboard()}/notification-unread` },
  { title: "Read", sub: "", path: `${getDashboard()}/notification-read` },
];

export const reviewTabsData = () => [
  { title: "New", sub: "3", path: `${getDashboard()}/new-reviews` },
  { title: "Read", sub: "", path: `${getDashboard()}/read-reviews` },
  { title: "UnRead", sub: "", path: `${getDashboard()}/unread-reviews` },
  { title: "Hide", sub: "", path: `${getDashboard()}/hide-reviews` },
  { title: "Live", sub: "", path: `${getDashboard()}/live-reviews` },
  { title: "Flagged", sub: "", path: `${getDashboard()}/flagged-reviews` },
];

export const settingsTabsData = () => [
  {
    title: "Change Password",
    sub: "3",
    path: `${getDashboard()}/settings-change-password`,
  },
  {
    title: "2 Step verification Review",
    sub: "",
    path: `${getDashboard()}/settings-step2-verification`,
  },
  {
    title: "Notification Setting",
    sub: "",
    path: `${getDashboard()}/settings-notification`,
  },
  {
    title: "Active Devices",
    sub: "3",
    path: `${getDashboard()}/settings-active-devices`,
  },
  {
    title: "Delete Profile",
    sub: "",
    path: `${getDashboard()}/settings-delete-profile`,
  },
  {
    title: "Security Questions",
    sub: "",
    path: `${getDashboard()}/settings-security-questions`,
  },
];

export const supportTabsData = () => [
  {
    title: "Raise a ticket",
    sub: "3",
    path: `${getDashboard()}/support-raise-ticket`,
  },
  {
    title: "Open Ticket",
    sub: "",
    path: `${getDashboard()}/support-open-ticket`,
  },
  {
    title: "Closed Ticket",
    sub: "",
    path: `${getDashboard()}/support-closed-ticket`,
  },
  {
    title: "Ticket Status",
    sub: "",
    path: `${getDashboard()}/support-ticket-status`,
  },
];

export const favoruitesTabs = [
  { title: "Doctors", path: "/user-dashboard/favoruite-doctors", sub: "" },
  {
    title: "Before & After",
    path: "/user-dashboard/favoruite-before-after",
    sub: "",
  },
  { title: "Reviews", path: "/user-dashboard/favoruite-reviews", sub: "" },
  { title: "Q&A", path: "/user-dashboard/favoruite-q&a", sub: "" },
  { title: "Offers", path: "/user-dashboard/favoruite-offers", sub: "" },
  {
    title: "Treatments",
    path: "/user-dashboard/favoruite-treatments",
    sub: "",
  },
  { title: "Blogs", path: "/user-dashboard/favoruite-blogs", sub: "" },
  { title: "Videos", path: "/user-dashboard/favoruite-videos", sub: "" },
  { title: "Articles", path: "/user-dashboard/favoruite-articles", sub: "" },
  {
    title: "Create Folder",
    path: "/user-dashboard/favoruite-create-folder",
    sub: "",
    showPlus: true,
  },
];

export const referFriendTabs = () => [
  {
    title: "Invite Friend",
    sub: "",
    path: `${getDashboard()}/refers-invite-friend`,
  },
  {
    title: "Invitees Status",
    sub: "",
    path: `${getDashboard()}/refers-invitees-status`,
  },
  {
    title: "Your Reward",
    sub: "",
    path: `${getDashboard()}/refers-your-reward`,
  },
];

export const QATabs = () => [
  { title: "Create", sub: "", path: `${getDashboard()}/create-qa` },
  { title: "New", sub: "3", path: `${getDashboard()}/new-qa` },
  { title: "Hide", sub: "", path: `${getDashboard()}/hide-qa` },
  { title: "Live", sub: "", path: `${getDashboard()}/live-qa` },
  { title: "Answers", sub: "3", path: `${getDashboard()}/answers-qa` },
];
export const QATabsForDoctor = () => [
  { title: "Create", sub: "", path: `${getDashboard()}/create-qa` },
  { title: "New", sub: "3", path: `${getDashboard()}/new-qa` },
  { title: "Read", sub: "", path: `${getDashboard()}/read-qa` },
  { title: "UnRead", sub: "", path: `${getDashboard()}/unread-qa` },
  { title: "Hide", sub: "", path: `${getDashboard()}/hide-qa` },
  { title: "Live", sub: "", path: `${getDashboard()}/live-qa` },
  { title: "Answers", sub: "3", path: `${getDashboard()}/answers-qa` },
];

export const redeemTabsData = [
  {
    title: "Earned Points",
    path: "/user-dashboard/redeem-earned-points",
    sub: "",
  },
  {
    title: "Redeemed Points",
    path: "/user-dashboard/redeem-redeemed-points",
    sub: "",
  },
  {
    title: "Point Status",
    path: "/user-dashboard/redeem-point-status",
    sub: "",
  },
];
