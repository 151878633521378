import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../services/config/config";
import { getAPIResponseData } from "../../services/generalService";
import { setLoading } from "../loading/loadingAction";
import { ALL_TTREATMENTS } from "./actionTypes";

export const setAllTreatmentsData = (data) => {
    return {
      type: ALL_TTREATMENTS,
      payload: data,
    };
  };
  
  
  export const getAllTreatments = () => {
    return (dispatch) => {
      axios
        .get(
          `${baseUrl}/treatments`
        )
        .then((response) => {
          dispatch(setAllTreatmentsData(getAPIResponseData(response.data.data)));
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    };
  };