import "../../../css/main.css";
import "../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ModalPopup } from "../../../shared/Modals/ModalPopup";
import { useMediaQuery } from "@mui/material";

export const OtpModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
    >
      <div
        className="bg-white m-0 p-0"
        id="forgotPassword2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-content">
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "#e78a82",
                width: "2rem",
                height: "2rem",
              }}
              className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
            >
              <FontAwesomeIcon icon={faClose} className="ms-2" />
            </button>
          </div>
          <div className="modal-body">
            <div className="forgot-password-inner">
              <span className="icon-box--modal">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <h5 className="mt-3">Check your E-mail</h5>
              <p>
                We have send you reset password instructions on your e-mail
                address.
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Please try again"
              />
              <div className="button-flex justify-content-center mb-3">
                <button
                  type="button"
                  className="primary__btn4"
                  data-bs-toggle="modal"
                  data-bs-target="#forgotPassword2"
                >
                  Enter OTP
                </button>
              </div>

              <small>
                Did n't receive the link{" "}
                <a href="/sign-in" className="color-4">
                  Resend
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
