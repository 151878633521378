import { useEffect, useState } from "react";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import moment from "moment";
import { imgBaseUrl } from "../../../../../services/config/config";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";

export const OffersList = (props) => {
  const [offers, setOffers] = useState([]);
  useEffect(() => {
    if (props.data) {
      let arr = [];
      props.data.forEach((d) => {
        if (d.offers && d.offers.data) {
          d.offers.data.forEach((x) => {
            if (x && x.attributes) {
              arr.push(x.attributes);
            }
          });
        }
      });
      setOffers(arr);
    }
  }, [props.data]);
  return (
    <div className="tab_container three-block-container">
      <div id="tab1" className="tab_content">
        {offers && offers.length > 0 ? (
          <div className="doctor-detail_articles-listing">
            {offers.map((data, ind) => (
              <div className="doctor-detail-row" key={ind + 1}>
                <div className="doctor-img-first">
                  <span>
                    <img
                      src={`${imgBaseUrl + data.image}`}
                      className="img-fluid border rounded-3"
                      style={{
                        width: "12rem",
                        height: "8rem",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </span>
                </div>
                <div className="doctor-detail-second">
                  <h5 style={{ fontSize: "22px", color: "black" }}>
                    {data.title}
                  </h5>
                  <p
                    className="two_lines_elipsis_2"
                    style={{ fontSize: "18px" }}
                  >
                    {data.description}
                  </p>
                  <div
                    className="pricing-box d-flex align-items-center gap-3 justify-content-xl-start justify-content-center mt-2"
                    style={{ marginTop: "-8px" }}
                  >
                    <h5 style={{ color: "black" }} className="fs-4">
                      AED {data.discountedAmount}
                    </h5>
                    <h6
                      className="px-3 pt-2 pb-1"
                      style={{ fontSize: "17px", marginTop: "10px" }}
                    >
                      {data.discountPercentage}%
                    </h6>
                  </div>
                </div>

                <div
                  className="doctor-detail-third"
                  style={{ marginTop: "-8px" }}
                >
                  <div className="published-date">
                    <p
                      style={{ whiteSpace: "nowrap" }}
                      className={`${
                        props.tab === "Purchased" || props.tab === "Recommended"
                          ? "rose-text"
                          : ""
                      }`}
                    >
                      {props.tab === "Deals" && "Reviewed On 12 sep 2022"}
                      {props.tab === "Purchased" &&
                        ind === 0 &&
                        `Expired On ${moment(data.validTo).format(
                          "DD MMM YYYY"
                        )}`}
                      {props.tab === "Recommended" && "Expired On 12 sep 2022"}
                    </p>
                    <a
                      href
                      className="primary__btn"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {props.tab === "Deals" && (
                        <span className={`orange-box box-left`}></span>
                      )}
                      {props.tab === "Deals"
                        ? "View Offer"
                        : props.tab === "Purchased"
                        ? "Buy Again"
                        : ""}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataComp
            title="No Data!"
            desc="There is no any data yet."
            className="mt-5"
          />
        )}
        {/* <div className="button-flex justify-content-center mt-5">
          <a
            href
            className="primary__btn7 px-4 py-1"
            style={{ fontSize: "14px" }}
          >
            See all Offers
          </a>
        </div> */}
      </div>
    </div>
  );
};
