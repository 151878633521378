import { AppointmentPageHeader } from "./components/upcommingAppointmentsComponents/appointmentPageHeader";
import { AppointmentsList } from "./components/upcommingAppointmentsComponents/appointmentsList";
import "../../css/main.css";
import "../../css/media.css";

export const VideoConsultation = () => {
  return (
    <div>
      <AppointmentPageHeader title="Video Consultation (140)" />
      <AppointmentsList />
    </div>
  );
};
