import { ModalPopup } from "../../../../../shared/Modals/ModalPopup";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faLock } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import Select from "react-select";
import { sampleOptions } from "../../../../../demoData/selectOptionsData";

export const RaiseTicketModal = (props) => {
  const matches = useMediaQuery("(max-width:630px)");
  const handleClose = () => {
    props.setShowModal(false);
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white modal-common-head rounded-3">
        <div className="modal-content">
          <div className="modal-header border-0 d-flex justify-content-end">
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "#e78a82",
                width: "2rem",
                height: "2rem",
              }}
              className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
            >
              <FontAwesomeIcon icon={faClose} className="ms-2" />
            </button>
          </div>
          <div className="modal-body">
            <div className="forgot-password-inner">
              <span className="icon-box--modal">
                <FontAwesomeIcon icon={faLock} />
              </span>
              <h5 className="mt-3">Raise a New Ticket </h5>
              <p>Your Password has been Updated Successfully</p>
              <Select
                options={sampleOptions}
                getOptionLabel={getOptionLabel}
                isSearchable={true}
                className="my-form-select-search" // Add a custom class name for styling
                classNamePrefix="my-form-select-search" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              <div className="form-group mt-3">
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  placeholder="Description"
                  rows="6"
                ></textarea>
              </div>
              <div className="button-flex">
                <a
                  href
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                  className="primary__btn3 cancel-btn"
                >
                  Cancel
                </a>
                <button
                  onClick={() => {
                    handleClose();
                    props.setShowModal2(true);
                  }}
                  type="button"
                  className="primary__btn3"
                >
                  Raise a ticket
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
