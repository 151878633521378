import { useRef, useState } from "react";
import { useCreateApoointment } from "../../../Store/appointment/appointmentHooks";
import "../../../css/main.css";
import "../../../css/media.css";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getTodayDate } from "../../../services/dynamicContentService";

export const AppointmentForm = () => {
  const [finalData, setFinalData] = useState({});
  const createApoointment = useCreateApoointment();
  const params = useParams();
  const formRef = useRef(null);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      ...finalData,
      time: moment(finalData.time, "HH:mm:ss").format("HH:mm:ss"),
      patient: currentPatientId(),
      doctor: params.id,
    };
    createApoointment(obj);
    formRef.current.reset();
    setFinalData({});
  };
  return (
    <div className="book-consultation-form in-person-consultation py-5">
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="form-flex">
          <div className="form-group">
            <label for="">First Name1</label>
            <input
              type="text"
              required
              onChange={(e) => handleChange("firstName", e.target.value)}
              value={finalData.firstName}
              className="form-control py-3 rounded-2 border-1"
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label for="">Last Name</label>
            <input
              type="text"
              required
              onChange={(e) => handleChange("lastName", e.target.value)}
              value={finalData.lastName}
              className="form-control py-3 rounded-2 border-1"
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label for="">Contact</label>
            <input
              type="number"
              required
              minLength={8}
              onChange={(e) => handleChange("phone", e.target.value)}
              value={finalData.phone}
              className="form-control py-3 rounded-2 border-1"
              placeholder=" Enter your Phone Number"
            />
          </div>
        </div>
        <div className="form-flex-2 justify-content-between date-time-picker">
          <div className="form-group">
            <label for="date">Date</label>
            <div className="input-group date" id="datePicker">
              <input
                required
                onChange={(e) => handleChange("date", e.target.value)}
                value={finalData.date}
                min={getTodayDate()}
                type="date"
                className="form-control rounded-2 border-1 input-group-addon"
              />
            </div>
          </div>
          <div className="form-group">
            <label for="time">Time</label>
            <div className="input-group date" id="timePicker">
              <input
                required
                onChange={(e) => handleChange("time", e.target.value)}
                value={finalData.time}
                type="time"
                className="form-control py-3 rounded-2 border-1 timePicker"
              />
            </div>
          </div>
        </div>
        <button type="submit" className="primary__btn3 d-inline-block">
          Submit
        </button>
      </form>
    </div>
  );
};
