import { QAHeader } from "./components/qaHeader";
import { TabsGroup } from "../../../shared/tabsGroup";
import { QATabs, QATabsForDoctor } from "../../../demoData/tabsData";
import { useEffect, useState } from "react";
import {
  currentDoctorId,
  currentPatientId,
} from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import { getDoctorQaByIsRead, getPatientQaByIsRead, updateQa } from "../../../Store/qa/qaAction";
import { isDoctor } from "src/Store/selectors/isDoctor";
import { QAList } from "./components/subComponents/qaList";

export const UnreadQA = () => {
  const [qas, setQA] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    if(currentDoctorId()){
        res = await getDoctorQaByIsRead(false)
    }
    if(currentPatientId()){
      res = await getPatientQaByIsRead(false)
    }
    setQA(res)
  }

  const handleUpdate= (data) => {
    if(data.read){
      updateQa({ id: data.id, read: false }, "Question marked as unread!", getData)
    }
    else{
    updateQa({ id: data.id, read: true }, "Question marked as read!", getData)
    }
  }
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <QAHeader title="Unread Questions (19)" />
        <TabsGroup tabs={isDoctor() ? QATabsForDoctor() : QATabs()} />
        <QAList data={qas} handleUpdate={handleUpdate} />
      </div>
    </section>
  );
};
