import { SettingsHeader } from "./components/settingsHeader";
import { NotificationsettingsContent } from "./components/notificationSettingsComponents/notificationsettingsContent";
import { TabsGroup } from "../../../shared/tabsGroup";
import { settingsTabsData } from "../../../demoData/tabsData";

export const NotificationSettings = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SettingsHeader title="Notification Settings" />
        <TabsGroup tabs={settingsTabsData()} />
        <NotificationsettingsContent />
      </div>
    </section>
  );
};
