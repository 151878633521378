import { toast } from "react-toastify";
import { baseUrl } from "../../services/config/config";
import axios from "axios";
import axiosInstance from "../../services/AxiosInstance";
import { PATIENT_PROFILE_FORM_DATA } from "./actionTypes";
import { setLoading } from "../loading/loadingAction";
import { makePatientFormDataToShow } from "../../services/patientService";
import { getMe } from "../Auth/AuthAction";

export const createPatient = (data) => {
  return () => {
    axios
      .post(`${baseUrl}/patients`, { data: data })
      .then(() => {
        toast.success("You are added as Patient successfully!");
      })
      .catch(() => {
        toast.success("Something went wrong!");
      });
  };
};


export const updatePatient = (data) => {
  return (dispatch) => {
    axiosInstance
      .put(`/patients/${data.id}`, { data: data })
      .then(() => {
        dispatch(getPatientById(data.id));
        dispatch(getMe());
        toast.success("your profile updated successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const setPatientProfileFormData = (data) => {
  return {
    type: PATIENT_PROFILE_FORM_DATA,
    payload: data,
  };
};


export const getPatientById = (id) => {
  return (dispatch) => {
    axiosInstance
      .get(`/patients/${id}`)
      .then(async (response) => {
        const data = {
          id: response.data.data.id,
          ...response.data.data.attributes,
        };
        const res = await makePatientFormDataToShow(data);
        if (res && Object.keys(res).length > 0) {
          dispatch(setPatientProfileFormData(res));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};