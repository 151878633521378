import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailReplyAll } from "@fortawesome/free-solid-svg-icons";

export const ReplyBox = (props) => {
  return (
    <div className={`story-box-inner`}>
      <div className="story-inner-box">
        <div className="form-group">
          <div className="comment-area-box">
            <span
              className="comment-back-click cursor-pointer"
              onClick={() => props.setShowCommentBox(false)}
            >
              <FontAwesomeIcon icon={faMailReplyAll} className="me-2" /> Reply
            </span>
            <textarea
              name=""
              id=""
              rows="5"
              className="rounded-3 w-100 border-0 shadow-none"
            >
              Write your comment here
            </textarea>
          </div>

          <div className="submit-filter justify-content-end">
            <a href className="primary__btn3 d-flex align-items-center gap-1">
              <FontAwesomeIcon icon={faPenToSquare} className="text-white" />{" "}
              Submit Review
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
