import { useEffect } from "react";
import { Loading } from "../../../shared/loadings/Loading";
import { useGetAllBodyParts } from "../../../Store/bodyPart/bodyPartHook";
import { useGetAllProcedures } from "../../../Store/procedure/procedureHooks";
import { useGetAllTreatments } from "../../../Store/treatment/treatmentHook";
import { BeforeAfterHeader } from "./components/beforeAfterHeader";
import { BeforeAfterTabs } from "./components/beforeAfterTabs";
import { UploadPhotosForm } from "./components/uploadPhotosComponents/uploadPhotosForm";

export const UploadPhotos = () => {
  const getAllBodyParts = useGetAllBodyParts()
  const getAllProcedures = useGetAllProcedures();
  const getAllTreatments = useGetAllTreatments()
  useEffect(() =>{
    getAllProcedures()
    getAllBodyParts()
    getAllTreatments()
  }, [])
  
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <BeforeAfterHeader title="Upload Photos" />
        <BeforeAfterTabs />
        <UploadPhotosForm />
      </div>
    </section>
  );
};
