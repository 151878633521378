import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { DoctorDetailsHeader } from "./components/doctorDetailsHeader";
import "../../css/main.css";
import "../../css/media.css";
import { DoctorProfileDetails } from "./components/doctorProfileDetails";
import { faSliders, faStar } from "@fortawesome/free-solid-svg-icons";
import { TabsBanner } from "./components/tabsBanner";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { FilterBox } from "./components/subComponents/filterBox";
import { QuestionsReviewItem } from "./components/q&a/questionsReviewItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiltersModal } from "./components/subComponents/filtersModal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../shared/loadings/Loading";
import { useGetDoctorByIdForPublic } from "../../Store/Doctor/doctorHooks";
import { getDoctorQaByStatus } from "src/Store/qa/qaAction";

export const DoctorDetailsQA = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const getDoctorByIdForPublic = useGetDoctorByIdForPublic();
  const [qas, setQA] = useState([]);

  const getData = async(id) => {
    let res = await getDoctorQaByStatus(id,"Live")
    setQA(res)
  }
  useEffect(() => {
    if (params.id) {
      getDoctorByIdForPublic(params.id);
    getData(params.id);

    }
  }, [params.id]);
  return (
    <div>
      <Loading duration={500} />
      <Header />
      <DoctorDetailsHeader />
      <div className="doctor-detailed-wrapper">
        <DoctorProfileDetails solidStar={faStar} />
        <section className="doctor-detailed-section">
          <TabsBanner id={params.id} />
          <div className="container-fluid">
            <div className="tab-head-block">
              <div className="row pt-5">
                <div className="col-lg-7">
                  <h4>Question & Answers</h4>
                </div>
                <div className="col-lg-5 text-end">
                  <p className="color-3 head-text">
                    consectetur adipiscing elit. Integer vulputate vehic
                  </p>
                </div>
              </div>

              <div className="button-flex justify-content-end fixed-box">
                <button
                  type="button"
                  className="btn btn-primary selectPopup-btn"
                  onClick={() => setShowFiltersModal(true)}
                >
                  <FontAwesomeIcon icon={faSliders} />
                </button>
                <FiltersModal
                  activeTab="Q&A"
                  showModal={showFiltersModal}
                  setShowModal={setShowFiltersModal}
                />
              </div>
              <FilterBox cols="6" activeTab="Q&A" />
              {qas && qas.length > 0 && 
              qas.map((item, ind) => (
                <QuestionsReviewItem key={ind + 1} item={item} />
              ))}
            </div>
          </div>
          <div className="button-flex justify-content-center mt-5 pt-5">
            <a
              href="/doctor-details-q-a-old"
              onClick={(e) => {
                e.preventDefault();
                navigate("/doctor-details-q-a-old");
              }}
              className="primary__btn5"
            >
              See More Answers From Doctor
            </a>
          </div>
        </section>
      </div>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Book your consultation today</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
