import { Footer } from "../../../shared/footer/footer";
import { Header } from "../../../shared/header";
import { HeaderSlider } from "../components/headerSlider";
import "../../../css/main.css";
import "../../../css/media.css";
import { TabsBanner } from "../components/tabsBanner";
import { PriceFilter } from "./components/priceFilter";
import { FilterBox } from "../components/filterBox";
import { RequestCallback } from "../../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FacesModal } from "../components/facesModal";
import { useEffect, useState } from "react";
import { FiltersModal } from "../components/filtersModal";
import { FacePhotosList } from "./components/facePhotosList";

export const FaceLiposuctionReviews = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const [showFaceModal, setShowFaceModal] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowFaceModal(false);
      setShowFiltersModal(false);
    });
  }, []);
  useEffect(() => {
    if (showFaceModal || showFiltersModal) {
      document.scrollingElement.style.overflow = "hidden";
    } else {
      document.scrollingElement.style.overflow = "auto";
    }
  }, [showFaceModal, showFiltersModal]);
  return (
    <div>
      <Header />
      <HeaderSlider />
      <section className="before-after-listing-page-section">
        <TabsBanner activeTab="Reviews" />
        <div className="container-fluid">
          <div className="pt-5 pt-md-0">
            <div id="tab1" className="">
              <section className="before-after-blocks before-after-face-blocks">
                <div className="resp-block-box justify-content-end fixed-box">
                  <button
                    type="button"
                    className="btn btn-primary selectPopup-btn"
                    onClick={() => setShowFiltersModal(true)}
                  >
                    <FontAwesomeIcon icon={faSliders} />
                  </button>
                  <span
                    className="open-click open-click2"
                    onClick={() => setShowFaceModal(true)}
                  >
                    <FontAwesomeIcon icon={faUserCheck} />
                  </span>
                </div>
                {showFaceModal && (
                  <FacesModal setShowModal={setShowFaceModal} />
                )}
                {showFiltersModal && (
                  <FiltersModal
                    activeTab="Q&A"
                    showModal={showFiltersModal}
                    setShowModal={setShowFiltersModal}
                  />
                )}
                <div className="tab-head-block py-5">
                  <PriceFilter heading="Botox Reviews (540)" />
                  <FilterBox />
                </div>
                <FacePhotosList
                  detailsPath="/before-after-face-liposuction-reviews-detail"
                  detailsType="FaceLiposuctionReviews"
                />
              </section>
            </div>
          </div>
        </div>
      </section>
      <div style={{marginBottom: matches? "-3rem" : ""}} className={`container-fluid pb-1 mt-5`}>
        <h4>Book your consultation today</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
