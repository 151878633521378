import { useEffect, useState } from "react";
import img1 from "../../../../../images/doctor-detail1.jpg";
import moment from "moment";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";

export const PurchasedOffersTable = (props) => {
  const [offers, setOffers] = useState([]);
  useEffect(() => {
    if (props.data) {
      setOffers(props.data);
    }
  }, [props.data]);

  return (
    <div className="tab_container">
      {offers && offers.length > 0 ? (
        <div id="tab1" className="overflow-scroll-table overflow-hidden">
          <table id="example" className="table table-style-2 w-100">
            <thead>
              <tr>
                <th width="35">SN</th>
                <th width="90">Offer</th>
                <th width="220">Title</th>
                <th width="80">Price</th>
                <th width="100">Discount</th>
                <th width="120">Discounted Price</th>
                <th width="80">Nos</th>
                <th width="80">Total</th>
                <th width="80">Status</th>
              </tr>
            </thead>
            <tbody>
              {offers.map((d, ind) => (
                <tr key={ind + 1} style={{ whiteSpace: "nowrap" }}>
                  <td>{ind + 1}</td>
                  <td>
                    <span>
                      <img
                        src={img1}
                        className="img-fluid"
                        style={{ width: "8rem", minWidth: "8rem" }}
                        alt=""
                      />
                    </span>
                  </td>
                  <td>
                    <div className="title" style={{ whiteSpace: "nowrap" }}>
                      {d.title}
                      <span style={{ whiteSpace: "nowrap" }}>
                        Expires On {moment(d.validTo).format("LT")}
                      </span>
                    </div>
                  </td>
                  <td>{d.actualAmount}</td>
                  <td>{d.discountPercentage}%</td>
                  <td>{d.discountedAmount}</td>
                  <td>4</td>
                  <td>500</td>
                  <td>{d.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <a href className="download-report">
            Download Report
          </a>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
