import { useMediaQuery } from "@mui/material";
import "../../../../../css/main.css";
import { MySwitch } from "../../../../../demoData/switch";

export const UnsubscribeContent = () => {
  const match = useMediaQuery("(max-width:766px)");

  return (
    <div
      className="tab-pane fade show active"
      id="contact"
      role="tabpanel"
      aria-labelledby="contact-tab"
    >
      <h4>Unsubscribe settings</h4>
      <p>
        You are currently receiving all promotional communications on
        <a href className="color-4" mailto="carolyn@gmail.com">
          +971554569336
        </a>
      </p>

      <div className="form-check form-switch">
        <MySwitch />
        <label className="form-check-label" style={{fontSize: match ? "18px": ""}} for="switch7">
          I am not interested in online health care services
        </label>
      </div>

      <div className="form-check form-switch">
        <MySwitch />
        <label className="form-check-label" style={{fontSize: match ? "18px": ""}} for="switch8">
          Mivana sends communications too frequently
        </label>
      </div>

      <div className="form-check form-switch">
        <MySwitch />
        <label className="form-check-label" style={{fontSize: match ? "18px": ""}} for="switch9">
          Mivana communications are not really relevant for me
        </label>
      </div>

      <div className="form-check form-switch">
        <MySwitch />
        <label className="form-check-label" style={{fontSize: match ? "18px": ""}} for="switch10">
          I have a difficulty reading and viewing communications from Mivana
        </label>
      </div>

      <div className="form-check form-switch">
        <MySwitch />
        <label className="form-check-label" style={{fontSize: match ? "18px": ""}} for="switch11">
          I don’t remember subscribing for receiving communications from Mivana
          Others
        </label>
      </div>
      <button
        className="primary__btn3 py-2"
        style={{ marginTop: "4px", zIndex: "10", position: "relative" }}
      >
        Save
      </button>
    </div>
  );
};
