import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";
import { APPOINTMENTS_BY_STATUS } from "./actionTypes";
import { getAPIResponseData } from "../../services/generalService";
import { currentDoctorId, currentPatientId } from "../selectors/UserSelectors";
export const createApoointment = (data) => {
  return (dispatch) => {
    axiosInstance
      .post(`/appointments`, { data: data })
      .then(() => {
        toast.success("Your appointment created successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};
export const setAppointmentsByStatus = (data) => {
  return {
    type: APPOINTMENTS_BY_STATUS,
    payload: data,
  };
};


export const getApoointmentsByStatusAndPatient = (status) => {
  return (dispatch) => {
    axiosInstance
      .get(`/appointments?filters[patient][id]=${currentPatientId()}&filters[status][$eq]=${status}&populate=*`)
      .then((response) => {
        dispatch(setAppointmentsByStatus(getAPIResponseData(response.data.data)))
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};

export const getApoointmentsByStatusAndDoctor = (status) => {
  return (dispatch) => {
    axiosInstance
      .get(`/appointments?filters[doctor][id]=${currentDoctorId()}&filters[status][$eq]=${status}&populate=*`)
      .then((response) => {
        dispatch(setAppointmentsByStatus(getAPIResponseData(response.data.data)))
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };
};