import { TabsGroup } from "../../../shared/tabsGroup";
import { userWalletTabs } from "../../../demoData/tabsData";
import { ReviewsHeader } from "../userReviews/components/reviewsHeader";
import { OrdersTable } from "./components/ordersComponents/ordersTable";
import { useEffect, useState } from "react";
import { getMyOrders } from "src/Store/order/orderAction";
import { Loading } from "src/shared/loadings/Loading";

export const WalletLocations = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res1 = await getMyOrders("Completed");
    let res2 = await getMyOrders("Pending");
    setList([...res1, ...res2]);
  };
  return (
    <section className="doctor-detail-stories-tab">
       <Loading duration={500} />
      <div className="container-fluid p-0">
        <ReviewsHeader title="Locations (19)" />
        <TabsGroup tabs={userWalletTabs} />
        <OrdersTable order={false} location={true} list={list} getData={() => {}} />
      </div>
    </section>
  );
};
