/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  useBodyPartState,
  useGetAllBodyParts,
} from "../../../Store/bodyPart/bodyPartHook";
import "../../../css/main.css";
import "../../../css/media.css";
import { imgBaseUrl } from "../../../services/config/config";

export const HeaderSlider = (props) => {
  const getAllBodyParts = useGetAllBodyParts();
  const bodyPart = useBodyPartState();

  useEffect(() => {
    getAllBodyParts();
  }, []);
  return (
    <section className="before-after-banner resp-hidden-box">
      <div className="container-fluid">
        <div className="banner-head-before-aftr common-pad resp-none">
          <div className="row">
            <div className="col-lg-7">
              <p
                className="text-white opacity-70 tracking-wide"
                style={{ fontSize: "19px" }}
              >
                Browse Before And After Photos By Body Area And Procedure
              </p>
            </div>
            <div className="col-lg-5">
              <form action="">
                <select name="" id="" className="form-control form-select ">
                  <option value="">Search Body Area</option>
                </select>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="scrollbar" id="style-3">
        <div className="force-overflow">
          <ul className="common-pad">
            {bodyPart.list.map((data, ind) => (
              <li key={ind + 1}
              onClick={() => props.setTab(data.name)}
              >
                <span
                  className={`hover__box ${
                    data.name === props.tab ? "hover__box-active" : ""
                  }`}
                >
                  <img
                    src={`${imgBaseUrl + data.image}`}
                    className="img-fluid rounded-circle"
                    style={{width:"8rem", height: "8rem"}}
                    alt=""
                  />
                </span>
                <h6>{data.name}</h6>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
