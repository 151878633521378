import img1 from '../images/procedure-details/procedure-detail1.jpg'
import img2 from '../images/procedure-details/procedure-detail2.jpg'
import img3 from '../images/procedure-details/procedure-detail4.jpg'
import img4 from '../images/procedure-details/procedure-detail3.jpg'
import img5 from '../images/procedure-details/procedure-detail5.jpg'
import img6 from '../images/procedure-details/procedure-detail6.jpg'
import img7 from '../images/procedure-details/procedure-detail7.jpg'
import img8 from '../images/procedure-details/procedure-detail8.jpg'
import img9 from '../images/procedure-details/procedure-detail9.jpg'
import img10 from '../images/procedure-details/procedure-detail10.jpg'
import img11 from '../images/procedure-details/procedure-detail11.jpg'
import img12 from '../images/procedure-details/procedure-detail12.jpg'
import img13 from '../images/procedure-details/procedure-detail13.jpg'

export const doctorDetailProcedurePageData = [
    {title: "Breast Augmentation", img: img1},
    {title: "Breast Augmentation", img: img2},
    {title: "Blepharoplasty", img: img3},
    {title: "Tummy Tuck", img: img4},
    {title: "Fillers", img: img5},
    {title: "Fillers1", img: img5},
    {title: "Blepharoplasty", img: img6},
    {title: "Breast Augmentation", img: img7},
    {title: "Blepharoplasty", img: img8},
    {title: "Blepharoplasty", img: img3},
    {title: "Fillers2", img: img5},
    {title: "Blepharoplasty", img: img9},
    {title: "Tummy 3", img: img5},
    {title: "Blepharoplasty", img: img10},
    {title: "Tummy4", img: img5},
    {title: "Blepharoplasty", img: img11},
    {title: "Blepharoplasty", img: img2},
    {title: "Tummy6", img: img5},
    {title: "Blepharoplasty", img: img12},
    {title: "Blepharoplasty", img: img13},
]