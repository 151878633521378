import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";

export const Blog = () => {
  return (
    <div>
      <Header />
      <Footer />
    </div>
  );
};
