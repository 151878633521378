import { useEffect } from "react";
import { ArticlesHeader } from "./components/articlesHeader";
import { ArticlesTabs } from "./components/articlesTabs";
import { UnderReviewArticlesList } from "./components/underReviewArticlesComponents/underReviewArticlesList";
import { currentDoctorId } from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import {
  useArticle,
  useGetArticlesByDoctorAndStatus,
} from "../../../Store/article/articleHook";

export const FlaggedArticles = () => {
  const article = useArticle();
  const getArticlesByDoctorAndStatus = useGetArticlesByDoctorAndStatus();
  useEffect(() => {
    getArticlesByDoctorAndStatus(currentDoctorId(), "Flagged");
  }, [getArticlesByDoctorAndStatus]);
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <ArticlesHeader title="Flagged Articles" />
        <ArticlesTabs />
        <UnderReviewArticlesList tab={"Flagged"} data={article.listBYStatus} />
      </div>
    </section>
  );
};
