import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "./ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import "../../css/main.css";

export const CongratsModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white rounded-3 modal-common-head px-3 pb-4 pt-5">
        <span
          style={{
            width: "2.2rem",
            height: "2.2rem",
            margin: "-10px -10px 0 0",
          }}
          className="close-mega-menu rounded-circle d-flex align-items-center justify-content-center p-0"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon icon={faClose} style={{ fontSize: "20px" }} />
        </span>

        <div className="modal-body">
          <div className="forgot-password-inner">
            <span className="icon-box--modal">
              {props.msg.toLowerCase().includes("security") ? (
                <FontAwesomeIcon icon={faShieldAlt} />
              ) : (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </span>
            <h5 className="mt-3">Congratulations</h5>
            <p>{props.msg}</p>
            <a
              onClick={() => props.setShowModal(false)}
              href
              className="primary__btn3 d-inline-block"
            >
              Close
            </a>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
