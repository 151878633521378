import img1 from "../../../images/doctor-registration/registration.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};

export const SignInImagesCarousel = () => {
  const CustomDot = ({ onClick, active }) => (
    <button
      className={`rounded-circle border-0 p-1 mx-1`}
      style={{
        background: active ? "#e78a82" : "white",
      }}
      onClick={() => onClick()}
    ></button>
  );
  return (
    <Carousel
      responsive={responsive}
      arrows={false}
      showDots={true}
      infinite={true}
      autoPlay={true}
      customDot={<CustomDot />}
      autoPlaySpeed={2000}
    >
      <div className="item">
        <span>
          <img src={img1} className="img-fluid w-100" alt="" />
        </span>
      </div>
      <div className="item">
        <span>
          <img src={img1} className="img-fluid w-100" alt="" />
        </span>
      </div>
      <div className="item">
        <span>
          <img src={img1} className="img-fluid w-100" alt="" />
        </span>
      </div>
    </Carousel>
  );
};
