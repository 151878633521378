import { BeforeAfterHeader } from "./components/beforeAfterHeader";
import { BeforeAfterTabs } from "./components/beforeAfterTabs";
import { UnderReviewArticlesList } from "../articles/components/underReviewArticlesComponents/underReviewArticlesList";
import { useEffect } from "react";
import { currentDoctorId } from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import {
  useBeforeAfter,
  useGetBeforeAftersByDoctorAndStatus,
} from "../../../Store/beforAfter/beforeAfterHook";

export const NeedImprovementPhotos = () => {
  const beforeAfter = useBeforeAfter();
  const getBeforeAftersByDoctorAndStatus =
    useGetBeforeAftersByDoctorAndStatus();
  useEffect(() => {
    getBeforeAftersByDoctorAndStatus(currentDoctorId(), "Need Improvement");
  }, [getBeforeAftersByDoctorAndStatus]);
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <BeforeAfterHeader title="Upload Photos" />
        <BeforeAfterTabs />
        <UnderReviewArticlesList data={beforeAfter.listBYStatusAndDoctor} />
      </div>
    </section>
  );
};
