import { useAppAction, useAppSelector } from "../../services/hooks/hooks";
import {
  createApoointment,
  getApoointmentsByStatus,
  getApoointmentsByStatusAndDoctor,
  getApoointmentsByStatusAndPatient,
} from "./appointmentAction";

export const useCreateApoointment = () => useAppAction(createApoointment);
export const useGetApoointmentsByStatusAndPatient = () =>
  useAppAction(getApoointmentsByStatusAndPatient);
  export const useAppointment = () =>
  useAppSelector((state) => state.appointment);
  export const useGetApoointmentsByStatusAndDoctor = () => useAppAction(getApoointmentsByStatusAndDoctor
  )