import { useState } from "react";
import { QuesAnsItem } from "./quesAnsItem";
import { EditQaModal } from "../createQAComponents/editQaModal";
export const AnswersList = (props) => {

  const [showModal, setShowModal] = useState(false)
const [selectedData, setSelectedData] = useState({})
  const EditQa = (item) => {
    setSelectedData(item)
    setShowModal(true)
  }
  return (
    <div className="tab_container px-0 overflow-hidden py-0">
      <div id="tab1" className="tab_content">
        <div className="questions-answer-div">
        {props.list && props.list.length > 0 && props.list.map((data, ind) => (
          <QuesAnsItem key={ind + 1} data={data} EditQa={EditQa} />
        )) }
      
        </div>
      </div>
      <EditQaModal showModal={showModal} setShowModal={setShowModal} selectedData={selectedData} getData={props.getData} />
    </div>
  );
};
