import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IsFav3Content = (props) => {
  return (
    <div className="p-0 m-0 d-flex align-items-center gap-2 fs-5">
      <span> {props.label}</span>
      {props.isFav && (
        <FontAwesomeIcon
          icon={faHeart}
          style={{
            color: "#e78a82",
            fontSize: "20px",
          }}
        />
      )}
      <span className="ms-3"> {props.subLabel}</span>
    </div>
  );
};
