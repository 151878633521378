import { useAppAction, useAppSelector } from "../../services/hooks/hooks";
import {
  getPatientById,
  setPatientProfileFormData,
  updatePatient,
} from "./patientAction";

export const useUpdatePatient = () => useAppAction(updatePatient);
export const useSetPatientProfileFormData = () =>
  useAppAction(setPatientProfileFormData);
export const usePatient = () => useAppSelector((state) => state.patient);
export const useGetPatientById = () => useAppAction(getPatientById);
