import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { sampleOptions } from "../../../../../demoData/selectOptionsData";
import { ProfileUploadDoc } from "../../../components/profilePageComponents/subComponents/ProfileUploadDoc";
import { useRef, useState } from "react";
import { formFinalData, getDataforSelect, getDataforSelectByName, getSelectData } from "../../../../../services/generalService";
import { currentDoctorId } from "../../../../../Store/selectors/UserSelectors";
import { useCreateArticle } from "../../../../../Store/article/articleHook";
import { useBodyPartState } from "../../../../../Store/bodyPart/bodyPartHook";
import { useProcedureState } from "../../../../../Store/procedure/procedureHooks";
import { useTreatmentState } from "../../../../../Store/treatment/treatmentHook";

export const ArticleUploadForm = () => {
  const [finalData, setFinalData] = useState({});
  const formRef = useRef(null);
  const imgRef = useRef(null);
  const imgRef2 = useRef(null);
  const createArticle = useCreateArticle();
  const procedure = useProcedureState();
  const bodyPartState = useBodyPartState()
  const treatmentState = useTreatmentState()
  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
  };
  const handleClear = () => {
    imgRef.current.clearData();
    imgRef2.current.clearData();
    formRef.current.reset();
    setFinalData({});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createArticle(formFinalData({ ...finalData, doctor: currentDoctorId() }));
    handleClear();
  };

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <form
      onSubmit={handleSubmit}
      ref={formRef}
      className="tab_container articles-container"
      style={{ overflowX: "hidden", overflowY: "visible" }}
    >
      <div id="tab1" className="tab_content">
        <div className="form-card">
          <ProfileUploadDoc
            childRef={imgRef}
            label="Upload Treatment Photo"
            placeholder="Upload Photo"
            editableImg={finalData.treatmentPhoto}
            setFile={(val) => handleChange("treatmentPhoto", val)}
          />
          <ProfileUploadDoc
            childRef={imgRef2}
            label="Add Video Link"
            placeholder="Select your file!"
            subText="Https://www.video.com/ygjkusgk"
            editableImg={finalData.videoLink}
            setFile={(val) => handleChange("videoLink", val)}
          />
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Body Part</label>
                <Select
                  required
                  options={getDataforSelectByName(bodyPartState.list)}
                  getOptionLabel={getOptionLabel}
                  value={finalData.bodyPart}
                  onChange={(e) => handleChange("bodyPart", e)}
                  isSearchable={true}
                  className="profile-form-select" // Add a custom class name for styling
                  classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Treatment</label>
                <Select
                  required
                  options={getDataforSelectByName(treatmentState.list)}
                  getOptionLabel={getOptionLabel}
                  value={finalData.treatment}
                  onChange={(e) => handleChange("treatment", e)}
                  isSearchable={true}
                  className="profile-form-select" // Add a custom class name for styling
                  classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Procedure</label>
                <Select
                  required
                  options={getDataforSelectByName(procedure.list2)}
                  getOptionLabel={getOptionLabel}
                  value={finalData.procedure}
                  onChange={(e) => handleChange("procedure", e)}
                  isSearchable={true}
                  className="profile-form-select" // Add a custom class name for styling
                  classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Article Title</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={finalData.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                  placeholder="Add"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Tags</label>
                <Select
                  required
                  options={sampleOptions}
                  getOptionLabel={getOptionLabel}
                  value={finalData.tags}
                  onChange={(e) => handleChange("tags", e)}
                  isMulti={true}
                  isSearchable={true}
                  className="profile-form-select" // Add a custom class name for styling
                  classNamePrefix="profile-form-select" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-9">
                <label className="fieldlabels">Description</label>
                <textarea
                  onChange={(e) => handleChange("description", e.target.value)}
                  required
                  rows="5"
                  className="form-control"
                >
                  Add
                </textarea>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-9 text-end">
                <button
                  type="submit"
                  className="primary__btn3 submit-action-button"
                >
                  Submit Article
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
