import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import docImg1 from "../../../../images/dashboard/doc1.jpg";
import docImg2 from "../../../../images/dashboard/doc2.jpg";
import docImg3 from "../../../../images/dashboard/doc3.jpg";
export const UpcommingEvents = () => {
  return (
    <div className="col-lg-6">
      <div className="upcoming-appoinments-wrapper">
        <div className="overflow-auto-table">
          <div className="head-menu-design">
            <div className="row">
              <div className="col-lg-6">
                <h4>Upcoming Appoinments</h4>
              </div>
              <div className="col-lg-6">
                <a href className="view-all">
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
        <table id="example" className="table" style={{ width: "100" }}>
          <thead>
            <tr>
              <th style={{ minWidth: "100px" }}>S.No</th>
              <th style={{ minWidth: "230px" }}>Name</th>
              <th style={{ minWidth: "150px" }}>Date</th>
              <th style={{ minWidth: "100px" }}>Time</th>
              <th style={{ minWidth: "100px" }}>Action </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <span>
                  <img src={docImg1} className="img-fluid" alt="" />
                </span>
                Benny Lawrence{" "}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>12 Mar 2022</td>
              <td style={{ whiteSpace: "nowrap" }}>09.00 am</td>
              <td>
                <button
                  type="button"
                  className="edit-btn border-radius-40"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td style={{ minWidth: "12rem", whiteSpace: "nowrap" }}>
                <span>
                  <img src={docImg2} className="img-fluid" alt="" />
                </span>
                Rosy Mathew{" "}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>13 Mar 2022</td>
              <td style={{ whiteSpace: "nowrap" }}>09.15 am</td>
              <td>
                <button
                  type="button"
                  className=" edit-btn border-radius-40"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td style={{ minWidth: "12rem", whiteSpace: "nowrap" }}>
                <span>
                  <img src={docImg3} className="img-fluid" alt="" />
                </span>
                Rosy Mathew{" "}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>14 Mar 2022</td>
              <td style={{ whiteSpace: "nowrap" }}>09.30 am</td>
              <td>
                <button
                  type="button"
                  className=" edit-btn border-radius-40"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
