import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import "../../css/countrySelect.css";
import { Banner } from "./components/banner";
import { useEffect, useState } from "react";
import { TreatmentsList } from "./components/treatmentsList";
import { Footer } from "../../shared/footer/footer";
import { RequestCallback } from "../../shared/requestCallback";
import { useGetProceduresByBodyPart } from "../../Store/procedure/procedureHooks";
import { useGetAllBodyParts } from "../../Store/bodyPart/bodyPartHook";

export const Procedure = () => {
  const [tab, setTab] = useState("Surgical");
  const [bodyTab, setBodyTab] = useState("Face");
  const getProcedures = useGetProceduresByBodyPart();
  const getAllBodyParts = useGetAllBodyParts();
  useEffect(() => {
    getAllBodyParts();
  }, [getAllBodyParts]);

  useEffect(() => {
    getProcedures(bodyTab);
  }, [bodyTab, getProcedures]);

  const handleTab = (val) => {
    setTab(val);
    // if (val === "Surgical") {
    //   setTreatmentsData(surgicalTreatmentsData);
    // } else if (val === "Non-Surgical") {
    //   setTreatmentsData(nonSurgicalTreatmentsData);
    // }
  };

  return (
    <div>
      <Header />
      <section className="doctors-listing-block procedure-section">
        <div className="container-fluid">
          <div className="filter-list">
            <form action="">
              <div className="tab-surgical">
                <button
                  onClick={() => handleTab("Surgical")}
                  type="button"
                  className="text-black surgical active-button-1"
                >
                  Surgical
                </button>
                <button
                  onClick={() => handleTab("Non-Surgical")}
                  type="button"
                  className="text-black non-surgical"
                >
                  Non-Surgical
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="procedure-tab-2 tab-first">
        <div className="container-fluid p-0">
          <Banner setBodyTab={setBodyTab} bodyTab={bodyTab} />
          <TreatmentsList tab={tab} bodyTab={bodyTab} />
        </div>
      </section>
      <RequestCallback />
      <Footer />
    </div>
  );
};
