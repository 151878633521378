import axios from "axios";
import moment from "moment";

const axiosInstance = axios.create({
  baseURL: `https://api.mivana.com/api`,
});

axiosInstance.interceptors.request.use((request) => {
  let token = localStorage.getItem("mivana_user_token");
  if (!request.url.includes("/auth/local")) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  function (response) {
    if (response && response.data && response.data.length) {
      let list = [...response.data];
      list = list.map((x) => {
        return {
          ...x,
          createdAt: x ? moment(x.createdAt).format("L") : null,
          updatedAt: x ? moment(x.updatedAt).format("L") : null,
        };
      });
      response.data = list;
    }
    return response;
  },
  function (response) {
    return Promise.reject(response);
  }
);

export default axiosInstance;
