import "../../../../css/main.css";
import "../../../../css/media.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};

export const FacesCarousel = (props) => {
  return (
    <div className="d-block d-sm-none">
      <Carousel
        responsive={responsive}
        arrows={true}
        showDots={false}
        infinite={true}
        autoPlay={false}
        customLeftArrow={
          <button
            style={{ width: "3rem", height: "3rem" }}
            className={`rounded-circle border-0 fs-6 position-absolute text-black bottom-0 z-3 leftArrow customArrows`}
          >
            <i
              className="fa fa-chevron-left"
              style={{ fontSize: "12px" }}
              aria-hidden="true"
            ></i>
          </button>
        }
        customRightArrow={
          <button
            style={{ width: "3rem", height: "3rem" }}
            className={`rounded-circle border-0 fs-6 position-absolute z-3 text-black bottom-0 rightArrow customArrows`}
          >
            <i
              className="fa fa-chevron-right"
              style={{ fontSize: "12px" }}
              aria-hidden="true"
            ></i>
          </button>
        }
      >
        <div style={{ paddingBottom: "5rem" }}>
          <a href={props.img1}>
            <img
              src={props.img1}
              className="zoom rounded-3"
              style={{
                objectFit: "cover",
                height: "300px",
                width: "100%",
                minWidth: "100%",
              }}
              alt=""
            />
          </a>
        </div>
        <div style={{ paddingBottom: "5rem" }}>
          <a href={props.img2}>
            <img
              src={props.img2}
              className="zoom rounded-3"
              style={{
                objectFit: "cover",
                height: "300px",
                width: "100%",
                minWidth: "100%",
              }}
              alt=""
            />
          </a>
        </div>
      </Carousel>
    </div>
  );
};
