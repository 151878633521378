import {
  ARTICLES_BY_DOCTOR,
  ARTICLES_BY_DOCTOR_AND_STATUS,
} from "./actionTypes";

const initialData = {
  list: [],
  listBYStatus: [],
};
export function ArticleReducer(state = initialData, action) {
  switch (action.type) {
    case ARTICLES_BY_DOCTOR: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    case ARTICLES_BY_DOCTOR_AND_STATUS: {
      const data = action.payload;
      return {
        ...state,
        listBYStatus: data,
      };
    }
    default:
      return state;
  }
}
