import { faqsData } from "../../../demoData/faqsData";
import "../../../css/main.css";
import "../../../css/media.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Faqs = () => {
  return (
    <div className="accordion-container">
      {faqsData.map((data, ind) => (
        <div key={ind + 1}>
          <Accordion
            style={{
              boxShadow: "none",
              borderBottom:
                ind + 1 === faqsData.length ? "" : "1px solid rgb(0 0 0 / 10%)",
            }}
            className="set"
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="p-0"
            >
              <h5 className="me-5">{data.ques}</h5>
            </AccordionSummary>
            <AccordionDetails style={{marginLeft: "0px", padding:"0 0"}} className="content">
              <p>{data.ans}</p>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};
