import { useNavigate } from "react-router-dom";
import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/img.jpg";

export const Banner3 = () => {
  const navigate = useNavigate();
  return (
    <div className="find-specialist black-bg-design">
      <div className="row">
        <div className="col-lg-4">
          <span>
            <img src={img1} className="img-fluid w-100" alt="" />
          </span>
        </div>
        <div className="col-lg-5 m-auto">
          <h4>Find a right provider near you</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            ullamcorper. Sed pretium ipsum elit, sit amet
          </p>
        </div>
        <div className="col-lg-3 m-auto">
          <a
            href="/find-doctor-doctors-listing"
            onClick={(e) => {
              e.preventDefault();
              navigate("/find-doctor-doctors-listing");
            }}
            className="primary__btn"
          >
            Find a Specialist
          </a>
        </div>
      </div>
    </div>
  );
};
