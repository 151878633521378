import { createContext, useContext, useState } from "react";
export const Operators = {
  equal: "$eq",
  not_equal: "$neq",
  between: "$between",
  contains: "$cont",
  not_contains: "$ncont",
  greater_than: "$gt",
  less_than: "$lt",
  before: "$before",
  after: "$after",
};

export const FilterContext = createContext();

export const DoctorFilterProvider = (props) => {
  const [doctorFilters, setDoctorFilters] = useState([]);
  return (
    <FilterContext.Provider value={{ doctorFilters, setDoctorFilters, }}>
      {props.children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("Please use filter provider in the parent element");
  }

  return context;
};
