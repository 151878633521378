import { TabsGroup } from "../../../shared/tabsGroup";
import { userWalletTabs } from "../../../demoData/tabsData";
import { ReviewsHeader } from "../userReviews/components/reviewsHeader";
import { OrdersTable } from "./components/ordersComponents/ordersTable";
import { useEffect, useState } from "react";
import { getMyOrders } from "src/Store/order/orderAction";
import { Loading } from "src/shared/loadings/Loading";

export const MyOrders = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await getMyOrders("Pending");
    setList(res);
  };

  return (
    <section className="doctor-detail-stories-tab">
       <Loading duration={500} />
      <div className="container-fluid p-0">
        <ReviewsHeader title="My Orders (19)" />
        <TabsGroup tabs={userWalletTabs} />
        <OrdersTable order={true} list={list} getData={getData} />
      </div>
    </section>
  );
};
