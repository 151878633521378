import Select from "react-select";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import {
  ageOptions,
  concernsOptions,
  ethinicityOptions,
  genderOptions,
  heightOptions,
  proceureOptions,
  reviewOptions,
  weightOptions,
} from "../../../demoData/selectOptionsData";
import { useMediaQuery } from "@mui/material";

export const FilterBox = (props) => {
  const matches = useMediaQuery("(max-width:1600px)");

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div
      className={`row resp-hidden-box mt-4 px-1 ${
        matches ? "gy-3 gx-3 row-cols-12" : "gy-3 gx-6 row-cols-7"
      }`}
    >
      {props.activeTab && props.activeTab === "Q&A" && (
        <div className={`${matches ? "col-4" : "col"}`}>
          <Select
            options={proceureOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={proceureOptions[0]}
            isSearchable={true}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={concernsOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={concernsOptions[0]}
          isSearchable={true}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={genderOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={genderOptions[0]}
          isSearchable={false}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={ethinicityOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={ethinicityOptions[0]}
          isSearchable={false}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={ageOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={ageOptions[0]}
          isSearchable={false}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={heightOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={heightOptions[0]}
          isSearchable={false}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={weightOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={weightOptions[0]}
          isSearchable={false}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className={`${matches ? "col-4" : "col"}`}>
        <Select
          options={reviewOptions}
          getOptionLabel={getOptionLabel}
          defaultValue={reviewOptions[0]}
          isSearchable={false}
          className="offers-box-select" // Add a custom class name for styling
          classNamePrefix="offers-box-select" // Add a custom class prefix for styling
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  );
};
