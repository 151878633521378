import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import doc from "../../../../images/Body contouring_Color.jpg";
import "../../../../css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const SubmitReasonModal = (props) => {
  const matches = useMediaQuery("(max-width:500px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="modal-content py-5">
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-3"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faClose} className="ms-2" />
          </button>
        </div>
        <div className="modal-body">
          <div className="forgot-password-inner">
            <h5 className="head-title">Cancelled !!</h5>
            <div className="head-img-text-box">
              <span className="icon-box--modal icon-box--image">
                <img src={doc} className="img-fluid" alt="" />
              </span>
              <h4>
                Botox Tummy Tuck <span>Offer</span>{" "}
              </h4>
            </div>
            <div className="form-group mt-3">
              <label for="" className="text-start w-100">
                Reason for Cancelling
              </label>
              <textarea
                type="text"
                className="form-control"
                rows="4"
              ></textarea>
            </div>
            <div className="button-flex justify-content-center">
              <button
                type="button"
                className="primary__btn4 submit-reason"
                onClick={handleClose}
              >
                Submit Reason
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
