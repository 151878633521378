import { faHeartCirclePlus, faHeartPulse, faStethoscope, faUserDoctor, faUserPlus } from "@fortawesome/free-solid-svg-icons";

export const searchData = [
  {
    heading: "Treatments",
    icon: faHeartCirclePlus,
    subHeadings: ["Botox Injections"],
  },
  {
    heading: "Procedures",
    icon: faHeartPulse,
    subHeadings: ["Body Lift", "Botox for Migraines", "BodyTite", "Body Wrap"],
  },
  {
    heading: "Symptoms",
    icon: faUserPlus,
    subHeadings: ["Wrinkles", "Eye Bags", "Love Handles & Belly Fat", "Jowls", "Hair Loss"],
  },
  {
    heading: "Concerns",
    icon: faStethoscope,
    subHeadings: ["Wrinkle Reduction", "Wrinkle removal"],
  },
  {
    heading: "Doctors",
    icon: faUserDoctor,
    subHeadings: ["Michael A. Bogdan, MD, MBA, FACS", "David Bogue, MD", "Michael F. Bohley, MD", "James Bonaparte, MD, MSc, FRCSC", "Boris M. Ackerman, MD"],
  },
];
