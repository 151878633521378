import "../../../../css/main.css";
import "../../../../css/media.css";
import img1 from "../../.././../images/aesthetics.jpg";
import img2 from "../../../../images/play.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ShareDropdown } from "../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { IsLike } from "../../../../shared/AddToComps/isLike";
import { AddToFav } from "../../../../shared/AddToComps/addToFav";

export const DoctorDetailsVideosInfo = () => {
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const matches = useMediaQuery("(max-width:575px)");
  const matches2 = useMediaQuery("(max-width:992px)");
  return (
    <div className="breast-augmentaion px-4">
      <div className="row">
        <div className="col-lg-6">
          <div className="video-box">
            <img src={img1} className="img-fluid w-100" alt="" />
            <span>
              <img src={img2} className="img-fluid pulse" alt="" />
            </span>
          </div>
        </div>
        <div className="col-lg-6">
          <h4>The Advantages and Disadvantages of Different Implants</h4>
          <p>Uploaded on 12 Sep 2020</p>
          <p className="p-head-size-17">
            Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Suspendisse
            Feugiat Mi Venenatis, Pulvinar Felis Sit Amet, Congue Lectus. Ut
            Venenatis Sagittis Augue, Vel Bibendum Nisl Porttitor Ut. Mauris Ac
            Turpis Elit. Class Aptent Taciti Sociosqu{" "}
          </p>
          <p className="p-head-size-17">
            Ad Litora Torquent Per Conubia Nostra, Per Inceptos Himenaeos. Etiam
            Id Dapibus Nunc. Donec At Vestibulum Nisl. Vivamus Ullamcorper
            Aliquet Augue, Id Consequat Neque Aliquam Et. Nulla Fringilla Mollis
            Quam, Eu Semper Urna. Duis Iaculis At Dolor At Feugiat. Curabitur
            Semper Sem Felis, Sit Amet Eleifend Urna Luctus Id. Sed At Lacus
            Vitae Enim Suscipit Feugiat. Lorem Ipsum Dolor Sit Amet, Consectetur
            Adipiscing Elit.
          </p>
          <div className="sharing-section highlight-share">
            <a href="book-appoinment.html" className="primary__btn3 mt-0">
              Get Price Estimate
            </a>
            <ul className="d-flex align-items-center gap-2 pb-5 pb-lg-0">
              <div
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  backgroundColor: "lightGrey",
                }}
                className="rounded-circle border-0 d-flex align-items-center justify-content-center"
              >
                <AddToFav />
              </div>
              <div
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  backgroundColor: "lightGrey",
                }}
                className="rounded-circle border-0 d-flex align-items-center justify-content-center"
              >
                <IsLike />
              </div>
              <div className="position-relative">
                <button
                  onClick={() => setShowShareDropdown(!showShareDropdown)}
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    backgroundColor: "lightGrey",
                  }}
                  className="rounded-circle border-0 d-flex align-items-center justify-content-center ps-2"
                >
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{ color: "gray", fontSize: "20px" }}
                  />
                </button>
                {showShareDropdown && (
                  <ShareDropdown
                    position={`mt-3 ${
                      matches ? "start-0 translate-middle mt-5" : matches2 ? "start-0" : "end-0"
                    }`}
                  />
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
