import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import { faArrowRightLong, faCheck } from "@fortawesome/free-solid-svg-icons";
import { RangeSlider } from "../../../../shared/rangeSlider/rangeSlider";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";
import { useNavigate } from "react-router-dom";

const options1 = [
  { value: "USD", label: "USD" },
  { value: "INR", label: "INR" },
];
export const PriceFilter = (props) => {
  const navigate = useNavigate();
  const getOptionLabel = (option) => (
    <div className="flex items-center gap-x-4 justify-between">
      <span className="text-sm xl:text-base">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="row">
      <div className="col-lg-7">
        <h2>
          <span className="head-intend">Face - </span> {props.heading}
        </h2>
        <div className="d-flex align-items-center gap-3">
          <IsHelpfull label="Botox" bg="black" color="white" />
          <a
            href="/botox-injections"
            onClick={(e) => {
              e.preventDefault();
              navigate("/botox-injections");
            }}
            className="read-more"
          >
            Read More <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="top-range-slider">
          <div className="row">
            <div className="col-lg-3">
              <Select
                options={options1}
                getOptionLabel={getOptionLabel}
                defaultValue={options1[0]}
                isSearchable={true}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="col-lg-9">
              <div className="from-to-block">
                <span className="avg-cost">Average Cost</span>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="From"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="to"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="slider-wrapper">
            <RangeSlider />
            <div className="marker marker-0">
              <sup>$</sup>10,000
            </div>
            <div className="marker marker-25">
              <sup>$</sup>35,000
            </div>
            <div className="marker marker-50">
              <sup>$</sup>60,000
            </div>
            <div className="marker marker-75">
              <sup>$</sup>85,000
            </div>
            <div className="marker marker-100">
              <sup>$</sup>110,000+
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
