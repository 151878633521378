import { faStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HandleFavContent = (props) => {
  return (
      <FontAwesomeIcon
        icon={faStar}
        className="mx-auto my-auto"
        style={{
          color: props.isFav ? "white" : "gray",
          fontSize: props.isFav ? "22px" : "22px",
        }}
      />
  );
};
