import {
    CART_BY_PATIENT
  } from "./actionTypes";
  
  const initialData = {
    item: [],
  };
  export function CartReducer(state = initialData, action) {
    switch (action.type) {
      case CART_BY_PATIENT: {
        const data = action.payload;
        return {
          ...state,
          item: data,
        };
      }
      default:
        return state;
    }
  }
  