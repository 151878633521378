/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import docImg from "../../../images/arab F-3.jpg";
import { ReviewStars } from "../../reviewStars";
import { useNavigate } from "react-router-dom";
import { LogoutModal } from "../../Modals/logoutModal";
import { useEffect, useState } from "react";
import { imgBaseUrl } from "../../../services/config/config";
import {
  currentPatientId,
} from "../../../Store/selectors/UserSelectors";
import {
  useGetPatientById,
  usePatient,
} from "../../../Store/Patient/patientHook";
const linksData = [
  {
    name: "My Profile",
    activePath: "user-profile",
    path: "/user-dashboard/user-profile",
  },
  {
    name: "Notifications",
    activePath: "/notification-",
    path: "/user-dashboard/notification-empty",
  },
  {
    name: "Upcoming appointments",
    activePath: "/upcomming-appointments",
    path: "/user-dashboard/upcomming-appointments",
  },
  {
    name: "Completed appointments",
    activePath: "/completed-appointments",
    path: "/user-dashboard/completed-appointments",
  },
  {
    name: "Video Consultation",
    activePath: "/video-consultation",
    path: "/user-dashboard/video-consultation",
  },
  {
    name: "Reviews",
    activePath: "-review",
    path: "/user-dashboard/my-new-reviews",
  },
  {
    name: "Offers",
    activePath: "-offers-",
    path: "/user-dashboard/purchased-offers-",
  },
  {
    name: "Wallet",
    activePath: "/wallet",
    path: "/user-dashboard/wallet-cart",
  },

  {
    name: "Settings",
    activePath: "/settings",
    path: "/user-dashboard/settings-change-password",
  },
  {
    name: "Support",
    activePath: "/support",
    path: "/user-dashboard/support-raise-ticket",
  },
  { name: "Feed", activePath: "/feed", path: "/user-dashboard/feed" },
  {
    name: "Favoruites",
    activePath: "/favoruite-",
    path: "/user-dashboard/favoruite-no-favoruits",
  },
  { name: "Q&A", activePath: "-qa", path: "/user-dashboard/new-qa" },
  {
    name: "Refer a Friend",
    activePath: "/refers",
    path: "/user-dashboard/refers-invite-friend",
  },

  {
    name: "Redeem Points",
    activePath: "redeem-",
    path: "/user-dashboard/redeem-earned-points",
  },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const patient = usePatient();
  const getPatientById = useGetPatientById();
  useEffect(() => {
    getPatientById(currentPatientId());
  }, [currentPatientId]);
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="col-lg-3 d-lg-block d-none">
      <div className="sidebar">
        {patient && patient.formData && (
          <div className="doctor-name">
            <span className="doc-img">
              <img
                src={
                  patient.formData.profileImg
                    ? `${imgBaseUrl + patient.formData.profileImg}`
                    : docImg
                }
                className="img-fluid doc-img doctor-img1"
                alt=""
              />
            </span>
            <h6>
              {patient.formData.firstName + " "}
              {patient.formData.lastName && patient.formData.lastName}
            </h6>
            <div className="detail-box">
              <ReviewStars />
              <span className="number">(1,378)</span>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-placement="top"
                title="Tooltip on top"
              >
                <FontAwesomeIcon icon={faTrophy} />
              </button>
            </div>
          </div>
        )}

        <ul>
          {linksData.map((data, ind) => (
            <li
              key={ind + 1}
              className={`${isActive(data.activePath) ? "active" : ""}`}
            >
              <a
                href={data.path}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(data.path);
                }}
              >
                {data.name}
              </a>
            </li>
          ))}
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              type="button"
              className="sidebar-logout"
              href
            >
              Logout
            </a>
          </li>
          <LogoutModal showModal={showModal} setShowModal={setShowModal} />
        </ul>
      </div>
    </div>
  );
};
