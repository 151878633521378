import img1 from "../images/botox-treatment1.jpg";
import img2 from "../images/botox-treatment2.jpg";
import img3 from "../images/botox-treatment3.jpg";
import img4 from "../images/botox-treatment4.jpg";
import img5 from "../images/botox-treatment5.jpg";
import img6 from "../images/botox-treatment6.jpg";

export const botoxTreatmentsData = [
  {
    title: "Botox For TMJ",
    img: img1,
  },
  {
    title: "Micro Botox",
    img: img2,
  },
  {
    title: "Botox For Vaginismus",
    img: img3,
  },
  {
    title: "Botox For Migraines",
    img: img4,
  },
  {
    title: "Botox For Hyperhidrosis",
    img: img5,
  },
  {
    title: "Botox For Gummy Smile",
    img: img6,
  },
  {
    title: "Botox For TMJ",
    img: img1,
  },
];
