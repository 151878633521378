import "../../css/main.css";
import { Analytics } from "./components/dashboardPageComponents/analytics";
import { PatientVisit } from "./components/dashboardPageComponents/patientVisit";
import { UpcommingEvents } from "./components/dashboardPageComponents/upcommingEvents";
import { PatientReview } from "./components/dashboardPageComponents/patientReview";
import { Notifications } from "./components/dashboardPageComponents/notifications";

export const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <Analytics />
      <div className="row">
        <PatientVisit />
        <UpcommingEvents />
        <div className="col-lg-7">
          <div className="row">
            <PatientReview title={"Patient Review"} />
            <PatientReview title={"Questions"} />
          </div>
        </div>
        <Notifications />
      </div>
    </div>
  );
};
