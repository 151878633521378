

import { favoruitesTabs } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { FavHeader } from "./components/favHeader";
import { FaVItemsList2 } from "./components/favItemsList/faVItemsList2";

export const FavBlogs = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <FavHeader title="Blogs (19)" />
        <TabsGroup tabs={favoruitesTabs} />
        <FaVItemsList2 page="blogs" />
      </div>
    </section>
  );
};
