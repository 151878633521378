import "../../../../css/main.css";
import "../../../../css/media.css";
import img1 from "../../../../images/video-img1.jpg";
import img2 from "../../../../images/video-img2.jpg";
import img3 from "../../../../images/video-img3.jpg";
import img4 from "../../../../images/video-img4.jpg";
import playImg from "../../../../images/play-small.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "@mui/material";
const carouselItems = [img1, img2, img3, img4];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};
export const RelatedVideos = () => {
  const matches2 = useMediaQuery("(min-width:992px)");
  return (
    <section className="video-related common-slider">
      <div className="container-fluid">
        <div className="video-related-row common-pad">
          <h4>Related Videos</h4>
          <div className="">
            <Carousel
              responsive={responsive}
              arrows={matches2 ? false : true}
              showDots={false}
              infinite={true}
              autoPlay={false}
              customLeftArrow={
                <button
                  className={`rounded-circle fs-6 border-0 position-absolute ${
                    matches2 ? "bottom-0 mb-5" : "bottom-0"
                  } leftArrow customArrows`}
                  style={{zIndex: '20', width: "3rem", height: '3rem'}}
                >
                  <i
                    className="fa fa-chevron-left text-base"
                    aria-hidden="true"
                  ></i>
                </button>
              }
              customRightArrow={
                <button
                  className={`rounded-circle fs-6 border-0 position-absolute text-black  ${
                    matches2 ? "bottom-0 mb-5" : "bottom-0"
                  } rightArrow customArrows`}
                  style={{zIndex: '20', width: "3rem", height: '3rem'}}
                >
                  <i
                    className="fa fa-chevron-right text-base"
                    aria-hidden="true"
                  ></i>
                </button>
              }
            >
              {carouselItems.map((data, ind) => (
                <div className={`item mx-2 ${matches2 ? "" : "pb-5"}`} key={ind + 1}>
                  <div className={`related-video-block ${matches2 ? "" : "pb-3"}`}>
                    <div className="overlay-video-related">
                      <img src={data} className="img-fluid w-100" alt="" />
                      <span>
                        <img src={playImg} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <p className="p-head-size-17">
                      Tummy Tuck Lorem Ispum Tuck Lor Em Isp Lorem Ispum
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="button-flex justify-content-center">
            <a href className="primary__btn4">
              See More Videos
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
