import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RewardsList = () => {
  return (
    <div className="tab_container articles-container px-0 overflow-hidden">
      <div id="tab1" className="tab_content px-0">
        <div className="refer-block">
          <div className="row">
            <div className="col-lg-5">
              <h5>Together we are going further </h5>
              <ul className="ul-list-2">
                <li>
                  Total Invitation Sent <span>25</span>
                </li>
                <li>
                  Total Invitation Entrolled <span>25</span>
                </li>
                <li>
                  Total Invitation Pending <span>25</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 m-auto mt-3 mt-lg-auto">
              <div className="reward-earned">
                <span className="reward-ico">
                  <FontAwesomeIcon icon={faStar} />
                </span>
                <h6 className="mt-3">
                  Reward earned
                  <span>125 points</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
