import img1 from "../images/dashboard/doc1.jpg";
import img2 from "../images/dashboard/doc2.jpg";
import img3 from "../images/dashboard/doc3.jpg";
import img4 from "../images/dashboard/doc4.jpg";
import img5 from "../images/dashboard/doc5.jpg";
import img6 from "../images/dashboard/doc6.jpg";
import img7 from "../images/dashboard/doc7.jpg";

export const patientReviewsData = [
  { img: img1, name: "Benny Lawrence", date: "12 Mar 2022" },
  { img: img2, name: "Rosy Mathew", date: "13 Mar 2022" },
  { img: img3, name: "Rosy Mathew", date: "14 Mar 2022" },
  { img: img4, name: "Jennifer Johnson", date: "15 Mar 2022" },
  { img: img5, name: "Robert Clive", date: "16 Mar 2022" },
  { img: img6, name: "Jasmine James", date: "17 Mar 2022" },
  { img: img7, name: "Justin Wright", date: "18 Mar 2022" },
];
