import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import img1 from "../../../../../images/face-liposection-review1/img1.png";
import img2 from "../../../../../images/face-liposection-review1/img2.png";
import img3 from "../../../../../images/face-liposection-review1/img3.png";
import img4 from "../../../../../images/face-liposection-review1/img4.png";
import { IsHelpfull } from "../../../../../shared/AddToComps/isHelpfull";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import {
  faComment,
  faFlag,
  faSmile,
} from "@fortawesome/free-regular-svg-icons";
import { AddToFavWithText } from "../../../../../shared/AddToComps/addToFavWithText";
import { ShareDropdown } from "./shareDropdown";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { FlagDropdown } from "./flagDropdown";

export const CommentBox = () => {
  const matches = useMediaQuery("(max-width:575px)");

  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showFlagDropdown, setShowFlagDropdown] = useState(false);
  return (
    <div className="q-a-inner mx-2">
      <h4>
        Fillers Done Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
        sed diam nonumy eirmod tempo
      </h4>
      <div className="qa-head-inner">
        <AddToFavWithText />
        <p className="mt-3 md:mt-0">
          <span className="date-month">12 Sep 2022 | 24 months ago</span>
        </p>
      </div>

      <p className="p-head-size">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores Lorem
        ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores
      </p>
      <ul className="img-list-popup">
        <li>
          <img src={img1} className="img-fluid" alt="" />
        </li>
        <li>
          <img src={img2} className="img-fluid" alt="" />
        </li>
        <li>
          <img src={img3} className="img-fluid" alt="" />
        </li>
        <li>
          <img src={img4} className="img-fluid" alt="" />
        </li>
        <li>
          <img src={img1} className="img-fluid" alt="" />
        </li>
        <li>
          <img src={img2} className="img-fluid" alt="" />
        </li>
        <li>
          <img src={img3} className="img-fluid" alt="" />
          <button
            type="button"
            className="num-photos border-0"
          >
            <span>5+</span>
          </button>
        </li>
      </ul>

      <div className="share-box-section">
        <div className="share-box-left d-flex">
          <div className="button-flex">
            <button className="button like rounded like-effect-click">
              <span>
                <FontAwesomeIcon icon={faSmile} />
              </span>
            </button>
            <span className="-ml-2">340 likes</span>
          </div>

          <span className="comment-onclick">
            <FontAwesomeIcon icon={faComment} className="mr-2" />
            240 Comments
          </span>
        </div>

        <div className="share-box-right share-box-flex text-left">
          <p>2638 people find it helpful</p>
          <IsHelpfull label="Helpful" />

          <div className="mx-2 mx-sm-3 position-relative">
            <button
              onClick={() => setShowShareDropdown(!showShareDropdown)}
              style={{background: "light-Grey", width: "2.5rem", height: "2.5rem"}}
              className="rounded-circle border-0 d-flex align-items-center justify-content-center"
            >
              <FontAwesomeIcon
                icon={faShareNodes}
                style={{ color: "rgb(161 161 170)" }}
              />
            </button>
            {showShareDropdown && (
              <ShareDropdown
                position={`mt-5 mt-sm-3 ${matches ? "start-0 translate-middle" : "end-0"}`}
              />
            )}
          </div>
          <div className="relative">
            <button 
              style={{background: "light-Grey", width: "2.5rem", height: "2.5rem"}}
            onClick={() => setShowFlagDropdown(!showFlagDropdown)} className="rounded-circle border-0 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faFlag}
                style={{ color: "rgb(161 161 170)" }}
              />
            </button>
           {showFlagDropdown &&  <FlagDropdown  position={`mt-3 end-0`} />}
          </div>
        </div>
      </div>
      <div className="form-group">
        <textarea name="" id="" className="form-control" rows="5">
          Add a Comment
        </textarea>
      </div>
    </div>
  );
};
