import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HandleHelpfullContent = (props) => {
  return (
    <div>
      {props.label}
      {props.isFav && (
        <FontAwesomeIcon
          icon={faHeart}
          style={{
            color: "#e78a82",
            fontSize: "18px",
            margin: "0 0 -2px 8px",
          }}
        />
      )}
    </div>
  );
};
