import FavoriteButton from "../fav";
import { HandleFavContent } from "./subComponents/favContent";

export const AddToFav = (props) => {
  return (
    <FavoriteButton
      handleClick={props.onClick}
      isFav={props.isFav}
      btnStyle={(isFav) => {
        return {
          background: isFav ? "#e78a82" : "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
          borderRadius: "50%",
          width: props.size ? props.size : "2.5rem",
          height: props.size ? props.size : "2.5rem",
        };
      }}
      handleContent={(isFav) => <HandleFavContent isFav={isFav} />}
    />
  );
};
