import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import {
  faHeart,
  faStarHalfAlt,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDoctor } from "../../../Store/Doctor/doctorHooks";
import {
  useCreateFavDoctor,
  useDeleteFavDoctor,
  useFavDoctor,
  useGetFavDoctorsByPatient,
} from "../../../Store/favoruiteDoctor/favoruiteDoctorHooks";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { imgBaseUrl } from "../../../services/config/config";

export const DoctorBanner = (props) => {
  const matches = useMediaQuery("(max-width:766px)");
  const [favData, setFavData] = useState({});
  const navigate = useNavigate();
  const doctor = useDoctor();
  const getFavDoctors = useGetFavDoctorsByPatient();
  const favDoctor = useFavDoctor();
  const createFavDoctor = useCreateFavDoctor();
  const removeFavDoctor = useDeleteFavDoctor();

  useEffect(() => {
    if (favDoctor) {
      let res = favDoctor.list.find(
        (d) =>
          d.doctor && d.doctor.data && d.doctor.data.id === doctor.docById.id
      );
      setFavData(res);
    }
  }, [favDoctor, doctor.docById.id]);

  useEffect(() => {
    getFavDoctors();
  }, [getFavDoctors]);

  const handleFavFunc = () => {
    if (favData && favData.id) {
      removeFavDoctor(favData.id);
    } else {
      createFavDoctor({
        patient: currentPatientId(),
        doctor: doctor.docById.id,
      });
    }
  };
  return (
    <div
      className="px-3 px-md-5"
      style={{ marginTop: matches ? "-15rem" : "-19rem" }}
    >
      <section
        className="doctors-listing-block doctors-detail-block bg-white p-0"
        style={{ borderRadius: "30px" }}
      >
        <div className="container-fluid px-3">
          <div className="doctors-listing-box book-appoinment-details">
            <div className="row">
              <div className="col-lg-2">
                <div className="doctor-img-list">
                  <span>
                    <img
                      src={`${imgBaseUrl + doctor.docById.profileImg}`}
                      className="img-fluid doctor-img1"
                      style={{
                        width: "18rem",
                        minWidth: "18rem",
                        height: "18rem",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </span>
                  <button
                    type="button"
                    onClick={handleFavFunc}
                    className="btn btn-primary favourite-add h-10 invisible"
                  >
                    {favData && favData.id
                      ? "Remove from favoruites"
                      : "Add to favoruites"}
                  </button>
                  <button
                    className="button like rounded like-effect-click"
                    style={{ marginRight: "-3rem" }}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faHeart}
                        style={{ color: favData && favData.id ? "red" : "" }}
                      />
                    </span>
                  </button>
                </div>
                <button
                  onClick={handleFavFunc}
                  type="button"
                  className="btn btn-primary favourite-add"
                >
                  {favData && favData.id
                    ? "Remove from favoruites"
                    : "Add to favoruites"}
                </button>
              </div>
              <div className="col-lg-9 m-auto">
                <div className="doctor-details">
                  <h5>
                    {" "}
                    {doctor.docById.firstName + " "}{" "}
                    {doctor.docById.lastName && doctor.docById.lastName}
                  </h5>
                  <ul className="d-flex flex-column gap-3 mt-4 fs-5">
                    {doctor.docById.educations &&
                      doctor.docById.educations.length > 0 && (
                        <li className="d-flex align-items-center flex-wrap gap-1 p-0 m-0">
                          {doctor.docById.educations.map((d, i) => (
                            <span key={i + 1} className="p-0 m-0">
                              {d},
                            </span>
                          ))}
                        </li>
                      )}

                    {(doctor.docById.state || doctor.docById.country) && (
                      <li>
                        {doctor.docById.state}, {doctor.docById.country}
                      </li>
                    )}
                    {doctor.docById.totalExperienceYears && (
                      <li>
                        {doctor.docById.totalExperienceYears}+ Yrs Experience
                      </li>
                    )}
                    {doctor.docById.title && <li>{doctor.docById.title}</li>}
                    {doctor.docById.languages &&
                      doctor.docById.languages.length > 0 && (
                        <li className="d-flex flex-wrap align-items-center gap-1 p-0 m-0">
                          Speaks:{" "}
                          {doctor.docById.languages.map((d, i) => (
                            <span key={i + 1} className="p-0 m-0">
                              {d},
                            </span>
                          ))}
                        </li>
                      )}
                  </ul>
                  <div className="detail-box mt-4">
                    <div className="rating">
                      <div className="">
                        <FontAwesomeIcon icon={props.solidStar} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={props.solidStar} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={props.solidStar} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                      </div>
                    </div>
                    <span className="number">(1,378)</span>
                    <a
                      href="/badge"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/badge");
                      }}
                      type="button"
                      data-toggle="tooltip"
                      title="Hooray!"
                    >
                      <FontAwesomeIcon
                        icon={faTrophy}
                        style={{ fontSize: "26px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
