import {
  currentDoctorId,
  currentPatientId,
} from "../Store/selectors/UserSelectors";

export const getAPIResponseData = (arr) => {
  let res = [];
  arr.forEach((d) => {
    res.push({ id: d.id, ...d.attributes });
  });
  return res;
};
export const getIdsOfArray = (arr) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push(d.id);
    });
  }
  return res;
};

export const getSelectValuesArray = (arr) => {
  let res = [];
  arr.forEach((d) => {
    if (d.value) {
      res.push(d.value);
    } else {
      res.push(d);
    }
  });
  return res;
};

export const formFinalData = (finalData) => {
  let data = finalData;
  Object.keys(finalData).forEach((d) => {
    if (finalData[d] && Array.isArray(finalData[d])) {
      data[d] = getSelectValuesArray(finalData[d]);
    } else if (finalData[d] && typeof finalData[d] === "object") {
      data[d] = data[d].value;
    }
  });
  return data;
};

export const getFullNameByWhoAsked = (data) => {
  if (data.whoAsked === "Doctor") {
    if (data.doctor && data.doctor.data && data.doctor.data.attributes) {
      return (
        data.doctor.data.attributes.firstName +
        " " +
        data.doctor.data.attributes.lastName
      );
    }
  }
  if (data.whoAsked === "Patient") {
    if (data.patient && data.patient.data && data.patient.data.attributes) {
      return (
        data.patient.data.attributes.firstName +
        " " +
        data.patient.data.attributes.lastName
      );
    }
  }
};
export const getFullName = (data) => {
  if (currentDoctorId()) {
    if (data.doctor && data.doctor.data && data.doctor.data.attributes) {
      return (
        data.doctor.data.attributes.firstName +
        " " +
        data.doctor.data.attributes.lastName
      );
    }
  }
  if (currentPatientId()) {
    if (data.patient && data.patient.data && data.patient.data.attributes) {
      return (
        data.patient.data.attributes.firstName +
        " " +
        data.patient.data.attributes.lastName
      );
    }
  }
};

export const getSelectData = (arrdata) =>
  arrdata &&
  arrdata.length > 0 &&
  arrdata.map((item) => {
    return { value: item.value, label: item.label };
  });

export const getDataforSelectByName = (arr) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({ value: d.name, label: d.name });
    });
  }
  return res;
};
export const getDataforSelect = (arr) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({ value: d.id, label: d.name });
    });
  }
  return res;
};
export const getDataforSelect2 = (arr, label, value) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({ value: d[value], label: d[label] });
    });
  }
  return res;
};
export const getDataforSelectforDoctor = (arr, label1, label2, value) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({
        value: d[value],
        label: `Dr. ${d[label1]} ${d[label2] ? d[label2] : ""}`,
      });
    });
  }
  return res;
};

export const setItemQuantity = (item, data) => {
  let result = data
  let res = data.find((d) => d.id === item.id)
  if (res && res.id) {
    let objIndex = result.findIndex((obj => obj.id == item.id));
    result[objIndex].quantity = res.quantity + 1
  }
  else {
    result = [...result, { ...item, quantity: 1 }]
  }
  return result
}

export const cartItemsUpdationFinalData = (i, data, quan) => {
  if (data && data.offers && data.offers.length > 0) {
    let res = data.offers

    res[i].quantity = quan
    let obj = {
      id: data.id,
      offers:
        data.offers && data.offers.length > 0
          ? res
          : data.offers,
    };

    return obj
  }
}

export const removeItemFromCartData = (id, data) => {
  if (data && data.offers && data.offers.length > 0) {
    let res = data.offers
    let res2 = res.filter((d) => d.id != id)
    let obj = {
      id: data.id,
      offers:
        data.offers
          ? res2
          : data.offers,
    };

    return obj
  }
}
