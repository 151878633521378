import {
  PROCEDURES_BY_BODY_PART,
  ALL_PROCEDURES,
  PROCEDURES_BY_DOCTOR,
} from "./actionTypes";

const initialData = {
  list: [],
  list2: [],
  list3: [],
};
export function ProcedureReducer(state = initialData, action) {
  switch (action.type) {
    case PROCEDURES_BY_BODY_PART: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    case ALL_PROCEDURES: {
      const data = action.payload;
      return {
        ...state,
        list2: data,
      };
    }
    case PROCEDURES_BY_DOCTOR: {
      const data = action.payload;
      return {
        ...state,
        list3: data,
      };
    }
    default:
      return state;
  }
}
