import { SIGN_UP_DATA } from "./actionTypes";

const initialData = {
  userData: {},
};
export function AuthReducer(state = initialData, action) {
  switch (action.type) {
    case SIGN_UP_DATA: {
      const data = action.payload;
      return {
        ...state,
        userData: data,
      };
    }
    default:
      return state;
  }
}
