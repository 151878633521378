import { useMediaQuery } from "@mui/material";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { useNavigate } from "react-router-dom";
import { imgBaseUrl } from "../../../../services/config/config";
import moment from "moment";
import { useArticle } from "../../../../Store/article/articleHook";

export const ArticlesListing = () => {
  const matches3 = useMediaQuery("(max-width:766px)");
  const matches = useMediaQuery("(min-width:992px)");
  const matches2 = useMediaQuery("(min-width:1280px)");
  const navigate = useNavigate();
  const article = useArticle();

  return (
    <div className="doctor-detail_articles-listing">
      {article.list.map((data, ind) => (
        <div
          className={`border border-zinc-700 rounded-3  py-3 mb-4 d-flex flex-column flex-xl-row align-items-start justify-content-between gap-0 gap-xl-5 ${
            matches ? "px-4" : "px-3 px-sm-4 "
          }`}
          key={ind + 1}
        >
          <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start gap-5">
            <div className="">
              <img
                src={`${imgBaseUrl + data.treatmentPhoto}`}
                className="img-fluid rounded-2"
                style={{
                  width: "12rem",
                  minWidth: "12rem",
                  height: "8rem",
                  objectFit: "cover",
                }}
                alt=""
              />
            </div>
            <div className="">
              <h5
                className="mt-3 mt-md-0"
                style={{ textAlign: matches3 ? "center" : "left" }}
              >
                {data.title}
              </h5>
              <p className="p-head-size-17">{data.description}</p>
            </div>
          </div>

          <div
            className={`d-flex pe-0 pe-xl-5 ${
              matches2
                ? "justify-content-start"
                : "justify-content-center justify-content-md-end"
            }`}
            style={{ width: matches2 ? "max-content" : "100%" }}
          >
            <div className="published-date d-flex flex-column align-items-center">
              <p style={{ whiteSpace: "nowrap" }}>
                Published On {moment(data.createdAt).format("DD MMM YYYY")}
              </p>
              <a
                href="/article-detiails"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/doctor-details-articles-detiails");
                }}
                style={{ whiteSpace: "nowrap" }}
                className="primary__btn"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      ))}
      <div className="button-flex justify-content-center">
        <a href className="primary__btn5">
          See More Articles
        </a>
      </div>
    </div>
  );
};
