export const MultiChoiceQues = (props) => {
  const isActive = (val) => {
    if (props.isMulti && props.value) {
      let res = props.value.find((d) => d === val);
      return res;
    }
  };

  const handleClick = (val) => {
    if (props.isMulti) {
      if (isActive(val)) {
        let res = props.value.filter((d) => d !== val);
        props.onChange(res);
      } else {
        let res = props.value ? [...props.value, val] : [val];
        props.onChange(res);
      }
    } else {
      props.onChange(val);
    }
  };
  return (
    <div className="form-group position-relative">
      <div className="row">
        <label className="fieldlabels">
          <span className="questions-num">Q{props.quesNo}</span>
          {props.ques}
        </label>
        <div className="col-lg-9">
          {props.isMulti ? (
            <div className="invisible-checkboxes">
              {props.choicesData.map((d, ind) => (
                <div
                  key={ind + 1}
                  className={`form-group position-relative`}
                  onClick={() => handleClick(d)}
                >
                  <label
                    className={`${
                      isActive(d) ? "checked_checkboxes" : ""
                    } checkbox-alias`}
                    for="r1"
                  >
                    {d}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <div className="invisible-checkboxes">
              {props.choicesData.map((d, ind) => (
                <div
                  key={ind + 1}
                  className={`form-group position-relative`}
                  onClick={() => props.onChange(d)}
                >
                  <label
                    className={`${
                      props.value === d ? "checked_checkboxes" : ""
                    } checkbox-alias`}
                    for="r1"
                  >
                    {d}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
