import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from "../../../../../images/doctor-detail1.jpg";
import img2 from "../../../../../images/doctor-detail2.jpg";
import img3 from "../../../../../images/doctor-detail3.jpg";
import img4 from "../../../../../images/doctor-detail4.jpg";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import { PaginationBox } from "../../../../../shared/paginationBox";

const listData = [img1, img2, img3, img4, img1];

export const FavItemsList = () => {
  const matches1 = useMediaQuery("(max-width:1600px)");
  const matches2 = useMediaQuery("(max-width:767px)");
  const matches3 = useMediaQuery("(max-width:1199px)");
  return (
    <div className="tab_container three-block-container px-0">
      <div id="tab1" className="tab_content">
        <div className="doctor-detail_articles-listing">
          {listData.map((item, ind) => (
            <div className="doctor-detail-row position-relative" key={ind + 1}>
              <div className="d-flex flex-column flex-xl-row">
                <div className="doctor-img-first p-0 d-flex justify-content-start">
                  <img
                    src={item}
                    className="img-fluid object-cover"
                    style={{
                      width: matches3 ? "16rem" : "14rem",
                      maxWidth: matches3 ? "16rem" : "14rem",
                      height: matches3 ? "" : "min-content",
                    }}
                    alt=""
                  />
                </div>
                <div className="w-100 mt-4 mt-xl-0">
                  <h5 className={`fs-4 ${matches2 ? "text-start" : " "}`}>
                    45-54 year old woman treated with Rhinoplasty
                  </h5>
                  <p style={{ fontSize: matches1 ? "15px" : "" }}>
                    Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.
                    Suspendisse Feugiat Mi Venenatis, Pulvinar Felis Sit Amet,
                    Congue Lectus. Ut Venenatis Sagittis Augue, Vel Bibendum
                    Nisl Porttitor Ut. Mauris Ac Turpis Elit. Class Aptent
                    Taciti Sociosqu Ad Litora Torquent Per Conubia.{" "}
                  </p>
                </div>
              </div>
              <span className="delete-icon-box">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          ))}
        </div>
      </div>
      <PaginationBox />
    </div>
  );
};
