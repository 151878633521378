import FavoriteButton from "../fav";
import { LikeContent } from "./subComponents/likeContent";

export const IsLike = (props) => {
  return (
    <FavoriteButton
      btnStyle={(isFav) => {
        return {
          background: isFav ? "#e78a82" : "white",
          width: "2.5rem",
          height: "2.5rem",
        };
      }}
      className={`border-0 rounded-circle d-flex align-items-center justify-content-center`}
      handleContent={(isFav) => (
        <LikeContent isFav={isFav} smileIcon={props.smileIcon} />
      )}
    />
  );
};
