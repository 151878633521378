import { useNavigate } from "react-router-dom";

export const RegistrationTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => window.location.href.includes(val);

  return (
    <section className="doctors-listing-block signin-page-list">
      <div className="container-fluid">
        <div className="filter-list resp-none-box">
          <form action="">
            <div className="tab-surgical">
              <a
                href="/sign-in"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/sign-in");
                }}
                type="button"
                className={`surgical ${
                  isActive("sign-in") ? "active-button-1" : ""
                }`}
              >
                Signin
              </a>
              <a
                href="/sign-up-users"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/sign-up-users");
                }}
                type="button"
                className={`non-surgical ${
                  isActive("sign-up") ? "active-button-1" : ""
                }`}
              >
                Signup
              </a>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
