import "../../../css/main.css";
import "../../../css/media.css";
import laptop from "../../../images/doctor-registration/laptop.jpg";

export const MavinaSummary = () => {
  return (
    <section className="mivana-data-section">
      <div className="container-fluid px-2">
        <div className="row">
          <div className="col-lg-6">
            <span>
              <img src={laptop} className="img-fluid" alt="laptop" />
            </span>
          </div>
          <div className="col-lg-6 mt-auto mb-auto">
            <div className="registration-mivana-data-content">
              <h6>Keep track of patient feedback & Appointments</h6>
              <p>
                Does your profile have all the information? We’ll remind you
                about all the minute details that can make an huge difference
                impact on your online presence.
              </p>
              <h6>Improve your profile with tips from us</h6>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At
              </p>
              <h6>Edit your profile from anywhere, effortlessly</h6>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
