import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { faComment, faEye, faFlag } from "@fortawesome/free-regular-svg-icons";
import { IsLike } from "../../../../shared/AddToComps/isLike";
import { AddToFavWithText } from "../../../../shared/AddToComps/addToFavWithText";
import { useState } from "react";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";
import { useMediaQuery } from "@mui/material";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ShareDropdown } from "../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { FlagDropdown } from "../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/flagDropdown";
import { ReviewCommentBox } from "../reviewCommentBox";
import img1 from "../../../../images/qa1.jpg";
import img2 from "../../../../images/qa2.jpg";
import img3 from "../../../../images/qa3.jpg";
import { useNavigate } from "react-router-dom";
import { getFullNameByWhoAsked } from "src/services/generalService";
import moment from "moment";
import { imgBaseUrl } from "src/services/config/config";
export const QuestionsReviewItem = (props) => {
  const [showSubmit, setShowSubmit] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const matches = useMediaQuery("(max-width:575px)");

  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showFlagDropdown, setShowFlagDropdown] = useState(false);
  const navigate = useNavigate();
  return (
    props.item && (
      <div className="story-box">
        <span className="name-style name-blue">
          {" "}
          {getFullNameByWhoAsked(props.item).split(" ")[0][0].toUpperCase()}
          {getFullNameByWhoAsked(props.item).split(" ")[1][0].toUpperCase()}
        </span>
        <div className="story-box-head">
          <div className="story-box-head-left">
            <h4>{getFullNameByWhoAsked(props.item)}</h4>
          </div>
          <div className="d-flex align-items-center justify-content-center gx-3 gy-1 flex-column flex-md-row mb-2 mb-md-0">
            <span className="d-flex flex-column-reverse gy-2 flex-md-row align-items-center justify-content-center gx-3">
              {moment(props.item.treatmentDate).format("DD MMM YYYY")}
              <div className="mx-2">
                <IsLike />
              </div>
            </span>
            <span>Highly recommend the doctor</span>
          </div>
        </div>
        <div className="rating-box-wrapper pb-0 d-flex justify-content-end">
          <div className="rating-box-stories">
            <div className="rating-box-wrapper">
              <div className="rating-box-stories">
                <span className="number ms-0">
                  {props.item.treatment &&
                    props.item.treatment.data &&
                    props.item.treatment.data.attributes && (
                      <span>{props.item.treatment.data.attributes.name}</span>
                    )}
                </span>
                <span>
                  <FontAwesomeIcon icon={faEye} /> 2,300{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h5>{props.item.QATitle}</h5>
          {props.item.photosData && props.item.photosData.length > 0 && (
            <ul className="img-list-popup d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap flex-sm-nowrap">
              {props.item.photosData.map((item, i) => (
                <li>
                  <img
                    src={`${imgBaseUrl + item.img}`}
                    className="img-fluid"
                    style={{
                      width: "8rem",
                      minWidth: "8rem",
                      height: "6rem",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </li>
              ))}
            </ul>
          )}
        </div>

        <p className="p-head-size-17 mt-3">{props.item.description}</p>

        <div className="position-relative bg-white">
          <div className="comment-textarea position-relative p-0">
            <div
              className={`position-relative bg-white pb-0 ${
                showCommentBox ? "pt-4" : ""
              }`}
              style={{ zIndex: "10" }}>
              <textarea
                name=""
                id=""
                className={`rounded-2 w-100`}
                style={{
                  textAlign: "left",
                  height: showSubmit ? "8rem" : "4rem",
                  transitionDuration: "500ms",
                }}
                rows={showSubmit ? "5" : "2"}
                placeholder="Add a Comment"
                defaultValue="This is a Comment"
                onClick={() => setShowSubmit(!showSubmit)}></textarea>
            </div>
            <div
              className={`position-relative w-100 overflow-hidden`}
              style={{
                marginTop: showSubmit ? "0.75rem" : "-3.5rem",
                transitionDuration: "500ms",
                height: "2.5rem",
                zIndex: "0",
              }}>
              <button
                className={`submit-comment-btn rose-bg rounded-pill text-white fs-5 d-flex align-items-center justify-content-center position-absolute border-0 ${
                  showSubmit ? "end-0" : "end-100"
                } `}
                style={{
                  transitionDuration: "500ms",
                  width: "10rem",
                  height: "2.5rem",
                  opacity: showSubmit ? "1" : "0",
                  zIndex: "0",
                }}>
                Submit
              </button>
            </div>
          </div>
          <div
            className={`comment-area-wrap-div position-relative`}
            style={{
              transitionDuration: "500ms",
              zIndex: "20",
              top: showCommentBox ? "2rem" : "0",
            }}>
            <div
              className={`w-100 bg-white pb-5 pb-md-0 pt-2 pt-md-2 d-flex align-items-center flex-wrap justify-content-between gap-4 ${
                showCommentBox ? "mb-5" : ""
              }`}>
              <div className="d-flex align-items-center gap-3 flex-wrap flex-sm-nowrap">
                {!showCommentBox && (
                  <span
                    className="comment-onclick"
                    onClick={() => {
                      setShowSubmit(false);
                      setShowCommentBox(!showCommentBox);
                    }}>
                    <FontAwesomeIcon icon={faComment} className="rose-text" />
                    240 Comments
                  </span>
                )}
                <AddToFavWithText />
              </div>

              <div className="share-box-right share-box-flex text-left d-flex align-items-center">
                <p>2638 people find it helpful</p>
                <IsHelpfull label="Helpful" />

                <div className="mx-1 mx-sm-2 position-relative">
                  <button
                    onClick={() => setShowShareDropdown(!showShareDropdown)}
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                      background: "lightGrey",
                    }}
                    className="rounded-circle d-flex align-items-center justify-content-center border-0">
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      style={{ color: "rgb(161 161 170)" }}
                    />
                  </button>
                  {showShareDropdown && (
                    <ShareDropdown
                      position={`mt-3 ${
                        matches ? "start-0 translate-middle mt-5" : "end-0"
                      }`}
                    />
                  )}
                </div>
                <div className="position-relative">
                  <button
                    onClick={() => setShowFlagDropdown(!showFlagDropdown)}
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                      background: "lightGrey",
                    }}
                    className="rounded-circle d-flex align-items-center justify-content-center border-0">
                    <FontAwesomeIcon
                      icon={faFlag}
                      style={{ color: "rgb(161 161 170)" }}
                    />
                  </button>
                  {showFlagDropdown && <FlagDropdown position={`mt-3 end-0`} />}
                </div>
              </div>
            </div>
            {showCommentBox && (
              <ReviewCommentBox setShowCommentBox={setShowCommentBox} />
            )}
          </div>
        </div>
      </div>
    )
  );
};
