/* eslint-disable no-loop-func */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import {
  faArrowRightLong,
  faCheck,
  faClose,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Select from "react-select";
import countries from "../../../../../demoData/countries.json";
import { ProfileUploadDoc } from "./ProfileUploadDoc";
import {
  useDoctor,
  useSetDoctorProfileFormData,
} from "../../../../../Store/Doctor/doctorHooks";
import { genderOptions } from "../../../../../demoData/selectOptionsData";

export const FormStep1 = (props) => {
  const [showAddressFields, setShowAddressFields] = useState(1);
  const [finalData, setFinalData] = useState({});
  const useDoctorState = useDoctor();
  const setDoctorProfileFormData = useSetDoctorProfileFormData();
  useEffect(() => {
    if (useDoctorState.formData) {
      if (
        useDoctorState.formData.educations &&
        useDoctorState.formData.educations.length > 1
      ) {
        setShowAddressFields(useDoctorState.formData.educations.length);
      }
      setFinalData(useDoctorState.formData);
    }
  }, [useDoctorState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setDoctorProfileFormData(updatedFinalData);
  };

  const handleChangeArray = (key, val, i) => {
    let arr = finalData[key] ? finalData[key] : [];
    arr[i - 1] = val;
    const updatedFinalData = { ...finalData, [key]: arr };
    setDoctorProfileFormData(updatedFinalData);
    setFinalData(updatedFinalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.setStep(2);
    setDoctorProfileFormData(finalData);
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );

  const handleField = () => {
    let arr = [];
    for (var i = 0; i < showAddressFields; i++) {
      if (i === 0) {
        arr.push(
          <div className="multi-field-wrapper">
            <div className="multi-fields">
              <div className="multi-field">
                <input
                  className="form-control"
                  type="text"
                  required
                  value={
                    finalData.educations &&
                    finalData.educations.length > 0 &&
                    finalData.educations[i]
                  }
                  onChange={(e) =>
                    handleChangeArray("educations", e.target.value, i)
                  }
                  placeholder="Add"
                />
              </div>
            </div>
            <button
              onClick={() => setShowAddressFields(showAddressFields + 1)}
              type="button"
              className="add-field"
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        );
      } else {
        arr.push(
          <div className="col-lg-9" key={i}>
            <div className="multi-fields">
              <div className="multi-field position-relative">
                <input
                  className="form-control pe-5"
                  type="text"
                  value={finalData.educations[i]}
                  onChange={(e) =>
                    handleChangeArray("educations", e.target.value, i)
                  }
                  placeholder="Add"
                />
                <button
                  type="button"
                  style={{
                    minWidth: "2rem",
                    width: "2rem",
                    height: "2rem",
                    background: "lightGrey",
                  }}
                  onClick={() =>
                    showAddressFields > 0 &&
                    setShowAddressFields(showAddressFields - 1)
                  }
                  className="border-0 d-flex align-items-center justify-content-center text-white rounded-circle position-absolute mt-2 me-2 top-0 end-0"
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    className="ms-2"
                    style={{ fontSize: "16px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        );
      }
    }
    return arr;
  };
  return (
    <fieldset>
      <form className="form-card">
        <ProfileUploadDoc
          label="Upload Profile Photo"
          placeholder=""
          editableImg={finalData.profileImg}
          setFile={(val) => handleChange("profileImg", val)}
        />

        <div className="form-group form-group-select">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-2">
                  <label className="fieldlabels">
                    Title <span className="mandatory">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={finalData.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                    placeholder=""
                  />
                </div>
                <div className="col-lg-5">
                  <label className="fieldlabels">
                    First Name <span className="mandatory">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={finalData.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    placeholder=""
                  />
                </div>
                <div className="col-lg-5">
                  <label className="fieldlabels">
                    Last Name<span className="mandatory">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={finalData.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 m-auto">Dr. John Abraham</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <div className="form-group">
              <label className="fieldlabels">
                Gender <span>*</span>
              </label>
              <Select
                options={genderOptions}
                getOptionLabel={getOptionLabel}
                required
                value={finalData.gender}
                onChange={(val) => handleChange("gender", val)}
                isSearchable={true}
                className="my-form-select" // Add a custom class name for styling
                classNamePrefix="my-form-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="fieldlabels">Education</label>
          <div className="row">
            <div className="col-11 col-lg-9">{handleField()}</div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                MBBS, Medical College, Great Britain, UK
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="fieldlabels">Medical Registration Number</label>
          <div className="row">
            <div className="col-lg-9">
              <input
                className="form-control"
                type="text"
                required
                value={finalData.regNo}
                onChange={(e) => handleChange("regNo", e.target.value)}
                placeholder="Enter"
              />
            </div>
            <div className="col-lg-3 m-auto"></div>
          </div>
        </div>
        <ProfileUploadDoc
          label="Upload Medical Registration Documents"
          placeholder="Add document file"
          editableImg={finalData.regDocumemt}
          setFile={(val) => handleChange("regDocumemt", val)}
        />

        <div className="form-group">
          <label className="fieldlabels">Healthcare Facility Name *</label>
          <div className="row">
            <div className="col-lg-9">
              <input
                className="form-control"
                type="text"
                required
                value={finalData.healthcareFacilityName}
                onChange={(e) =>
                  handleChange("healthcareFacilityName", e.target.value)
                }
                placeholder="Add facility  by ID"
              />
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                {" "}
                HYGG (Cosmetic Medical Center)
              </span>
            </div>
          </div>
        </div>
        <div className="form-group form-group-select">
          <div className="row">
            <div className="col-lg-9">
              <div className="row gy-4">
                <div className="col-lg-3">
                  <label className="fieldlabels">Country</label>
                  <Select
                    options={countries}
                    getOptionLabel={getOptionLabel}
                    value={finalData.country}
                    onChange={(e) => handleChange("country", e)}
                    isSearchable={true}
                    className="offers-box-select" // Add a custom class name for styling
                    classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="fieldlabels">State</label>
                  <Select
                    options={countries}
                    getOptionLabel={getOptionLabel}
                    value={finalData.state}
                    onChange={(e) => handleChange("state", e)}
                    isSearchable={true}
                    className="offers-box-select" // Add a custom class name for styling
                    classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="fieldlabels">District</label>
                  <Select
                    options={countries}
                    getOptionLabel={getOptionLabel}
                    value={finalData.district}
                    onChange={(e) => handleChange("district", e)}
                    isSearchable={true}
                    className="offers-box-select" // Add a custom class name for styling
                    classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="fieldlabels">City</label>
                  <Select
                    options={countries}
                    getOptionLabel={getOptionLabel}
                    value={finalData.city}
                    onChange={(e) => handleChange("city", e)}
                    isSearchable={true}
                    className="offers-box-select" // Add a custom class name for styling
                    classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 m-auto">
              UK, Britain, Yorkshire, Whales
            </div>
          </div>
        </div>
        <button onClick={handleSubmit} className="next action-button py-1">
          Next
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </form>
    </fieldset>
  );
};
