import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";

export const BannerSection = () => {
  return (
    <section className="banner-section">
      <div className="container-fluid">
        <div className="slider-one">
          <div className="slider-one-image">
            <div className="slider-text"></div>
          </div>
        </div>
        <div className="slider-two">
          <div className="slider-two-image">
            <div className="slider-text"></div>
          </div>
        </div>
        <div className="slider-three">
          <div className="slider-three-image">
            <div className="slider-text"></div>
          </div>
        </div>
        <div className="slider-four">
          <div className="slider-four-image">
            <div className="slider-text"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
