import "../../../../css/main.css";
import "../../../../css/media.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { imgBaseUrl } from "../../../../services/config/config";
import { useBeforeAfter } from "../../../../Store/beforAfter/beforeAfterHook";

export const PhotosBeforeAfter = () => {
  const navigate = useNavigate();
  const beforeAfter = useBeforeAfter();

  return (
    <div className="before-after-section">
      <div className="row">
        {beforeAfter.list.map((data, ind) => (
          <div className="col-lg-6" key={ind + 1}>
            <div className="before-after-box">
              <div className="before-after-head">
                <h6>
                  <a href>{data.title}</a>
                </h6>
                <span>Uploaded on 12 Sep 2020</span>
              </div>
              <div className="owl-carousel owl-theme d-flex gap-3 gap-xl-5">
                <a href className="beforAfterImages">
                  <img
                    src={`${imgBaseUrl + data.beforePhoto}`}
                    className="zoom overflow-hidden rounded-3 border"
                    style={{
                      objectFit: "cover",
                      height: "300px",
                      width: "100%",
                      minWidth: "100%",
                    }}
                    alt=""
                  />
                </a>
                <a href className="ms-xxl-4 beforAfterImages">
                  <img
                    src={`${imgBaseUrl + data.afterPhoto}`}
                    className="zoom overflow-hidden rounded-3 border"
                    style={{
                      objectFit: "cover",
                      height: "300px",
                      width: "100%",
                      minWidth: "100%",
                    }}
                    alt=""
                  />
                </a>
              </div>
              <ul>
                <li>
                  Gender: <span className="rose-text">Female</span>
                </li>
                <li>
                  Age: <span className="rose-text">: 30-39</span>
                </li>
                <li>
                  Ethnicity:{" "}
                  <span className="rose-text">: African-American</span>
                </li>
                <li>
                  Height: <span className="rose-text">: 5’ 0’’ - 5’ 5’’</span>
                </li>
                <li>
                  Weight: <span className="rose-text">: 200 - 250 lbs</span>
                </li>
                <li></li>
              </ul>
              <p className="p-head-size-17">
                PROCEDURE: Blepharoplasty, Eyelid Surgery, LOWER BLEPHAROPLASTY,
                Excessive Eyelid Skin, Aging Face, Facial Aging, Droopy Eyelid,
                Loose Skin
              </p>
              <div className="get-price-estimate">
                <div className="row">
                  <div className="col-xl-6">
                    <p>
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        style={{ fontSize: "20px" }}
                        className="rose-text me-2"
                      />
                      87% Worth It{" "}
                    </p>
                    <div className="button-flex justify-content-start mt-3">
                      <a
                        href="/before-after-detail"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/before-after-detail");
                        }}
                        className="cta read--more d-flex align-items-center"
                      >
                        <span className="whitespace-nowrap">Read More</span>
                        <span className="ms-2">
                          <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-auto">
                    <a href className="primary__btn4 mt-0">
                      260 People Found This Helpful
                    </a>
                    <div className="button-flex justify-content-end mt-3">
                      <a href="book-appoinment.html" className="primary__btn3">
                        Get Price Estimate
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
