import "../css/main.css";
import "../css/media.css";
import "../css/countrySelect.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountrySelect } from "../Pages/Procedure/components/subComponents/countrySelect";

export const RequestCallback = () => {
  return (
    <section className="request-block-form common-pad">
      <div className="container-fluid">
        <div className="request-call-back">
          <div className="request-call-back-header">
            <div className="row">
              <div className="col-lg-8">
                <h4>Request a Callback</h4>
              </div>
              <div className="col-lg-4">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  ullamcorper. Sed pretium ipsum elit, sit amet auctor massa
                  maximus eget.
                </p>
              </div>
            </div>
          </div>

          <div className="request-form">
            <form action="">
              <div className="form-flex">
                <div className="form-group">
                  <label for="">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter"
                  />
                </div>
                <div className="form-group">
                  <label for="">E-mail</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter"
                  />
                </div>
                <div className="form-group mobile-menu-block">
                  <label for="">Mobile</label>
                  <PhoneInput
                    country={"us"}
                    className="input-container shadow-none border-none w-full"
                    placeholder="e.g. +1 702 123 4567"
                    inputClass="phone-input"
                    buttonClass="dropdown-btn"
                    dropdownClass="custom-dropdown"
                    inputProps={{
                      name: "phone",
                    }}
                  />
                </div>
                <div className="form-group mobile-menu-block">
                  <label for="">Treatment Location</label>
                  <CountrySelect />
                </div>
              </div>
              <div className="mt-4 px-0">
                <div className="d-flex flex-column flex-xl-row align-items-xl-center gap-3 gap-xl-4 justfy-content-xl-between w-100 px-0">
                  <div className="mb-3 form-group checkbox-customise">
                    <input type="checkbox" id="html" />
                    <label for="html" className="">
                      By enabling the send button, I agree to the Guidelines and
                      Policies of Mivana
                    </label>
                  </div>
                  <div className="request-callback-btn d-flex justify-content-end">
                  <input type="submit" className="submit-btn" value="Send" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
