import { useState } from "react";
import { CreateQAForm1 } from "./createQAForm1";
import { CreateQAForm2 } from "./createQAForm2";

export const CreateQAMain = () => {
  const [showForm, setShowForm] = useState(1);
  return (
    <div className="tab_container overflow-hidden myreviews-section px-0 py-0">
      <div id="tab1" className="tab_content px-0">
        <div className="card formSlider">
          <div id="msform" className="px-0">
            <ul id="progressbar">
              <li className={`active`} id="account"></li>
              <li
                className={`${showForm === 2 ? "active" : ""}`}
                id="personal"
              ></li>
            </ul>
            {showForm === 1 && <CreateQAForm1 setShowForm={setShowForm} />}
            {showForm === 2 && <CreateQAForm2 setShowForm={setShowForm} handleClose={()=>{}} />}
          </div>
        </div>
      </div>
    </div>
  );
};
