import img1 from "../images/badge_1.svg";
import img2 from "../images/badge_2.svg";

export const badgesData = [
  {
    title: "User level 1",
    img: img1,
  },
  {
    title: "User level 2",
    img: img2,
  },
  {
    title: "User level 3",
    img: img2,
  },
  {
    title: "User level 4",
    img: img2,
  },
  {
    title: "User level 5",
    img: img2,
  },
  {
    title: "User level 6",
    img: img2,
  },
];
