import { useNavigate } from "react-router-dom";
import "../../../css/main.css";
import docImg from "../../../images/adam-eve.png";

const linksData = [
  {
    name: "My Profile",
    activePath: "/health-care/profile",
    path: "/health-care/profile",
  },
  {
    name: "Doctors List",
    activePath: "/health-care/doctors-list",
    path: "/health-care/doctors-list",
  },
  {
    name: "Wallet",
    activePath: "/health-care/wallet",
    path: "/health-care/wallet-status",
  },
  {
    name: "Add Doctor",
    activePath: "/health-care/add-doctor",
    path: "/health-care/add-doctor",
  },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="col-lg-3 d-lg-block d-none">
      <div className="sidebar h-100">
        <div className="doctor-name">
          <span className="doctor-ico">
            <img src={docImg} className="img-fluid" alt="" />
          </span>
          <h6>
            Adam & Eve Healthcare
            <br />
            <span style={{ fontSize: "12px" }} className="text-secondary">
              Facility ID: QR579hgus
            </span>
          </h6>
        </div>

        <ul>
          {linksData.map((data, ind) => (
            <li
              key={ind + 1}
              className={`${isActive(data.activePath) ? "active" : ""}`}
            >
              <a
                href={data.path}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(data.path);
                }}
              >
                {data.name}
              </a>
            </li>
          ))}
          <li>
            <a type="button" className="sidebar-logout" href>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
