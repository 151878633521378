import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proceureOptions } from "../../../../demoData/selectOptionsData";

export const AlphabetFilter = (props) => {
  const getOptionLabel = (option) => (
    <div className="flex items-center gap-x-4 justify-between">
      <span className="text-sm xl:text-base">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="tab-head-block pt-10">
      <div className="row justify-content-center">
        <div className="col-lg-9 filter-list-by-name">
          <ul className="alphabet">
            <li className="all" onClick={() => props.setFilterTab("All")}>
              <a href>All</a>{" "}
            </li>
            <li onClick={() => props.setFilterTab("A")}>
              <a href>A</a>
            </li>
            <li onClick={() => props.setFilterTab("B")}>
              <a href>B</a>
            </li>
            <li onClick={() => props.setFilterTab("C")}>
              <a href>C</a>
            </li>
            <li onClick={() => props.setFilterTab("D")}>
              <a href>D</a>
            </li>
            <li onClick={() => props.setFilterTab("E")}>
              <a href>E</a>
            </li>
            <li onClick={() => props.setFilterTab("F")}>
              <a href>F</a>
            </li>
            <li onClick={() => props.setFilterTab("S")}>
              <a href>S</a>
            </li>
            <li onClick={() => props.setFilterTab("T")}>
              <a href>T</a>
            </li>
          </ul>
        </div>
        <div className="col-lg-3">
          <Select
            options={proceureOptions}
            getOptionLabel={getOptionLabel}
            defaultValue={proceureOptions[0]}
            isSearchable={true}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
    </div>
  );
};
