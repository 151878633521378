import "../../../../css/main.css";
import { useEffect, useState } from "react";
import { NoDataComp } from "../../../../shared/emptyComponents/noDataComp";
import { UserReviewItem } from "./userReviewItem";

export const UserReviewsList = (props) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    if (props.data) {
      setReviews(props.data);
    }
  }, [props.data]);
  return (
    <div className="tab_container py-0 overflow-hidden">
      {reviews && reviews.length > 0 ? (
        <div id="tab1" className="tab_content py-0">
          <div className="stories-list py-0">
            {reviews.map((data, ind) => (
              <UserReviewItem data={data} ind={ind} handleUpdate={props.handleUpdate} />
            ))}
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
