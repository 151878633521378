import { useNavigate } from "react-router-dom";
import { EmptyComp } from "../emptyComponents/emptyComp";
import notiImg from "../../images/notification.png";

export const NoAppointments = (props) => {
  const navigate = useNavigate();
  return (
    <section className="doctor-detail-stories-tab">
      <div className="container-fluid p-0">
        <div className="no-content pt-5 mt-5 pe-0 pe-lg-5">
          <EmptyComp
            image={notiImg}
            title={props.title}
            showButton={true}
            desc={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse",
              <br />,
              "feugiat mi venenatis, pulvinar",
            ]}
            buttonText="Find A Specialist"
            onClickButton={(e) => {
              e.preventDefault();
              navigate("/find-doctor");
            }}
          />
        </div>
      </div>
    </section>
  );
};
