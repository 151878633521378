import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faImage,
  faStar,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import "../../../css/main.css";
import "../../../css/media.css";
import { FacesCarousel } from "./subComponents/facesCarousel";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { imgBaseUrl } from "../../../services/config/config";
import { NoDataComp } from "../../../shared/emptyComponents/noDataComp";
import moment from "moment";

export const FacePhotosList = (props) => {
  const matches = useMediaQuery("(max-width:1570px)");
  const navigate = useNavigate();

  return (
    <div className="before-after-phases">
      {props.items && props.items.length > 0 ? (
        <div className="row">
          {props.items.map((data, ind) => (
            <div className="col-md-6" key={ind + 1}>
              <div className="before-after-img-box">
                <div className="before-after-phase-head">
                  <h4>{data.title}</h4>
                  <h6>Uploaded on {moment(data.createdAt).format("DD MMM YYYY")}</h6>
                </div>

                <div className="d-none d-sm-block">
                  <div className="before-after-flex common-slider zoom-slider">
                    <div className="owl-carousel owl-theme d-flex gap-3 gap-xl-5">
                      <a href className="beforAfterImages">
                        <img
                          src={`${imgBaseUrl + data.beforePhoto}`}
                          className="zoom border overflow-hidden"
                          style={{
                            borderRadius: "14px",
                            objectFit: "cover",
                            height: "320px",
                            width: "320px",
                            minWidth: "320px",
                          }}
                          alt=""
                        />
                      </a>
                      <a href className="ms-xxl-4 beforAfterImages">
                        <img
                          src={`${imgBaseUrl + data.afterPhoto}`}
                          className="zoom border overflow-hidden"
                          style={{
                            borderRadius: "14px",
                            objectFit: "cover",
                            height: "320px",
                            width: "320px",
                            minWidth: "320px",
                          }}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <FacesCarousel
                  img1={`${imgBaseUrl + data.beforePhoto}`}
                  img2={`${imgBaseUrl + data.afterPhoto}`}
                />
                <div
                  className={`mb-3 mt-4 d-flex flex-column flex-sm-row flex-wrap align-items-sm-center gy-2 ${
                    props.activeTab === "Reviews"
                      ? "gap-5"
                      : matches
                      ? "gap-3 gap-lg-4 gap-lg-5"
                      : "gap-5"
                  }`}
                >
                  <div
                    className={`${
                      props.activeTab === "Photos" ? "me-xl-5 pe-xxl-5" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className="me-1 redSvg"
                    />
                    <span
                      style={{ color: "black" }}
                      className="fs-6 fs-sm-5 fs-lg-4"
                    >
                      {" "}
                      87% Worth It
                    </span>
                  </div>
                  {props.activeTab !== "Q&A" && (
                    <div
                      className={`${
                        props.activeTab === "Photos" ? "ms-lg-5 ps-xxl-5" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faImage} className="me-1 redSvg" />{" "}
                      <span
                        style={{ color: "black" }}
                        className="fs-6 fs-sm-5 fs-lg-4"
                      >
                        {" "}
                        26,895 Photos
                      </span>
                    </div>
                  )}
                  {props.activeTab === "Reviews" && (
                    <div>
                      <FontAwesomeIcon icon={faStar} className="me-1 redSvg" />
                      <span
                        style={{ color: "black" }}
                        className="fs-6 fs-sm-5 fs-lg-4"
                      >
                        {" "}
                        12,895 Reviews{" "}
                      </span>
                    </div>
                  )}
                  {props.activeTab === "Q&A" && (
                    <div
                      className="text-white rounded-2 px-2 py-1"
                      style={{ background: "#e78a82", width: "max-content" }}
                    >
                      <FontAwesomeIcon icon={faCircleQuestion} /> 10,606
                      Questions
                    </div>
                  )}
                </div>
                <p className="p-head-size-17">{data.description}</p>
                <div className="button-flex justify-content-center">
                  <a
                    href={props.detailPath}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(props.detailPath);
                    }}
                    className="primary__btn3"
                  >
                    View More
                  </a>
                </div>
              </div>
            </div>
          ))}
          {props.showSeeAllButton && (
            <div className="button-flex justify-content-center">
              <a
                href
                className="primary__btn5"
                onClick={() => props.setSeeAll(true)}
              >
                See More Before & After Photos
              </a>
            </div>
          )}
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
