import doc1 from "../images/arab F-3.jpg";
import doc2 from "../images/arab male doctor -1.jpg";
import doc3 from "../images/doctor3.jpg";
import doc4 from "../images/doctor4.jpg";
import doc5 from "../images/doctor5.jpg";
import doc6 from "../images/doctor6.jpg";
import doc7 from "../images/doctor7.jpg";
import doc8 from '../images/dashboard/doc1.jpg'

export const doctorsData = [
  {
    name: "Dr. Lauren Clinton",
    img: doc1,
  },
  {
    name: "Dr. Ihab Bin Ramadan",
    img: doc2,
  },
  {
    name: "Dr. Nabeela Mahmoud",
    img: doc3,
  },
  {
    name: "Dr. Sivam Natarajan",
    img: doc4,
  },
  {
    name: "Dr. Salma Jassim Mohammad",
    img: doc5,
  },
  {
    name: "Dr. Osama Younes",
    img: doc6,
  },
  {
    name: "Dr.Yousra Ghada Jaber",
    img: doc7,
  },
];



export const doctorsData2 = [
  {
    name: "Liya John",
    img: doc8,
  },
  {
    name: "Dr. Ihab Bin Ramadan",
    img: doc8,
  },
  {
    name: "Dr. Nabeela Mahmoud",
    img: doc8,
  },
  {
    name: "Dr. Sivam Natarajan",
    img: doc8,
  },
  {
    name: "Dr. Salma Jassim Mohammad",
    img: doc8,
  },
  {
    name: "Dr. Osama Younes",
    img: doc8,
  },
  {
    name: "Dr.Yousra Ghada Jaber",
    img: doc8,
  },
  {
    name: "Liya John",
    img: doc8,
  },
  {
    name: "Dr. Ihab Bin Ramadan",
    img: doc8,
  },
  {
    name: "Dr. Nabeela Mahmoud",
    img: doc8,
  },
  {
    name: "Dr. Sivam Natarajan",
    img: doc8,
  },
  {
    name: "Dr. Salma Jassim Mohammad",
    img: doc8,
  },
  {
    name: "Dr. Osama Younes",
    img: doc8,
  },
  {
    name: "Dr.Yousra Ghada Jaber",
    img: doc8,
  },
];
