import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { useNavigate } from "react-router-dom";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FaceQuestionAnswersSelectionData } from "../../../../demoData/faceQuestionAnswersSelectionData";

export const FaceQuestionAnswersPhotosList = (props) => {
  const navigate = useNavigate();
  return (
    <div className="before-after-phases">
      <div className="row">
        {FaceQuestionAnswersSelectionData.map((data, ind) => (
          <div className="col-xxl-4 col-md-6" key={ind + 1}>
            <div className="before-after-img-box">
              <div className="before-after-phase-head">
                <a href="Face_Question-Answer_ Details.html">
                  <h5>{data.title}</h5>
                </a>
              </div>
              <h6 className="mb-2 fs-6">Uploaded on 12 Sep 2020</h6>
              <div className="row">
                <div className="col-lg-6">
                  <span>
                    <img src={data.img} className="img-fluid" alt="" />
                  </span>
                </div>
                <div className="col-lg-6">
                  <div className="wrapper-qa-div">
                    <p className="p-head-size-16 text-container">
                      I’m a 25 year old South Asian female, 5’1 and weigh
                      approximately 58kg. I believe I have axillary breast
                      tissue under both armpits (one more than the other) and
                      would like to find out how to address this issue. I think
                      part of the.. problem may also be the looser skin under
                      one of the arms. It’s causing cosmetic issues and I’m
                      worried about future hormonal problems.
                    </p>
                  </div>
                </div>
              </div>

              <div className="detail-before-after d-flex align-items-start gap-4 mt-1">
                <span>
                  <FontAwesomeIcon icon={faThumbsUp} /> 2 person found this
                  helpful{" "}
                </span>
                <span>
                  <FontAwesomeIcon icon={faFileCircleExclamation} /> 2 Expert
                  Answers
                </span>
              </div>
              <div className="button-flex justify-content-end">
                <a
                  href={props.detailPath}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(props.detailPath);
                  }}
                  className="cta read--more flex items-center"
                >
                  <span className="whitespace-nowrap">Read More</span>
                  <span className="ml-6">
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                      <path d="M1,5 L11,5"></path>
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        ))}
        <div className="button-flex justify-content-center">
          <a href className="primary__btn5">
            See More Before & After Photos
          </a>
        </div>
      </div>
    </div>
  );
};
