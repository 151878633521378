import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import { useEffect } from "react";
import blog1 from "../../../images/blog1.jpg";
import blog2 from "../../../images/blog2.jpg";
import blog3 from "../../../images/blog3.jpg";
import nonSurgical from "../../../images/non-surgical.jpg";
import { useNavigate } from "react-router-dom";

export const BlogSection = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div className="blog-section">
      <div className="container-fluid">
        <div className="row resp-none-box">
          <div className="col-lg-6">
            <div className="before-after-box1">
              <h2>
                BLOG
                <span className="title-head">
                  <span className="type-writer">BE INSPIRED</span>
                </span>
              </h2>
              <p className="p-head-size">
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.ut ullamcorper.ut
                ullamcorper.ut ullamcorper.ut ullamcorper.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="book-consultation">
              <svg id="rectangle">
                <rect id="stroke" rx="3" ry="5" />
              </svg>
              <a
                href="/book-appoinment"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/book-appointment");
                }}
                className="book-appoinment-btn"
              >
                Book a Consultation
              </a>
              <p>
                Lorem ipsum dolor sit amet, <br />
                consectetur
              </p>
            </div>
          </div>
        </div>
        <div className="blog-row">
          <div className="row">
            <div className="col-lg-5">
              <span>
                <img src={nonSurgical} className="img-fluid" alt="" />
              </span>
              <div className="blog-large">
                <div className="date-blog">
                  Health / <span> Apr 21, 2022</span>
                </div>
                <h4>Non-Surgery Skin Renewal Procedures To Try</h4>
                <p className="p-head-size-17">
                  Lorem ipsum dolor sit amet, consectetur adipiscing eger
                  vulputate vehicula urna ut ullamcorper.Lorem ipsum
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="blog-list">
                <span>
                  <img src={blog1} className="img-fluid" alt="" />
                </span>
                <div className="blog-list-content">
                  <div className="date-blog">
                    Health / <span>Apr 21, 2022</span>
                  </div>
                  <h5>Non-Surgery Skin Renewal Procedures To Try</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.Lorem ipsum
                  </p>
                </div>
              </div>
              <div className="blog-list">
                <span>
                  <img src={blog2} className="img-fluid" alt="" />
                </span>
                <div className="blog-list-content">
                  <div className="date-blog">
                    Health / <span>Apr 21, 2022</span>
                  </div>
                  <h5>Non-Surgery Skin Renewal Procedures To Try</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.Lorem ipsum
                  </p>
                </div>
              </div>
              <div className="blog-list">
                <span>
                  <img src={blog3} className="img-fluid" alt="" />
                </span>
                <div className="blog-list-content">
                  <div className="date-blog">
                    Health / <span>Apr 21, 2022</span>
                  </div>
                  <h5>Non-Surgery Skin Renewal Procedures To Try</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.Lorem ipsum
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row resp-block-box">
          <div className="col-lg-6">
            <div className="before-after-box1">
              <h3>
                BLOG
                <span className="title-head">
                  <span className="type-writer">BE INSPIRED</span>
                </span>
              </h3>
              <p className="p-head-size-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.ut ullamcorper.ut
                ullamcorper.ut ullamcorper.ut ullamcorper.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="book-consultation">
              <svg id="rectangle">
                <rect id="stroke" rx="3" ry="5" />
              </svg>
              <a
                href="/book-appoinment"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/book-appointment");
                }}
                className="book-appoinment-btn"
              >
                Book a Consultation
              </a>
              <p className="p-head-size-17">
                Lorem ipsum dolor sit amet, <br />
                consectetur
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
