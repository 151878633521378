import { ReviewsHeader } from "./components/reviewsHeader";
import { NewReviewsList } from "./components/newReviewsComponents/newReviewsList";
import { TabsGroup } from "../../../shared/tabsGroup";
import { userReviewsTabs } from "../../../demoData/tabsData";
import { useEffect } from "react";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import {
  useGetReviewsByPatientAndStatus,
  useReview,
} from "../../../Store/review/reviewHook";

export const CommentsReviews = () => {
  const reviewState = useReview();
  const getReviewsByPatientAndStatus = useGetReviewsByPatientAndStatus();
  useEffect(() => {
    getReviewsByPatientAndStatus("Comment");
  }, [getReviewsByPatientAndStatus]);

  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <ReviewsHeader title="My Comment Reviews (19)" />
        <TabsGroup tabs={userReviewsTabs} />
        <NewReviewsList
          showLastCommrntBox={true}
          commentsReview={true}
          data={reviewState.list}
        />
      </div>
    </section>
  );
};
