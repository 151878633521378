import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import {
  faComment,
  faEye,
  faFlag,
  faStarHalfAlt,
} from "@fortawesome/free-regular-svg-icons";
import { IsLike } from "../../../../shared/AddToComps/isLike";
import { AddToFavWithText } from "../../../../shared/AddToComps/addToFavWithText";
import { useState } from "react";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";
import { useMediaQuery } from "@mui/material";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ShareDropdown } from "../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { FlagDropdown } from "../../../beforAfter/face-liposuction-reviews-detail/components/subcomponents/flagDropdown";
import { ReviewCommentBox } from "../reviewCommentBox";

export const ReviewItem = (props) => {
  const [showSubmit, setShowSubmit] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const matches = useMediaQuery("(max-width:575px)");

  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showFlagDropdown, setShowFlagDropdown] = useState(false);
  return (
    <div className="story-box">
      <span className="name-style name-blue">FF</span>
      <div className="story-box-head ms-md-auto">
        <div className="story-box-head-left">
          <h4>Marilyn Matthews</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3 flex-column flex-md-row mb-2 mb-md-0">
          <span className="d-flex flex-column-reverse gap-2 flex-md-row align-items-center justify-content-center gap-3">
            12 Sep 2022 | 24 months ago
            <div className="">
              <IsLike />
            </div>
          </span>
          <span>Highly recommend the doctor</span>
        </div>
      </div>
      <div className="rating-box-wrapper pb-4 pb-md-0">
        <div className="rating-box-stories">
          <div className="rating justify-content-start">
            <FontAwesomeIcon icon={props.solidStar} />
            <FontAwesomeIcon icon={props.solidStar} />
            <FontAwesomeIcon icon={props.solidStar} />
            <FontAwesomeIcon icon={faStarHalfAlt} />
            <FontAwesomeIcon icon={faStarHalfAlt} />
          </div>
          <span className="number">(1,378)</span>
          <span>
            <FontAwesomeIcon icon={faEye} /> 2,300{" "}
          </span>
        </div>
        <div className="rating-box-wrap-right d-flex align-items-center gap-3">
          <span>Chest Reduction</span>
          <AddToFavWithText />
        </div>
      </div>

      <h5 className="fw-bold mt-2">
        Tuberous breast correction with areola reduction & augmentation
      </h5>
      <p className="p-head-size-17">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        vulputate vehicula urna ut Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. .Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Integer vulputate vehicula urna ut Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Integer vulputate vehicula urna ut Lorem ipsum dolor
        sit amet, consectetur adipiscing elit. .Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Integer vulputate vehicula urna ut Lorem
        ipsum dolor sit amet, consectetur adipiscing elit.{" "}
      </p>
      <div className="story-types d-flex align-items-start gap-2">
        <h6 className="mt-0 whitespace-nowrap">Satisfied with:</h6>
        <div className="d-flex align-items-center justify-content-center justify-content-lg-start flex-wrap">
          <li
            className="fs-6 text-dark rounded-pill px-3 py-1 d-flex align-items-center justify-content-center"
            style={{ background: "lightGrey" }}
          >
            Friendlines
          </li>
          <li
            className="fs-6 text-dark rounded-pill px-3 py-1 d-flex align-items-center justify-content-center"
            style={{ background: "lightGrey" }}
          >
            Explanation of health issue
          </li>
          <li
            className="fs-6 text-dark rounded-pill px-3 py-1 d-flex align-items-center justify-content-center"
            style={{ background: "lightGrey" }}
          >
            Treatment Satisfaction
          </li>
          <li
            className="fs-6 text-dark rounded-pill px-3 py-1 d-flex align-items-center justify-content-center"
            style={{ background: "lightGrey" }}
          >
            Value for money
          </li>
          <li
            className="fs-6 text-dark rounded-pill px-3 py-1 d-flex align-items-center justify-content-center"
            style={{ background: "lightGrey" }}
          >
            Less wait time
          </li>
        </div>
      </div>

      <div className="position-relative bg-white">
        <div className="comment-textarea position-relative px-1">
          <div
            className={`position-relative bg-white pb-0 ${
              showCommentBox ? "pt-4" : ""
            }`}
            style={{ zIndex: "10" }}
          >
            <textarea
              name=""
              id=""
              className={`form-control`}
              style={{
                height: showSubmit ? "8rem" : "4rem",
                transitionDuration: "500ms",
              }}
              rows={showSubmit ? "5" : "2"}
              placeholder="Add a Comment"
              defaultValue="This is a Comment"
              onClick={() => setShowSubmit(!showSubmit)}
            ></textarea>
          </div>
          <div
            className={`position-relative w-100 overflow-hidden`}
            style={{
              marginTop: showSubmit ? "0.75rem" : "-3.5rem",
              transitionDuration: "500ms",
              height: "2.5rem",
              zIndex: "0",
            }}
          >
            <button
              className={`submit-comment-btn rose-bg rounded-pill text-white fs-5 d-flex align-items-center justify-content-center position-absolute border-0 ${
                showSubmit ? "end-0" : "end-100"
              } `}
              style={{
                transitionDuration: "500ms",
                width: "10rem",
                height: "2.5rem",
                opacity: showSubmit ? "1" : "0",
                zIndex: "0",
              }}
            >
              Submit
            </button>
          </div>
        </div>
        <div
          className={`comment-area-wrap-div position-relative`}
          style={{ transitionDuration: "500ms", zIndex: "20", top: showCommentBox? "2rem" : "0" }}
        >
          <div className="w-100 bg-white pb-5 pb-md-0 pt-2 pt-md-2 md:h-24 d-flex align-items-center flex-wrap justify-content-between gap-4">
            <div className="d-flex align-items-center gap-3 flex-wrap flex-sm-nowrap">
              <div className="d-flex align-items-center gx-2">
                <IsLike smileIcon={true} />
                <span>340 likes</span>
              </div>

              {!showCommentBox && (
                <span
                  className="comment-onclick"
                  onClick={() => {
                    setShowSubmit(false);
                    setShowCommentBox(!showCommentBox);
                  }}
                >
                  <FontAwesomeIcon icon={faComment} className="rose-text" />
                  240 Comments
                </span>
              )}
            </div>

            <div className="share-box-right share-box-flex text-left d-flex align-items-center">
              <p>2638 people find it helpful</p>
              <IsHelpfull label="Helpful" />

              <div className="mx-1 mx-sm-2 position-relative">
                <button
                  onClick={() => setShowShareDropdown(!showShareDropdown)}
                  style={{width: "2.5rem", height:'2.5rem', background: "lightGrey"}}
                  className="rounded-circle d-flex align-items-center justify-content-center border-0"
                >
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{ color: "rgb(161 161 170)" }}
                  />
                </button>
                {showShareDropdown && (
                  <ShareDropdown
                    position={`mt-3 ${matches ? "start-0 translate-middle mt-5" : "end-0"}`}
                  />
                )}
              </div>
              <div className="position-relative">
                <button
                  onClick={() => setShowFlagDropdown(!showFlagDropdown)}
                  style={{width: "2.5rem", height:'2.5rem', background: "lightGrey"}}
                  className="rounded-circle d-flex align-items-center justify-content-center border-0"
                >
                  <FontAwesomeIcon
                    icon={faFlag}
                    style={{ color: "rgb(161 161 170)" }}
                  />
                </button>
                {showFlagDropdown && <FlagDropdown position={`mt-3 end-0`} />}
              </div>
            </div>
          </div>
          {showCommentBox && (
            <ReviewCommentBox setShowCommentBox={setShowCommentBox} />
          )}
        </div>
      </div>
    </div>
  );
};
