import { UserProfileForm } from "./components/userProfileComponents/userProfileForm";
import { useEffect } from "react";
import { currentPatientId } from "../../Store/selectors/UserSelectors";
import { Loading } from "../../shared/loadings/Loading";
import { useGetPatientById } from "../../Store/Patient/patientHook";

export const UserProfile = () => {
  const getPatientById = useGetPatientById();
  useEffect(() => {
    if (currentPatientId()) getPatientById(currentPatientId());
  }, [getPatientById]);
  return (
    <>
      <Loading duration={500} />
      <UserProfileForm />
    </>
  );
};
