import { faCheck, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imgCategoryOptions, imgViewOptions, sampleOptions } from "../../../../../../demoData/selectOptionsData"
import Select from "react-select";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { imgBaseUrl } from "../../../../../../services/config/config";
import { useReview, useSetReviewFormData } from "../../../../../../Store/review/reviewHook";

export const UploadPhotosFormComp = (props) => {
  const [photosData, settPhotosData] = useState([]) 
  const reviewState = useReview();
  
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );

useEffect(() => {
    if(photosData && photosData.length > 0 ){
        props.saveData({...props.finalData, photosData: photosData})
    }
}, [photosData]);
useEffect(() => {
    if (props.myPhotos && props.myPhotos.length > 0) {
        settPhotosData(props.myPhotos);
    }
  }, [props.myPhotos]);


const handleUpdate = (index, newValue) => {
    const updatedData = [...photosData];
    updatedData[index] = { ...updatedData[index], ...newValue };
    settPhotosData(updatedData);
  };

  const handleUploadPhoto = (e) => {
    let imgData = e.target.files[0];
    if (imgData && imgData.name) {
      let formData = new FormData();
      formData.append("files", imgData, imgData.name);
      axiosInstance
        .post("upload", formData)
    
        .then((response) => {
            settPhotosData([...photosData, {img: response.data[0].url}]);
          toast.success("File uploaded successfully!");
        })
        .catch(() => {
          toast.error("Something is going wrong!");
        });
    }
  };
    return (
        <div className="100">
            <div className="upload-img-flex flex-wrap flex-sm-nowrap">
                  <label className="fieldlabels">
                    <span className="questions-num">3</span>Upload photos or
                    videos
                  </label>
                
                </div>
            {photosData && photosData.length > 0 && photosData.map((d, i) => (

            <div key={i+ 1} className="row upload-img-row gy-3">
                  <div className="col-lg-2">
                    <div className="uploaded-img">
                      <span>
                        <img src={`${imgBaseUrl + d.img}`} className="img-fluid rounded-2" style={{width: "8rem", minWidth: "8rem", height: "6rem", objectFit: "cover"}} alt="myImg" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="form- mb-2">
                      <Select
                        options={imgCategoryOptions}
                        getOptionLabel={getOptionLabel}
                        onChange={(val) => handleUpdate(i, {imgCategory: val})}
                        value={d.imgCategory}
                        isSearchable={true}
                        className="my-form-select" // Add a custom class name for styling
                        classNamePrefix="my-form-select" // Add a custom class prefix for styling
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        options={imgViewOptions}
                        getOptionLabel={getOptionLabel}
                        onChange={(val) => handleUpdate(i, {imgView: val})}
                        value={d.imgView}
                        isSearchable={true}
                        className="my-form-select" // Add a custom class name for styling
                        classNamePrefix="my-form-select" // Add a custom class prefix for styling
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <textarea
                      name=""
                      value={d.desc}
                      onChange={(e) => handleUpdate(i, {desc: e.target.value})}
                      className="form-control"
                      id=""
                      rows="3"
                    ></textarea>
                  </div>
            </div>))}
                <div className="drag-drop-div mt-3">
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <h6>Drag drop your files here</h6>
                  <div
                    className="file-upload-wrapper select-file"
                    data-text="Select files"
                  >
                    <input
                    onChange={handleUploadPhoto}
                      name="file-upload-field form-control"
                      type="file"
                      className="file-upload-field"
                      value=""
                    />
                  </div>
                </div>
            </div>
    )
}