import { useEffect } from "react";
import { OffersHeader } from "./components/offerHeader";
import { OffersTabs } from "./components/offersTabs";
import { OffersList } from "./components/underReviewOffersComponents/offersList";
import { currentDoctorId } from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import {
  useGetOffersByDoctorAndStatus,
  useOfferState,
} from "../../../Store/offer/offerHooks";

export const ExpiredOffers = () => {
  const offerState = useOfferState();
  const getOffers = useGetOffersByDoctorAndStatus();
  useEffect(() => {
    getOffers(currentDoctorId(), "Expired");
  }, [getOffers]);

  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <OffersHeader title="Upload Offers" />
        <OffersTabs />
        <OffersList data={offerState.listBYStatus} />
      </div>
    </section>
  );
};
