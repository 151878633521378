import { ModalPopup } from "../../../shared/Modals/ModalPopup";
import "../../../css/main.css";
import "../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useMediaQuery } from "@mui/material";

const options1 = [
  { value: "Mother", label: "Mother" },
  { value: "Father", label: "Father" },
  { value: "Wife", label: "Wife" },
  { value: "Husband", label: "Husband" },
  { value: "Sister", label: "Sister" },
  { value: "Brother", label: "Brother" },
  { value: "Grandmother", label: "Grandmother" },
  { value: "Grandfather", label: "Grandfather" },
  { value: "Cousin", label: "Cousin" },
  { value: "Friend", label: "Friend" },
  { value: "Other", label: "Other" },
];

export const SomeoneModal = (props) => {
  const matches = useMediaQuery("(max-width:480px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "30rem"}
      closeOnDocumentClick={true}
    >
      <div className="modal-content">
        <div className="modal-header" style={{ backgroundColor: "#e78a82" }}>
          <h5
            className="modal-title fs-6 fs-sm-5 text-white"
            id="someOneModalLabel"
          >
            Ask a question for someone else
          </h5>
          <button
            onClick={handleClose}
            style={{ width: "2rem", height: "2rem" }}
            className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center border-0"
          >
            <FontAwesomeIcon icon={faClose} className="ms-2" />
          </button>
        </div>
        <div className="modal-body bg-transparent">
          <form action="">
            <div className="form-group">
              <input
                type="text"
                className="px-3 w-100 mb-2"
                placeholder="What is their Name?"
                style={{height: "50px", border: "1px solid grey", borderRadius: "10px"}}
              />
            </div>
            <Select
              options={options1}
              getOptionLabel={getOptionLabel}
              defaultValue={options1[0]}
              isSearchable={true}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
            <button
              style={{ background: "black", width: "6rem" }}
              className="text-white border-1 border-black py-1 rounded-pill float-right mt-3"
            >
              Add
            </button>
          </form>
        </div>
      </div>
    </ModalPopup>
  );
};
