import "../../../../../css/main.css";

export const TicketStatusContent = () => {
  return (
    <div className="tab_container articles-container overflow-hidden">
      <div id="tab1" className="tab_content">
        <div className="row">
          <div className="col-lg-6">
            <h5>Lorem Lorem Lorem Lorem Lorem</h5>
            <ul className="ul-list-2">
              <li>
                Total Raised Tickets <span>25</span>
              </li>
              <li>
                Total Closed Tickets <span>25</span>
              </li>
              <li>
                Total Open Tickets <span>25</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
