// For Public

import axios from "axios";
import { baseUrl } from "../../services/config/config";
import { getAPIResponseData } from "../../services/generalService";
import { setLoading } from "../loading/loadingAction";
import { toast } from "react-toastify";
import { ALL_PROCEDURES, PROCEDURES_BY_BODY_PART, PROCEDURES_BY_DOCTOR } from "./actionTypes";

export const setProceduresByBodyPart = (data) => {
  return {
    type: PROCEDURES_BY_BODY_PART,
    payload: data,
  };
};


export const getProceduresByBodyPart = (bodyPart) => {
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/procedures?filters[body_part][name][$eq]=${bodyPart}&populate=*`
      )
      .then((response) => {
        dispatch(setProceduresByBodyPart(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

export const setAllProceduresData = (data) => {
  return {
    type: ALL_PROCEDURES,
    payload: data,
  };
};


export const getAllProcedures = () => {
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/procedures`
      )
      .then((response) => {
        dispatch(setAllProceduresData(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};

export const setProceduresDataByDoctor = (data) => {
  return {
    type: PROCEDURES_BY_DOCTOR,
    payload: data,
  };
};


export const getProceduresByDoctor = (id) => {
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/procedures?filters[doctor][id][$eq]=${id}`
      )
      .then((response) => {
        dispatch(setProceduresDataByDoctor(getAPIResponseData(response.data.data)));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error("Something went wrong!");
      });
  };
};
