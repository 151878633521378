import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import Select from "react-select";
import { faCheck, faChevronDown, faSliders, faStar } from "@fortawesome/free-solid-svg-icons";
import { RatingDropdown } from "../../../shared/dropdowns/ratingDropdown";
import { useState } from "react";
import { OffersFilterModal } from "./offersFilterModal";

const options1 = [
  { value: "Filter by Procedure", label: "Filter by Procedure" },
];


export const OffersFiltrBox = () => {
  const [showModal, setShowModal] = useState(false);
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="tab-head-block">
      <div className="button-flex justify-content-end fixed-box">
        <button
          type="button"
          className="btn btn-primary selectPopup-btn"
          onClick={() => setShowModal(true)}
        >
         <FontAwesomeIcon icon={faSliders} />
        </button>
      </div>
      <OffersFilterModal showModal={showModal} setShowModal={setShowModal} />
      <div className="d-flex align-items-center gap-4 resp-hidden-box w-100">
        <div className="w-100">
          <Select
            options={options1}
            getOptionLabel={getOptionLabel}
            defaultValue={options1[0]}
            isSearchable={true}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="w-100 position-relative">
          <button
            className="offers-box-select__control d-flex align-items-center gap-5 justify-content-between"
            type="button"
            onClick={() => setShowRatingDropdown(!showRatingDropdown)}
          >
            <></>
            Rating
            <FontAwesomeIcon icon={faChevronDown} className="fs-6" />
          </button>
          {showRatingDropdown && <RatingDropdown faSolidStar={faStar} />}
        </div>
        <div className="w-100">
          <Select
            options={options1}
            getOptionLabel={getOptionLabel}
            defaultValue={options1[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="w-100">
          <Select
            options={options1}
            getOptionLabel={getOptionLabel}
            defaultValue={options1[0]}
            isSearchable={false}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
      <h4 className="font-weight-normal mt-4 mb-0 px-2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        feugiat mi venenatis,
      </h4>
    </div>
  );
};
