import { useState } from "react";
import { EmptyComp } from "../../../../../shared/emptyComponents/emptyComp";
import { RaiseTicketModal } from "./raiseTicketModal";
import { TicketConfirmModal } from "./TicketConfirmModal";
import notiImg from "../../../../../images/notification.png";

export const RaiseTicketContent = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  return (
    <div className="d-flex w-100 justify-content-center">
      <EmptyComp
        image={notiImg}
        title="Create a New Ticket"
        desc={[
          "Fill out a form for something specific or give us a brief",
          <br />,
          "description of your issue.",
        ]}
        showButton={true}
        buttonText="Raise a ticket"
        onClickButton={() => setShowModal1(true)}
      />
      <RaiseTicketModal
        showModal={showModal1}
        setShowModal={setShowModal1}
        setShowModal2={setShowModal2}
      />
      <TicketConfirmModal showModal={showModal2} setShowModal={setShowModal2} />
    </div>
  );
};
