import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import {
  faArrowLeft,
  faArrowRightLong,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";

const options1 = [
  { value: "Sorted by", label: "Sorted by" },
  { value: "New", label: "New" },
  { value: "Old", label: "Old" },
];
export const HeadingComp = (props) => {
  const navigate = useNavigate();
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="row">
      <div className="col-lg-8">
        <h2>
          <span className="head-intend">Face - </span> {props.heading}
        </h2>
        <div className="d-flex align-items-center gap-3">
          <IsHelpfull label="Botox" bg="black" color="white" />
          <a
            href="/botox-injections"
            onClick={(e) => {
              e.preventDefault();
              navigate("/botox-injections");
            }}
            className="read-more"
          >
            Read More <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </div>
      </div>
      <div className="col-lg-4 ms-auto">
        <div className="previous-back-btn">
          <span className="previous ms-2">Previous</span>
          <a
            href={props.backPath}
            onClick={(e) => {
              e.preventDefault();
              navigate(props.backPath);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
        </div>
        <div style={{width: "10rem"}} className="mt-4 ms-auto">
          <Select
            options={options1}
            getOptionLabel={getOptionLabel}
            defaultValue={options1[0]}
            isSearchable={true}
            className="offers-box-select" // Add a custom class name for styling
            classNamePrefix="offers-box-select" // Add a custom class prefix for styling
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
    </div>
  );
};
