export const getFullName2 = (obj) => {
  let n = "";
  if (obj && Object.keys(obj).length > 0) {
    n = obj.firstName;
    if (obj.lastName) {
      n = `${n} ${obj.lastName}`;
    }
  }
  return n;
};

export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
};

export const getStep2NestedField = (obj, nestedObjName, fieldName) => {
  let n = "";
  if (
    obj &&
    Object.keys(obj).length > 0 &&
    obj[nestedObjName] &&
    obj[nestedObjName].data &&
    obj[nestedObjName].data.attributes
  ) {
    n = obj[nestedObjName].data.attributes[fieldName];
  }
  return n;
};


export const getIdOfNestedObj = (obj, nestedObjName) => {
  let n = "";
  if (
    obj &&
    Object.keys(obj).length > 0 &&
    obj[nestedObjName] &&
    obj[nestedObjName].data
  ) {
    n = obj[nestedObjName].data.id;
  }
  return n;
};


export const getselectObjOfNestedObj = (obj, nestedObjName, fieldName) => {
  let n = {};
  if (
    obj &&
    Object.keys(obj).length > 0 &&
    obj[nestedObjName] &&
    obj[nestedObjName].data &&
    obj[nestedObjName].data.attributes
  ) {
    n = {value: obj[nestedObjName].data.id, label: obj[nestedObjName].data.attributes[fieldName]};
  }
  return n;
};


export const getSelectObjOfStep1Field = (obj, fieldName) => {
  let n = {};
  if (
    obj &&
    Object.keys(obj).length > 0 &&
obj.attributes
  ) {
    n = {value: obj.id, label : obj.attributes[fieldName]};
  }
  return n;
};
export const getSelectDataOfArray = (obj, nestedObjName) => {
  let res = []
  if (
    obj &&
    Object.keys(obj).length > 0 &&
    obj[nestedObjName] &&
    obj[nestedObjName].data &&
    obj[nestedObjName].data.length > 0
  ){
    let arr = obj[nestedObjName].data
    arr.forEach((d) => {
      res.push(getSelectObjOfStep1Field(d, "name"))
    })
  }
  return res
}

export const makeQaSelectedDataAsFormData= (obj) =>{
let myObj = obj
  delete myObj.doctor
  delete myObj.patient
myObj={...myObj, 
  bodyPart: getselectObjOfNestedObj(obj, "bodyPart", "name"),
  treatment: getselectObjOfNestedObj(obj, "treatment", "name"),
  procedures: getSelectDataOfArray(obj, "procedures")
}
return myObj
}


