import { useMediaQuery } from "@mui/material";
import "../../../css/main.css";
import "../../../css/media.css";
import { LineChart } from "./subcomponents/LineChart";

export const BotoxTreatmentCosts = () => {
  const matches = useMediaQuery('(max-width:992px)');

  return (
    <div className={`botox-treatment left-box overflow-hidden ${matches ? "hidden" : ""}`}>
      <h5>Botox treatment costs in Abu Dhabi</h5>
     <div className="-ml-5 lg:-ml-0">
     <LineChart height={320}></LineChart>
     </div>
    </div>
  );
};
