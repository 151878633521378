import { faStar } from "@fortawesome/free-solid-svg-icons";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import img1 from "../../../../../images/qa1.jpg";
import img2 from "../../../../../images/qa2.jpg";
import img3 from "../../../../../images/qa3.jpg";
import { AddToFav } from "../../../../../shared/AddToComps/addToFav";
import { AnswerContent } from "./answerContent";
export const QuestionAnswerDetails = () => {
  return (
    <div className="questions-answer-div">
      <div className="question-answer-box">
        <span className="search-icon-qa">Q</span>
        <div className="question-ans-head-main">
          <h4>Axillary Lipo tissue and loose skin, what are my options?</h4>
          <div className="worthit-box pr-1">
            <button
              className="button like rounded like-effect-click m-0"
              data-toggle="tooltip"
              title="Add to Favourites!"
            >
              <AddToFav />
            </button>
          </div>

          <div className="answer-div-primary w-100">
            <span>Asked by Pamela</span>
            <span>12 Sep 2022 | 24 months ago</span>
            <span>Liposuction</span>
            <span className="ms-auto mr-0">Viewed by 2,300 </span>
          </div>
          <p className="p-head-size-16">
            I’m a 25 year old South Asian female, 5’1 and weigh approximately
            58kg. I believe I have axillary breast tissue under both armpits
            (one more than the other) and would like to find out how to address
            this issue. I think part of the problem may also be the looser skin
            under one of the arms. It’s causing cosmetic issues and I’m worried
            about future hormonal problems.
          </p>
          <ul className="img-list-popup">
            <li>
              <img src={img1} className="img-fluid" alt="" />
            </li>
            <li>
              <img src={img2} className="img-fluid" alt="" />
            </li>
            <li>
              <img src={img3} className="img-fluid" alt="" />
            </li>
            <li>
              <img src={img1} className="img-fluid" alt="" />
              <span>5+</span>
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 mb-3 mt-5">
          <h5 style={{whiteSpace: "nowrap"}} className="ans-head fs-4">Answers (2)</h5>
          <div className="border-bottom w-100 mb-3"></div>
        </div>
        <AnswerContent solidStar={faStar} />
        <hr className="my-5" />
        <AnswerContent solidStar={faStar} />
      </div>
    </div>
  );
};
