import { FeedHeader } from "./components/feedHeader";
import { FeedsList } from "./components/feedsList";

export const Feed = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <FeedHeader title="Purchased Offers" />
        <FeedsList />
      </div>
    </section>
  );
};
