import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import {
  faExclamationCircle,
  faHeart,
  faTrophy,
  faUser,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faStarHalfAlt,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { HowItWorksModal } from "./subComponents/howItWorksModal";
import { useEffect, useState } from "react";
// import { AddToFavModal } from "./subComponents/addToFavModal/addToFavModal1";
// import { AddToFavModal2 } from "./subComponents/addToFavModal/addToFavModal2";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { imgBaseUrl } from "../../../services/config/config";
import {
  useCreateFavDoctor,
  useDeleteFavDoctor,
  useFavDoctor,
  useGetFavDoctorsByPatient,
} from "../../../Store/favoruiteDoctor/favoruiteDoctorHooks";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { useDoctor } from "../../../Store/Doctor/doctorHooks";

export const DoctorProfileDetails = (props) => {
  const matches = useMediaQuery("(max-width:766px)");
  const [showHowWorksModal, setShowHowWorksModal] = useState(false);
  // const [showFavModal1, setShowFavModal1] = useState(false);
  // const [showFavModal2, setShowFavModal2] = useState(false);
  const [favData, setFavData] = useState({});
  const navigate = useNavigate();
  const doctor = useDoctor();
  const getFavDoctors = useGetFavDoctorsByPatient();
  const favDoctor = useFavDoctor();
  const createFavDoctor = useCreateFavDoctor();
  const removeFavDoctor = useDeleteFavDoctor();

  useEffect(() => {
    if (favDoctor) {
      let res = favDoctor.list.find(
        (d) =>
          d.doctor && d.doctor.data && d.doctor.data.id === doctor.docById.id
      );
      setFavData(res);
    }
  }, [favDoctor, doctor.docById.id]);

  useEffect(() => {
    getFavDoctors();
  }, [getFavDoctors]);

  const handleFavFunc = () => {
    if (favData && favData.id) {
      removeFavDoctor(favData.id);
    } else {
      createFavDoctor({
        patient: currentPatientId(),
        doctor: doctor.docById.id,
      });
    }
  };
  return (
    <section
      className="doctors-listing-block doctors-detail-block px-0 py-0"
      style={{ marginTop: matches ? "-15rem" : "-19rem" }}
    >
      <div className="container-fluid">
        <div className="doctors-listing-box bg-white px-sm-5">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="doctor-img-list" style={{width: "max-content"}}>
                <span>
                  <img
                    src={`${imgBaseUrl + doctor.docById.profileImg}`}
                    className="img-fluid doctor-img1"
                    style={{
                      width: "24rem",
                      minWidth: "24rem",
                      height: "24rem",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </span>
                <button
                  type="button"
                  onClick={handleFavFunc}
                  className="btn btn-primary favourite-add h-10 invisible"
                >
                  {favData && favData.id
                    ? "Remove from favoruites"
                    : "Add to favoruites"}
                </button>
                <button className="button like rounded like-effect-click">
                  <span>
                    <FontAwesomeIcon
                      icon={faHeart}
                      style={{ color: favData && favData.id ? "red" : "" }}
                    />
                  </span>
                </button>
                <button
                onClick={handleFavFunc}
                type="button"
                className="favourite-add mx-auto"
              >
                {favData && favData.id
                  ? "Remove from favoruites"
                  : "Add to favoruites"}
              </button>
              </div>
              
              {/* <AddToFavModal
                showModal={showFavModal1}
                setShowModal={setShowFavModal1}
                setShowFavModal2={setShowFavModal2}
              />
              <AddToFavModal2
                showModal={showFavModal2}
                setShowModal={setShowFavModal2}
              /> */}
            </div>
            <div className="col-xl-3 col-lg-4 ">
              <div className="doctor-details">
                <h5>
                  <a href="doctor-details.html">
                    {doctor.docById.firstName + " "}{" "}
                    {doctor.docById.lastName && doctor.docById.lastName}
                  </a>
                </h5>
                <ul className="d-flex flex-column gap-3 mt-4 fs-5">
                  {doctor.docById.educations &&
                    doctor.docById.educations.length > 0 && (
                      <li className="d-flex align-items-center flex-wrap gap-1 p-0 m-0">
                        {doctor.docById.educations.map((d, i) => (
                          <span key={i + 1} className="p-0 m-0">
                            {d},
                          </span>
                        ))}
                      </li>
                    )}

                  {(doctor.docById.state || doctor.docById.country) && (
                    <li>
                      {doctor.docById.state}, {doctor.docById.country}
                    </li>
                  )}
                  {doctor.docById.totalExperienceYears && (
                    <li>
                      {doctor.docById.totalExperienceYears}+ Yrs Experience
                    </li>
                  )}
                  {doctor.docById.title && <li>{doctor.docById.title}</li>}
                  {doctor.docById.languages &&
                    doctor.docById.languages.length > 0 && (
                      <li className="d-flex flex-wrap align-items-center gap-1 p-0 m-0">
                        Speaks:{" "}
                        {doctor.docById.languages.map((d, i) => (
                          <span key={i + 1} className="p-0 m-0">
                            {d},
                          </span>
                        ))}
                      </li>
                    )}
                </ul>
                <div className="detail-box mt-4">
                  <div className="rating">
                    <div className="">
                      <FontAwesomeIcon icon={props.solidStar} />
                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={props.solidStar} />
                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={props.solidStar} />
                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={faStarHalfAlt} />
                    </div>
                    <div className="">
                      <FontAwesomeIcon icon={faStarHalfAlt} />
                    </div>
                  </div>
                  <span className="number">(1,378)</span>
                  <a href type="button" title="Hooray!" className="fs-4">
                    <FontAwesomeIcon icon={faTrophy} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 ">
              <div className="working-hours">
                <h5>Working Hours</h5>
                <ul>
                  <li>
                    <span>Mon – Wed</span> 8AM – 7PM
                  </li>
                  <li>
                    <span>Thu </span>8AM – 5PM
                  </li>
                  <li>
                    <span>Fri </span>8AM – 5PM
                  </li>
                  <li>
                    <span>Sat – Sun</span> Closed
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="detailed-last-block">
                <div className="exclamation">
                  <a href className="share-stories">
                    <FontAwesomeIcon icon={faUser} /> Share your stories
                  </a>
                  <button
                    onClick={() => {
                      setShowHowWorksModal(true);
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </button>
                </div>
                <p>
                  <span>
                    <FontAwesomeIcon icon={faThumbsUp} /> 95% &nbsp;
                  </span>
                  {doctor.docById.patients &&
                    doctor.docById.patients.data &&
                    doctor.docById.patients.data.length > 0 &&
                    doctor.docById.patients.data.length}
                  Patient Stories
                </p>
                <div className="exclamation">
                  <a
                    href
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/book-appointment/" + doctor.docById.id);
                    }}
                    className="primary__btn3"
                  >
                    <FontAwesomeIcon icon={faClock} /> Book an Appointment
                  </a>
                  <button
                    onClick={() => {
                      setShowHowWorksModal(true);
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </button>
                </div>
                <div className="exclamation">
                  <a href="book-appoinment.html" className="primary__btn4">
                    <FontAwesomeIcon icon={faVideoCamera} />
                    Video Consultation
                  </a>
                  <button
                    onClick={() => {
                      setShowHowWorksModal(true);
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorksModal
        showModal={showHowWorksModal}
        setShowModal={setShowHowWorksModal}
      />
    </section>
  );
};
