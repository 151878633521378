import { useNavigate } from "react-router-dom";
import "../../../css/main.css";
import "../../../css/media.css";
import docImg from "../../../images/doctor-img.png";

export const Banner1 = () => {
  const navigate = useNavigate();
  return (
    <div className="find-doctor">
      <div className="row">
        <div className="col-lg-5">
          <h4>Find Doctors for Botox treatments in Abu Dhabi</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit, sit
            amet auctor massa maximus eget.
          </p>
        </div>
        <div className="col-lg-3 mt-auto text-center">
          <img src={docImg} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-4 m-auto text-center">
          <a
            href="/find-doctor-doctors-listing"
            onClick={(e) => {
              e.preventDefault();
              navigate("/find-doctor-doctors-listing");
            }}
            className="primary_btn"
          >
            Find A Doctor Near You
          </a>
        </div>
      </div>
    </div>
  );
};
