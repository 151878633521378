import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/aos.css";
import "../../css/main.css";
import "../../css/media.css";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export const Footer2 = () => {
  return (
    <footer className="dashboard-footer pb-5 pb-lg-0 pt-3 pt-lg-0">
      <div className="container-fluid py-0">
        <div className="footer-secondary pb-3 pt-4">
          <div className="row">
            <div className="col-lg-4">
              <div className="social-icons-block">
                <p>SOCIAL </p>
                <ul className="social-footer-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/ortilhealthcare"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/ortilhealthcare"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/ortilhealthcare"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href=" https://www.youtube.com/channel/UCazb6NAxmhmtAFjTgBi6bBQ"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/ortilhealthcare?lang=en"
                      target="_NEW"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="mivana-link justify-content-center justify-content-lg-end">
                <p>
                  Copyright © Mivana 2022. All Rights Reserved. Mivana by Ortil
                  Healthcare Pvt. Ltd.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
