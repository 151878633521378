// For Public

import axios from "axios";
import { baseUrl } from "../../services/config/config";
import { getAPIResponseData } from "../../services/generalService";
import { setLoading } from "../loading/loadingAction";
import { toast } from "react-toastify";
import { ALL_BODY_PARTS } from "./actionTypes";

export const setAllBodyPartsData = (data) => {
    return {
      type: ALL_BODY_PARTS,
      payload: data,
    };
  };
  
  export const getAllBodyParts = () => {
    return (dispatch) => {
      axios
        .get(`${baseUrl}/body-parts?populate=*`)
        .then((response) => {
          dispatch(setAllBodyPartsData(getAPIResponseData(response.data.data)));
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    };
  };