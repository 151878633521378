import { Operators } from "./doctorFilterProvider";

export const isFilter = (filters, key) => {
  return filters.find((d) => d[0] === key);
};
const handleGenderChange = (filters, setFilters, val) => {
  if (isFilter(filters, "gender")) {
    const newValue = filters.filter((condition) => condition[0] !== "gender");
    setFilters([...newValue, ["gender", Operators.equal, val]]);
  } else {
    setFilters([...filters, ["gender", Operators.equal, val]]);
  }
};

const handleExperienceChange = (filters, setFilters, val) => {
  if (isFilter(filters, "totalExperienceYears")) {
    const newValue = filters.filter(
      (condition) => condition[0] !== "totalExperienceYears"
    );
    setFilters([...newValue, ...findExperienceValue(val)]);
  } else {
    setFilters([...filters, ...findExperienceValue(val)]);
  }
};

export const handleCityChange = (filters, setFilters, checked, val) => {
  if (checked) {
    setFilters([...filters, ["city", Operators.equal, val]]);
  } else {
    const newValue = filters.filter((condition) => condition[2] !== val);
    setFilters(newValue);
  }
};
const handleServiceChange = (filters, setFilters, val) => {
  if (isFilter(filters, "procedures")) {
    const newValue = filters.filter(
      (condition) => condition[0] !== "procedures"
    );
    setFilters([...newValue, ["procedures", Operators.equal, val, "name"]]);
  } else {
    setFilters([...filters, ["procedures", Operators.equal, val, "name"]]);
  }
};

export const handleAnyChange = (filters, setFilters, key, val) => {
  switch (key) {
    case "gender": {
      handleGenderChange(filters, setFilters, val);
      return;
    }
    case "procedures": {
      handleServiceChange(filters, setFilters, val);
      return;
    }
    case "totalExperienceYears": {
      handleExperienceChange(filters, setFilters, val);
      return;
    }
    default:
      return;
  }
};

const findExperienceValue = (val) => {
  if (val.includes("+")) {
    let e = val.split("+");
    let d = ["totalExperienceYears", Operators.greater_than, parseInt(e[0])];
    return [d];
  } else {
    let a = val.split("-");
    let b = ["totalExperienceYears", Operators.between, parseInt(a[0])];
    let c = ["totalExperienceYears", Operators.between, parseInt(a[1])];
    return [b, c];
  }
};
