import { useState } from "react";
import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/doctor-registration/img-right.png";
import { useNavigate } from "react-router-dom";
import { useUserSignUpAction } from "../../../Store/Auth/authHooks";

const initialData = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
};
export const UserSignUpForm = () => {
  // const [showOtpModal, setShowOtpModal] = useState(false);
  // const [showEmailModal, setShowEmailModal] = useState(false);
  // const [showColorModal, setShowColorModal] = useState(false);
  // const [showDateModal, setShowDateModal] = useState(false);
  // const [showSportModal, setShowSportModal] = useState(false);
  const [finalData, setFinalData] = useState(initialData);
  const userSignUpAction = useUserSignUpAction();
  const handleChange = (key, val) => {
    finalData[key] = val;
    setFinalData(finalData);
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (finalData.password === finalData.confirmPassword) {
      userSignUpAction(finalData);
    } else {
      alert("Password should be match with Re-enter Passwrod value!");
    }
  };

  return (
    <section className="doctor-registration-section signup-page userSignUpContentPage">
      <div className="container-fluid">
        <div className="tab_container">
          <div className="p-0">
            <div className="doctor-registration-first-block common-pad">
              <div className="row justify-content-center g-0">
                <div className="col-lg-5">
                  <h4 className="resp-none-box">
                    Patients are looking for doctors like you
                  </h4>
                  <p className="resp-none-box">
                    Millions of patients are looking for the right doctor on
                    Practo. Start your digital journey with Practo ProfileLet’s
                    take the first step and create your account.
                  </p>
                  <form action="" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label for="">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        placeholder="Enter your full Name"
                        defaultValue={finalData.username}
                        onChange={(e) =>
                          handleChange("username", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group mobile-menu-block">
                      <label for="">Phone</label>
                      <div className="md-group show-label">
                        <input
                          className="form-control"
                          id="phone"
                          type="number"
                          name="phone"
                          defaultValue={finalData.phone}
                          onChange={(e) =>
                            handleChange("phone", e.target.value)
                          }
                          required
                          minLength={11}
                          placeholder="e.g. +1 702 123 4567"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your Password"
                        defaultValue={finalData.password}
                        onChange={(e) =>
                          handleChange("password", e.target.value)
                        }
                      />
                      <div className="password">
                        <span>Upper case</span>
                        <span>Number</span>
                        <span>Lower case</span>
                        <span>Special characters</span>
                        <i className="fa-solid fa-circle-check"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="">Re-enter Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your Password"
                        defaultValue={finalData.confirmPassword}
                        onChange={(e) =>
                          handleChange("confirmPassword", e.target.value)
                        }
                      />
                      <i className="fa-solid fa-circle-check"></i>
                    </div>
                    <div className="form-group">
                      <label for="">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        defaultValue={finalData.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                      <small>
                        OTP will be sent to your email for verification
                      </small>
                    </div>
                    <button type="submit" className="primary__btn3">
                      Proceed
                    </button>
                    <small>
                      If you already have an account. Please{" "}
                      <button
                        onClick={() => navigate("/sign-in")}
                        className="bg-transparent border-0 outline-none shadow-none mx-1"
                        style={{ color: "#e78a82" }}
                      >
                        Login
                      </button>
                      &nbsp; here.
                    </small>
                  </form>
                </div>
                <div className="col-lg-7">
                  <div className="img-right-box resp-hidden-box">
                    <span>
                      <img src={img1} className="img-fluid w-100" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <OtpModal
        showModal={showOtpModal}
        setShowModal={setShowOtpModal}
        setShowEmailModal={setShowEmailModal}
      />
      <ChangeEmailIdModal
        showModal={showEmailModal}
        setShowModal={setShowEmailModal}
        setShowColorModal={setShowColorModal}
      />
      <FavColorModal
        showModal={showColorModal}
        setShowModal={setShowColorModal}
        setShowPrevModal={setShowEmailModal}
        setShowNextModal={setShowDateModal}
      />
      <FavDateModal
        showModal={showDateModal}
        setShowModal={setShowDateModal}
        setShowPrevModal={setShowColorModal}
        setShowNextModal={setShowSportModal}
      />
      <SportsModal
        showModal={showSportModal}
        setShowModal={setShowSportModal}
        setShowPrevModal={setShowDateModal}
        setShowNextModal={setShowOtpModal}
      /> */}
    </section>
  );
};
