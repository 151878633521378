/* eslint-disable no-loop-func */
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const handleFieldContent = (
  fields,
  setFields,
  finalData,
  handleChangeArray,
  key
) => {
  let arr = [];
  for (var i = 0; i < fields; i++) {
    if (i === 0) {
      arr.push(
        <div className="multi-field-wrapper">
          <div className="multi-fields">
            <div className="multi-field">
              <input
                className="form-control"
                type="text"
                required
                defaultValue={
                  finalData[key] &&
                  finalData[key].length > 0 &&
                  finalData[key][i]
                }
                onChange={(e) => handleChangeArray(key, e.target.value, 1)}
                placeholder="Add"
              />
            </div>
          </div>
          <button
            onClick={() => setFields(fields + 1)}
            type="button"
            className="add-field"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      );
    } else {
      arr.push(
        <div className="" key={i + 1}>
          <div className="multi-fields">
            <div className="multi-field position-relative">
              <input
                className="form-control pe-5"
                type="text"
                defaultValue={finalData[key][i]}
                onChange={(e) => handleChangeArray(key, e.target.value, i)}
                placeholder="Enter"
              />
              <button
                type="button"
                style={{
                  minWidth: "2rem",
                  width: "2rem",
                  height: "2rem",
                  background: "lightGrey",
                }}
                onClick={() => fields > 0 && setFields(fields - 1)}
                className="border-0 d-flex align-items-center justify-content-center text-white rounded-circle position-absolute mt-2 me-2 top-0 end-0"
              >
                <FontAwesomeIcon
                  icon={faClose}
                  className="ms-2"
                  style={{ fontSize: "16px" }}
                />
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
  return arr;
};
