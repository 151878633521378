import { useEffect } from "react";
import { ArticlesHeader } from "./components/articlesHeader";
import { ArticlesTabs } from "./components/articlesTabs";
import { UnderReviewArticlesList } from "./components/underReviewArticlesComponents/underReviewArticlesList";
import { currentDoctorId } from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import {
  useArticle,
  useGetArticlesByDoctor,
} from "../../../Store/article/articleHook";

export const ArticlesHistory = () => {
  const article = useArticle();
  const getArticlesByDoctor = useGetArticlesByDoctor();
  useEffect(() => {
    getArticlesByDoctor(currentDoctorId());
  }, [getArticlesByDoctor]);

  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <ArticlesHeader title="Articles History" />
        <ArticlesTabs />
        <UnderReviewArticlesList tab={"History"} data={article.list} />
      </div>
    </section>
  );
};
