import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../css/main.css";
import "../../../../css/media.css";
import Select from "react-select";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import {
  QA_Options,
  ageOptions,
  durationOptions,
  ethinicityOptions,
  genderOptions,
  heightOptions,
  populrSelectOptions,
  proceureOptions,
  viewOptions,
  weightOptions,
} from "../../../../demoData/selectOptionsData";
import { useState } from "react";

export const FiltersModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");
  const [selectedDuration, setSelectedDuration] = useState();

  const handleDurationChange = (e) => {
    const value = parseInt(e.value);
    let res = getLastNDays(value);
    setSelectedDuration(res);
  };

  const getLastNDays = (n) => {
    const today = new Date();
    const lastNDays = [];
    for (let i = n - 1; i >= 0; i--) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      lastNDays.push(day.toDateString());
    }
    return lastNDays;
  };
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  const getOptionLabel2 = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6">{`${selectedDuration[0]} - ${
        selectedDuration[selectedDuration.length - 1]
      }`}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white rounded-3 modal-common-head px-4 pb-4 pt-5">
        <span
          className="close-mega-menu"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="left_chev left-left_chev"
          />
          <FontAwesomeIcon icon={faChevronLeft} className="left_chev" /> Close
        </span>
        <div className="row row-cols-12 pt-4 gy-3 gx-3 w-100">
          <div className="col-12 col-sm-6">
            <Select
              options={proceureOptions}
              getOptionLabel={getOptionLabel}
              defaultValue={proceureOptions[0]}
              isSearchable={true}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          {props.activeTab === "Q&A" && (
            <div className="col-12 col-sm-6">
              <Select
                options={QA_Options}
                getOptionLabel={getOptionLabel}
                defaultValue={QA_Options[0]}
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
          {props.activeTab === "Q&A" && (
            <div className="col-12 col-sm-6">
              <Select
                options={populrSelectOptions}
                getOptionLabel={getOptionLabel}
                defaultValue={populrSelectOptions[0]}
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
          <div className="col-12 col-sm-6">
            <Select
              options={genderOptions}
              getOptionLabel={getOptionLabel}
              defaultValue={genderOptions[0]}
              isSearchable={false}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          {props.activeTab === "Photos" && (
            <div className="col-12 col-sm-6">
              <Select
                options={ethinicityOptions}
                getOptionLabel={getOptionLabel}
                defaultValue={ethinicityOptions[0]}
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
          <div className="col-12 col-sm-6">
            <Select
              options={ageOptions}
              getOptionLabel={getOptionLabel}
              defaultValue={ageOptions[0]}
              isSearchable={false}
              className="offers-box-select" // Add a custom class name for styling
              classNamePrefix="offers-box-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          {props.activeTab === "Photos" && (
            <div className="col-12 col-sm-6">
              <Select
                options={heightOptions}
                getOptionLabel={getOptionLabel}
                defaultValue={heightOptions[0]}
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
          {props.activeTab === "Photos" && (
            <div className="col-12 col-sm-6">
              <Select
                options={weightOptions}
                getOptionLabel={getOptionLabel}
                defaultValue={weightOptions[0]}
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
          {props.activeTab === "Photos" && (
            <div className="col-12 col-sm-6">
              <Select
                options={viewOptions}
                getOptionLabel={getOptionLabel}
                defaultValue={viewOptions[0]}
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
          {props.activeTab === "Q&A" && (
            <div className="col-span-12">
              <Select
                options={durationOptions}
                getOptionLabel={getOptionLabel}
                onChange={handleDurationChange}
                placeholder="Period"
                isSearchable={false}
                className="offers-box-select" // Add a custom class name for styling
                classNamePrefix="offers-box-select" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                  SingleValue: getOptionLabel2,
                }}
              />
            </div>
          )}
        </div>
        <div className="w-100 d-flex justify-content-end mt-4">
          <input
            type="submit"
            style={{backgroundColor: "black"}}
            className="border-0 text-white rounded-pill px-4 py-2 fs-6"
            value="Submit"
          />
        </div>
      </div>
    </ModalPopup>
  );
};
