import { userOffersTabs } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { OffersHeader } from "./components/offerHeader";
import { OffersList } from "./components/purchasedOffersComponents/offersList";

export const RecommendedOffers = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <OffersHeader title="Recommended Offers" />
        <TabsGroup tabs={userOffersTabs} />
        <OffersList tab="Recommended" />
      </div>
    </section>
  );
};
