import "../../../../css/aos.css";
import "../../../../css/main.css";
import "../../../../css/media.css";
import { FeaturedProcedureCarousel } from "./featuredProcedureCarousel";
import { FeaturedProcedureOnBigScreen } from "./featuredProcedureOnBigScreen";

export const FeaturedProcedures = () => {
  return (
    <>
      <FeaturedProcedureOnBigScreen />
      <FeaturedProcedureCarousel />
    </>
  );
};
