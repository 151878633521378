import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ReviewStars = (props) => {
  const isActive = (val) => props.value < val;
  return (
    <div className="rating m-0">
      <div className="">
        <FontAwesomeIcon
          icon={faStar}
          style={{ fontSize: props.size ? props.size : "" }}
          onClick={() => props.onChange(1)}
          className={`${isActive(1) ? "text-secondary" : ""}`}
        />
      </div>
      <div className="">
        <FontAwesomeIcon
          icon={faStar}
          style={{ fontSize: props.size ? props.size : "" }}
          onClick={() => props.onChange(2)}
          className={`${isActive(2) ? "text-secondary" : ""}`}
        />
      </div>
      <div className="">
        <FontAwesomeIcon
          icon={faStar}
          style={{ fontSize: props.size ? props.size : "" }}
          onClick={() => props.onChange(3)}
          className={`${isActive(3) ? "text-secondary" : ""}`}
        />
      </div>
      <div className="">
        <FontAwesomeIcon
          icon={faStar}
          style={{ fontSize: props.size ? props.size : "" }}
          onClick={() => props.onChange(4)}
          className={`${isActive(4) ? "text-secondary" : ""}`}
        />
      </div>
      <div className="">
        <FontAwesomeIcon
          icon={faStar}
          style={{ fontSize: props.size ? props.size : "" }}
          onClick={() => props.onChange(5)}
          className={`${isActive(5) ? "text-secondary" : ""}`}
        />
      </div>
    </div>
  );
};
