import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../css/main.css";
import "../../../css/media.css";
import { botoxTreatmentsData } from "../../../demoData/botoxTreatmentsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 1400, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

export const BotoxTreatmentsCarousel = () => {
  return (
    <div className="related-botox-treatment botox-similiar-box">
      <div className="commen-head-sec">
        <div className="row">
          <div className="col-lg-7">
            <h3>Related Botox Treatments</h3>
          </div>
          <div className="col-lg-5">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              ullamcorper. Sed pretium ipsum elit, sit amet auctor massa maximus
              eget.
            </p>
          </div>
        </div>
      </div>
      <Carousel
        responsive={responsive}
        arrows={true}
        showDots={false}
        infinite={true}
        autoPlay={false}
        customLeftArrow={
          <button
            style={{ width: "2rem", height: "2rem" }}
            className={`rounded-circle mt-5 z-3 position-absolute top-0 start-0 simplecarousel-chev d-flex align-items-center justify-content-center`}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mx-auto" />
          </button>
        }
        customRightArrow={
          <button
            style={{ width: "2rem", height: "2rem" }}
            className={`rounded-circle mt-5 z-3 position-absolute top-0 end-0 simplecarousel-chev d-flex align-items-center justify-content-center`}
          >
            <FontAwesomeIcon icon={faChevronRight} className="mx-auto" />
          </button>
        }
      >
        {botoxTreatmentsData.map((data, ind) => (
          <div className="item mx-2 px-1" key={ind + 1}>
            <div className="w-100 d-flex justify-content-center">
              <img
                src={data.img}
                style={{
                  width: "75%",
                  borderRadius: "14px",
                  marginBottom: "-3rem",
                }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="item-content">
              <h5>{data.title}</h5>
              <a href className="primary__btn">
                View more
              </a>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
