import { useNavigate } from "react-router-dom";
import "../../../../css/main.css";

export const OffersTabs = () => {
  const navigate = useNavigate();
  const isActive = (val) => {
    let loc = window.location;
    if (loc.pathname.includes(val)) {
      return true;
    }
    return false;
  };
  return (
    <div className="pb-0">
      <ul className="tabs tab-common-dashboard ps-4 pt-4 gap-4">
        <li
          className={`px-0 pt-1 me-2 ${
            isActive("/doctor-dashboard/upload-offers") ? "active" : ""
          }`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/upload-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/upload-offers");
            }}
          >
            Upload Offers
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${
            isActive("/doctor-dashboard/under-review-offers") ? "active" : ""
          }`}
          rel="tab2"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/under-review-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/under-review-offers");
            }}
          >
            Under Review
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${isActive("/doctor-dashboard/live-offers") ? "active" : ""}`}
          rel="tab3"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/live-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/live-offers");
            }}
          >
            Live Offers
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${
            isActive("/doctor-dashboard/rejected-offers") ? "active" : ""
          }`}
          rel="tab4"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/rejected-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/rejected-offers");
            }}
          >
            Rejected Offers
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${isActive("/doctor-dashboard/expired-offers") ? "active" : ""}`}
          rel="tab1"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/expired-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/expired-offers");
            }}
          >
            Expired Offers
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${
            isActive("/doctor-dashboard/purchased-offers") ? "active" : ""
          }`}
          rel="tab6"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/purchased-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/purchased-offers");
            }}
          >
            Purchased Offers
          </a>
        </li>
        <li
          className={`px-0 pt-1 me-2 ${isActive("/doctor-dashboard/history-offers") ? "active" : ""}`}
          rel="tab6"
        >
          <a
            className="px-0 pt-0 pb-1"
            href="/history-offers"
            onClick={(e) => {
              e.preventDefault();
              navigate("/doctor-dashboard/history-offers");
            }}
          >
            Offers History
          </a>
        </li>
      </ul>
    </div>
  );
};
