import img1 from "../../../../../images/video-img1.jpg";
import img2 from "../../../../../images/video-img2.jpg";
import img3 from "../../../../../images/video-img3.jpg";
import img4 from "../../../../../images/video-img4.jpg";
import playImg from "../../../../../images/play-small.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const videosData = [img1, img2, img3, img4, img1, img2, img3, img4];

export const FavVideosList = () => {
  return (
    <div className="tab_container three-block-container px-0">
      <div id="tab1" className="tab_content px-0">
        <section className="common-slider bg-white px-0">
          <div className="row common-pad">
            <div className="row">
              {videosData.map((d, ind) => (
                <div
                  className="col-sm-6 col-lg-4 col-xl-3 position-relative px-4 mb-4"
                  key={ind + 1}
                >
                  <div className="related-video-block">
                    <div className="overlay-video-related position-relative">
                      <img src={d} className="img-fluid" alt="" />
                      <span>
                        <img src={playImg} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <p className="p-head-size-17 fs-6 mt-2">
                      Tummy Tuck Lorem Ispum Tuck Lor Em Isp Lorem Ispum
                    </p>
                    <span className="delete-icon-box">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
