import { useAppAction } from "../../services/hooks/hooks";
import {
  LoginAction,
  Logout,
  doctorSignUpAction,
  userSignUpAction,
} from "./AuthAction";

export const useUserSignUpAction = () => useAppAction(userSignUpAction);
export const useDoctorSignUpAction = () => useAppAction(doctorSignUpAction);
export const useLoginAction = () => useAppAction(LoginAction);
export const useLogout = () => useAppAction(Logout);
