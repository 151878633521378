import { useState } from "react";
import "../../../../../css/main.css";
import { doctorsData2 } from "../../../../../demoData/doctorsData";
import { PaginationBox } from "../../../../../shared/paginationBox";
import { SelectOrderModal } from "../selectOrderModal";
import { SubmitReasonModal } from "../submitReasonModal";

export const HealthStatusTable = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  return (
    <div class="tab_container overflow-auto px-0">
      <div class="overflow-scroll-table overflow-auto">
        <table
          id="example"
          class="table table-style-2 w-100 overflow-auto"
          style={{ whiteSpace: "nowrap" }}
        >
          <thead>
            <tr>
              <th class="sort-none">SN</th>
              <th>Name</th>
              <th>From</th>
              <th>Description</th>
              <th>Gender</th>
              <th>Date</th>
              <th>Time</th>
              <th>Particulars</th>
              <th>Qty</th>
              <th>Amount</th>
              <th class="sort-none">Status</th>
            </tr>
          </thead>
          <tbody>
            {doctorsData2.map((data, ind) => (
              <tr key={ind + 1}>
                <td>{ind + 1}</td>
                <td>
                  <span>
                    <img src={data.img} class="img-fluid" alt="" />
                  </span>
                  {data.name} <i class="fa-light fa-video"></i>{" "}
                </td>
                <td>Abu Dhabi, AE</td>
                <td>Breast Augmentation</td>
                <td>Female</td>
                <td>13 Mar 2022</td>
                <td>09.15 am</td>
                <td>
                  <span>
                    <img src={data.img} class="img-fluid" alt="" />
                  </span>
                  Dr. Benny Lawrence{" "}
                </td>
                <td>2</td>
                <td>2,506</td>
                <td>
                  {" "}
                  <button
                    type="button"
                    class="primary__btn4 m-0 line-height border-radius-40 px-4 py-0"
                    style={{ fontSize: "14px" }}
                    onClick={() => setShowModal1(true)}
                  >
                    Booked
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <a href class="download-report mt-5">
          Download Report
        </a>
      </div>
      <div className="">
        <PaginationBox />
      </div>
      <SelectOrderModal
        showModal={showModal1}
        setShowModal={setShowModal1}
        setShowModal2={setShowModal2}
      />
      <SubmitReasonModal showModal={showModal2} setShowModal={setShowModal2} />
    </div>
  );
};
