import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import botoxImg from "../../../images//Botox.jpg";
import {
  faBed,
  faCar,
  faClipboardQuestion,
  faDumbbell,
  faMaskVentilator,
  faPlaneDeparture,
  faShirt,
  faShower,
  faStethoscope,
  faVenusMars,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendarCheck,
  faClock,
  faHospital,
  faStar,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { AddToFav } from "../../../shared/AddToComps/addToFav";
import { IsFav3 } from "../../../shared/AddToComps/isFav3";
import { BotoxTreatmentModal } from "./botoxTreatmentModal";
import { useState } from "react";
export const BotoxInjectionHeader = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <section className="botox-injection-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="botox-injection-content">
              <h3 style={{fontSize: "5rem"}}>Botox Injections</h3>
              <p className="p-head-size px-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit,
                sit amet auctor massa maximus eget.
              </p>
              <ul>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faBed} />
                  </span>
                  <h5>
                    Average Stay <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faHospital} />
                  </span>
                  <h5>
                    Stay in hospital <span>1 day</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faClock} />
                  </span>
                  <h5>
                    Duration <span>1-6 hours</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faShower} />
                  </span>
                  <h5>
                    Shower <span>1 week</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faVial} />
                  </span>
                  <h5>
                    Pre Admission Tests <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faMaskVentilator} />
                  </span>
                  <h5>
                    Anesthesia <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faPlaneDeparture} />
                  </span>
                  <h5>
                    Travel <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCalendarCheck} />
                  </span>
                  <h5>
                    Downtime <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faStethoscope} />
                  </span>
                  <h5>
                    Doctors & Clinics <span>1 day</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <h5>
                    Ratings <span>1-6 hours</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCar} />
                  </span>
                  <h5>
                    Driving <span>1 week</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faDumbbell} />
                  </span>
                  <h5>
                    Exercise <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faShirt} />
                  </span>
                  <h5>
                    Inner Garments <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faVenusMars} />
                  </span>
                  <h5>
                    Sexual Activity <span>1 week</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faBed} />
                  </span>
                  <h5>
                    Sleeping on back <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faClock} />
                  </span>
                  <h5>
                    Full Recovery <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <h5>
                    Before & After <span>4-6 weeks</span>
                  </h5>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faClipboardQuestion} />
                    <i className="fa-light fa-message-question"></i>
                  </span>
                  <h5>
                    Queries <span>4-6 weeks</span>
                  </h5>
                </li>
              </ul>
              <div className="d-flex flex-column flex-xl-row align-items-center justify-content-center mt-4 gap-2 gap-xl-4">
                <IsFav3
                  label="Worth it"
                  bg="black"
                  color="white"
                  border="1px solid black"
                  subLabel="2678"
                />
                <button
                  onClick={() => setShowModal(true)}
                  type="button"
                  className="book-appoinmnet mt-0 fs-6 ms-1"
                >
                  <span className="fs-4 fs-xl-5 fs-xxl-4">
                    Book an Appointment
                  </span>
                </button>
                <BotoxTreatmentModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 position-relative">
            <span>
              <img src={botoxImg} className="img-fluid" alt="" />
            </span>
            <div
              className="position-absolute top-0 mt-3 end-0 me-5 rounded-circle bg-white"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <AddToFav />
            </div>
            <div className="breadcrumb">
              <a href>Procedures</a> &nbsp; / &nbsp;
              <a href>Botox Injections</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
