/* eslint-disable react-hooks/exhaustive-deps */
import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { HeaderSlider } from "./components/headerSlider";
import "../../css/main.css";
import "../../css/media.css";
import { TabsBanner } from "./components/tabsBanner";
import { PriceFilter } from "./components/priceFilter";
import { FilterBox } from "./components/filterBox";
import { FacePhotosList } from "./components/facePhotosList";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FacesModal } from "./components/facesModal";
import { useEffect, useState } from "react";
import { FiltersModal } from "./components/filtersModal";
import {
  useBeforeAfter,
  useGetBeforeAftersByBodyPart,
} from "../../Store/beforAfter/beforeAfterHook";

export const BeforAfter = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const [showFaceModal, setShowFaceModal] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const getBeforeAftersByBodyPart = useGetBeforeAftersByBodyPart();
  const beforeAfter = useBeforeAfter();
  const [tab, setTab] = useState("Face");
  const [seeAll, setSeeAll] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowFaceModal(false);
      setShowFiltersModal(false);
    });
  }, []);
  useEffect(() => {
    getBeforeAftersByBodyPart(tab);
  }, [tab]);

  useEffect(() => {
    if (showFaceModal || showFiltersModal) {
      document.scrollingElement.style.overflow = "hidden";
    } else {
      document.scrollingElement.style.overflow = "auto";
    }
  }, [showFaceModal, showFiltersModal]);
  return (
    <div>
      <Header />
      <HeaderSlider setTab={setTab} tab={tab} />
      <section className="before-after-listing-page-section">
        <TabsBanner activeTab="Photos" />
        <div className="container-fluid">
          <div className="pt-5 pt-md-0">
            <div id="tab1" className="">
              <section className="before-after-blocks">
                <div className="resp-block-box justify-content-end fixed-box">
                  <button
                    type="button"
                    className="btn btn-primary selectPopup-btn"
                    onClick={() => setShowFiltersModal(true)}
                  >
                    <FontAwesomeIcon icon={faSliders} />
                  </button>
                  <span
                    className="open-click open-click2"
                    onClick={() => setShowFaceModal(true)}
                  >
                    <FontAwesomeIcon icon={faUserCheck} />
                  </span>
                </div>
                {showFaceModal && (
                  <FacesModal setShowModal={setShowFaceModal} />
                )}
                {showFiltersModal && (
                  <FiltersModal
                    activeTab="Q&A"
                    showModal={showFiltersModal}
                    setShowModal={setShowFiltersModal}
                  />
                )}
                <div className="tab-head-block py-5">
                  <PriceFilter
                    tab={tab}
                    heading="Before and After Pictures (540)"
                  />
                  <FilterBox />
                </div>
                <FacePhotosList
                  detailPath="/before-after-face-detail"
                  activeTab="Photos"
                  items={
                    seeAll
                      ? beforeAfter.listBYStatus
                      : beforeAfter.listBYStatus.filter((d, i) => i < 6 && d)
                  }
                  showSeeAllButton={!seeAll && beforeAfter.listBYStatus.length > 6}
                  setSeeAll={setSeeAll}
                />
              </section>
            </div>
          </div>
        </div>
      </section>
      <div className={`container-fluid ${matches ? "-mb-12" : ""} pb-1 mt-10`}>
        <h4>Book your consultation today</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
