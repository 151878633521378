import { ReviewsHeader } from "./components/reviewsHeader";
import { FlaggedReviewsList } from "./components/flaggedReviewsComponents/flaggedReviewsList";
import { TabsGroup } from "../../../shared/tabsGroup";
import { userReviewsTabs } from "../../../demoData/tabsData";
import { useEffect } from "react";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { Loading } from "../../../shared/loadings/Loading";
import {
  useGetReviewsByPatientAndStatus,
  useReview,
} from "../../../Store/review/reviewHook";

export const FlaggedUserReviews = () => {
  const reviewState = useReview();
  const getReviewsByPatientAndStatus = useGetReviewsByPatientAndStatus();

  useEffect(() => {
    getReviewsByPatientAndStatus("Flagged");
  }, [getReviewsByPatientAndStatus]);

  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <ReviewsHeader title="Flagged Reviews (19)" />
        <TabsGroup tabs={userReviewsTabs} />
        <FlaggedReviewsList data={reviewState.list} />
      </div>
    </section>
  );
};
