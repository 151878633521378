import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import moment from "moment";
import { imgBaseUrl } from "../../../services/config/config";
import { AddToFav } from "../../../shared/AddToComps/addToFav";
import {
  useCreateFavOffer,
  useDeleteFavOfferById,
  useFavOffer,
} from "../../../Store/favoruiteOffer/favoruiteOfferHooks";
import { currentPatientId } from "../../../Store/selectors/UserSelectors";
import { NoDataComp } from "../../../shared/emptyComponents/noDataComp";
import { useHandleCart } from "../../../Store/cart/cartHook";

export const OffersList = (props) => {
  const createFavOffer = useCreateFavOffer();
  const removeFavOffer = useDeleteFavOfferById();
  const useFavOfferState = useFavOffer();
  const [offersData, setOffersData] = useState([]);
  const handleCart = useHandleCart();
  useEffect(() => {
    if (props.offersData) {
      setOffersData(props.offersData);
    }
  }, [props.offersData]);

  const isFavOffer = (id) => {
    let res = useFavOfferState.list.find(
      (d) => d.offer && d.offer.data && d.offer.data.id === id
    );
    if (res) return res.id;
  };

  const handleFavFunc = (id) => {
    if (isFavOffer(id)) {
      removeFavOffer(isFavOffer(id));
    } else {
      createFavOffer({
        patient: currentPatientId(),
        offer: id,
      });
    }
  };

  return (
    <>
      {offersData && offersData.length > 0 ? (
        <div className="detail-offers-list">
          <div className="row">
            {offersData.map((data, ind) => (
              <div className="col-lg-3 col-md-6" key={ind + 1}>
                <div className="detailed-offers-block overflow-hidden">
                  <div className="offer-img-box">
                    <span className="expired-on">
                      Expired on {moment(data.validTo).format("DD MMM YYYY")}
                    </span>
                    <img
                      src={`${imgBaseUrl + data.image}`}
                      className="img-fluid"
                      style={{ height: "400px" }}
                      alt=""
                    />

                    <button className="button like rounded like-effect-click d-flex justify-content-center align-items-center">
                      <AddToFav
                        size="2.8rem"
                        isFav={isFavOffer(data.id)}
                        onClick={() => handleFavFunc(data.id)}
                      />
                    </button>
                  </div>
                  <div className="detailed-offers-inner">
                    <h5>{data.title}</h5>
                    <div className="rating">
                      <div className="">
                        <FontAwesomeIcon icon={props.starIcon} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={props.starIcon} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={props.starIcon} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={faStarHalfStroke} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={faStarHalfStroke} />
                      </div>
                      <span>(1378)</span>
                    </div>
                    <p className="two-lines-elipsis">{data.description}</p>
                    <div className="pricing-box mt-2">
                      <h5>AED {data.discountedAmount}</h5>
                      <h6>{data.discountPercentage}%</h6>
                    </div>
                    <button
                      onClick={() => {
                        let obj = {...data}
                        delete obj.doctor;
                        delete obj.new_reviews;
                        if (
                          data.doctor &&
                          data.doctor.data &&
                          data.doctor.data.id
                        ) {
                          handleCart({
                            patient: currentPatientId(),
                            offers: [
                              {
                                ...obj,
                                quantity: 1,
                                doctorId: data.doctor.data.id,
                              },
                            ],
                          });
                        }
                      }}
                      className="primary__btn mt-0"
                    >
                      Add to Wallet
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="button-flex justify-content-center mt-5">
            <a href="" className="primary__btn5 ">
              See all Offers
            </a>
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </>
  );
};
