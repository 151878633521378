import img1 from "../images/botox-stories1.jpg";
import img2 from "../images/botox-stories2.jpg";
import img3 from "../images/botox-stories3.jpg";
import img4 from "../images/botox-stories4.jpg";

export const botoxStoriesData = [
  {
    desc: " The fda just approved daxxify, an injectable neurotoxin that lasts months longer than botox",
    img: img1,
  },
  {
    desc: " The fda just approved daxxify, an injectable neurotoxin that lasts months longer than botox",
    img: img2,
  },
  {
    desc: " The fda just approved daxxify, an injectable neurotoxin that lasts months longer than botox",
    img: img3,
  },
  {
    desc: " The fda just approved daxxify, an injectable neurotoxin that lasts months longer than botox",
    img: img4,
  },
  {
    desc: " The fda just approved daxxify, an injectable neurotoxin that lasts months longer than botox",
    img: img1,
  },
];
