import { Header } from "../../shared/header";
import "../../css/main.css";
import "../../css/media.css";
import { Footer } from "../../shared/footer/footer";

import { SignInform } from "./components/signInform";
import { RegistrationTabs } from "../../shared/pageComponents/registrationTabs";

export const SignIn = () => {
  return (
    <div className="p-0">
      <Header />
      <RegistrationTabs />
      <SignInform />
      <Footer />
    </div>
  );
};
