import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ModalPopup } from "src/shared/Modals/ModalPopup";
import { useMediaQuery } from "@mui/material";
import { useCancelOrderByDoctor } from "src/Store/order/orderHook";
import { useState } from "react";

export const RejectOrderModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");
  const cancelOrderByDoctor = useCancelOrderByDoctor();
  const [reason, setReason] = useState("");
  const handleClose = () => {
    setReason("");
    props.setShowModal(false);
  };

  const isValid = () => {
    if (
      props.selectedItem &&
      Object.keys(props.selectedItem).length > 0 &&
      reason
    ) {
      return true;
    }
    return false;
  };
  const handleCancelOrder = () => {
    if (isValid()) {
      cancelOrderByDoctor(
        {
          id: props.selectedItem.id,
          status: "Rejected",
          cancellationReason: reason,
          facilityCancelled: true,
        },
        () => {
          handleClose();
          props.getData();
        }
      );
    }
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white px-3 py-3">
        <h5 className="text-dark fs-5 fw-bold m-0 p-0">Order Cancellation</h5>
        <span
          style={{ width: "2rem", height: "2rem", marginTop: "-5px" }}
          className="close-mega-menu rounded-circle d-flex align-items-center justify-content-center p-0"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faClose} style={{ fontSize: "20px" }} />
        </span>
        <div className="mt-4" id="style-3">
          <label>Explain reason for cancel order</label>
          <textarea
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="rounded-2 border px-2 py-2 w-100"
          ></textarea>
          <div className="d-flex justify-content-end mt-3">
            <button
              onClick={handleCancelOrder}
              disabled={!isValid()}
              style={{
                background: "black",
                cursor: isValid() ? "pointer" : "not-allowed",
              }}
              className="py-1 px-4 rounded-pill text-white"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
