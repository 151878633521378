import { TabsGroup } from "../../../shared/tabsGroup";
import { redeemTabsData } from "../../../demoData/tabsData";
import { RewardsList } from "../../dashboard/referFreind/components/rewardComponents/rewardsList";
import { RedeemHeader } from "./components/redeemHeader";

export const PointStatus = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <RedeemHeader title="Point Status" />
        <TabsGroup tabs={redeemTabsData} />
        <RewardsList />
      </div>
    </section>
  );
};
