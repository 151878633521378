import { faComment, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import "../../../css/main.css";
import "../../../css/media.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserReviewItem } from "./userReviewItem.";

export const ReviewCommentBox = (props) => {
  return (
    <div className={`story-box-inner`}>
      <div className="story-inner-box">
        <div className="form-group">
          <div className="comment-area-box">
            <span
              className="comment-back-click"
              style={{cursor: "pointer"}}
              onClick={() => props.setShowCommentBox(false)}
            >
              <FontAwesomeIcon icon={faComment} /> Comments
            </span>
            <textarea
              name=""
              id=""
              rows="10"
              style={{marginTop: "-2.5rem"}}
              className="rounded-3 w-100 border-0 shadow-none"
            >
              Write your comment here
            </textarea>
          </div>

          <div className="submit-filter justify-content-end">
            <a href className="primary__btn3 d-flex align-items-center gap-1 py-2 px-4">
            <FontAwesomeIcon icon={faPenToSquare} className="text-white" /> Submit
              Review
            </a>
          </div>
        </div>
      </div>
      <UserReviewItem />
      <UserReviewItem />
      <div className="button-flex justify-content-center">
        <a href className="primary__btn4">
          See more reviews
        </a>
      </div>
    </div>
  );
};
