import { setLoading } from "../loading/loadingAction";
import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { currentPatientId } from "../selectors/UserSelectors";
import { getAPIResponseData } from "../../services/generalService";
import OutHook from "../../services/navigation";
import { FAV_OFFERS_BY_PATIENT } from "./actionTypes";

export const createFavOffer = (data) => {
  return (dispatch) => {
    if (currentPatientId()) {
      axiosInstance
        .post(`/favoruite-offers`, { data: data })
        .then(() => {
          toast.success("Offer added to your Favoruites!");
          dispatch(setLoading(false));
          dispatch(getFavOffersByPatient());
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    } else {
      alert("Please first Login as a patient...");
      OutHook.navigateTo("/sign-in");
    }
  };
};

export const setAllFavOffersData = (data) => {
  return {
    type: FAV_OFFERS_BY_PATIENT,
    payload: data,
  };
};
export const getFavOffersByPatient = () => {
  return (dispatch) => {
    if (currentPatientId()) {
      axiosInstance
        .get(
          `/favoruite-offers?filters[patient][id][$eq]=${currentPatientId()}&populate=*`
        )
        .then((response) => {
          dispatch(
            setAllFavOffersData(getAPIResponseData(response.data.data))
          );
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    }
  };
};

export const deleteFavOfferById = (id) => {
  return (dispatch) => {
    if (currentPatientId()) {
      axiosInstance
        .delete(`/favoruite-offers/${id}`)
        .then(() => {
          toast.success("Offer removed to your Favoruites!");
          dispatch(setLoading(false));
          dispatch(getFavOffersByPatient());
        })
        .catch(() => {
          dispatch(setLoading(false));
          toast.error("Something went wrong!");
        });
    } else {
      alert("Please first Login as a patient...");
      OutHook.navigateTo("/sign-in");
    }
  };
};
