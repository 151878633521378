import { useMediaQuery } from "@mui/material";
import "../../../../../css/main.css";

export const DeleteProfileContent = () => {
  const matches = useMediaQuery("(max-width:580px)");
  const matches2 = useMediaQuery("(max-width:1300px)");

  return (
    <div className="tab_container articles-container overflow-hidden px-0 pt-5 pb-0">
      <div id="tab1" className="tab_content px-0 pb-0">
        <div className="settings-div pb-0">
          <div className="delete-profile pb-0 mb-0">
            <div className="row">
              <div className="col-lg-6">
                <h4>Why do I need this?</h4>
                <p>
                  Are you sure? This will permanently delete your Practo
                  account. Once the deletion process begins, you won’t be able
                  to reactivate your account or retrieve any data or
                  information..
                </p>
                <p>
                  Can you please share to us what was not working? We are fixing
                  bugs as soon as we spot them. If something slipped through our
                  fingers, we’d be so grateful to be aware of it and fix it.
                </p>
                <form action="">
                  <div className="form-group">
                    <label for="">Enter your E-mail ID</label>
                    <textarea
                      name=""
                      className="form-control"
                      id=""
                      rows="5"
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    className="primary__btn3 d-inline-block"
                    data-bs-toggle="modal"
                    data-bs-target="#welldoneModal"
                  >
                    Delete My profile
                  </button>
                </form>
              </div>

              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className="confirm-deleteion px-3 px-md-5 px-lg-3 px-xl-5">
                  <h4>Confirm account deletion</h4>
                  <p>
                    We’re sorry to see you go. Once your account is deleted, all
                    of your content will be permanently gone, including your
                    profile, stories, publications, notes, and responses.
                    Deleting your Medium account will not delete any Stripe
                    account you have connected to your Medium account. If you’re
                    not sure about that, we suggest you deactivate or contact
                    yourfriends@medium.com instead.
                  </p>
                  <h5>To confirm deletion, type “delete” below:</h5>
                  <input type="text" className="form-control" />
                  <div className="gap-2 gap-sm-3 d-flex align-items-center mt-4">
                    <a href className="primary__btn3 w-50" style={{whiteSpace: "nowrap", fontSize: matches? "13px" : matches2? "13px" : "", padding: matches? "2px auto" : ""}}>
                      Delete My profile
                    </a>
                    <a href className="primary__btn3 w-50 cancel-btn" style={{padding: matches ? "2px auto" : "", fontSize: matches? "13px" : ""}}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
