
import { FAV_DOCTORS_BY_PATIENT } from "./actionTypes";

const initialData = {
  list: [],
};
export function FavDoctorReducer(state = initialData, action) {
  switch (action.type) {
    case FAV_DOCTORS_BY_PATIENT: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
   
    default:
      return state;
  }
}
