import { SupportHeader } from "./components/supportHeader";
import { OpenTickesTable } from "./components/openTicketComponents/openTickesTable";
import { supportTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";

export const OpenTicket = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SupportHeader title="Open Tickets" />
        <TabsGroup tabs={supportTabsData()} />
        <OpenTickesTable />
      </div>
    </section>
  );
};
