import "../../../../css/main.css";

export const Notifications = () => {
  return (
    <div className="col-lg-5">
      <div className="notification-block">
        <div className="head-menu-design">
          <div className="row">
            <div className="col-lg-6">
              <h4>Notifications</h4>
            </div>
            <div className="col-lg-6">
              <a href className="view-all">
                View all
              </a>
            </div>
          </div>
        </div>
        <div className="stories-list">
          <div className="story-box">
            <span className="name-style name-red">RF</span>
            <div className="story-box-head">
              <h4>Roy Ford</h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago{" "}
                  <span className="primary__btn4">Reviews</span>{" "}
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Posted New Review</p>
              </div>
            </div>
          </div>

          <div className="story-box">
            <span className="name-style name-blue">CG</span>
            <div className="story-box-head">
              <h4>Carolyin Garrett</h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago{" "}
                  <span className="primary__btn4">Reviews</span>{" "}
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Commented on your answer</p>
              </div>
            </div>
          </div>

          <div className="story-box">
            <span className="name-style name-blue">PV</span>
            <div className="story-box-head">
              <h4>Patrica Valdelz </h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="date-time-review">
                  12 Sep 2022 | 30 min ago{" "}
                  <span className="primary__btn4">Reviews</span>{" "}
                </p>
              </div>
              <div className="col-lg-4">
                <p className="text-end">Appoinment Booked</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
