import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { AuthReducer } from "./Auth/AuthReducer";
import { RolesReducer } from "./Role/RolesReducer";
import { DoctorReducer } from "./Doctor/doctorReducer";
import { ArticleReducer } from "./article/articleReducer";
import { OfferReducer } from "./offer/offerReducer";
import { OrderReducer } from "./order/orderReducer";
import { BeforeAfterReducer } from "./beforAfter/beforAfterReducer";
import { PatientReducer } from "./Patient/patientReducer";
import { ReviewReducer } from "./review/reviewReducer";
import { PurchasedOfferReducer } from "./purchasedOffer/purchasedOfferReducer";
import { QAReducer } from "./qa/qaReducer";
import { LoadingReducer } from "./loading/loadingReducer";
import { BodyPartReducer } from "./bodyPart/bodyPartReducer";
import { ProcedureReducer } from "./procedure/procedureReducer";
import { FavDoctorReducer } from "./favoruiteDoctor/favoruiteDoctorReducer";
import { FavOfferReducer } from "./favoruiteOffer/favoruiteOfferReducer";
import { AppointmentReducer } from "./appointment/appointmentReducer";
import { CartReducer } from "./cart/cartReducer";
import { TreatmentReducer } from "./treatment/treatmentReducer";

const loggerMiddleware = () => (next) => (action) => {
  let result = next(action);
  return result;
};

const middleware = applyMiddleware(thunk, loggerMiddleware);
const composeEnhancers = compose;
const reducers = combineReducers({
  auth: AuthReducer,
  role: RolesReducer,
  doctor: DoctorReducer,
  article: ArticleReducer,
  offer: OfferReducer,
  order: OrderReducer,
  beforeAfter: BeforeAfterReducer,
  patient: PatientReducer,
  review: ReviewReducer,
  purchasedOffer: PurchasedOfferReducer,
  qa: QAReducer,
  loading: LoadingReducer,
  bodyPart: BodyPartReducer,
  procedure: ProcedureReducer,
  favDoctor: FavDoctorReducer,
  favOffer: FavOfferReducer,
  appointment: AppointmentReducer,
  cart: CartReducer,
  treatment: TreatmentReducer
  ,
});

export const store = createStore(reducers, composeEnhancers(middleware));
