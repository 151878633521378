import { UserProfile } from "../Pages/userDashboard/userProfile";
import { NotificationNew } from "../Pages/dashboard/notification/notificationNew";
import { NotificationUnread } from "../Pages/dashboard/notification/notificationUnread";
import { NotificationRead } from "../Pages/dashboard/notification/notificationRead";
import { UpcommingAppointments } from "../Pages/dashboard/upcommingAppointments";
import { CompletedAppointments } from "../Pages/dashboard/completedAppointments";
import { VideoConsultation } from "../Pages/dashboard/videoConsultation";
import { Route, Routes } from "react-router-dom";
import { CreateUserReviews } from "../Pages/userDashboard/userReviews/createUserReviews";
import { HideUserReviews } from "../Pages/userDashboard/userReviews/hideUserReviews";
import { LiveUserReviews } from "../Pages/userDashboard/userReviews/liveUserReviews";
import { FlaggedUserReviews } from "../Pages/userDashboard/userReviews/flaggedUserReviews";
import { StartsReviewNoti } from "../Pages/userDashboard/userReviews/startsReviewNoti";
import { NewUserReviews } from "../Pages/userDashboard/userReviews/newUserReviews";
import { CommentsReviews } from "../Pages/userDashboard/userReviews/commentsReviews";
import { PurchasedOffers } from "../Pages/userDashboard/userOffers/purchasedOffers";
import { RecommendedOffers } from "../Pages/userDashboard/userOffers/recommendedOffers";
import { DealsOffers } from "../Pages/userDashboard/userOffers/dealsOffers";
import { MyWishlist } from "../Pages/userDashboard/userWallet/myWishlist";
import { MyCart } from "../Pages/userDashboard/userWallet/myCart";
import { MyOrders } from "../Pages/userDashboard/userWallet/myOrders";
import { WalletHistory } from "../Pages/userDashboard/userWallet/walletHistory";
import { WalletLocations } from "../Pages/userDashboard/userWallet/walletLocations";
import { WalletCancelled } from "../Pages/userDashboard/userWallet/walletCancelled";
import { ChangePassword } from "../Pages/dashboard/settings/changePassword";
import { Step2Verification } from "../Pages/dashboard/settings/step2Verification";
import { NotificationSettings } from "../Pages/dashboard/settings/notificationSettings";
import { ActiveDevices } from "../Pages/dashboard/settings/activeDevices";
import { DeleteMyProfile } from "../Pages/dashboard/settings/deleteMyProfile";
import { SecurityQuestions } from "../Pages/dashboard/settings/securityQuestions";
import { RaiseTicket } from "../Pages/dashboard/support/raiseTicket";
import { OpenTicket } from "../Pages/dashboard/support/openTicket";
import { ClosedTicket } from "../Pages/dashboard/support/closedTicket";
import { TicketStatus } from "../Pages/dashboard/support/ticketStatus";
import { Feed } from "../Pages/userDashboard/feed/feed";
import { FavDoctors } from "../Pages/userDashboard/favoruites/favDoctors";
import { FavBeforeAfter } from "../Pages/userDashboard/favoruites/favBeforeAfter";
import { FavReviews } from "../Pages/userDashboard/favoruites/favReviews";
import { FavQA } from "../Pages/userDashboard/favoruites/favQA";
import { FavOffers } from "../Pages/userDashboard/favoruites/favOffers";
import { FavTreatments } from "../Pages/userDashboard/favoruites/favTreatments";
import { FavBlogs } from "../Pages/userDashboard/favoruites/favBlogs";
import { FavVideos } from "../Pages/userDashboard/favoruites/favVideos";
import { FavArticles } from "../Pages/userDashboard/favoruites/favArticles";
import { FavCreateFolder } from "../Pages/userDashboard/favoruites/favCreateFolder";
import { InviteFriend } from "../Pages/dashboard/referFreind/inviteFriends";
import { InviteesStatus } from "../Pages/dashboard/referFreind/inviteesStatus";
import { YourReward } from "../Pages/dashboard/referFreind/yourReward";
import { EmptyCart } from "../Pages/dashboard/wallet/emptyCart";
import { EmptyWishlist } from "../Pages/dashboard/wallet/emptyWishlist";
import { NoAppointments } from "../shared/emptyListPages/noAppointments";
import { NoFavoruits } from "../Pages/userDashboard/favoruites/noFavoruits";
import { NoNotification } from "../Pages/dashboard/notification/noNotification";
import { NewQA } from "../Pages/dashboard/q&a/new-q-a";
import { HideQA } from "../Pages/dashboard/q&a/hide-q-a";
import { LiveQA } from "../Pages/dashboard/q&a/live-q-a";
import { CreateQA } from "../Pages/dashboard/q&a/create-qa";
import { EarnedPoints } from "../Pages/userDashboard/redeemPoints/earnedPoints";
import { RedeemedPoints } from "../Pages/userDashboard/redeemPoints/redeemedPoints";
import { PointStatus } from "../Pages/userDashboard/redeemPoints/pointStatus";
import { AnswersQA } from "../Pages/dashboard/q&a/answers-qa";

export const UserDashboardRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="user-profile" element={<UserProfile />} />
        <Route path="notification-new" element={<NotificationNew />} />
        <Route path="notification-unread" element={<NotificationUnread />} />
        <Route path="notification-read" element={<NotificationRead />} />
        <Route path="notification-empty" element={<NoNotification />} />
        {/* <Route path="no-appointments" element={<NoAppointments />} /> */}
        <Route
          path="upcomming-appointments"
          element={<UpcommingAppointments />}
        />
        <Route
          path="completed-appointments"
          element={<CompletedAppointments />}
        />
        <Route path="video-consultation" element={<VideoConsultation />} />
        <Route path="create-review" element={<CreateUserReviews />} />
        <Route path="my-hide-reviews" element={<HideUserReviews />} />
        <Route path="my-live-reviews" element={<LiveUserReviews />} />
        <Route path="my-flagged-reviews" element={<FlaggedUserReviews />} />
        <Route path="start-review" element={<StartsReviewNoti />} />
        <Route path="my-new-reviews" element={<NewUserReviews />} />
        <Route path="my-comment-reviews" element={<CommentsReviews />} />
        <Route path="purchased-offers-" element={<PurchasedOffers />} />
        <Route path="recommended-offers-" element={<RecommendedOffers />} />
        <Route path="deals-offers-" element={<DealsOffers />} />
        <Route path="wallet-wishlist" element={<MyWishlist />} />
        <Route path="wallet-empty-wishlist" element={<EmptyWishlist />} />
        <Route path="wallet-empty-cart" element={<EmptyCart />} />
        <Route path="wallet-cart" element={<MyCart />} />
        <Route path="wallet-orders" element={<MyOrders />} />
        <Route path="wallet-history" element={<WalletHistory />} />
        <Route path="wallet-location" element={<WalletLocations />} />
        <Route path="wallet-cancelled" element={<WalletCancelled />} />

        <Route path="settings-change-password" element={<ChangePassword />} />
        <Route
          path="settings-step2-verification"
          element={<Step2Verification />}
        />
        <Route
          path="settings-notification"
          element={<NotificationSettings />}
        />
        <Route path="settings-active-devices" element={<ActiveDevices />} />
        <Route path="settings-delete-profile" element={<DeleteMyProfile />} />
        <Route
          path="settings-security-questions"
          element={<SecurityQuestions />}
        />
        <Route path="support-raise-ticket" element={<RaiseTicket />} />
        <Route path="support-open-ticket" element={<OpenTicket />} />
        <Route path="support-closed-ticket" element={<ClosedTicket />} />
        <Route path="support-ticket-status" element={<TicketStatus />} />
        <Route path="feed" element={<Feed />} />
        <Route path="favoruite-doctors" element={<FavDoctors />} />
        <Route path="favoruite-before-after" element={<FavBeforeAfter />} />
        <Route path="favoruite-reviews" element={<FavReviews />} />
        <Route path="favoruite-q&a" element={<FavQA />} />
        <Route path="favoruite-offers" element={<FavOffers />} />
        <Route path="favoruite-treatments" element={<FavTreatments />} />
        <Route path="favoruite-blogs" element={<FavBlogs />} />
        <Route path="favoruite-videos" element={<FavVideos />} />
        <Route path="favoruite-articles" element={<FavArticles />} />
        <Route path="favoruite-create-folder" element={<FavCreateFolder />} />
        <Route path="favoruite-no-favoruits" element={<NoFavoruits />} />
        <Route path="refers-invite-friend" element={<InviteFriend />} />
        <Route path="refers-invitees-status" element={<InviteesStatus />} />
        <Route path="refers-your-reward" element={<YourReward />} />
        <Route path="new-qa" element={<NewQA />} />
        <Route path="hide-qa" element={<HideQA />} />
        <Route path="live-qa" element={<LiveQA />} />
        <Route path="create-qa" element={<CreateQA />} />
        <Route path="answers-qa" element={<AnswersQA />} />
        <Route path="redeem-earned-points" element={<EarnedPoints />} />
        <Route path="redeem-redeemed-points" element={<RedeemedPoints />} />
        <Route path="redeem-point-status" element={<PointStatus />} />
      </Routes>
    </>
  );
};
