export const concernsOptions = [
  { value: "Filter by Concerns", label: "Filter by Concerns" },
];

export const availabilityOptions = [
  { value: "Availability", label: "Availability" },
  { value: "Today", label: "Today" },
  { value: "Tommorow", label: "Tommorow" },
  { value: "A Week", label: "A Week" },
];

export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];
export const languageOptions = [
  { value: "English", label: "English" },
  { value: "Arabic", label: "Arabic" },
  { value: "Urdu", label: "Urdu" },
  { value: "Chineese", label: "Chineese" },
  { value: "Italian", label: "Italian" },
];

export const experienceOptions = [
  { value: "1-5", label: "1 - 5 years" },
  { value: "5-10", label: "5 - 10 years" },
  { value: "10-15", label: "10 - 15 years" },
  { value: "15+", label: "15+ years" },
];

export const ethinicityOptions = [
  { value: "Ethinicity", label: "Ethinicity" },
  { value: "Asian", label: "Asian" },
  { value: "Caucasian", label: "Caucasian" },
  { value: "African-American", label: "African-American" },
  { value: "Hispanic", label: "Hispanic" },
  { value: "American Indian", label: "American Indian" },
  { value: "Alaska Native", label: "Alaska Native" },
  { value: "Pacific Islander", label: "Pacific Islander" },
];

export const ageOptions = [
  { value: "Age", label: "Age" },
  { value: "18-20", label: "18-20" },
  { value: "21-30", label: "21-30" },
  { value: "31-40", label: "31-40" },
  { value: "41-50", label: "41-50" },
  { value: "51-60", label: "51-60" },
  { value: "Above 60", label: "Above 60" },
];

export const heightOptions = [
  { value: "Height", label: "Height" },
  { value: "Below 5' 0''", label: "Below 5' 0''" },
  { value: "5' 0'' - 5' 5''", label: "5' 0'' - 5' 5''" },
  { value: "5' 6'' - 6' 0''", label: "5' 6'' - 6' 0''" },
  { value: "Above 6' 0''", label: "Above 6' 0''" },
];

export const weightOptions = [
  { value: "Weight", label: "Weight" },
  { value: "80 lbs", label: "80 lbs" },
  { value: "81 - 100 lbs", label: "81 - 100 lbs" },
  { value: "100 - 150", label: "100 - 150" },
  { value: "151 - 200 lbs", label: "151 - 200 lbs" },
  { value: "201 - 250 lbs", label: "201 - 250 lbs" },
  { value: "251 - 300 lbs", label: "251 - 300 lbs" },
  { value: "Above 300 lbs", label: "Above 300 lbs" },
];

export const reviewOptions = [
  { value: "Sorted by", label: "Sorted by" },
  { value: "Review", label: "Review" },
  { value: "Highest", label: "Highest" },
  { value: "Lower", label: "Lower" },
  { value: "New", label: "New" },
  { value: "Old", label: "Old" },
  { value: "Helpful", label: "Helpful" },
];
export const proceureOptions = [
  { value: "Filter by proceure", label: "Filter by proceure" },
];

export const filterByProcedureOptions = [
  {
    value: "Filter Reviews by procedure",
    label: "Filter Reviews by procedure",
  },
  { value: "18-22", label: "18-22" },
  { value: "23-28", label: "23-28" },
  { value: "29-45", label: "29-45" },
  { value: "46-65", label: "46-65" },
  { value: "66-85", label: "66-85" },
  { value: "86-810", label: "86-105" },
  { value: "106-125", label: "106-125" },
];

export const QA_Options = [
  { value: "Q&A", label: "Q&A" },
  { value: "Before & After Procedure", label: "Before & After Procedure" },
  { value: "Before Procedure", label: "Before Procedure" },
  { value: "After Procedure", label: "After Procedure" },
];

export const populrSelectOptions = [
  { value: "Popular", label: "Popular" },
  { value: "Recently Answered", label: "Recently Answered" },
  { value: "Recently Asked", label: "Recently Asked" },
  { value: "Highest view", label: "Highest view" },
  { value: "Most Answered", label: "Most Answered" },
];

export const durationOptions = [
  { value: 7, label: "Last 7 days" },
  { value: 15, label: "Last 15 days" },
  { value: 30, label: "Last 30 days" },
  { value: 180, label: "Last 6 months" },
  { value: 365, label: "Last 1 year" },
];

export const viewOptions = [
  { value: "View", label: "View" },
  { value: "Recent", label: "Recent" },
  { value: "Old", label: "Old" },
  { value: "Highest Rating", label: "Highest Rating" },
  { value: "Lowest Rating", label: "Lowest Rating" },
  { value: "Top View", label: "Top View" },
  { value: "Front View", label: "Front View" },
  { value: "Side View", label: "Side View" },
  { value: "Oblique View", label: "Oblique View" },
  { value: "Back View", label: "Back View" },
];

export const priceOptions = [{ value: "Price", label: "Price" }];

export const offersOptions = [
  { value: "Search Offers", label: "Search Offers" },
];

export const searchOptions = [{ value: "Select", label: "Search Offers" }];

export const sampleOptions = [
  {
    value: "First option",
    label: "First option",
  },
  {
    value: "Second option",
    label: "Second option",
  },
  {
    value: "Third option",
    label: "Third option",
  },
  {
    value: "Fourth option",
    label: "Fourth option",
  },
  {
    value: "Fifth option",
    label: "Fifth option",
  },
];

export const workingDaysOptions = [
  {
    label: "Monday 08:00 AM - 06:00 PM",
    value: "Monday 08:00 AM - 06:00 PM",
  },
  {
    label: "Tuesday 08:00 AM - 06:00 PM",
    value: "Tuesday 08:00 AM - 06:00 PM",
  },
  {
    label: "Wednesday 08:00 AM - 06:00 PM",
    value: "Wednesday 08:00 AM - 06:00 PM",
  },
  {
    label: "Thursday 08:00 AM - 06:00 PM",
    value: "Thursday 08:00 AM - 06:00 PM",
  },
  {
    label: "Friday 08:00 AM - 06:00 PM",
    value: "Friday 08:00 AM - 06:00 PM",
  },
  {
    label: "Saturday 08:00 AM - 06:00 PM",
    value: "Saturday 08:00 AM - 06:00 PM",
  },
  {
    label: "Sunday 08:00 AM - 06:00 PM",
    value: "Sunday 08:00 AM - 06:00 PM",
  },
];


export const departments = [
  {label: "Dermatology", value: "Dermatology"},
  {label: "Gastroenterology", value: "Gastroenterology"},
  {label: "Cardiology", value: "Cardiology"},
  {label: "Endocrinologist", value: "Endocrinologist"},
  {label: "Family Medicine", value: "Family Medicine"},
  {label: "Radiologists", value: "Radiologists"},
  {label: "Gynecology", value: "Gynecology"},
  {label: "Nephrologist", value: "Nephrologist"},
  {label: "Neurology", value: "Neurology"},
  {label: "Oncologist", value: "Oncologist"},
  {label: "Psychiatrist", value: "Psychiatrist"},
  {label: "Anesthesiology", value: "Anesthesiology"},
  {label: "Ophthalmologist", value: "Ophthalmologist"},
  {label: "Otolaryngologist", value: "Otolaryngologist"},
  {label: "Pediatrics", value: "Pediatrics"},
  {label: "Pulmonologists", value: "Pulmonologists"},
  {label: "Hematology", value: "Hematology"},
  {label: "Orthopedics", value: "Orthopedics"},
  {label: "Pathology", value: "Pathology"},
  {label: "Acute Care Surgery", value: "Acute Care Surgery"},
  {label: "Critical Care", value: "Critical Care"},
  {label: "Elderly Services", value: "Elderly Services"},
  {label: "Internal Medicine", value: "Internal Medicine"},
  {label: "Hospice and palliative medicine", value: "Hospice and palliative medicine"},
]

export const experienceRatingOptions = [
  {label: "Highly Recommended", value: "Highly Recommended"},
  {label: "Above Average", value: "Above Average"},
  {label: "Average", value: "Average"},
  {label: "Below Average", value: "Below Average"},
  {label: "Low (Not Recomended)", value: "Low (Not Recomended)"},
]

export const imgCategoryOptions = [
  {label: "Before", value: "Before"},
  {label: "After", value: "After"},
  {label: "Goal", value: "Goal"},
]
export const imgViewOptions = [
  {label: "Top", value: "Top"},
  {label: "Front", value: "Front"},
  {label: "Side", value: "Side"},
  {label: "Oblique", value: "Oblique"},
  {label: "Back", value: "Back"},
]