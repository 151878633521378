import "../../css/main.css";
import "../../css/media.css";
import { HealthProfileHeader } from "./components/profileComponents/healthProfileHeader";
import { ProfileForm } from "./components/profileComponents/profileForm";

export const HealthCareProfile = () => {
  return (
    <div>
      <HealthProfileHeader title="Add Healthcare Facility" page="" />
      <ProfileForm />
    </div>
  );
};
