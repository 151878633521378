export const setSelectValueArray = (arr) => {
  let res = [];
  if (arr && arr.length > 0) {
    arr.forEach((d) => {
      res.push({ value: d, label: d });
    });
  }
  return res;
};
export const makePatientFormDataToShow = (data) => {
  const finalData = {
    ...data,
    title: { value: data.title, label: data.title },
    bloodGroup: { value: data.bloodGroup, label: data.bloodGroup },
    height: { value: data.height, label: data.height },
    weight: { value: data.weight, label: data.weight },
    ethinicity: { value: data.ethinicity, label: data.ethinicity },
    gender: { value: data.gender, label: data.gender },
    language: { value: data.language, label: data.language },
    country: { value: data.country, label: data.country },
    state: { value: data.state, label: data.state },
    district: { value: data.district, label: data.district },
    city: { value: data.city, label: data.city },
  };
  return finalData;
};

export const getUniqueArrayOfObjects = (dataArray) => {
  let unique = [
    ...new Map(dataArray.map((item) => [item["id"], item])).values(),
  ];
  return unique;
};