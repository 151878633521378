import { useEffect, useState } from "react";
import "../../../../../css/main.css";
import "../../../../../css/media.css";
import { NoDataComp } from "../../../../../shared/emptyComponents/noDataComp";
import { imgBaseUrl } from "../../../../../services/config/config";

export const OffersList = (props) => {
  const [offers, setOffers] = useState([]);
  useEffect(() => {
    if (props.data) {
      setOffers(props.data);
    }
  }, [props.data]);

  return (
    <div className="tab_container three-block-container">
      {offers && offers.length > 0 ? (
        <div id="tab1" className="tab_content">
          <div className="doctor-detail_articles-listing">
            {offers.map((data, ind) => (
              <div className="doctor-detail-row pb-0" key={ind + 1}>
                <div className="doctor-img-first">
                  <span>
                    <img
                      src={`${imgBaseUrl + data.image}`}
                      className="img-fluid"
                      style={{ width: "12rem", height: "8rem", objectFit: "cover" }}
                      alt=""
                    />
                  </span>
                </div>
                <div className="doctor-detail-second">
                  <h5 style={{ fontSize: "22px", color: "black" }}>
                    {data.title}
                  </h5>
                  <p className="three-lines-elipsis" style={{ fontSize: "18px" }}>{data.description}</p>
                  <div
                    className="pricing-box d-flex align-items-center gap-3 justify-content-xl-start justify-content-center"
                    style={{ marginTop: "-8px" }}
                  >
                    <h5 style={{ color: "black" }} className="fs-4">
                      AED {data.discountedAmount}
                    </h5>
                    <h6
                      className="px-3 pt-2 pb-1"
                      style={{ fontSize: "17px", marginTop: "10px" }}
                    >
                      {data.discountPercentage}%
                    </h6>
                  </div>
                </div>

                <div
                  className="doctor-detail-third"
                  style={{ marginTop: "-8px" }}
                >
                  <div className="published-date">
                    <p style={{ whiteSpace: "nowrap" }}>{data.status}</p>
                    <a
                      href
                      className="primary__btn"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {data.status === "Under Review" && (
                        <span className={`orange-box box-left`}></span>
                      )}
                      {data.status === "Under Review"
                        ? "Evaluation Started"
                        : data.status === "Expired"
                        ? "Activate Offer"
                        : "View Details"}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoDataComp
          title="No Data!"
          desc="There is no any data yet."
          className="mt-5"
        />
      )}
    </div>
  );
};
