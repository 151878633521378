import styled from "@emotion/styled";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: "3px 5px",
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + .MuiSwitch-track": {
        backgroundColor: "#e78a82",
        opacity: 1,
        border: "1px solid #e78a82",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      ".MuiSwitch-thumb": {
        backgroundColor: "white",
        color: "white",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "white",
      border: "6px solid #e78a82",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: "white",
      color: "white",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    color: "grey",
    width: 13,
    height: 13,
  },
  "& .MuiSwitch-track": {
    border: "1px solid grey",
    borderRadius: 26 / 2,
    backgroundColor: "white",
    opacity: 1,
  },
}));

export const MySwitch = () => {
  const [check, setCheck] = useState(false);
  return (
    <div className="mt-2">
      <FormControlLabel
        style={{ marginLeft: "-30px" }}
        control={
          <IOSSwitch
            value={check}
            onChange={() => setCheck(!check)}
          />
        }
      />
    </div>
  );
};
