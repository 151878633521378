import "../../../css/main.css";
import "../../../css/media.css";
import img1 from "../../../images/expert-advice.png";

export const Banner7 = () => {
  return (
    <div className="black-bg-design">
      <div className="row">
        <div className="col-lg-4">
          <h4>Get Specialist Opinion</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            vulputate vehicula urna ut ullamcorper. Sed pretium ipsum elit, sit
            amet auctor massa maximus eget.
          </p>
        </div>
        <div className="col-lg-4 m-auto text-center">
          <span>
            <img src={img1} className="img-fluid mx-auto" alt="" />
          </span>
        </div>
        <div className="col-lg-4 m-auto">
          <a href className="primary__btn">
            Ask a question
          </a>
        </div>
      </div>
    </div>
  );
};
