import { ORDERS_BY_DOCTOR } from "./actionTypes";

  
  const initialData = {
    list: [],
  };
  export function OrderReducer(state = initialData, action) {
    switch (action.type) {
      case ORDERS_BY_DOCTOR: {
        const data = action.payload;
        return {
          ...state,
          list: data,
        };
      }
      default:
        return state;
    }
  }
  