import { useEffect } from "react";
import { Loading } from "../../../shared/loadings/Loading";
import { ArticlesHeader } from "./components/articlesHeader";
import { ArticlesTabs } from "./components/articlesTabs";
import { ArticleUploadForm } from "./components/uploadArticles/articleUploadForm";
import { setLoading } from "../../../Store/loading/loadingAction";
import { useDispatch } from "react-redux";
import { useGetAllBodyParts } from "../../../Store/bodyPart/bodyPartHook";
import { useGetAllProcedures } from "../../../Store/procedure/procedureHooks";
import { useGetAllTreatments } from "../../../Store/treatment/treatmentHook";

export const UploadArticles = () => {
  const dispatch = useDispatch();
  const getAllBodyParts = useGetAllBodyParts()
  const getAllProcedures = useGetAllProcedures();
  const getAllTreatments = useGetAllTreatments()
  useEffect(() => {
    dispatch(setLoading(false));
    getAllBodyParts()
    getAllProcedures()
    getAllTreatments()
  }, []);
  return (
    <section class="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div class="container-fluid p-0">
        <ArticlesHeader title="Articles" />
        <ArticlesTabs />
        <ArticleUploadForm />
      </div>
    </section>
  );
};
