import "../../../../css/main.css";
import { patientReviewsData } from "../../../../demoData/patientReviewsData";
import { ReviewStars } from "../../../../shared/reviewStars";

export const PatientReview = (props) => {
  return (
    <div className="col-lg-6">
      <table
        id="example"
        className="table dashboard-table"
        style={{ width: "100" }}
      >
        <thead>
          <tr>
            <th>
              <h6 className="m-0">{props.title}</h6>
            </th>
            <th>
              <span className="text-dark font-weight-normal"> View all</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {patientReviewsData.map((data, ind) => (
            <tr key={ind + 1}>
              <td>
                <span className="ico-img-round">
                  <img src={data.img} className="img-fluid" alt="" />
                </span>
                <div className="person-data">
                  {data.name}
                  <ReviewStars />
                </div>
              </td>
              <td>{data.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
