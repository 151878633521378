import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { makeQaSelectedDataAsFormData } from "src/services/dynamicContentService";
import { useGetAllBodyParts } from "src/Store/bodyPart/bodyPartHook";
import { useGetAllProcedures } from "src/Store/procedure/procedureHooks";
import { useSetQAFormData } from "src/Store/qa/qaHook";
import { useGetAllTreatments } from "src/Store/treatment/treatmentHook";
import { ModalPopup } from "../../../../../shared/Modals/ModalPopup"
import { CreateQAForm1 } from "./createQAForm1";
import { CreateQAForm2 } from "./createQAForm2";

export const EditQaModal = (props) => {
  const matches = useMediaQuery("(max-width:992px)");
  const [showForm, setShowForm] = useState(1);
  const setQAFormData = useSetQAFormData();
  
  const getAllBodyParts = useGetAllBodyParts()
  const getAllProcedures = useGetAllProcedures();
  const getAllTreatments = useGetAllTreatments()
  useEffect(() =>{
    getAllProcedures()
    getAllBodyParts()
    getAllTreatments()
  }, [])
  const handleClose = () => {
    setQAFormData(null);    
    props.getData()
    props.setShowModal(false)
  }
  useEffect(() => {
    if(props.selectedData && props.selectedData.id){
    setQAFormData(makeQaSelectedDataAsFormData(props.selectedData));
    }
  }, [props.selectedData])
  return (
        <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "60rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-danger rounded-3" style={{maxHeight: "95vh", overflowY: "auto"}}>
      <div className=" myreviews-section px-0 py-0">
      <div id="tab1" className="px-0">
      <div className="card formSlider flex justify-center px-5 py-4">
          <div id="msform" className="ps-5">
            <ul id="progressbar">
              <li className={`active`} id="account"></li>
              <li
                className={`${showForm === 2 ? "active" : ""}`}
                id="personal"
              ></li>
            </ul>
            {showForm === 1 && <CreateQAForm1 fullView setShowForm={setShowForm} />}
            {showForm === 2 && <CreateQAForm2 fullView setShowForm={setShowForm} handleClose={handleClose} />}
          </div>
        </div>
      </div>
      </div>
      </div>
      </ModalPopup>
    )
}