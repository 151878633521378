import {
  OFFERS_BY_DOCTOR,
  OFFERS_BY_DOCTOR_AND_STATUS,
  ALL_OFFERS,
} from "./actionTypes";

const initialData = {
  list: [],
  listBYStatus: [],
  fullList: [],
};
export function OfferReducer(state = initialData, action) {
  switch (action.type) {
    case OFFERS_BY_DOCTOR: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    case OFFERS_BY_DOCTOR_AND_STATUS: {
      const data = action.payload;
      return {
        ...state,
        listBYStatus: data,
      };
    }
    case ALL_OFFERS: {
      const data = action.payload;
      return {
        ...state,
        fullList: data,
      };
    }
    default:
      return state;
  }
}
