import "../../../../../css/main.css";

export const VerificationContent = () => {
    return (
        <div className="tab_container articles-container px-0 pt-5 overflow-hidden">
        <div id="tab1" className="tab_content">
          <div className="settings-div">
            <div className="2-step-confirmation">
              <div className="row">
                <div className="col-lg-6">
                  <h4>Why do I need this?</h4>
                  <p>
                    Passwords can get stolen - especially if you use
                    the same password for multiple sites. Adding
                    Two-Step Verification means that even if your
                    password gets stolen, your account will remain
                    secure.
                  </p>
                  <h4>How does it work?</h4>
                  <p>
                    After you turn on Two-Step Verification for your
                    account, signing in will be a little different:
                  </p>
                  <ul style={{fontSize: "12px"}}>
                    <li>You’ll enter your password, as usual.</li>
                    <li>We’ll send you an OTP.</li>
                    <li>
                      You’ll enter the OTP, and complete your Sign-In.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <h4>Use your e-mail as 2 step verification</h4>
                  <p>
                    Tell us the email ID you’d like to use as your
                    authenticator. When you sign in, we’ll send this
                    email a text message with a one-time password.
                  </p>
                  <form action="">
                    <div className="form-group">
                      <label for="">Enter your E-mail ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter"
                      />
                    </div>
                   
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}