import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/main.css";
import "../../../css/media.css";
import {
  faUserInjured,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

export const TabBanner = (props) => {
  const matches = useMediaQuery("(max-width:992px)");
  return (
    <div className="common-pad switch-box px-2">
      <span className="question-tag">
        How would you like to meet your doctor?{" "}
      </span>
      <div className={`switches-container ${matches ? "mt-2" : ""}`}>
        <input
          type="radio"
          id="switchMonthly"
          name="switchPlan"
          value="Monthly"
          defaultChecked="checked"
          className="fs-6 d-flex align-items-center gap-1 justify-content-center"
        />
        <input
          type="radio"
          id="switchYearly"
          name="switchPlan"
          value="Yearly"
          className="fs-6 d-flex align-items-center gap-1 justify-content-center"
        />
        <label
          onClick={() => props.setTab(0)}
          for="switchMonthly"
          id="inperson"
          className="fs-6 d-flex align-items-center gap-1 justify-content-center"
        >
          In-person consultation <FontAwesomeIcon icon={faUserInjured} />
        </label>
        <label
          onClick={() => props.setTab(1)}
          for="switchYearly"
          id="virtual-consultation"
          className="fs-6 d-flex align-items-center gap-1 justify-content-center"
        >
          Virtual consultation <FontAwesomeIcon icon={faVideoCamera} />
        </label>
        <div className="switch-wrapper">
          <div className="switch">
            <div className="fs-6">
              <div className="d-flex align-items-center gap-1 justify-content-center" style={{paddingTop: "2px"}}>
                In-person consultation <FontAwesomeIcon icon={faUserInjured} />
              </div>
            </div>
            <div className="fs-6" style={{paddingTop: "2px"}}>
              Virtual consultation <FontAwesomeIcon icon={faVideoCamera} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
