import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import "../../../../css/aos.css";
import "../../../../css/main.css";
import "../../../../css/media.css";
import antiaging from "../../../../images/Featured/Antiaging.jpg";
import antiagingColor from "../../../../images/Featured/Antiaging-Color.jpg";

import Hair_Color from "../../../../images/Featured/Hair_Color.jpg";
import Hair from "../../../../images/Featured/Hair.jpg";
import LipsColor from "../../../../images/Featured/Lips-Color.jpg";
import Lips from "../../../../images/Featured/Lips.jpg";
import Body_contouring_Color from "../../../../images/Featured/Body-contouring_Color.jpg";
import Body_contouring from "../../../../images/Featured/Body-contouring.jpg";
import Face_Lift_Color from "../../../../images/Featured/Face_Lift_Color.jpg";
import FaceLift from "../../../../images/Featured/Face-Lift.jpg";
import Acne_color from "../../../../images/Featured/Acne_color.jpg";
import Acne from "../../../../images/Featured/Acne.jpg";
import ButtliftColor from "../../../../images/Featured/Buttlift-Color.jpg";
import Buttlift from "../../../../images/Featured/Buttlift.jpg";
import DentalColor from "../../../../images/Featured/Dental-Color.jpg";
import Dental from "../../../../images/Featured/Dental.jpg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const FeaturedProcedureOnBigScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div className="featured-procedure">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="before-after-box1">
              <h2>
                Featured Procedures
                <span className="title-head">
                  <span className="type-writer">BE INSPIRED</span>
                </span>
              </h2>
              <p className="p-head-size">
                Lorem ipsum dolor sit amet, consectetur adipiscing eger
                vulputate vehicula urna ut ullamcorper.ut ullamcorper.ut
                ullamcorper.ut ullamcorper.ut ullamcorper.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="book-consultation"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <svg id="rectangle">
                <rect id="stroke" rx="3" ry="5" />
              </svg>
              <a
                href="/book-appoinment"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/book-appointment");
                }}
                className="book-appoinment-btn"
              >
                Book a Consultation
              </a>
              <p>
                Lorem ipsum dolor sit amet, <br />
                consectetur
              </p>
            </div>
          </div>
        </div>
        <div className="featured-block-row2">
          <div className="row">
            <div className="col-md-4">
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={antiaging} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={antiagingColor} className="img-fluid" alt="" />
                  </span>
                  <h5>Anti Aging</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={Hair} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={Hair_Color} className="img-fluid" alt="" />
                  </span>
                  <h5>Hair Loss</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={Lips} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={LipsColor} className="img-fluid" alt="" />
                  </span>
                  <h5>Lip Augmentation</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={Body_contouring} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img
                      src={Body_contouring_Color}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h5>Body Contouring</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={FaceLift} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={Face_Lift_Color} className="img-fluid" alt="" />
                  </span>
                  <h5>Face Lift</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={Acne} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={Acne_color} className="img-fluid" alt="" />
                  </span>
                  <h5>Acne & Rosace</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={Buttlift} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={ButtliftColor} className="img-fluid" alt="" />
                  </span>
                  <h5>Butt Lift</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
              <div
                className="featured-procedure-block hover-box"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <a href="/">
                  <span className="before-hover-grey">
                    <img src={Dental} className="img-fluid" alt="" />
                  </span>
                  <span className="after-hover-color">
                    <img src={DentalColor} className="img-fluid" alt="" />
                  </span>
                  <h5>Dental</h5>
                  <p className="p-head-size-17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing eger
                    vulputate vehicula urna ut ullamcorper.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book-consultation booking-seperate">
          <svg id="rectangle">
            <rect id="stroke" rx="3" ry="5" />
          </svg>
          <a
            href="/book-appoinment"
            onClick={(e) => {
              e.preventDefault();
              navigate("/book-appointment");
            }}
            className="book-appoinment-btn"
          >
            Book a Consultation
          </a>
          <p>
            Lorem ipsum dolor sit amet, <br />
            consectetur
          </p>
        </div>
      </div>
    </div>
  );
};
