import { ReferFriendHeader } from "./components/referFriendHeader";
import { InviteFriendContent } from "./components/inviteFriendComponents/inviteFriendContent";
import { TabsGroup } from "../../../shared/tabsGroup";
import { referFriendTabs } from "../../../demoData/tabsData";

export const InviteFriend = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <ReferFriendHeader title="Invite Friends" />
        <TabsGroup tabs={referFriendTabs()} />
        <InviteFriendContent />
      </div>
    </section>
  );
};
