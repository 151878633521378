// import "../../css/aos.css";
// import "../../css/main.css";
// import "../../css/media.css";
// import img1 from "../../images/Procedures/procedure-white3.jpg";
// import img2 from "../../images/Procedures/Image_0005_Body.jpg";
// import img3 from "../../images/Procedures/procedure-color2.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faBlog,
//   faChevronLeft,
//   faFeed,
// } from "@fortawesome/free-solid-svg-icons";
// import {
//   faCircleQuestion,
//   faComments,
//   faStar,
// } from "@fortawesome/free-regular-svg-icons";
// import Expand from "react-expand-animated";

// export const MenuModal = (props) => {
//   const transitions = ["width", "opacity"];
//   const styles = {
//     open: {
//       background: "#fff",
//     },
//   };
//   const handleClose = () => {
//     props.setOpenAnimation(false);
//     setTimeout(() => {
//       props.setShowMenuModal(false);
//     }, 1000);
//   };
//   return (
//     <Expand
//       open={props.openAnimation}
//       duration={500}
//       styles={styles}
//       transitions={transitions}
//       className="menu-block"
//     >
//       <span className="close-btn" onClick={handleClose}>
//         <FontAwesomeIcon icon={faChevronLeft} />
//         <FontAwesomeIcon icon={faChevronLeft} className="-ml-1 mr-1 text-sm" />
//         Close
//       </span>
//       <div className="row pb-52 sm:pb-48 lg:pb-0">
//         <div className="col-lg-3">
//           <div className="menu-block-inner-first">
//             <div>
//               <ul>
//                 <li>
//                   <a href="/">
//                     <FontAwesomeIcon icon={faStar} />
//                     Reviews
//                   </a>
//                   <p>
//                     Real Stories, Real Transformations, Authentic Insights -
//                     Patient Reviews That Inspire
//                   </p>
//                 </li>
//                 <li>
//                   <a href="/">
//                     <FontAwesomeIcon icon={faComments} />
//                     FAQ
//                   </a>
//                   <p>
//                     Navigating Your Journey, Unlock Answers, Explore Doctor's
//                     Insights - Demystifying the Unknown
//                   </p>
//                 </li>
//                 <li>
//                   <a href="/">
//                     <FontAwesomeIcon icon={faCircleQuestion} />
//                     Wallet
//                   </a>
//                   <p>
//                     Best Companion, Maximize Savings, Smart Alerts - Your
//                     Signature of Style
//                   </p>
//                 </li>
//                 <li>
//                   <a href="/">
//                     <FontAwesomeIcon icon={faBlog} />
//                     Blog
//                   </a>
//                   <p>
//                     Proven Tips, Procedures, Expert Suggestions - Journey to
//                     Radiance
//                   </p>
//                 </li>
//                 <li>
//                   <a href="/">
//                     <FontAwesomeIcon icon={faFeed} />
//                     Feed
//                   </a>
//                   <p>
//                     Experts Insights, Find Procedure, Real life Experiences -
//                     Unleash Your Beauty Potential
//                   </p>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-3 col-sm-4">
//           <div className="menu-img-box">
//             <img src={img1} className="img-fluid" alt="" />
//             <h6>
//               Thigh lift<span>25% OFF</span>{" "}
//             </h6>
//           </div>
//         </div>
//         <div className="col-lg-3 col-sm-4">
//           <div className="menu-img-box">
//             <img src={img2} className="img-fluid" alt="" />
//             <h6>
//               Thigh lift<span>25% OFF</span>{" "}
//             </h6>
//           </div>
//         </div>
//         <div className="col-lg-3 col-sm-4">
//           <div className="menu-img-box">
//             <img src={img3} className="img-fluid" alt="" />
//             <h6>
//               Thigh lift<span>25% OFF</span>{" "}
//             </h6>
//           </div>
//         </div>
//       </div>

//       <div className="contact-us-sidebar">
//         <a href="/" className="primary__btn">
//           Contact us
//         </a>
//         <p className="ms-0">
//           Your Ideas Matters, Your Voice Be Heard, Your Story Inspires - Reach
//           Out and Let's Chat to Create Memorable Moments
//         </p>
//       </div>
//     </Expand>
//   );
// };

import "../../css/aos.css";
import "../../css/main.css";
import "../../css/media.css";
import img1 from "../../images/Procedures/procedure-white3.jpg";
import img2 from "../../images/Procedures/Image_0005_Body.jpg";
import img3 from "../../images/Procedures/procedure-color2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlog,
  faChevronLeft,
  faFeed,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleQuestion,
  faComments,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import { Grow } from "@mui/material";

export const MenuModal = (props) => {
  return (
    <Grow
      in={props.showMenuModal}
      style={{
        transformOrigin: "-100% -100%",
        background: "white",
        opacity: "1",
      }}
      {...(props.showMenuModal ? { timeout: 1000 } : {})}
    >
      <div className="menu-block">
        <span
          className="close-btn"
          onClick={() => props.setShowMenuModal(false)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="-ml-1 mr-1 text-sm"
          />
          Close
        </span>
        <div className="row pb-52 sm:pb-48 lg:pb-0">
          <div className="col-lg-3">
            <div className="menu-block-inner-first">
              <div>
                <ul>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faStar} />
                      Reviews
                    </a>
                    <p>
                      Real Stories, Real Transformations, Authentic Insights -
                      Patient Reviews That Inspire
                    </p>
                  </li>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faComments} />
                      FAQ
                    </a>
                    <p>
                      Navigating Your Journey, Unlock Answers, Explore Doctor's
                      Insights - Demystifying the Unknown
                    </p>
                  </li>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faCircleQuestion} />
                      Wallet
                    </a>
                    <p>
                      Best Companion, Maximize Savings, Smart Alerts - Your
                      Signature of Style
                    </p>
                  </li>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faBlog} />
                      Blog
                    </a>
                    <p>
                      Proven Tips, Procedures, Expert Suggestions - Journey to
                      Radiance
                    </p>
                  </li>
                  <li>
                    <a href="/">
                      <FontAwesomeIcon icon={faFeed} />
                      Feed
                    </a>
                    <p>
                      Experts Insights, Find Procedure, Real life Experiences -
                      Unleash Your Beauty Potential
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-4">
            <div className="menu-img-box">
              <img src={img1} className="img-fluid" alt="" />
              <h6>
                Thigh lift<span>25% OFF</span>{" "}
              </h6>
            </div>
          </div>
          <div className="col-lg-3 col-sm-4">
            <div className="menu-img-box">
              <img src={img2} className="img-fluid" alt="" />
              <h6>
                Thigh lift<span>25% OFF</span>{" "}
              </h6>
            </div>
          </div>
          <div className="col-lg-3 col-sm-4">
            <div className="menu-img-box">
              <img src={img3} className="img-fluid" alt="" />
              <h6>
                Thigh lift<span>25% OFF</span>{" "}
              </h6>
            </div>
          </div>
        </div>

        <div className="contact-us-sidebar">
          <a href="/" className="primary__btn">
            Contact us
          </a>
          <p className="ms-0">
            Your Ideas Matters, Your Voice Be Heard, Your Story Inspires - Reach
            Out and Let's Chat to Create Memorable Moments
          </p>
        </div>
      </div>
    </Grow>
  );
};
