import { NoDataComp } from "src/shared/emptyComponents/noDataComp";
import moment from "moment";
import { imgBaseUrl } from "src/services/config/config";
import { getIdOfNestedObj, getStep2NestedField } from "src/services/dynamicContentService";
import { useCancelOrderByPatient, useReOrder } from "src/Store/order/orderHook";
import { currentPatientId } from "src/Store/selectors/UserSelectors";
import ShortUniqueId from 'short-unique-id';

export const OrdersTable = (props) => {
  const cancelOrderByPatient = useCancelOrderByPatient()
  const reOrder = useReOrder()
  const uid = new ShortUniqueId({ length: 6 });
  const total = (item) => {
    let res = 0;
    if (item.discountedAmount > 0) {
      res = item.discountedAmount * item.quantity;
    } else {
      res = item.actualAmount * item.quantity;
    }

    return res;
  };
  const isCancelled =
    window.location.pathname === "/user-dashboard/wallet-cancelled";
  const isLocation =
    window.location.pathname === "/user-dashboard/wallet-location";

  return (
    <div className="tab_container articles-container px-0">
      <div id="tab1" className="tab_content">
        <div className="overflow-scroll-table overflow-auto">
          {props.list && props.list.length > 0 ? (
            <table
              className="table table-style-2 wallet-my-orders-table w-100"
              id="example"
            >
              <thead>
                <tr>
                  <th> Order#</th>
                  <th>Date of Purchase</th>
                  <th> Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                  {isCancelled && <th>Cancelled Reason</th>}
                  {(isLocation || isCancelled) && <th>Location</th>}
                   <th>Status</th>
                  {props.order && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {props.list.map((d, ind) => (
                  <tr key={ind + 1}>
                    <td style={{ whiteSpace: "nowrap" }}>{d.orderId}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {moment(d.createdAt).format("DD MMM YYYY")}
                    </td>
                    <td className="d-flex align-items-center gap-1 w-100">
                      <span>
                        <img
                          src={`${imgBaseUrl + d.offer.image}`}
                          className="img-fluid rounded-2"
                          style={{
                            width: "6rem",
                            minWidth: "6rem",
                            height: "4rem",
                          }}
                          alt=""
                        />
                      </span>
                      <div
                        className="flex-table-box product-block-box"
                        style={{ width: "10rem", minWidth: "10rem" }}
                      >
                        <h6 style={{ whiteSpace: "nowrap" }}>
                          {d.offer.title}
                          <span className="product-sub-text1">Offers</span>
                          <span className="product-sub-text2">
                            Expires On{" "}
                            {moment(d.offer.validTo).format("DD MMM YYYY")}
                          </span>
                        </h6>
                      </div>
                    </td>
                    <td className="text-center">{d.offer.quantity}</td>
                    <td>
                      <div
                        className="price-strikethrough"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <h6>
                          <span>AED {d.offer.actualAmount}</span>
                          AED {d.offer.discountedAmount}
                        </h6>
                      </div>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      AED {total(d.offer)}
                    </td>
                    {isCancelled && (
                      <td>
                        <p
                          className="pe-2"
                          style={{
                            height: "45px",
                            fontSize: "11px",
                            lineHeight: "16px",
                            overflowY: "auto",
                            width: "16rem",
                          }}
                        >
                          {d.cancellationReason}
                        </p>
                      </td>
                    )}
                    {(isLocation || isCancelled) && (
                      <td>
                        <div className="flex-table-box">
                          <span>
                            <img
                              src={`${
                                imgBaseUrl +
                                getStep2NestedField(d, "doctor", "profileImg")
                              }`}
                              className="img-fluid"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                minWidth: "3rem",
                              }}
                              alt=""
                            />
                          </span>

                          <h6>
                            <span style={{ whiteSpace: "nowrap" }}>
                              {getStep2NestedField(d, "doctor", "firstName")}{" "}
                              {getStep2NestedField(d, "doctor", "lastName")}
                            </span>
                            <span style={{ whiteSpace: "nowrap" }}>
                              {getStep2NestedField(d, "doctor", "city")}
                              {", "}
                              {getStep2NestedField(d, "doctor", "country")}
                            </span>
                          </h6>
                        </div>
                      </td>
                    )}{" "}
                     <td>
                      {d.status === "Pending" ? (
                        <div className="d-flex align-items-center">
                          <span className="pending-round-orange"></span>
                          {d.status}
                        </div>
                      ) : d.status === "Rejected" ? (
                        <div className="d-flex align-items-center">
                          <span className="cancelled-round-red"></span>
                          {d.status}
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <span className="processed-round-green"></span>{" "}
                          {d.status}
                        </div>
                      )}
                    </td>
                    {props.order && (
                      <td className="p-0">
                        <button
                        onClick={() => reOrder({ orderId: uid.rnd(), doctor: getIdOfNestedObj(d, "doctor"), status: "Pending", patient: currentPatientId(), offer: d.offer }, props.getData)}
                          className="primary__btn3 smallText py-2 px-0 mt-0"
                          style={{ width: "8rem" }}
                        >
                          Buy Agian
                        </button>
                        <button
                        onClick={() => cancelOrderByPatient(d.id, props.getData)}
                          className="primary__btn smallText py-2 px-0 mt-2"
                          style={{ width: "8rem" }}
                        >
                          Cancel Order
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoDataComp
              title="No any order"
              desc="You didn't create even a single order yet!"
            />
          )}
        </div>
      </div>
    </div>
  );
};
