import "../../../../css/main.css";
import "../../../../css/media.css";
import img1 from "../../../../images/face-liposection-review1/img1.png";
import img2 from "../../../../images/face-liposection-review1/img2.png";
import img3 from "../../../../images/face-liposection-review1/img3.png";
import img4 from "../../../../images/face-liposection-review1/img4.png";
import { CommentBox } from "./subcomponents/commentBox";
import doctorImg from "../../../../images/arab F-3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faStarHalfAlt } from "@fortawesome/free-regular-svg-icons";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { IsLike } from "../../../../shared/AddToComps/isLike";
import { AddToFav } from "../../../../shared/AddToComps/addToFav";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export const DetailsContent = (props) => {
  const matches = useMediaQuery("(max-width:766px)");
  const navigate = useNavigate();
  return (
    <div className="questions-answer-div mt-0">
      <div className="question-answer-box border-0">
        <span className="review-person-name">MM</span>
        <div className="story-box-head">
          <div className="story-box-head-left">
            <h4>Marilyn Matthews</h4>
          </div>
        </div>
        <div className="rating-box-wrapper">
          <div className="rating-head-data">
            <div className="rating justify-content-start">
              <FontAwesomeIcon icon={props.solidStar} />
              <FontAwesomeIcon icon={props.solidStar} />
              <FontAwesomeIcon icon={props.solidStar} />
              <FontAwesomeIcon icon={faStarHalfAlt} />
              <FontAwesomeIcon icon={faStarHalfAlt} />
              <span className="number">(3.5/5)</span>
            </div>
            <span className="eye-ico">
              <FontAwesomeIcon icon={faEye} /> 2,300
            </span>
            <span className="">1 Recommend this Doctor</span>
            <div
              style={{ marginTop: matches ? "20px" : "-8px" }}
              className={`d-flex align-items-center gap-0 mb-5 mb-md-3`}
            >
              <div className="mx-3">
                <IsLike />
              </div>
              <span>
                <a href className="primary__btn3">
                  Worth it
                </a>
              </span>
            </div>
          </div>
          <div className="rating-box-wrap-right h-12">
            <span className="usd-value"> USD 234</span>
          </div>
        </div>
        <h4 className="d-flex justify-content-between w-100 mt-4 worthit-box">
          Received Botox and filler - feel more confident with my appearance
          <button
            className="button like rounded like-effect-click"
            data-toggle="tooltip"
            title="Add to Favourites!"
          >
            <AddToFav />
          </button>
        </h4>

        <div className="row mt-3">
          <div className="col-lg-8">
            <div className="question-ans-head-main">
              <ul className="img-list-popup">
                <li className="mb-2">
                  <img src={img1} className="img-fluid" alt="" />
                </li>
                <li className="mb-2">
                  <img src={img2} className="img-fluid" alt="" />
                </li>
                <li className="mb-2">
                  <img src={img3} className="img-fluid" alt="" />
                </li>
                <li className="mb-2">
                  <img src={img4} className="img-fluid" alt="" />
                  <button type="button" className="num-photos border-0">
                    <span>5+</span>
                  </button>
                </li>
              </ul>
              <div className="patient-details-box1 w-100">
                <ul>
                  <li>
                    Gender: <span>Female</span>
                  </li>
                  <li>
                    Age: <span>30-39</span>
                  </li>
                  <li>
                    Ethnicity: <span>African-American</span>
                  </li>
                  <li>
                    Height: <span> 5’ 0’’ - 5’ 5’’</span>
                  </li>
                  <li>
                    Weight: <span>200 - 250 lbs</span>
                  </li>
                </ul>
              </div>
              <p className="p-head-size">
                PROCEDURE: Blepharoplasty, Eyelid Surgery, LOWER BLEPHAROPLASTY,
                Excessive Eyelid Skin, Aging Face, Facial Aging, Droopy Eyelid,
                Loose Skin
              </p>
              <div className="story-types">
                <h6>Satisfied with:</h6>
                <div className="d-flex flex-wrap">
                  <li
                    style={{ background: "lightGrey", fontSize: "14px" }}
                    className="rounded-2 px-2 text-dark d-flex align-items-center mb-3"
                  >
                    Friendlines
                  </li>
                  <li
                    style={{ background: "lightGrey", fontSize: "14px" }}
                    className="rounded-2 px-2 text-dark d-flex align-items-center mb-3"
                  >
                    Explanation of health issue
                  </li>
                  <li
                    style={{ background: "lightGrey", fontSize: "14px" }}
                    className="rounded-2 px-2 text-dark d-flex align-items-center mb-3"
                  >
                    Treatment Satisfaction
                  </li>
                  <li
                    style={{ background: "lightGrey", fontSize: "14px" }}
                    className="rounded-2 px-2 text-dark d-flex align-items-center mb-3"
                  >
                    Value for money
                  </li>
                  <li
                    style={{ background: "lightGrey", fontSize: "14px" }}
                    className="rounded-2 px-2 text-dark d-flex align-items-center mb-3"
                  >
                    Less wait time
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="doctors-listing-box">
              <div className="row">
                <div className="col-md-12">
                  <div className="doctor-img-list">
                    <span>
                      <img
                        src={doctorImg}
                        className="img-fluid w-100 doctor-img1 mx-auto"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="doctor-details">
                    <h5>Dr. Lauren Clinton</h5>
                    <div className="detail-box">
                      <div className="rating">
                        <div className="">
                          <FontAwesomeIcon icon={props.solidStar} />
                        </div>
                        <div className="">
                          <FontAwesomeIcon icon={props.solidStar} />
                        </div>
                        <div className="">
                          <FontAwesomeIcon icon={props.solidStar} />
                        </div>
                        <div className="">
                          <FontAwesomeIcon icon={faStarHalfAlt} />
                        </div>
                        <div className="">
                          <FontAwesomeIcon icon={faStarHalfAlt} />
                        </div>
                      </div>
                      <span className="number">(1,378)</span>
                      <a
                        href="/badge"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/badge");
                        }}
                        type="button"
                        data-toggle="tooltip"
                        title="Hooray!"
                      >
                        <FontAwesomeIcon icon={faTrophy} className="fs-4" />
                      </a>
                    </div>
                    <a
                      href="/book-appoinment"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/book-appointment");
                      }}
                      className="primary__btn4 w-full"
                    >
                      <span className="text-base xl:text-xl">
                        {" "}
                        Book an Appointment
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CommentBox />
        <CommentBox />
      </div>
    </div>
  );
};
