import { ALL_BODY_PARTS } from "./actionTypes";

const initialData = {
  list: [],
};
export function BodyPartReducer(state = initialData, action) {
  switch (action.type) {
    case ALL_BODY_PARTS: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    default:
      return state;
  }
}
