
const initialData = {
    isLoading: true,
};
export function LoadingReducer(state = initialData, action) {
  switch (action.type) {
    case "LOADING_ACTION": {
      const data = action.payload;
      return {
        ...state,
        isLoading: data,
      };
    }
    default:
      return state;
  }
}
