import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/main.css";
import "../../css/aos.css";
import "../../css/media.css";
import userImg from "../../images/doctor-detail1.jpg";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faBell, faCalendarCheck } from "@fortawesome/free-regular-svg-icons";
import Expand from "react-expand-animated";
import { isDoctor } from "../../Store/selectors/isDoctor";
import { isPatient } from "../../Store/selectors/isPatient";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../Store/selectors/UserSelectors";
import { imgBaseUrl } from "../../services/config/config";
import { useLogout } from "../../Store/Auth/authHooks";

export const UserDropdown = (props) => {
  const user = getUser();
  const transitions = ["height", "opacity"];
  const styles = {
    open: {
      zIndex: "20",
      background: "transparent",
      boxShadow: "1px 1px 6px 1px #00000024",
    },
  };
  const navigate = useNavigate();
  const logout = useLogout();
  const handleDashboardsRouting = (e) => {
    e.preventDefault();
    if (isDoctor()) {
      navigate("/doctor-dashboard");
      return;
    }
    if (isPatient()) {
      navigate("/user-dashboard");
      return;
    }
  };
  return (
    <Expand
      open={props.showUserDropdow}
      duration={500}
      styles={styles}
      transitions={transitions}
      className={`bg-white rounded-2 px-4 py-4 position-absolute top-100 ${props.position} end-0`}
    >
      <div className="d-flex flex-column gap-2 rounded-2">
        <div className="user-icon-list">
          <div className="view-profile-box">
            <span>
              <img
                src={
                  user && user.profileImg
                    ? `${imgBaseUrl + user.profileImg}`
                    : userImg
                }
                className="img-fluid"
                alt="user"
              />
            </span>
            <div className="user-data">
              {user && user.id && (
                <h6>
                  {user.username} <span>{user.email}</span>{" "}
                </h6>
              )}
              <a
                href
                onClick={handleDashboardsRouting}
                className="primary__btn3"
              >
                View profile
              </a>
            </div>
          </div>
          <ul>
            <li>
              <a href="/">
                Settings
                <FontAwesomeIcon icon={faGear} />
              </a>
            </li>
            <li>
              <a href="/">
                Appointments
                <FontAwesomeIcon icon={faCalendarCheck} />
              </a>
            </li>
            <li>
              <a href="/">
                Notifications
                <FontAwesomeIcon icon={faBell} />
              </a>
            </li>
            {/* <!-- <li><a href="/">Logout<i className="fa-light fa-power-off"></i></a></li> --> */}
          </ul>
          <div className="button-flex justify-content-center">
            <button onClick={logout} type="button" className="logout-btn">
              Logout
            </button>
          </div>
        </div>
      </div>
    </Expand>
  );
};
