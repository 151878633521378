import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../css/main.css";
import {
  faArrowLeftLong,
  faCheck,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { languageOptions, workingDaysOptions } from "../../../../../demoData/selectOptionsData";
import { useEffect, useState } from "react";
import { handleFieldContent } from "./contentsServices";
import { HelpModal } from "../../../../../shared/Modals/helpModal";
import { getUser } from "../../../../../Store/selectors/UserSelectors";
import { formFinalData } from "../../../../../services/generalService";
import {
  useDoctor,
  useSetDoctorProfileFormData,
  useUpdateDoctor,
} from "../../../../../Store/Doctor/doctorHooks";

export const FormStep3 = (props) => {
  const [showAwards, setShowAwards] = useState(1);
  const [showMembership, setShowMembership] = useState(1);
  const [showPublications, setShowPublications] = useState(1);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [finalData, setFinalData] = useState({});
  const user = getUser();
  const updateDoctor = useUpdateDoctor();
  const useDoctorState = useDoctor();
  const setDoctorProfileFormData = useSetDoctorProfileFormData();

  useEffect(() => {
    if (useDoctorState.formData) {
      if (
        useDoctorState.formData.awards &&
        useDoctorState.formData.awards.length > 1
      ) {
        setShowAwards(useDoctorState.formData.awards.length);
      }
      if (
        useDoctorState.formData.membership &&
        useDoctorState.formData.membership.length > 1
      ) {
        setShowMembership(useDoctorState.formData.membership.length);
      }
      if (
        useDoctorState.formData.publications &&
        useDoctorState.formData.publications.length > 1
      ) {
        setShowPublications(useDoctorState.formData.publications.length);
      }
      setFinalData(useDoctorState.formData);
    }
  }, [useDoctorState.formData]);

  const handleChange = (key, val) => {
    const updatedFinalData = { ...finalData, [key]: val };
    setFinalData(updatedFinalData);
    setDoctorProfileFormData(updatedFinalData);
  };

  const handleChangeArray = (key, val, i) => {
    let arr = finalData[key] ? finalData[key] : [];
    arr[i - 1] = val;
    const updatedFinalData = { ...finalData, [key]: arr };
    setDoctorProfileFormData(updatedFinalData);
    setFinalData(updatedFinalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user && user.doctor)
      updateDoctor({ id: user.doctor.id, ...formFinalData(finalData) });
    props.setStep(1);
  };

  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );

  return (
    <fieldset>
      <div className="form-card">
        <div className="form-group">
          <label className="fieldlabels">Working Days</label>
          <div className="row">
            <div className="col-lg-9">
              <div className="edit-con-box">
                <Select
                  options={workingDaysOptions}
                  getOptionLabel={getOptionLabel}
                  value={finalData.workingDays}
                  onChange={(e) => handleChange("workingDays", e)}
                  isMulti={true}
                  isSearchable={true}
                  className="profile-form-select-search" // Add a custom class name for styling
                  classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Monday 10am - 7pm
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="fieldlabels">Languages Spoken</label>
          <div className="row">
            <div className="col-lg-9">
              <Select
                options={languageOptions}
                getOptionLabel={getOptionLabel}
                value={finalData.languages}
                onChange={(e) => handleChange("languages", e)}
                isMulti={true}
                isSearchable={true}
                className="profile-form-select-search" // Add a custom class name for styling
                classNamePrefix="profile-form-select-search" // Add a custom class prefix for styling
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                English / Arabic
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="fieldlabels">Short Bio</label>
          <div className="row">
            <div className="col-lg-9">
              <textarea
                className="form-control text-left"
                rows="5"
                required
                value={finalData.shortBio}
                onChange={(e) => handleChange("shortBio", e.target.value)}
              >
                Plastic Surgeon with more than 24 years of experience and
                specialized in both Surgical and Non surgical treatments.
              </textarea>
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Senior Dermatologist
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="fieldlabels">Awards</label>
          <div className="row">
            <div className="col-11 col-lg-9">
              {handleFieldContent(
                showAwards,
                setShowAwards,
                finalData,
                handleChangeArray,
                "awards"
              )}
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Acheivement Awards
                <br />
                Plastic Surgery (20221-2022)
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="fieldlabels">Membership</label>
          <div className="row">
            <div className="col-11 col-lg-9">
              {handleFieldContent(
                showMembership,
                setShowMembership,
                finalData,
                handleChangeArray,
                "membership"
              )}
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Life Member association of Plastic surgery
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="fieldlabels">Publications</label>
          <div className="row">
            <div className="col-11 col-lg-9">
              {handleFieldContent(
                showPublications,
                setShowPublications,
                finalData,
                handleChangeArray,
                "publications"
              )}
            </div>
            <div className="col-lg-3 m-auto">
              <span className="d-inline-block ms-0 ms-lg-4 ps-lg-1 ps-0">
                Acheivement Awards
                <br />
                Plastic Surgery (20221-2022)
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row unlink-btn-block">
            <div className="col-lg-9">
              <div className="unlink-btn-box d-flex align-items-center justify-content-between">
                <label className="fieldlabels">
                  Your profile is not linked with healthcare facility dashboard
                </label>
                <button
                  style={{ height: "2.2rem", marginTop: "4px" }}
                  className="py-1 px-4 fs-6 primary__btn4"
                >
                  Unlink
                </button>
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                onClick={() => setShowHelpModal(true)}
                className="border-0 rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  background: "lightGrey",
                  width: "2.5rem",
                  height: "3em",
                  minHeight: "3em",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamation}
                  className="text-white ps-2"
                  style={{ fontSize: "17px" }}
                />
              </button>
              <HelpModal
                showModal={showHelpModal}
                setShowModal={setShowHelpModal}
              />
            </div>
          </div>
        </div>
      </div>
      <button onClick={handleSubmit} className="submit-btn-final action-button">
        Submit <i className="fa-light fa-arrow-right-long"></i>
      </button>
      <button
        onClick={() => props.setStep(2)}
        type="button"
        name="previous"
        className="previous action-button-previous"
      >
        <FontAwesomeIcon icon={faArrowLeftLong} /> Previous
      </button>
    </fieldset>
  );
};
