import "../../../../css/main.css";
import "../../../../css/media.css";
import img1 from "../../../../images/before-after-detail/Before after Face_0001.jpg";
import img2 from "../../../../images/before-after-detail/Before after Face_0002.jpg";
import img3 from "../../../../images/before-after-detail/Before after Face_0003.jpg";
import img4 from "../../../../images/before-after-detail/Before after Face_0004.jpg";
import doctorImg from "../../../../images/arab F-3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarHalfAlt } from "@fortawesome/free-regular-svg-icons";
import { faShareNodes, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { AddToFav } from "../../../../shared/AddToComps/addToFav";
import { IsHelpfull } from "../../../../shared/AddToComps/isHelpfull";
import { useState } from "react";
import { ShareDropdown } from "../../face-liposuction-reviews-detail/components/subcomponents/shareDropdown";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const DetailsContent = (props) => {
  const matches = useMediaQuery("(max-width:575px)");
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="before-after-phases mt-0">
      <div className="before-after-phase-head">
        <div className="row w-100 g-0">
          <div className="col-lg-9">
            <h4>45 - 54 year old woman treated with Botox</h4>
          </div>
          <div className="col-lg-3">
            <div className="worthit-box d-flex align-items-center gap-4 position-relative">
              <AddToFav />
              <a href className="primary__btn3 cursor-pointer">
                Worth It
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div className="before-after-img-box">
            <div className="before-after-detail-box">
              <span className="image1">
                <img src={img1} className="img-fluid" alt="" />
              </span>
              <span className="image1">
                <img src={img2} className="img-fluid" alt="" />
              </span>
              <span className="image1">
                <img src={img3} className="img-fluid" alt="" />
              </span>
              <span className="image1">
                <img src={img4} className="img-fluid" alt="" />
              </span>
              <div className="loupe"></div>
            </div>

            <div className="detail-before-after d-flex flex-column flex-sm-row align-items-sm-center gap-3">
              <IsHelpfull label="Helpful" />
              <p>2638 people find it helpful</p>
              <div className="mx-2 mx-sm-3 position-relative">
                <button
                  onClick={() => setShowShareDropdown(!showShareDropdown)}
                  className="d-flex align-items-center justify-content-center border-0 bg-transparent gap-2"
                >
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    className="mx-auto rose-text"
                  />
                  Share
                </button>
                {showShareDropdown && (
                  <ShareDropdown
                    position={`mt-1 ${matches ? "start-0" : "end-0"}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="doctors-listing-box order-resp-second">
            <div className="row">
              <div className="col-md-12">
                <div className="doctor-img-list text-center">
                  <span>
                    <img
                      src={doctorImg}
                      className="img-fluid doctor-img1 mx-auto"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="doctor-details">
                  <h5>Dr. Lauren Clinton</h5>
                  <div className="detail-box">
                    <div className="rating">
                      <div className="">
                        <FontAwesomeIcon icon={props.solidStar} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={props.solidStar} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={props.solidStar} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                      </div>
                      <div className="">
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                      </div>
                    </div>
                    <span className="number">(1,378)</span>
                    <a
                      href="/badge"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/badge");
                      }}
                      type="button"
                      data-toggle="tooltip"
                      title="Hooray!"
                    >
                      <FontAwesomeIcon icon={faTrophy} />
                    </a>
                  </div>
                  <a
                    href="/book-appoinment"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/book-appointment");
                    }}
                    className="primary__btn4"
                  >
                    Book an Appointment
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="procedure-detail order-resp-first">
            <h5>PROCEDURE</h5>
            <p className="p-head-size-17">
              Blepharoplasty, Eyelid Surgery, LOWER BLEPHAROPLASTY, Excessive
              Eyelid Skin, Aging Face, Facial Aging, Droopy Eyelid, Loose Skin
            </p>
            <p className="p-head-size-17">
              During the years our nose is also aging. Mostly the cartilage part
              growing, therefore to get a younger and fresh look, the dorsal
              hight needed to be reduced and also tip reduction with elevation.
              In our clinic we also in the same time we correct all nasal airway
              problems too, like septum deviation and turbinate size reduction.
            </p>
          </div>
          <div className="patient-details">
            <h5>Working Hours</h5>
            <ul>
              <li>
                Gender: <span>Female</span>&nbsp;&nbsp;&nbsp; Age:{" "}
                <span>30-39</span>{" "}
              </li>
              <li>
                Ethnicity: <span>African-American</span>{" "}
              </li>
              <li>
                Height: <span>5’ 0’’ - 5’ 5’’</span>&nbsp;&nbsp;&nbsp; Weight:{" "}
                <span> 200 - 250 lbs</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="button-flex justify-content-center">
          <a href="/before-after-detail" className="primary__btn5">
            See More Before & After Photos
          </a>
        </div>
      </div>
    </div>
  );
};
