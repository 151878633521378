import { supportTabsData } from "../../../demoData/tabsData";
import { TabsGroup } from "../../../shared/tabsGroup";
import { SupportHeader } from "./components/supportHeader";
import { TicketStatusContent } from "./components/ticketStatusComponents/ticketStatusContent";

export const TicketStatus = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SupportHeader title="Ticket Status" />
        <TabsGroup tabs={supportTabsData()} />
        <TicketStatusContent />
      </div>
    </section>
  );
};
