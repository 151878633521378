/* eslint-disable no-undef */
import "../../../css/main.css";
import "../../../css/media.css";

import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronDown,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { VirtualSelect } from "./subcomponents/virtualSelect/virtualSelect";
import { useMediaQuery } from "@mui/material";
import { RatingDropdown } from "../../../shared/dropdowns/ratingDropdown";
import {
  availabilityOptions,
  experienceOptions,
  genderOptions,
} from "../../../demoData/selectOptionsData";
import { handleAnyChange, isFilter } from "../../../services/doctorsFilter/doctorFilterService";
import { useGetSearchedDoctors } from "../../../Store/Doctor/doctorHooks";
import { baseUrl } from "../../../services/config/config";
import { useFilters } from "../../../services/doctorsFilter/doctorFilterProvider";

export const FiltersBanner = () => {
  const matches = useMediaQuery("(max-width:992px)");
  const { doctorFilters, setDoctorFilters } = useFilters();
  const getSearchedDoctors = useGetSearchedDoctors();

  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );

  const handleChange = (key, val) => {
    handleAnyChange(doctorFilters, setDoctorFilters, key, val);
  };

  return (
    <div className={`filter-list resp-hidden-box ${matches ? "d-none" : ""}`}>
      <form action="">
        <div className="filter-box">
          <span className="close-list-modal resp-block-box">
            <i className="fa-light fa-chevrons-left"></i> Close
          </span>
          <div className="form-group">
            <a
              href
              style={{ cursor: "pointer", color:"white", backgroundColor: (!!doctorFilters.length) ? "" : "#e78a82" }}
              onClick={(e) => {
                e.preventDefault();
                getSearchedDoctors(`${baseUrl}/doctors`);
                setDoctorFilters([]);
              }}
            >
              All
            </a>
          </div>
          <div className="form-group">
            <Select
              options={availabilityOptions}
              getOptionLabel={getOptionLabel}
              defaultValue={availabilityOptions[0]}
              isSearchable={false}
              className="custom-select" // Add a custom class name for styling
              classNamePrefix="custom-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="form-group rounded-pill" style={{backgroundColor: isFilter(doctorFilters, "gender") ? "#e78a82" : ""}}>
            <Select
              options={genderOptions}
              defaultValue={genderOptions[0]}
              getOptionLabel={getOptionLabel}
              onChange={(val) => handleChange("gender", val.value)}
              isSearchable={false}
              className="custom-select" // Add a custom class name for styling
              classNamePrefix="custom-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="form-group rounded-pill" style={{backgroundColor: isFilter(doctorFilters, "totalExperienceYears") ? "#e78a82" : ""}}>
            <Select
              options={experienceOptions}
              defaultValue={experienceOptions[0]}
              getOptionLabel={getOptionLabel}
              onChange={(val) =>
                handleChange("totalExperienceYears", val.value)
              }
              isSearchable={false}
              className="custom-select" // Add a custom class name for styling
              classNamePrefix="custom-select" // Add a custom class prefix for styling
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="form-group position-relative">
            <button
              className="rating_btn d-flex align-items-center gap-5 justify-content-between"
              type="button"
              onClick={() => setShowRatingDropdown(!showRatingDropdown)}
            >
              <></>
              Rating
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ fontSize: "12px" }}
              />
            </button>
            {showRatingDropdown && <RatingDropdown faSolidStar={faStar} />}
          </div>
          <div className="form-group">
            <VirtualSelect />
          </div>
        </div>
      </form>
    </div>
  );
};
