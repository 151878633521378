import { Footer } from "../../shared/footer/footer";
import { Header } from "../../shared/header";
import { DoctorDetailsHeader } from "./components/doctorDetailsHeader";
import "../../css/main.css";
import "../../css/media.css";
import { DoctorProfileDetails } from "./components/doctorProfileDetails";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { TabsBanner } from "./components/tabsBanner";
import { RequestCallback } from "../../shared/requestCallback";
import { useMediaQuery } from "@mui/material";
import { CommentBox } from "./components/videos/commentBox";
import { ArticlDetailsHeading } from "./components/articleDetails/articlDetailsHeading";
import { ArticleDetailsCarousel } from "./components/articleDetails/articleDetailsCarousel";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Loading } from "../../shared/loadings/Loading";
import { useGetDoctorByIdForPublic } from "../../Store/Doctor/doctorHooks";

export const ArticleDetiails = () => {
  const matches = useMediaQuery("(min-width:992px)");
  const params = useParams();
  const getDoctorByIdForPublic = useGetDoctorByIdForPublic();
  useEffect(() => {
    if (params.id) {
      getDoctorByIdForPublic(params.id);
    }
  }, [getDoctorByIdForPublic, params.id]);
  return (
    <div>
      <Loading duration={500} />
      <Header />
      <DoctorDetailsHeader />
      <div className="doctor-detailed-wrapper">
        <DoctorProfileDetails solidStar={faStar} />
        <section className="doctor-detailed-section">
          <TabsBanner id={params.id} />
          <div className="container-fluid">
            <div className="tab-head-block pt-5">
              <ArticlDetailsHeading />
              <ArticleDetailsCarousel />
            </div>
          </div>
          <CommentBox />
        </section>
      </div>
      <div
        style={{ marginBottom: matches ? "-3rem" : "" }}
        className={`container-fluid pb-1 mt-5`}
      >
        <h4>Contact Dr. Lauren Clinton</h4>
      </div>
      <RequestCallback />
      <Footer />
    </div>
  );
};
