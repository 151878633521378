import { useAppAction, useAppSelector } from "../../services/hooks/hooks";
import {
  createOffer,
  getAllOffers,
  getOffersByDoctor,
  getOffersByDoctorAndStatus,
} from "./offerAction";

export const useCreateOffer = () => useAppAction(createOffer);
export const useGetOffersByDoctor = () => useAppAction(getOffersByDoctor);
export const useOfferState = () => useAppSelector((state) => state.offer);
export const useGetOffersByDoctorAndStatus = () =>
  useAppAction(getOffersByDoctorAndStatus);
export const useGetAllOffers = () => useAppAction(getAllOffers)