import { useState } from "react";
import "../../../css/main.css";
import "../../../css/media.css";
import { SignInImagesCarousel } from "./signInImagesCarousel";
import { ForgetPasswordModal } from "./forgetPasswordModal";
import { useNavigate } from "react-router-dom";
import { useLoginAction } from "../../../Store/Auth/authHooks";

const initialData = {
  email: "",
  password: "",
};
export const SignInform = () => {
  const [showForgetModal, setShowForgetModal] = useState(false);
  const [finalData, setFinalData] = useState(initialData);
  const login = useLoginAction();
  const handleChange = (key, val) => {
    finalData[key] = val;
    setFinalData(finalData);
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    login({ identifier: finalData.email, password: finalData.password });
  };
  return (
    <section className="doctor-registration-section tab-second px-2">
      <div className="container-fluid">
        <div className="tab_container pe-0">
          <div className="doctor-registration-first-block common-pad p-0">
            <div className="row justify-content-start">
              <div className="col-lg-5">
                <h4 className="resp-none-box">
                  Patients are looking for doctors like you
                </h4>
                <p className="resp-none-box">
                  Millions of patients are looking for the right doctor on
                  Practo. Start your digital journey with Practo ProfileLet’s
                  take the first step and create your account.
                </p>
                <form action="" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label for="">E-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your E-mail"
                      defaultValue={finalData.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter your Password"
                      defaultValue={finalData.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                    />
                  </div>
                  <input
                    type="submit"
                    className="primary__btn3"
                    value="Sign in"
                  />
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <small>
                      Don't have an account yet{" "}
                      <button
                        onClick={() => navigate("/sign-up-users")}
                        className="bg-transparent border-0 outline-none shadow-none mx-1"
                        style={{ color: "#e78a82" }}
                      >
                        Register
                      </button>
                      &nbsp; here.
                    </small>
                    <button
                      type="button"
                      className="forgot-password"
                      onClick={() => setShowForgetModal(true)}
                    >
                      Forgot password
                    </button>
                    <ForgetPasswordModal
                      showModal={showForgetModal}
                      setShowModal={setShowForgetModal}
                    />
                  </div>
                </form>
              </div>
              <div className="col-lg-7 resp-hidden-box">
                <div className="sign-inbox-right">
                  <SignInImagesCarousel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
