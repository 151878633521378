import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import "../../../css/main.css";
import "../../../css/media.css";
import { useNavigate } from "react-router-dom";

export const BotoxTreatmentModal = (props) => {
  const matches = useMediaQuery("(max-width:550px)");
  const navigate = useNavigate();
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "24rem"}
      closeOnDocumentClick={true}
    >
      <div className="p-0 favouriteModal">
        <div className="modal-content pb-5 px-2">
          <div
            className="modal-header border-0 d-flex justify-content-end"
            style={{ marginRight: "-12px", marginTop: "-8px" }}
          >
            <button
              onClick={handleClose}
              style={{
                background: "rgb(161 161 170)",
                width: "30px",
                height: "30px",
              }}
              className="text-white rounded-circle d-flex align-items-center justify-content-center border-0"
            >
              <FontAwesomeIcon icon={faClose} className="ms-2" />
            </button>
          </div>
          <div className="modal-body">
            <span className="folder-ico flex justify-center">
              <FontAwesomeIcon icon={faUserDoctor} className="pl-1" />
            </span>
            <h6
              className="text-center text-2xl"
              style={{ color: "#fdb8b6", fontFamily: "inherit" }}
            >
              Best Doctors for Botox treatment
            </h6>
            <form action=""></form>
          </div>
          <div className="modal-footer border-0">
            <small className="text-center text-zinc-700">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate vehicula
            </small>
            <div className="button-flex justify-content-center">
              <a
                href="/find-doctor-botox-doctors"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/find-doctor-botox-doctors");
                }}
                className="primary__btn3"
              >
                Browse Doctor
              </a>
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
