import "../../../css/main.css";
import "../../../css/media.css";
import { HealthProfileHeader } from "../components/profileComponents/healthProfileHeader";
import { HealthCareWalletTabs } from "./components/healthCareWalletTabs";
import { HealthStatusTable } from "./components/healthCareStatusComponents/healthStatusTable";

export const HealthCreWalletStatus = () => {
  return (
    <div>
      <HealthProfileHeader title="Orders History (19)" page="Doctors List" />
      <HealthCareWalletTabs />
      <HealthStatusTable />
    </div>
  );
};
