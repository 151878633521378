import { useMediaQuery } from "@mui/material";
import { ModalPopup } from "../../../../shared/Modals/ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const DoneModal = (props) => {
  const matches = useMediaQuery("(max-width:500px)");

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <ModalPopup
      show={props.showModal}
      setShow={props.setShowModal}
      handleClose={handleClose}
      width={matches ? "95%" : "32rem"}
      closeOnDocumentClick={true}
    >
      <div className="bg-white rounded-3 modal-common-head px-3 pb-4 pt-5">
        <div className="modal-body">
          <div className="forgot-password-inner">
            <span className="icon-box--modal">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <h5 className="mt-3 head-title">Congratulations! !</h5>
            <p>Your account has been succesfully created.</p>

            <button
              type="button"
              className="primary__btn3"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};
