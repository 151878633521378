import { doctorProfileFormInitialData } from "../../services/doctorProfileService";
import {
  DOCTOR_PROFILE_FORM_DATA,
  ALL_DOCTORS,
  DOCTOR_BY_ID,
} from "./actionTypes";

const initialData = {
  formData: doctorProfileFormInitialData,
  list: [],
  docById: {},
};
export function DoctorReducer(state = initialData, action) {
  switch (action.type) {
    case DOCTOR_PROFILE_FORM_DATA: {
      const data = action.payload;
      return {
        ...state,
        formData: data,
      };
    }
    case ALL_DOCTORS: {
      const data = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    case DOCTOR_BY_ID: {
      const data = action.payload;
      return {
        ...state,
        docById: data,
      };
    }
    default:
      return state;
  }
}
