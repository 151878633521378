import "../../../css/aos.css";
import "../../../css/main.css";
import "../../../css/media.css";
import procedureBlack1 from "../../../images/Procedures/procedure-black1.jpg";
import procedureWhite1 from "../../../images/Procedures/procedure-white1.jpg";
import procedureBlack2 from "../../../images/Procedures/procedure-black2.jpg";
import procedureColor2 from "../../../images/Procedures/procedure-color2.jpg";
import procedureBlack3 from "../../../images/Procedures/procedure-black3.jpg";
import procedureWhite3 from "../../../images/Procedures/procedure-white3.jpg";
import procedureBlack4 from "../../../images/Procedures/procedure-black4.jpg";
import procedureWhite4 from "../../../images/Procedures/procedure-white4.jpg";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
  },
};

export const PopularProcedure = () => {
    const matches2 = useMediaQuery('(min-width:992px)');
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <section className="popular-procedure p-0">
      <h2 className="text-5xl pt-5">Most Popular Procedures</h2>
      <div className="container-fluid">
        <Carousel
          responsive={responsive}
          arrows={matches2? false : true}
          showDots={false}
          infinite={true}
          autoPlay={false}
          customLeftArrow={
            <button className={`rounded-full h-12 w-12 text-xs absolute text-black ${matches2? "-bottom-10" :"bottom-0"} z-20 leftArrow customArrows`}>
              <i className="fa fa-chevron-left text-base" aria-hidden="true"></i>
            </button>
          }
          customRightArrow={
            <button className={`rounded-full h-12 w-12 text-xs absolute z-20 text-black  ${matches2? "-bottom-10" :"bottom-0"} rightArrow customArrows`}>
              <i className="fa fa-chevron-right text-base" aria-hidden="true"></i>
            </button>
          }
        >
          <div className={`item w-full h-full ${matches2 ? "pb-0" : "pb-14"} px-3`}>
            <a href="/">
              <div
                className="popular-procedure-block hover-box"
                data-aos="flip-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <span className="before-hover-grey">
                  <img src={procedureBlack1} className="img-fluid" alt="" />
                </span>
                <span className="after-hover-color">
                  <img src={procedureWhite1} className="img-fluid" alt="" />
                </span>
                <div className="overlay-text">
                  <span>Breast</span>
                </div>
              </div>
            </a>
          </div>
          <div className={`item w-full h-full ${matches2 ? "pb-0" : "pb-14"} px-3`}>
            <a href="/">
              <div
                className="popular-procedure-block hover-box"
                data-aos="flip-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <span className="before-hover-grey">
                  <img src={procedureBlack2} className="img-fluid" alt="" />
                </span>
                <span className="after-hover-color">
                  <img src={procedureColor2} className="img-fluid" alt="" />
                </span>
                <span className="overlay-text"> Injectables </span>
              </div>
            </a>
          </div>
          <div className={`item w-full h-full ${matches2 ? "pb-0" : "pb-14"} px-3`}>
            <a href="/">
              <div
                className="popular-procedure-block hover-box"
                data-aos="flip-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <span className="before-hover-grey">
                  <img src={procedureBlack3} className="img-fluid" alt="" />
                </span>
                <span className="after-hover-color">
                  <img src={procedureWhite3} className="img-fluid" alt="" />
                </span>
                <span className="overlay-text"> Face </span>
              </div>
            </a>
          </div>
          <div className={`item w-full h-full ${matches2 ? "pb-0" : "pb-14"} px-3`}>
            <a href="/">
              <div
                className="popular-procedure-block hover-box"
                data-aos="flip-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <span className="before-hover-grey">
                  <img src={procedureBlack4} className="img-fluid" alt="" />
                </span>
                <span className="after-hover-color">
                  <img src={procedureWhite4} className="img-fluid" alt="" />
                </span>
                <span className="overlay-text"> Reconstruction </span>
              </div>
            </a>
          </div>
        </Carousel>
      </div>
    </section>
  );
};
