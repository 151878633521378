import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { beforeAfterHeaderSliderData } from "../../../demoData/beforeAfterPageData";
import "../../../css/main.css";
import "../../../css/media.css";

export const FacesModal = (props) => {
  return (
    <div className="fixed-modal">
      <section className="before-after-banner pl-5">
        <span
        style={{width: "2.5rem", height: "2.5rem"}}
          className="close-mega-menu rounded-circle d-flex align-items-center justify-content-center p-0"
          onClick={() => props.setShowModal(false)}
        >
          <FontAwesomeIcon icon={faClose} style={{fontSize: "20px"}}  />
        </span>
        <div className="scrollbar mt-4" id="style-3">
          <div className="force-overflow">
            <ul className="common-pad">
              {beforeAfterHeaderSliderData.map((data, ind) => (
                <li key={ind + 1}>
                  <span
                    className={`hover__box ${
                      data.title === "face" ? "hover__box-active" : ""
                    }`}
                  >
                    <img src={data.img} className="img-fluid" alt="" />
                  </span>
                  <h6 className="text-zinc-400 text-sm">{data.title}</h6>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};
