import { faStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "../../css/main.css";
import "../../css/media.css";

export const RatingDropdown = (props) => {
  const [selected, setSelected] = useState(5);
  const dropOptions = [5, 4, 3, 2, 1];
  const dropOptions2 = [1, 2, 3, 4, 5];

  const handleChange = (val) => {
    setSelected(val);
  };
  return (
    <div
      className="bg-white rounded-2 px-3 py-3 d-flex flex-column gap-2 position-absolute top-100 mt-1 start-0 end-0 border shadow-2"
      style={{ width: "max-content", zIndex: "500" }}
    >
      {dropOptions.map((data, ind) => (
        <label className="d-flex align-items-center gap-2" key={ind + 1}>
          <input
            type="radio"
            value={selected}
            checked={selected === data}
            style={{ width: "15px", height: "15px" }}
            onChange={() => handleChange(data)}
          />
          <span
            style={{ width: "14px" }}
            className={`${
              selected === data ? "active_rating_icon" : "disable_rating_icon"
            }`}
          >
            {data}
          </span>
          <div className="d-flex align-items-center gap-1">
            {dropOptions2.map((d, i) =>
              data < d ? (
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ width: "16px" }}
                  className={`${
                    selected === data ? "active_rating_icon" : "rating_icon"
                  }`}
                  key={i + 1}
                />
              ) : (
                <FontAwesomeIcon
                  icon={props.faSolidStar}
                  style={{ width: "16px" }}
                  className={`${
                    selected === data ? "active_rating_icon" : "rating_icon"
                  }`}
                  key={i + 1}
                />
              )
            )}
          </div>
        </label>
      ))}
    </div>
  );
};
