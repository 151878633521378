import { QAHeader } from "./components/qaHeader";
import { TabsGroup } from "../../../shared/tabsGroup";
import { QATabs, QATabsForDoctor } from "../../../demoData/tabsData";
import { AnswersList } from "./components/answersQAComponents/answersList";
import { Loading } from "../../../shared/loadings/Loading";
import { useEffect, useState } from "react";
import { currentDoctorId, currentPatientId } from "src/Store/selectors/UserSelectors";
import { getAllQAByDoctor, getAllQAByPatient } from "src/Store/qa/qaAction";
import { isDoctor } from "src/Store/selectors/isDoctor";

export const AnswersQA = () => {
  const [qas, setQA] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    if(currentDoctorId()){
        res = await getAllQAByDoctor()
    }
    if(currentPatientId()){
      res = await getAllQAByPatient()
    }
    setQA(res)
  }

  // const handleUpdate= (data) => {
  //   if(data.read){
  //     updateQa({ id: data.id, read: false }, "Question marked as unread!", getData)
  //   }
  //   else{
  //   updateQa({ id: data.id, read: true }, "Question marked as read!", getData)
  //   }
  // }
  return (
    <section className="doctor-detail-stories-tab">
      <Loading duration={500} />
      <div className="container-fluid p-0">
        <QAHeader title="Answers (19)" />
        <TabsGroup tabs={isDoctor() ? QATabsForDoctor() : QATabs()} />
        <AnswersList list={qas} getData={getData} />
      </div>
    </section>
  );
};
