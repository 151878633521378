import "../../../css/main.css";
import "../../../css/media.css";
import "../../../css/countrySelect.css";
import { useBodyPartState } from "../../../Store/bodyPart/bodyPartHook";

export const Banner = (props) => {
  const bodyPart = useBodyPartState();

  return (
    <div
      className="px-0 overflow-tab-scroll w-100"
      style={{ marginTop: "-2px", overflowY: "hidden", height: "2rem" }}
    >
        <ul className="tabs justify-content-start mx-auto overflow-hidden px-0" style={{width: "max-content"}}>
          {bodyPart.list.map((d, ind) => (
            <li
              className={props.bodyTab === d.name ? "active" : ""}
              key={ind + 1}
              onClick={() => props.setBodyTab(d.name)}
            >
              <a href>{d.name}</a>
            </li>
          ))}
        </ul>
    </div>
  );
};
