import { SupportHeader } from "./components/supportHeader";
import { RaiseTicketContent } from "./components/raiseTicketComponents/raiseTicketContent";
import { TabsGroup } from "../../../shared/tabsGroup";
import { supportTabsData } from "../../../demoData/tabsData";

export const RaiseTicket = () => {
  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <SupportHeader title="Help Desk" />
        <TabsGroup tabs={supportTabsData()} />
        <RaiseTicketContent />
      </div>
    </section>
  );
};
