import "../../../css/main.css";
import "../../../css/media.css";
import "../../../css/countrySelect.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { imgBaseUrl } from "../../../services/config/config";
import { useProcedureState } from "../../../Store/procedure/procedureHooks";
import { NoDataComp } from "../../../shared/emptyComponents/noDataComp";

const options1 = [
  { value: "", label: "Search Treatments" },
  { value: "18-22", label: "18-22" },
  { value: "23-28", label: "23-28" },
  { value: "29-45", label: "29-45" },
  { value: "46-65", label: "46-65" },
  { value: "66-80", label: "66-80" },
];

export const TreatmentsList = (props) => {
  const procedure = useProcedureState();
  const getOptionLabel = (option) => (
    <div className="d-flex align-items-center gap-3 justify-content-between">
      <span className="fs-6 fs-xl-5">{option.label}</span>
      <FontAwesomeIcon icon={faCheck} className="check_icon" />
    </div>
  );
  return (
    <div className="tab_container">
      <div id="tab1" className="block px-4 py-4">
        <div className="tab-head-block">
          <div className="row justify-content-center">
            <div className="col-lg-8 filter-list-by-name">
              <h3 className="m-0">{props.bodyTab} Treatments</h3>
              <p className="resp-none p-head-size pe-xl-5">
                Our surgical treatments for the face area range from facelifts
                and rhinoplasties, to blepharoplasties, neck lifts and ear
                reshaping and repair.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="search-filter">
                <Select
                  options={options1}
                  getOptionLabel={getOptionLabel}
                  defaultValue={options1[0]}
                  isSearchable={true}
                  className="custom-search-select" // Add a custom class name for styling
                  classNamePrefix="custom-search-select" // Add a custom class prefix for styling
                  components={{
                    DropdownIndicator: false,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {procedure.list && procedure.list.length > 0 ? (
          <div className="procedure-list">
            <div className="row">
              {procedure.list.map((data, ind) => (
                <div className="col-lg-4 col-md-6" key={ind + 1}>
                  <div className="procedure-box-overlay">
                    <div className="procedure-box">
                      <span>
                        <img
                          src={`${imgBaseUrl + data.image}`}
                          className="img-fluid rounded-2 mx-auto border"
                          style={{width: "14em", height: "6rem", objectFit: "cover"}}
                          alt=""
                        />
                      </span>
                      <span className="procedure-detail-box">
                        <h5>{data.name}</h5>
                        <p className="four-lines-elipsis">{data.description}</p>
                      </span>
                    </div>
                    {props.tab === "Surgical" && (
                      <button className="view-more-overlay">
                        <span>View more</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <NoDataComp
            title="No Data!"
            desc="There is no any data yet."
            className="mt-5"
          />
        )}
      </div>
    </div>
  );
};
