import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Navigate, Outlet } from "react-router-dom";
import { Header } from "../../header";
import { FixedFooterNav } from "../../../Pages/dashboard/subComponents/fixedFooterNav";
import { Footer } from "../../footer/footer";
import { Sidebar } from "./sidebar";
import { isPatient } from "../../../Store/selectors/isPatient";

export const UserDashboardLayout = () => {
  if (!isPatient()) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <div>
      <Header />
      <section className="wrapper-dashboard">
        <div className="container-fluid">
          <span className="menu-click-sidebar">
            <FontAwesomeIcon icon={faBars} />
          </span>
          <div className="row">
            <Sidebar />
            <div className="col-lg-9">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
      <FixedFooterNav />
      <Footer />
    </div>
  );
};
