import { ReviewsHeader } from "./components/reviewsHeader";
import { TabsGroup } from "../../../shared/tabsGroup";
import { reviewTabsData } from "../../../demoData/tabsData";
import { useEffect, useState } from "react";
import { getAllReviewsByDoctor, updateReview } from "src/Store/review/reviewAction";
import { UserReviewsList } from "src/Pages/userDashboard/userReviews/components/userReviewsList";

export const NewReviews = () => {
 
  const [reviewsList, setReeviewsList] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    let res = [];
    res = await getAllReviewsByDoctor()
    setReeviewsList(res)
  }
  const handleUpdate= (data) => {
    if(data.read){
      updateReview({ id: data.id, read: false }, "Review marked as unread successfully!", getData)
    }
    else{
      updateReview({ id: data.id, read: true }, "Review marked as read successfully!", getData)
    }
  }

  return (
    <section class="doctor-detail-stories-tab">
      <div class="container-fluid p-0">
        <ReviewsHeader title="My Reviews (19)" />
        <TabsGroup tabs={reviewTabsData()} />
        <UserReviewsList data={reviewsList} handleUpdate={handleUpdate} />
      </div>
    </section>
  );
};
